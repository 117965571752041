import React, { useState, useEffect } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { Box, Card, Flex, Text, MdIcon, SwitchButton } from '@workshop/ui';

import { commonUtils, hooks, getParamFromUrl } from 'utils';

import {
  ISessionFormat,
  SESSION_FORMAT,
  SESSION_TYPE,
} from 'constants/courses';

import {
  stepQuestionActions,
  stepPromptActions,
  sessionActions,
  stepActions,
  videoClipActions,
} from 'redux/actions/cms';
import {
  getStepsForSession,
  getVideoClipsForSession,
  getVideoClipsForStep,
  getCategoryOptions,
} from 'redux/selectors';
import { useHasPermission } from 'redux/selectors/organisation';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import {
  formattedRequirementData,
  groupByStepType,
  DraggableStep,
  getExpandedVideoClipIds,
  formatVideoClipData,
  DraggableClip,
  generatePlayerSteps,
  generatePlayerChecklist,
} from 'screens/cms/SessionEdit/src/dataUtils';

import {
  SectionTitle,
  FixedFooter,
  InformationCard,
  InPageNav,
} from 'components/Common';
import { IDraggableData } from 'components/Draggable';
import { OverviewCard, OverviewInput } from 'components/OverviewCard';
import { FurtherDetailsSessionCard } from 'components/FurtherDetailsCard';
import { PromptItem, IAddItem, MCQFormData } from 'components/ListItem';
import { FormCard } from 'components/FormCard';
import { SessionPlayer } from 'components/SessionPlayer';

import { PromptFormData } from 'components/ListItem';

import { GlobalState } from 'types';
import { PERMISSION_SLUGS, CompleteUploadChunkAction } from 'types/common';
import {
  DescriptionSessionFormData,
  ExerciseNoteSessionFormData,
  FurtherDetailsSessionFormData,
  OverviewSessionFormData,
  RequirementSessionFormData,
  StepUpdateFormData,
  ISession,
  IStepListItem,
  IStep,
  VideoClipCreateAction,
  IVideoClip,
} from 'types/cms';

import {
  Requirement,
  StepEdit,
  VideoClipsUpload,
  VideoClipsList,
  VideoClipItem,
} from 'screens/cms/SessionEdit';

// Routing Props
interface MatchParams {
  courseId: string;
  unitId: string;
  sessionId: string;
}

type SessionFormData = FormData | Partial<ISession>;

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const sessionFormatOptions: { [key in Partial<ISessionFormat>]: string } = {
  reflect: 'Reflective',
  longform: 'Extended',
  practice: 'Practice',
  guided: 'Guided',
  research: 'Research (Coming Soon)',
  challenge: 'Challenge (Coming Soon)',
  assessment: 'Assessment (Coming Soon)',
};

const SessionEditScreen: React.FC<Props> = ({
  match: { params },
  questions,
  questionIds,
  session,
  sessionUI,
  categoryOptions,
  steps,
  stepsUI,
  videoClips,
  unassignedVideoClips,
  videoClipUI,
  location,
  history,
}) => {
  const { courseId, unitId, sessionId } = params;

  const currentStepId = getParamFromUrl(location, 'step');

  // Determine whether the user has editing permissions and whether the
  // unit is open for editing
  const hasEditPermissions = useHasPermission(
    PERMISSION_SLUGS.can_edit_content
  );

  const hasSummaryEditPermissions = useHasPermission(
    PERMISSION_SLUGS.can_edit_clip_summaries
  );

  // Constants to define what aspects of the UI should be editable
  const isLockedForEditing = session && session.isLockedForEditing;

  const isEditingDisabled = !hasEditPermissions || isLockedForEditing;

  const isTextEditingDisabled =
    !hasSummaryEditPermissions || isLockedForEditing;

  const [mutliVideoUpload, setMultiVideoUpload] = useState<{
    visible: boolean;
    error: string | null;
  }>({
    visible: false,
    error: null,
  });

  const [uploadingFileNames, setUploadingFileNames] = useState<string[] | null>(
    null
  );
  const [pollingInProgress, setPollingInProgress] = useState(false);
  const [currentView, setCurrentView] = useState('details');
  const [loadingSessionPlayer, setLoadingSessionPlayer] = useState(false);

  const dispatch = useDispatch();
  const methods = useForm();

  // TODO: Temporary - register all step related form data separately
  const {
    handleSubmit: stepHandleSubmit,
    register: stepRegister,
    reset: stepReset,
    formState: stepFormstate,
    errors: stepErrors,
    setValue: stepSetValue,
  } = useForm<StepUpdateFormData>();

  // Temporary - register all video clip related form data separately
  const videoClipMethods = useForm<{ [key: string]: string | FileList }>();
  const {
    handleSubmit: clipHandleSubmit,
    formState: clipFormState,
    errors: clipErrors,
  } = videoClipMethods;

  // Initialise some local state which can be used to control the UI
  // during data update requests to the API
  const [isUpdating, setIsUpdating] = useState({
    overview: false,
    furtherDetails: false,
    description: false,
    requirements: false,
    exercises: false,
  });

  // Control which steps & related clips are displayed
  const [expandedStep, setExpandedStep] = useState<string | null>(null);
  const [expandedClipIds, setExpandedClipIds] = useState<DraggableClip[]>([]);

  // The session format dictates how the `SessionEdit` screen is rendered/what
  // functionality is available within the interface
  const [selectedSessionFormat, setSessionFormat] = useState(
    session?.moduleFormat
  );

  // When a new video is added to a clip, `thumbnailPollState` is used to
  // keep track of how many times we have polled the API for an updated
  // version of the clip until the clip is returned with a thumbnail present.
  //
  // It's also used to store the current thumbnail of an existing clip so that
  // we can detect when thumbnails have changed.
  const [thumbnailPollState, setThumbnailPollState] = useState<{
    [id: string]: {
      pollCount: number;
      thumbnail: string | null;
      complete: boolean;
    };
  }>({});

  /** ------------ DATA LOADING ------------ */
  const { session: sessionLoading, steps: stepsLoading } =
    hooks.useLoadingDataState(
      {
        session: {
          actions: [() => sessionActions.retrieve(parseInt(sessionId))],
        },
        steps: {
          actions: [
            () =>
              stepActions.list(
                parseInt(courseId),
                parseInt(unitId),
                parseInt(sessionId)
              ),
          ],
        },
        videoClips: {
          actions: [
            () =>
              videoClipActions.list(
                parseInt(courseId),
                parseInt(unitId),
                parseInt(sessionId)
              ),
          ],
        },
      },
      [courseId, unitId, sessionId]
    );

  hooks.useLoadingDataState(
    {
      questionsLoading: {
        actions: questionIds.length
          ? [() => stepQuestionActions.list(questionIds)]
          : [],
        startLoading: !Boolean(stepsLoading),
      },
    },
    [...questionIds, stepsLoading]
  );

  useEffect(() => {
    if (currentView !== 'builder') {
      return;
    }

    const loadSteps = async () => {
      setLoadingSessionPlayer(true);
      await Promise.all(
        Object.values(steps).map((step) => loadStepData(`${step.id}`))
      );
      setLoadingSessionPlayer(false);
    };

    loadSteps();
  }, [currentView]);

  // Init our chunk upload generator
  const createChunkUpload = hooks.useChunkUpload(isEditingDisabled);

  // If the session has an intro step then the handling of step
  // labels & indexes changes
  const hasIntroStep = !!Object.values(steps).find(
    (step) => step.stepType === SESSION_TYPE.intro
  );

  // Collect & format the list of step data by step type
  //
  // TODO: Move to state or memoize
  const {
    normal: normalSteps,
    intro: introSteps,
    outro: outroSteps,
  } = groupByStepType(steps, () => {}, hasIntroStep);

  // A session should only have 1 (or none) intro step and 1 (or none)
  // outro step, so extract them
  const [intro] = introSteps;
  const [outro] = outroSteps;

  // Extract the video clips for the selected step. If the
  // video clips for the selected step haven't yet been loaded this
  // will return an empty array.
  //
  // TODO: Move to state or memoize
  const expandedStepData = expandedStep ? steps[expandedStep] : null;
  const expandedStepVideoClipData = expandedStep
    ? getVideoClipsForStep(videoClips, parseInt(expandedStep))
    : {};

  // When a step is expanded, generate an array of objects containing
  // nothing but the clip ID. This data is passed to the Draggable component
  // which displays our list of clips.
  //
  // The Draggable component then uses the array of clip IDs to populate
  // the 'real' data for the clips shown. This is done by using the clip IDs
  // to pull data in from out `expandedStepVideoClipData` variable. Using
  // this method our video clip data is rendered based on this state array
  // which only changes when the remote data (expandedStepVideoClipData)
  // updates. As a result we don't experience any 'flicker' in the video
  // clip data as it gets updated via the API.
  hooks.useDeepEqualEffect(() => {
    const expandedVideoClipIds = expandedStep
      ? getExpandedVideoClipIds(
          expandedStepVideoClipData,
          parseInt(expandedStep)
        )
      : [];

    setExpandedClipIds((prevState) => [
      ...expandedVideoClipIds,
      // Ensure any optimistic/temporary clips remain untouched
      ...prevState.filter((clip) => clip.id.toString().startsWith('clip')),
    ]);
  }, [expandedStep, expandedStepVideoClipData]);

  // Detect whether a thumbnail has been added to our video clips
  // on each poll response. On each poll response, the screen will
  // re-render and our `pollingClipData` will be re-calculated
  // based on the latest response from the API.
  const pollingClipData = Object.keys(thumbnailPollState).map(
    (id) => videoClips[id]
  );
  // TODO: The design of this effect will lead to some potentially
  // nasty race conditions as it could run multiple times in parallel.
  //
  // Ideally the polling would be handled using some sort interval, or
  // controlled way to not allow parallel calls.
  hooks.useDeepEqualEffect(() => {
    // Prevent parallel polling processes from running. Only start polling
    // again if we aren't already currently polling.
    if (pollingInProgress || !pollingClipData.length) return;

    const pollStateValues = Object.values(thumbnailPollState);

    const hasIncompletePolling =
      pollingClipData.length < pollStateValues.length ||
      pollStateValues.find((val) => !val.complete);

    // All thumbnails have been fetched, nothing to do
    if (!hasIncompletePolling) return;

    setPollingInProgress(true);

    // If the `pollingClipData` has changed then we will loop
    // through the clip IDs and send a GET request for each
    // ID if the clip in our state has no thumbnail
    //
    // TODO: Cleanup `thumbnailPollState` if a clip has transitioned
    // from no thumbnail -> thumnail remove if from the array - this
    // already happens to a degree but can it 'error'?
    const newState: typeof thumbnailPollState = {};

    Promise.all(
      pollingClipData
        .filter((a) => a)
        .map(async (clip) => {
          const { id } = clip;
          const pollStateForClip = thumbnailPollState[id.toString()];

          // If the clip has no thumbnail and we've attempted
          // to retrieve the thumbnail less than 10 times
          if (
            clip.videoThumbnail === null &&
            clip.videoThumbnail === pollStateForClip.thumbnail &&
            pollStateForClip.pollCount < 10
          ) {
            await dispatch(videoClipActions.retrieve(id));

            // We build a 'new' copy of the `thumbnailPollState` from scratch
            // so that we can update it in a single state update at the end of
            // the effect.

            // If there are no poll requests made then the `thumbnailPollState`
            // will be reset.
            newState[id.toString()] = {
              pollCount: pollStateForClip.pollCount + 1,
              thumbnail: pollStateForClip.thumbnail,
              complete: false,
            };
          } else {
            newState[id.toString()] = {
              pollCount: pollStateForClip.pollCount,
              thumbnail: clip.videoThumbnail,
              complete: true,
            };
          }
        })
    ).then(() => {
      if (!isEqual(newState, thumbnailPollState)) {
        setThumbnailPollState(newState);
        setPollingInProgress(false);
      }
    });
  }, [pollingClipData, pollingInProgress]);

  const handleSaveOverview = async (data: OverviewSessionFormData) => {
    const formData: SessionFormData = new FormData();
    if (data.landscape) {
      formData.append('image', data.landscape);
    }
    if (data.portrait) {
      formData.append('image_portrait', data.portrait);
    }
    formData.append('title', data.title);

    setIsUpdating({ ...isUpdating, overview: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, overview: false });
  };

  const handleSaveFurtherDetails = async (
    data: FurtherDetailsSessionFormData
  ) => {
    const formData: SessionFormData = {
      moduleFormat: data.moduleFormat,
      duration: data.sessionDuration,
    };
    setIsUpdating({ ...isUpdating, furtherDetails: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, furtherDetails: false });
  };

  const handleSaveDescription = async (data: DescriptionSessionFormData) => {
    const formData: SessionFormData = {
      description: data.description,
    };
    setIsUpdating({ ...isUpdating, description: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, description: false });
  };

  const handleSaveRequirement = async (data: RequirementSessionFormData) => {
    const formData: SessionFormData = {
      // @ts-ignore
      checkList: formattedRequirementData(data, session?.checkList[0]),
    };
    setIsUpdating({ ...isUpdating, requirements: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, requirements: false });
  };

  const handleSaveExercises = async (data: ExerciseNoteSessionFormData) => {
    const formData: SessionFormData = {
      exerciseText: data.exercise,
    };
    setIsUpdating({ ...isUpdating, exercises: true });
    await dispatch(sessionActions.update(parseInt(sessionId), formData));
    setIsUpdating({ ...isUpdating, exercises: false });
  };

  const handleCancel = () => {};

  const handleStepReorder = (data: IDraggableData<DraggableStep>) => {
    data.forEach((item, idx) => {
      if (!item.hasChanged || !steps[item.id]) return;
      // If there is an intro step, offset by 2, not 1. `idx` is 0-based whereas
      // our step indexes are 1-based. Only normal steps can be re-ordered which
      // is why a step with `idx` 0 will either have an index of 1 (no intro step)
      // or an index of 2 (with intro step)
      const offset = hasIntroStep ? 2 : 1;
      dispatch(stepActions.update(parseInt(item.id), { index: idx + offset }));
    });
  };

  const handleClipReorder = (data: IDraggableData<DraggableClip>) => {
    data.forEach((item, idx) => {
      if (!item.hasChanged) return;
      // TODO: Determine if the below is required anymore - video clips should always
      // exist rather than existing as a 'temporary' clip

      // If the id of the item is a number then we know that the clip being re-ordered
      // exists in the backend, so we update the index.
      //
      // If the id of the item is a string then we know the clip doesn't yet exist in
      // the backend and so we create the clip first.
      if (typeof item.id === 'number') {
        dispatch(videoClipActions.update(item.id, { index: idx + 1 }));
      } else {
        if (expandedStep) {
          dispatch(
            videoClipActions.create(
              {
                courseId: parseInt(courseId),
                unitId: parseInt(unitId),
                sessionId: parseInt(sessionId),
                stepId: parseInt(expandedStep),
              },
              { index: idx + 1 }
            )
          );
        }
      }
    });
  };

  const loadStepData = async (id: string) => {
    // Get Step
    await dispatch(stepActions.retrieve(parseInt(id)));
    // Get Video Clip list
    await dispatch(
      videoClipActions.list(
        parseInt(courseId),
        parseInt(unitId),
        parseInt(sessionId),
        parseInt(id)
      )
    );
  };

  const onStepExpanded = async (id: string) => {
    // If the step is already expanded, reset to null to close
    // the expanded step. Otherwise expand the selected step id.
    const isExpanding = expandedStep !== id;
    setExpandedStep(isExpanding ? id : null);
    setExpandedClipIds([]);
    // Only load the data if we're expanding the step
    if (isExpanding) {
      await loadStepData(id);
    }
  };

  const handleAddStep = async (data: IAddItem) => {
    const formData: Partial<IStepListItem> = {
      title: data.inputText,
      stepType: 'normal',
    };

    const newStep = await dispatch(
      stepActions.create(
        parseInt(courseId),
        parseInt(unitId),
        parseInt(sessionId),
        formData
      )
    );

    // TODO: Any ideas on this ts error?
    const newStepId =
      newStep?.payload && 'result' in newStep?.payload
        ? newStep?.payload.result
        : null;

    // Outro index will have incremented after adding
    // a step so fetch it to update UI
    await loadStepData(outro.id);

    if (newStepId) {
      await handleAddClip(newStepId.toString());
    }
  };

  const handleUpdateStep = async (stepId: string, data: StepUpdateFormData) => {
    const formData = {
      title: data.title,
      notes: data.supportingNotes,
    } as const;

    await dispatch(stepActions.update(parseInt(stepId), formData));

    // When updating/saving a step we also loop through the video clip data
    // for that step and determine which video clips need updating or creating.
    //
    // No video related data is updated here - this is handled by our
    // `handleAddVideoToClip` function.
    clipHandleSubmit((data) => {
      // Transform the video clip form data into a format the API will accept
      let clipData = formatVideoClipData(data);
      // Loop through the formatted data
      Object.keys(clipData).forEach(async (id) => {
        // Only update/create a clip if the data has changed. Since 'summary'
        // is the only field which can be updated here, we do a simple equality
        // check between the form data and the existing data in the state.
        if (
          clipData[id].summary &&
          clipData[id].summary !== videoClips[id]?.summary
        ) {
          const clipFormData = { summary: clipData[id].summary };

          // A 'new' video clip will have the format 'clip-{step-id}-{index}'
          // otherwise we know we're updating an existing clip
          if (id.startsWith('clip')) {
            const stepId = id.split('-')[1];
            const index = id.split('-')[2];

            const clipResponse = await dispatch(
              videoClipActions.create(
                {
                  courseId: parseInt(courseId),
                  unitId: parseInt(unitId),
                  sessionId: parseInt(sessionId),
                  stepId: parseInt(stepId),
                },
                { ...clipFormData, index: parseInt(index) + 1 }
              )
            );

            // `clipResponse` will be undefined if the user does not have permission
            // to perform the action.
            if (!clipResponse) return;

            const { payload } = clipResponse;
            // Following the successful creation of the video clip object,
            // replace the optimistic/temporary clip ID from the list of
            // expanded clip IDs with the real one and use the ID returned
            // from the backend to start the thumbnail polling process.
            if (payload && 'result' in payload) {
              const { result } = payload;

              setExpandedClipIds((prevState) => [
                ...prevState.filter((clipId) => clipId.id !== id),
                { id: result },
              ]);
            }
          } else {
            await dispatch(videoClipActions.update(parseInt(id), clipFormData));
          }
        }
      });
    })();
  };

  const handleAddClip = async (stepId?: string) => {
    if (!expandedStep && !stepId) return;

    const clipIndex = stepId
      ? steps[stepId]?.clipCount || 1
      : expandedClipIds.length + 1;

    // Add a clip to the currently expanded step
    await dispatch(
      videoClipActions.create(
        {
          courseId: parseInt(courseId),
          unitId: parseInt(unitId),
          sessionId: parseInt(sessionId),
          stepId: stepId ? parseInt(stepId) : parseInt(expandedStep as string),
        },
        { index: clipIndex }
      )
    );
  };

  const handleAddVideoToClip = async (
    e: React.ChangeEvent<HTMLInputElement>,
    /** The ID of the clip */
    id: string
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];
    const chunkUpload = createChunkUpload(file.name, file.size, { id });

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;

    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalize
    // the upload process
    if (!payload || !('file' in payload)) return;

    const { file: videoFile, filename } = payload;
    // The `file` included in the successful upload response gives us the full
    // path to the uploaded file. We want to add this to our video clip to 'link'
    // the uploaded file to a video clip object.
    await dispatch(
      videoClipActions.update(parseInt(id), {
        video: videoFile,
        originalFilename: filename,
      })
    );
    // Following the successful update of the video clip object,
    // start the thumbnail polling process.
    setThumbnailPollState((prevState) => ({
      ...prevState,
      [id]: {
        pollCount: 0,
        thumbnail: videoClips[id]?.videoThumbnail,
        complete: false,
      },
    }));
  };

  const handleMultipleClipUpload = (files: File[]) => {
    setUploadingFileNames(files.map((f) => f.name));

    const pollState: {
      [key: string]: {
        pollCount: number;
        thumbnail: string | null;
        complete: boolean;
      };
    } = {};

    const uploadPromises = files.map((file) => async () => {
      const chunkUpload = createChunkUpload(file.name, file.size);

      // TODO: Consolidate logic here with `handleAddVideoToClip()` to create
      // shared helper functions where possible
      const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
        file
      );

      // TODO: Handle chunk upload failure

      // If chunked uploads are disabled (e.g. due to permissions) then calling
      // `startUpload` will result in a void response
      if (!response) return;

      const { payload: uploadPayload } = response;

      // Only run the remaining code if the upload was successful. A successful
      // upload will contain the 'file' property which we then use to finalise
      // the upload process
      if (!uploadPayload || !('file' in uploadPayload)) return null;

      const { file: videoFile, filename } = uploadPayload;

      const clipResponse = await dispatch(
        videoClipActions.create(
          {
            courseId: parseInt(courseId),
            unitId: parseInt(unitId),
            sessionId: parseInt(sessionId),
          },
          {
            video: videoFile,
            originalFilename: filename,
          }
        )
      );

      // TODO: Handle clip creation errors
      // `clipResponse` will be undefined if the user does not have permission
      // to perform the action.
      if (!clipResponse || clipResponse?.error) return null;

      const { payload: clipPayload } = clipResponse;

      if (!clipPayload || !('result' in clipPayload)) return null;

      pollState[clipPayload.result.toString()] = {
        pollCount: 0,
        thumbnail: null,
        complete: false,
      };

      return null;
    });

    return commonUtils
      .resolveQueue(uploadPromises)
      .then(() =>
        setThumbnailPollState((prevState) => ({
          ...prevState,
          ...pollState,
        }))
      )
      .then(() => setUploadingFileNames(null));
  };

  const handleDeleteStep = (id: string) => {
    dispatch(stepActions.remove(parseInt(id)));
  };

  const handleDeleteClip = (id: string) => {
    // Remove the clip from the array of expanded clips
    setExpandedClipIds((prevState) =>
      prevState.filter((clip) => clip.id !== id)
    );
    // If the clip exists on the backend, delete it there too.
    //
    // We only submit the deletion request if the id can be
    // parsed to a number. If it can't, then it's likely a temporary
    // ID meaning the video clip does not exist in the backend.
    if (!isNaN(parseInt(id))) {
      dispatch(videoClipActions.remove(parseInt(id)));
    }
  };

  const handleCreateQuestion = async (
    { question, choices, choiceOrder, explanation }: MCQFormData,
    stepId: number
  ) =>
    await dispatch(
      stepQuestionActions.create({
        course: parseInt(courseId),
        unit: parseInt(unitId),
        session: parseInt(sessionId),
        step: stepId,
        data: {
          content: question,
          answers: choices.map(({ text, isCorrect }) => ({
            content: text,
            correct: isCorrect,
          })),
          answerOrder: choiceOrder || ('random' as const),
          explanation,
        },
      })
    );

  const handleUpdateQuestion = async (
    { question, choices, choiceOrder, explanation }: MCQFormData,
    stepId: number,
    questionId: number
  ) =>
    await dispatch(
      stepQuestionActions.update({
        course: parseInt(courseId),
        unit: parseInt(unitId),
        session: parseInt(sessionId),
        step: stepId,
        question: questionId,
        data: {
          content: question,
          answers: choices.map(({ text, isCorrect, id }) =>
            id
              ? {
                  id,
                  content: text,
                  correct: isCorrect,
                }
              : {
                  content: text,
                  correct: isCorrect,
                }
          ),
          answerOrder: choiceOrder || ('random' as const),
          explanation,
        },
      })
    );

  const handleSaveClipList = async (clips: VideoClipItem[]) => {
    // Only process clips which have a summary or a step ID set. If neither
    // of these values are set, then we don't need to make the API call.
    const dirtyClips = clips.filter(
      (clip) => Boolean(clip.stepId) || Boolean(clip.summary)
    );

    /** Compile a list of step ids that will need to be re-fetched */
    const stepIds = dirtyClips
      .map(({ stepId }) => stepId)
      .filter((value, index, self) => value && self.indexOf(value) === index);

    /**
     * Build a mapping of stepId -> array of clip ids
     * (video clipIds that we are about to assign to each step)
     * e.g : {1 : [1, 2, 3], 2: [4, 5] }
     */
    const stepClips: { [key: number]: number[] } = stepIds.reduce(
      (acc, stepId) =>
        stepId
          ? {
              ...acc,
              [stepId]: dirtyClips
                .filter(({ stepId: clipStepId }) => stepId === clipStepId)
                .map((clip) => clip.id),
            }
          : acc,
      {}
    );

    await Promise.all(
      dirtyClips.map(({ id, summary }, idx) => {
        // Always update the clip summary
        let clipData: Partial<IVideoClip> = { summary };

        // Attempt to find the relevant stepId using the stepClips object
        // --> this is the step that this clip will be assigned to
        const stepId = Object.keys(stepClips).find((stepId) =>
          stepClips[parseInt(stepId, 10)].find((clipId) => clipId === id)
        );

        if (stepId) {
          // If we found a stepId, find the current clipCount for that step
          // and use it to set the index for that clip
          const step = steps[stepId];
          const clipStepIdx = stepClips[parseInt(stepId, 10)].findIndex(
            (clipId) => clipId === id
          );
          const index = step.clipCount + clipStepIdx + 1;
          clipData = { ...clipData, step: parseInt(stepId, 10), index };
        }

        return dispatch(videoClipActions.update(id, clipData));
      })
    );

    // So that we have accurate and correct `clipCount` values, reload the
    // steps which have had video clips added to them
    Promise.all(
      stepIds.map((id) =>
        id ? dispatch(stepActions.retrieve(id)) : Promise.resolve(null)
      )
    );
  };

  const handleSavePrompt =
    (step: IStepListItem | undefined) =>
    async ({ label, title, responseType, tip }: PromptFormData) => {
      if (!step || !label || !title || !responseType || !tip) {
        return;
      }

      dispatch(
        stepActions.update(step.id, {
          prompt: { label, title, responseType, tip },
        })
      );
    };

  const handleSaveClipSummary = async (id: string, summary: string) => {
    dispatch(videoClipActions.update(parseInt(id), { summary }));
  };

  const expandedStepQuestions = questions.filter((a) =>
    expandedStepData?.questions.find((id) => a.id === id)
  );

  // Progressive loading state used to only show UI as loading if elements are
  // loading for the first time.
  const isSessionLoading = sessionLoading && !session;
  const isStepListLoading = stepsLoading && !expandedStepData;
  const isClipListLoading =
    videoClipUI.videoClipList.loading && isEmpty(expandedClipIds);

  const pageLoading =
    isSessionLoading || isStepListLoading || isClipListLoading;

  // Session Types
  const isIntroOrOutro = session
    ? session?.moduleType === SESSION_TYPE.intro ||
      session?.moduleType === SESSION_TYPE.outro
    : false;

  // Session Formats

  const isGuided =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.guided;

  const isPractice =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.practice;

  const isLongform =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.longform;

  const isResearch =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.research;

  const isReflective =
    selectedSessionFormat && selectedSessionFormat === SESSION_FORMAT.reflect;

  const introStep = Object.values(steps).find(
    (step) => step.stepType === 'intro'
  );

  const outroStep = Object.values(steps).find(
    (step) => step.stepType === 'outro'
  );
  const playerSteps = generatePlayerSteps({
    session,
    steps,
    questions,
    videoClips,
  });

  const playerChecklist = generatePlayerChecklist(session);

  return (
    <>
      <ScreenWrapper>
        <Flex flexDirection="column" flex={1} mb={24}>
          {/* TODO: Support previews for other session formats */}
          {(isGuided || isReflective) && (
            <InPageNav
              tabs={[
                {
                  slug: 'details',
                  label: 'Detailed View',
                  icon: 'FormatListBulleted',
                },
                {
                  slug: 'builder',
                  label: 'Session Builder',
                  icon: 'PlayCircleOutline',
                },
              ]}
              initialTab="details"
              onSwitchTab={(activeTab) => setCurrentView(activeTab)}
              disabled={pageLoading}
              navByParams
            />
          )}
          {currentView === 'details' && (
            <>
              <Flex
                flexDirection={{ base: 'column', xl: 'row' }}
                mb={{ base: 0, xl: 'defaultMargin' }}
              >
                <Flex
                  flexDirection="column"
                  marginRight="defaultMargin"
                  marginBottom={{ base: 'defaultMargin', xl: 0 }}
                >
                  <SectionTitle title="Session Overview" />
                  <Flex flex={1}>
                    <FormContext {...methods}>
                      <OverviewCard
                        onSave={handleSaveOverview}
                        onCancel={handleCancel}
                        landscape={session?.image}
                        portrait={session?.imagePortrait}
                        title={session?.title}
                        isDisabled={isEditingDisabled}
                        isUpdating={isUpdating.overview}
                        isLoading={isSessionLoading}
                      >
                        <OverviewInput
                          id="title"
                          name="title"
                          label="Title"
                          defaultValue={session?.title}
                          isDisabled={isEditingDisabled}
                          isLoading={isSessionLoading}
                          validation={{
                            required: {
                              value: true,
                              message: 'Please enter a title.',
                            },
                          }}
                          tooltip="session_title"
                        />
                      </OverviewCard>
                    </FormContext>
                  </Flex>
                </Flex>
                {!isIntroOrOutro && (
                  <Flex
                    flexDirection="column"
                    flex={1}
                    marginY={{ base: 'defaultMargin', xl: 0 }}
                  >
                    <SectionTitle title="Further Details" />
                    <FurtherDetailsSessionCard
                      moduleFormatOptions={sessionFormatOptions}
                      moduleFormat={session?.moduleFormat}
                      onSave={handleSaveFurtherDetails}
                      onCancel={handleCancel}
                      sessionDuration={session?.duration}
                      isDisabled={isEditingDisabled}
                      isUpdating={isUpdating.furtherDetails}
                      isLoading={isSessionLoading}
                      setModuleFormat={setSessionFormat}
                    />
                  </Flex>
                )}
              </Flex>
              {!isIntroOrOutro && (
                <Flex flexDirection="column" marginY="defaultMargin">
                  <SectionTitle title="Description" />
                  <FormCard
                    onSave={handleSaveDescription}
                    isDisabled={isEditingDisabled}
                    isUpdating={isUpdating.description}
                    isLoading={isSessionLoading}
                    items={[
                      {
                        id: 'description',
                        name: 'description',
                        label: 'Summary',
                        helpText: '',
                        errorMessage: 'Please enter a summary',
                        defaultValue: session?.description,
                        tooltip: 'session_summary',
                      },
                    ]}
                  />
                </Flex>
              )}
              {/* We don't show requirements for Longform (Extended) Sessions or Intro/Outro sessions */}
              {!isLongform && !isIntroOrOutro && (
                <Flex flexDirection="column" marginY="defaultMargin">
                  <SectionTitle title="Requirements Checklist" />
                  <Requirement
                    onSave={handleSaveRequirement}
                    isDisabled={isEditingDisabled}
                    isUpdating={isUpdating.requirements}
                    isLoading={isSessionLoading}
                    name="name"
                    requirements={session?.checkList[0]}
                  />
                </Flex>
              )}
              {/* If the user has permission to edit clip summaries, show the following card */}
              {hasSummaryEditPermissions && !hasEditPermissions && (
                <Box marginY="defaultMargin" flex={1}>
                  <InformationCard id="copywriter_overview" />
                </Box>
              )}
              {/* We don't show Introduction & Summary Steps for Practice/Longform/Research sessions */}
              {!isPractice && !isLongform && !isResearch && !isIntroOrOutro && (
                <Flex flexDirection="column" marginY="defaultMargin">
                  <SectionTitle title="Introduction & Summary" />
                  {isReflective ? (
                    <Card direction="column" padding={0}>
                      <PromptItem
                        key="prompt-item-1"
                        id="prompt-item-1"
                        title="Introduction"
                        label="Introduction"
                        defaultFormValues={introStep?.prompt || {}}
                        disableCancel={Boolean(introStep?.prompt)}
                        showPrompt={Boolean(introStep?.prompt)}
                        onClick={() => {}}
                        isDisabled={isEditingDisabled}
                        isLoading={isSessionLoading || isStepListLoading}
                        complete={!!intro}
                        helpText="Recommended: Ask the learner to reflect on a “Key Question” before starting this session."
                        onSubmit={handleSavePrompt(introStep)}
                        handleDeletePrompt={() => {
                          if (!introStep) return;

                          dispatch(
                            stepActions.update(introStep.id, {
                              prompt: undefined,
                            })
                          );
                        }}
                      />
                      <PromptItem
                        key={2}
                        id="prompt-item-2"
                        title="Summary"
                        label="Summary"
                        defaultFormValues={outroStep?.prompt || {}}
                        disableCancel={Boolean(outroStep?.prompt)}
                        showPrompt={Boolean(outroStep?.prompt)}
                        onClick={() => {}}
                        isDisabled={isEditingDisabled}
                        isLoading={isSessionLoading || isStepListLoading}
                        complete={!!outro}
                        helpText="Recommended: Ask the learner to submit a response to a “Discussion Point” after completing this session."
                        onSubmit={handleSavePrompt(outroStep)}
                        handleDeletePrompt={() => {
                          if (!outroStep) return;

                          dispatch(
                            stepActions.update(outroStep.id, {
                              prompt: undefined,
                            })
                          );
                        }}
                      />
                    </Card>
                  ) : (
                    <FormContext {...videoClipMethods}>
                      <StepEdit
                        stepRegister={stepRegister}
                        stepSetValue={stepSetValue}
                        expandedStep={expandedStep}
                        expandedStepData={expandedStepData}
                        expandedClipData={expandedStepVideoClipData}
                        stepData={[...introSteps, ...outroSteps]}
                        clipData={expandedClipIds}
                        shouldShowQuestions={false}
                        questions={expandedStepQuestions.sort(
                          (q1, q2) => q1.id - q2.id
                        )}
                        onStepExpanded={onStepExpanded}
                        onDragEnd={handleStepReorder}
                        onClipDragEnd={handleClipReorder}
                        handleAddStep={handleAddStep}
                        handleUpdateStep={(stepId) =>
                          stepHandleSubmit((data) =>
                            handleUpdateStep(stepId, data)
                          )()
                        }
                        handleCancelUpdateStep={() =>
                          stepReset({
                            supportingNotes: expandedStepData?.notes,
                          })
                        }
                        handleCreateQuestion={handleCreateQuestion}
                        handleUpdateQuestion={handleUpdateQuestion}
                        handleDeleteStep={handleDeleteStep}
                        handleAddClip={handleAddClip}
                        handleAddVideoToClip={handleAddVideoToClip}
                        handleDeleteClip={(id) => handleDeleteClip(id)}
                        onSaveClipSummary={handleSaveClipSummary}
                        saveDisabled={Boolean(
                          !stepFormstate.dirty &&
                            Object.keys(stepErrors).length === 0 &&
                            !clipFormState.dirty &&
                            Object.keys(clipErrors).length === 0
                        )}
                        isDisabled={isEditingDisabled}
                        isTextEditingDisabled={isTextEditingDisabled}
                        isLoading={isStepListLoading}
                        isStepUpdating={stepsUI.step.loading}
                        isStepListUpdating={stepsUI.stepList.loading}
                        isThumbnailUpdating={(id) => {
                          const pollState = thumbnailPollState[id];
                          // If a poll state exists and it's not complete then the
                          // thumbnail is still updating
                          return pollState ? !pollState.complete : false;
                        }}
                        isClipLoading={isClipListLoading}
                        isDraggable={false}
                        canAddStep={false}
                        // If the clip is an intro or outro we hide the option to
                        // delete first video to ensure there is always at least one clip
                        canDeleteClip={
                          !isEditingDisabled &&
                          isIntroOrOutro &&
                          Object.keys(expandedStepVideoClipData).length <= 1
                        }
                        // Intro & Outro steps should not be deletable, should not display notes
                        // and should not display a title
                        canDeleteStep={false}
                        shouldShowTitleAndNotes={false}
                      />
                    </FormContext>
                  )}
                </Flex>
              )}
              {isLongform && (
                <Box marginY="defaultMargin" flex={1}>
                  <InformationCard id="extended_session_step" />
                </Box>
              )}
              {!isPractice && !isResearch ? (
                <Flex
                  flexDirection="column"
                  marginY="defaultMargin"
                  position="relative"
                >
                  <SectionTitle title="Steps" />
                  {isEditingDisabled ? null : mutliVideoUpload.visible ? (
                    <Card flexDir="column" mb={2}>
                      <VideoClipsUpload
                        onUploadClips={(clips) =>
                          handleMultipleClipUpload(clips)
                            .then(() => {
                              setMultiVideoUpload({
                                visible: false,
                                error: null,
                              });
                              return true;
                            })
                            .catch(() => {
                              setMultiVideoUpload({
                                visible: true,
                                error:
                                  'The upload failed for the above clips. Please refresh the page and try again.',
                              });
                              return false;
                            })
                        }
                        error={mutliVideoUpload.error}
                        onClearError={() =>
                          setMultiVideoUpload((state) => ({
                            ...state,
                            error: null,
                          }))
                        }
                      />
                    </Card>
                  ) : (
                    <Flex
                      alignItems="center"
                      cursor="pointer"
                      justifyContent="center"
                      position="absolute"
                      right={0}
                      top={0}
                      onClick={() =>
                        setMultiVideoUpload({ visible: true, error: null })
                      }
                      paddingRight={{ base: 'defaultPadding', md: 0 }}
                    >
                      <MdIcon
                        color="blue.500"
                        name="CloudUpload"
                        boxSize={6}
                        mr={2}
                      />
                      <Text fontWeight="semibold" color="blue.500">
                        Upload Videos
                      </Text>
                    </Flex>
                  )}
                  {unassignedVideoClips.length ? (
                    <>
                      {/* Don't display the card about assigning clips if editing is disabled */}
                      {!isEditingDisabled && (
                        <InformationCard
                          id="unassigned_clips"
                          marginBottom="defaultMargin"
                        />
                      )}
                      <Card
                        flexDir="column"
                        mb="defaultMargin"
                        overflow="visible"
                      >
                        <VideoClipsList
                          availableSteps={normalSteps}
                          uploadInProgress={pollingInProgress}
                          videoClips={unassignedVideoClips.map(
                            ({
                              id,
                              orientation,
                              video: videoSrc,
                              originalFilename: name,
                              videoThumbnail: image,
                              summary,
                            }) => ({
                              id,
                              name: name || '',
                              orientation,
                              videoSrc,
                              image,
                              summary,
                            })
                          )}
                          isDisabled={isEditingDisabled}
                          isTextEditingDisabled={isTextEditingDisabled}
                          onDelete={async (id) => {
                            await dispatch(videoClipActions.remove(id));
                          }}
                          onSave={handleSaveClipList}
                        />
                      </Card>
                    </>
                  ) : null}
                  <FormContext {...videoClipMethods}>
                    <StepEdit
                      stepRegister={stepRegister}
                      stepSetValue={stepSetValue}
                      expandedStep={expandedStep}
                      expandedStepData={expandedStepData}
                      expandedClipData={expandedStepVideoClipData}
                      stepData={orderBy(normalSteps, 'index')}
                      clipData={expandedClipIds}
                      shouldShowQuestions={
                        selectedSessionFormat === SESSION_FORMAT.reflect &&
                        !isIntroOrOutro
                      }
                      questions={expandedStepQuestions.sort(
                        (q1, q2) => q1.id - q2.id
                      )}
                      onStepExpanded={onStepExpanded}
                      onDragEnd={handleStepReorder}
                      onClipDragEnd={handleClipReorder}
                      handleAddStep={handleAddStep}
                      handleUpdateStep={(stepId) =>
                        stepHandleSubmit((data) =>
                          handleUpdateStep(stepId, data)
                        )()
                      }
                      handleCancelUpdateStep={() =>
                        stepReset({
                          supportingNotes: expandedStepData?.notes,
                        })
                      }
                      handleCreateQuestion={handleCreateQuestion}
                      handleUpdateQuestion={handleUpdateQuestion}
                      handleDeleteStep={handleDeleteStep}
                      handleAddClip={handleAddClip}
                      handleAddVideoToClip={handleAddVideoToClip}
                      handleDeleteClip={(id) => handleDeleteClip(id)}
                      onSaveClipSummary={handleSaveClipSummary}
                      saveDisabled={Boolean(
                        !stepFormstate.dirty &&
                          Object.keys(stepErrors).length === 0 &&
                          !clipFormState.dirty &&
                          Object.keys(clipErrors).length === 0
                      )}
                      isDisabled={isEditingDisabled}
                      isTextEditingDisabled={isTextEditingDisabled}
                      isLoading={isStepListLoading}
                      isStepUpdating={stepsUI.step.loading}
                      isStepListUpdating={stepsUI.stepList.loading}
                      isThumbnailUpdating={(id) => {
                        const pollState = thumbnailPollState[id];
                        // If a poll state exists and it's not complete then the
                        // thumbnail is still updating
                        return pollState ? !pollState.complete : false;
                      }}
                      isClipLoading={isClipListLoading}
                      // Intro & Outro steps should not be deletable, should not display notes
                      // and should not display a title. Additionally, Practice/Research/Longform
                      // session types only have a single step so should not be deletable either
                      canDeleteStep={
                        isIntroOrOutro
                          ? expandedStepData?.index
                            ? expandedStepData?.index > 1
                            : false
                          : isPractice || isResearch || isLongform
                          ? false
                          : true
                      }
                      shouldShowTitleAndNotes={!isIntroOrOutro}
                      // Longform sessions should have a single step with a single video clip.
                      // Practice and Research sessions should have a single step with no video clips.
                      // As such, we prevent the user from adding any clips or steps for these sessions.
                      canAddStep={!isLongform && !isPractice && !isResearch}
                      canAddClip={!isLongform && !isPractice && !isResearch}
                    />
                  </FormContext>
                </Flex>
              ) : (
                <Flex flexDirection="column" marginY="defaultMargin">
                  <SectionTitle title="Exercises & Notes" />
                  <FormCard
                    onSave={handleSaveExercises}
                    isDisabled={!isEditingDisabled}
                    isUpdating={isUpdating.exercises}
                    isLoading={isSessionLoading}
                    items={[
                      {
                        id: 'exercise',
                        name: 'exercise',
                        label: 'Exercises',
                        helpText: '',
                        errorMessage: 'Please enter an exercise',
                        defaultValue: session?.exerciseText,
                      },
                      {
                        id: 'notes',
                        name: 'notes',
                        label: 'Notes',
                        helpText: '',
                        errorMessage: 'Please enter a note',
                      },
                    ]}
                  />
                </Flex>
              )}
            </>
          )}
          {currentView === 'builder' && (
            <Flex flexDir="column">
              <SessionPlayer
                onCompleteSession={async () => null}
                onSetOrienation={async () => null}
                onUnlockStep={async () => null}
                requirements={playerChecklist}
                showRequirements={Boolean(playerChecklist)}
                steps={playerSteps}
                loading={loadingSessionPlayer}
                previewModeEnabled
                isEditable={!isEditingDisabled}
                onSaveClipSummary={handleSaveClipSummary}
                handleVideoUpload={handleAddVideoToClip}
                handleAddClip={handleAddClip}
                handleAddStep={handleAddStep}
                // handleUpdateStep={(stepId, title) =>
                //   stepHandleSubmit((data) =>
                //     handleUpdateStep(stepId, { ...data, title })
                //   )()
                // }
                pathname={location.pathname}
                navigationStep={currentStepId}
                navigateToStep={(idx: number) => {
                  let currentSearchParams = new URLSearchParams(
                    location.search
                  );
                  currentSearchParams.set('step', idx.toString());
                  history.push({
                    pathname: location.pathname,
                    search: currentSearchParams.toString(),
                  });
                }}
              />
            </Flex>
          )}
        </Flex>
      </ScreenWrapper>
      {isEditingDisabled && (
        <FixedFooter
          text={
            isLockedForEditing
              ? 'This session is locked for editing.'
              : hasSummaryEditPermissions
              ? 'You have limited permissions to make changes'
              : 'You do not have the required permissions to make changes'
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  const { sessionId } = ownProps.match.params;
  const {
    cms: {
      session: sessionState,
      step: stepState,
      videoClip: { videoClipList },
    },
  } = state;

  const session = sessionState.session[sessionId];
  const steps = session
    ? getStepsForSession(stepState.stepList, session.id)
    : {};

  /** Build a list of question Ids - used to fetch question data */
  const questionIds = Object.keys(steps)
    .map((k) => steps[k])
    .reduce(
      (acc: number[], { questions }) =>
        questions ? [...acc, ...questions] : [...acc],
      []
    );

  /** Build list of questions - after having fetched question data */
  const questions = questionIds
    .map((id) => stepState.question[id])
    .filter((a) => a);

  /** Build list of video clips that haven't been assigned to a specific step */
  const unassignedVideoClips = getVideoClipsForSession(
    videoClipList,
    parseInt(sessionId)
  );

  return {
    questionIds,
    questions,
    session,
    sessionUI: state.ui.session,
    stepCount: Object.keys(steps).length,
    steps,
    categoryOptions: getCategoryOptions(state),
    stepsUI: state.ui.step,
    videoClips: state.cms.videoClip.videoClipList,
    unassignedVideoClips: Object.values(unassignedVideoClips),
    videoClipUI: state.ui.videoClip,
  };
};

const connector = connect(mapStateToProps);

export default connector(SessionEditScreen);
