import { VideoClipAT } from 'redux/actionTypes/cms';

import { Action } from 'types';
import {
  VideoClipListState,
  VideoClipDetailState,
  VideoClipUIState,
  IVideoClipListItem,
  IVideoClip,
} from 'types/cms';

const optimisticCache: VideoClipListState = {};
const initialState: VideoClipListState = {};

export const videoClipListReducer = (
  state = initialState,
  action: Action
): VideoClipListState => {
  switch (action.type) {
    case VideoClipAT.CMS_CREATE_VIDEOCLIP_REQUEST:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_REQUEST:
      return state;
    case VideoClipAT.CMS_CREATE_VIDEOCLIP_SUCCESS:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.videoClips,
      };
    case VideoClipAT.CMS_CREATE_VIDEOCLIP_FAILURE:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_FAILURE:
      return state;
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_SUCCESS:
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_SUCCESS: {
      // When we get or update a videoClip, we find that videoClip in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const videoClip = action.payload.entities.videoClip[idString];

      // If we don't have a list representation of the videoClip yet
      // then do nothing
      if (!state[idString] || Object.keys(state).length === 0) return state;

      const item = Object.keys(state[idString]).reduce((acc, k: string) => {
        const key = k as keyof IVideoClipListItem;
        const value = videoClip[key] || state[idString][key];

        // @ts-ignore
        acc[key] = value;

        return acc;
      }, {} as IVideoClipListItem);

      return {
        ...state,
        [idString]: item,
      };
    }
    case VideoClipAT.CMS_DELETE_VIDEOCLIP_REQUEST: {
      let videoClipId = action.meta.videoClipId as string;
      let { [videoClipId]: _, ...rest } = state;
      // Add the optimistically deleted object to the cache
      optimisticCache[videoClipId] = _;
      return rest;
    }
    case VideoClipAT.CMS_DELETE_VIDEOCLIP_SUCCESS: {
      let videoClipId = action.meta.videoClipId;

      if (typeof videoClipId !== 'number' && typeof videoClipId !== 'string') {
        return state;
      }

      let { [videoClipId]: _, ...rest } = state;
      // Success, so remove the stored optimistic cache item, after
      // taking a copy of the deleted steps's index and the deleted
      // step's parent step for use in the next operation
      const deletedIndex = optimisticCache[videoClipId]?.index;
      const deletedStep = optimisticCache[videoClipId]?.step;
      delete optimisticCache[videoClipId];

      // When removing a step, we need to make sure that the index of any
      // steps with a higher index than the deleted one are decremented by 1
      return Object.values(rest).reduce<VideoClipListState>(
        (acc, videoClip: IVideoClip) => {
          if (
            videoClip.step === deletedStep &&
            videoClip.index > deletedIndex
          ) {
            acc[videoClip.id] = {
              ...videoClip,
              index: videoClip.index - 1,
            };
          } else {
            acc[videoClip.id] = videoClip;
          }
          return acc;
        },
        {}
      );
    }
    case VideoClipAT.CMS_DELETE_VIDEOCLIP_FAILURE: {
      let videoClipId = action.meta.videoClipId as string;
      // Failure, so restore the optimistically deleted item
      // from the optimistic cache
      return {
        ...state,
        [videoClipId]: optimisticCache[videoClipId],
      };
    }
    default:
      return state;
  }
};

const videoClipInitialState: VideoClipDetailState = {};

export const videoClipReducer = (
  state = videoClipInitialState,
  action: Action
): VideoClipDetailState => {
  switch (action.type) {
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_REQUEST:
      return state;
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.videoClip,
      };
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_FAILURE:
      return state;
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_REQUEST:
      return state;
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.videoClip,
      };
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_FAILURE:
      return state;
    default:
      return state;
  }
};

export const videoClipUIReducer = (
  state: VideoClipUIState = {
    videoClip: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
    },
    videoClipList: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
    },
  },
  action: Action
): VideoClipUIState => {
  switch (action.type) {
    case VideoClipAT.CMS_CREATE_VIDEOCLIP_REQUEST:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_REQUEST:
      return {
        ...state,
        videoClipList: {
          ...state.videoClipList,
          loading: !action.error,
        },
      };
    case VideoClipAT.CMS_CREATE_VIDEOCLIP_SUCCESS:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_SUCCESS:
    case VideoClipAT.CMS_CREATE_VIDEOCLIP_FAILURE:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_LIST_FAILURE:
      return {
        ...state,
        videoClipList: {
          ...state.videoClipList,
          loading: false,
        },
      };
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_REQUEST:
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_REQUEST:
    case VideoClipAT.CMS_DELETE_VIDEOCLIP_REQUEST:
      return {
        ...state,
        videoClip: {
          ...state.videoClip,
          loading: !action.error,
        },
      };
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_SUCCESS:
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_SUCCESS:
    case VideoClipAT.CMS_DELETE_VIDEOCLIP_SUCCESS:
    case VideoClipAT.CMS_FETCH_VIDEOCLIP_FAILURE:
    case VideoClipAT.CMS_PATCH_VIDEOCLIP_FAILURE:
    case VideoClipAT.CMS_DELETE_VIDEOCLIP_FAILURE:
      return {
        ...state,
        videoClip: {
          ...state.videoClip,
          loading: false,
        },
      };
    default:
      return state;
  }
};
