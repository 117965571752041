import { useState, useEffect } from 'react';
import moment from 'moment';

import { Box, Flex, Text, MdIcon, Divider, Card, useTheme } from '@workshop/ui';
import { HeaderTag } from 'components/AppHeader';

import { courseUtils } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { Cohort, CohortStatus, TeamMember, TeamMemberUser } from 'types/common';
interface MultiSelectMentor extends TeamMemberUser {
  value: string;
  label: string;
}

interface CohortListCardProps {
  data: Cohort[];
  availableMentors: TeamMember[];
  onEdit: (id: number) => void;
  onSave: (values: { mentors: number[] }, id: number) => Promise<any>;
  isDisabled?: boolean;
  isLoading?: boolean;
}

interface CohortListItemProps {
  cohort: Cohort;
  /**
   * An array of the available mentors to be used as the select options
   */
  mentorOptions: MultiSelectMentor[];
  onEdit: () => void;
  onSave: (values: { mentors: number[] }) => Promise<any>;
  isDisabled?: boolean;
  isLoading?: boolean;
}

const CohortListItem = (props: CohortListItemProps) => {
  const {
    startDate,
    endDate,
    spacesTotal,
    numParticipants,
    mentors: currentCohortMentors,
    label,
    status,
    isAnonymous,
    numEnrolments,
    socialType,
  } = props.cohort;
  const { mentorOptions, onEdit } = props;

  // We filter out to display the existing mentors
  const defaultValue = mentorOptions.filter((mentor) => {
    return currentCohortMentors.find((m) => m.id === mentor.id);
  });

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  // Our selected options to be passed to the save method
  const [selectedMentors, setSelectedMentors] = useState(defaultValue);

  useEffect(() => {
    const newMentors = mentorOptions.filter((mentor) => {
      return currentCohortMentors.find((m) => m.id === mentor.id);
    });
    setSelectedMentors(newMentors);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(currentCohortMentors)]);

  // Cohort is locked if it has already finished
  const locked = moment(endDate).isBefore();

  const cohortType = courseUtils.getCohortType(props.cohort);

  return (
    <Flex
      py={3}
      px={4}
      alignItems="center"
      cursor="pointer"
      _hover={{ background: 'background.tint1' }}
      onClick={onEdit}
    >
      <Flex flex={1} alignItems="center">
        {cohortType ? (
          <MdIcon name={cohortType.icon} color="icon.info" mr={3} />
        ) : null}
        {status === CohortStatus.draft && (
          <HeaderTag
            title="Draft"
            bg="background.tint2"
            color="text.muted"
            mr={3}
          />
        )}
        <Text>
          {moment(startDate).format(
            isMobile ? "Do MMMM [']YY" : 'Do MMMM YYYY'
          )}
        </Text>
        {label ? <Text ml={3} color="text.muted">{`${label}`}</Text> : null}
      </Flex>
      <Flex
        alignItems="center"
        justifyContent="flex-end"
        display={{ base: 'none', md: 'flex' }}
      >
        {selectedMentors.length > 0 && (
          <>
            {' '}
            <MdIcon name="School" color="icon.muted" mr={2} />
            <Flex flexWrap="wrap">
              <Text color="text.muted">{`${
                selectedMentors[0].label
              }${'\u00A0'}`}</Text>
              <Text color="text.muted">
                {selectedMentors.length > 1 &&
                  `and ${selectedMentors.length - 1} more`}
              </Text>
            </Flex>
          </>
        )}
      </Flex>
      <Flex ml={6} color="icon.muted" alignItems="center">
        <MdIcon name="People" />
        {isAnonymous ? (
          <Text ml={1} fontWeight="bold">
            {numEnrolments}
          </Text>
        ) : (
          <>
            <Text ml={1} fontWeight="bold">
              {numParticipants}
            </Text>
            {courseUtils.hasClassSizeLimit({ socialType, isAnonymous }) ? (
              <Text>/{spacesTotal}</Text>
            ) : null}
          </>
        )}
      </Flex>
      <MdIcon
        color={locked ? 'icon.error' : 'common.primary'}
        cursor="pointer"
        name={locked ? 'History' : 'Edit'}
        ml={6}
      />
    </Flex>
  );
};

const CohortListCard = (props: CohortListCardProps) => {
  const {
    data,
    onEdit,
    onSave,
    availableMentors,
    isLoading,
    isDisabled = false,
  } = props;

  const availableMentorsFormatted = availableMentors.map((member) => ({
    value: member.user.username,
    label: member.user.name,
    ...member.user,
  }));

  return (
    <Card flexDirection="column" padding={0}>
      {data.map((cohort: Cohort, idx) => {
        return (
          <Box key={`${cohort.id}-${idx}`}>
            <CohortListItem
              cohort={cohort}
              onEdit={() => onEdit(cohort.id)}
              onSave={(values) => onSave(values, cohort.id)}
              mentorOptions={availableMentorsFormatted}
              isLoading={isLoading}
              isDisabled={isDisabled}
            />
            {idx + 1 < data.length && <Divider m={0} />}
          </Box>
        );
      })}
    </Card>
  );
};

export default CohortListCard;
