import React from 'react';

import { Heading, Card, Text, Box, Button, Flex, Skeleton } from '@workshop/ui';

export const CatalogueSkeleton: React.FC<{ isLoading?: boolean }> = ({
  isLoading = true,
}) => {
  const skeletors = [0, 1, 2]; // Numbers of 👻 courses
  return (
    <Flex direction="column" minW="screen.md">
      {skeletors.map((skeletor, idx) => {
        return (
          <Card
            key={`skeleton-${skeletor}`}
            direction="column"
            my="defaultMargin"
            padding={0}
          >
            <Flex padding="defaultPadding" flex={1}>
              <Flex flex={1}>
                <Box>
                  <Skeleton isLoaded={!isLoading}>
                    <Text boxSize={32} marginX={'defaultMargin'}>
                      Image
                    </Text>
                  </Skeleton>
                </Box>
                <Flex flex={1} flexDirection="column" marginX="defaultMargin">
                  <Box>
                    <Skeleton isLoaded={!isLoading}>
                      <Heading as="h3">Title</Heading>
                    </Skeleton>
                  </Box>
                  <Box marginY="defaultMargin" maxW={'100px'}>
                    <Skeleton isLoaded={!isLoading}>
                      <Text>Status</Text>
                    </Skeleton>
                  </Box>
                </Flex>
                <Flex flex={1} alignItems="center" justifyContent="flex-end">
                  <Skeleton isLoaded={!isLoading}>
                    <Button>Button</Button>
                  </Skeleton>
                </Flex>
              </Flex>
            </Flex>
            {skeletors.map((skeletor, idx) => (
              <Box key={`skeleton-unit-${idx}`}>
                <Flex
                  flex={1}
                  padding="defaultPadding"
                  cursor="pointer"
                  border="1px"
                  borderColor="border.muted"
                >
                  <Skeleton isLoaded={!isLoading}>
                    <Text minW="200px">unit</Text>
                  </Skeleton>
                </Flex>
              </Box>
            ))}
          </Card>
        );
      })}
    </Flex>
  );
};
