import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { FormContext, useForm } from 'react-hook-form';
import { decamelizeKeys } from 'humps';
import orderBy from 'lodash/orderBy';
import pickBy from 'lodash/pickBy';

import { Flex, Card, useDisclosure } from '@workshop/ui';

import { PERMISSION_SLUGS } from 'types/common';
import navRoutes from 'navigation/Routes';
import { hooks } from 'utils';

import { courseActions, unitActions } from 'redux/actions/cms';
import { getUnitsForCourse, getCategoryOptions } from 'redux/selectors';
import { useHasAnyPermission } from 'redux/selectors/organisation';

import { FormCard } from 'components/FormCard';
import { IAddItem } from 'components/ListItem/AddItem';
import { SectionTitle, FixedFooter } from 'components/Common';
import {
  OverviewCard,
  OverviewInput,
  OverviewVideoInput,
} from 'components/OverviewCard';
import { FurtherDetailsCard } from 'components/FurtherDetailsCard';
import {
  EmailConfig,
  EmailConfigFormData,
  ModalFormData,
} from 'components/EmailConfig';
import { Draggable, IDraggableData } from 'components/Draggable';
import { Item, AddItem } from 'components/ListItem';

import { ModalVideo } from 'components/ModalVideo';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { GlobalState } from 'types';
import { CompleteUploadChunkAction } from 'types/common';
import {
  DescriptionFormData,
  FurtherDetailsFormData,
  IClassType,
  OverviewFormData,
  ICourse,
  IUnitListItem,
} from 'types/cms';

import { formatUnitList, DraggableUnit } from './dataUtils';

const boolToString = (bool: boolean) => (bool ? 'true' : 'false');

// Routing Props
interface MatchParams {
  courseId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CourseEditScreen: React.FC<Props> = ({
  course,
  units,
  categoryOptions,
  courseUI,
  unitUI,
  match: { params },
  currentTeam,
  history,
}) => {
  const { courseId } = params;

  const dispatch = useDispatch();
  const methods = useForm();
  const { onOpen, isOpen, onClose } = useDisclosure();

  // Initialise some local state which can be used to control the UI
  // during data update requests to the API
  const [isUpdating, setIsUpdating] = useState({
    overview: false,
    furtherDetails: false,
    description: false,
    emailConfiguration: false,
    addUnit: false,
  });

  // Determine whether the user has editing permissions and whether the
  // course is open for editing
  const hasEditPermissions = useHasAnyPermission([
    PERMISSION_SLUGS.can_edit_content,
  ]);

  const isEditingDisabled =
    !hasEditPermissions || (course && course.isLockedForEditing);

  // Init our chunk upload generator
  const createChunkUpload = hooks.useChunkUpload(isEditingDisabled);

  // On mount, hit the API
  const { course: courseLoading, unitList: unitListLoading } =
    hooks.useLoadingDataState(
      {
        course: {
          actions: [() => courseActions.retrieve(parseInt(courseId))],
        },
        unitList: { actions: [() => unitActions.list(parseInt(courseId))] },
      },
      [courseId]
    );

  // Collect & format the list of unit data by unit type
  const {
    normal: normalUnits,
    intro: introUnits,
    outro: outroUnits,
    assessment: assessmentUnits,
  } = formatUnitList(units);

  // A course should only have 1 (or none) intro unit and 1 (or none)
  // outro unit, so extract them
  const [intro] = introUnits;
  const [outro] = outroUnits;

  const handleSaveOverview = async (data: OverviewFormData) => {
    const { landscape, portrait, title, subtitle } = data;

    const formData = new FormData();

    if (landscape) {
      formData.append('image_landscape', landscape);
    }
    if (portrait) {
      formData.append('image_portrait', portrait);
    }
    formData.append('title', title);
    formData.append('subtitle', subtitle);

    setIsUpdating({ ...isUpdating, overview: true });
    const response = await dispatch(
      courseActions.update(parseInt(courseId), formData)
    );
    setIsUpdating({ ...isUpdating, overview: false });
  };

  const handleSaveFurtherDetails = async (data: FurtherDetailsFormData) => {
    const formData: Partial<ICourse> = {
      category: data.category,
      classType: data.classType,
      duration: data.courseDuration,
      accessDuration: data.accessDuration,
      // Price is read_only
      // price: data.price,
    };
    setIsUpdating({ ...isUpdating, furtherDetails: true });
    await dispatch(courseActions.update(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, furtherDetails: false });
  };

  const handleSaveDescription = async (data: DescriptionFormData) => {
    const formData: Partial<ICourse> = {
      description: data.description,
      summaryText: data.summary,
    };
    setIsUpdating({ ...isUpdating, description: true });
    await dispatch(courseActions.update(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, description: false });
  };

  const handleCreateUnit = async (data: IAddItem) => {
    const formData: Partial<IUnitListItem> = {
      title: data.inputText,
      unitType: 'normal',
    };

    setIsUpdating({ ...isUpdating, addUnit: true });
    await dispatch(unitActions.create(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, addUnit: false });
  };

  const handleUnitReorder = async (data: IDraggableData<DraggableUnit>) => {
    data.forEach((item, idx: number) => {
      if (item.hasChanged) {
        dispatch(unitActions.update(parseInt(item.id), { index: idx + 1 }));
      }
    });
  };

  const handleCancel = () => {};

  /**
   * Handler for sending a test email of the selected type
   */
  const handleSendTestEmail = async (data: ModalFormData) => {
    const { emailType, emailAddress, ...rest } = data;

    return await dispatch(
      courseActions.sendPreviewEmail({
        emailType,
        emailAddress,
        course: course.id,
        dynamicData: rest,
      })
    );
  };

  /**
   * Handler for updating course email configurations
   */
  const handleEmailConfigurationSave = async ({
    welcomeEmailEnabled,
    automatedEmailEnabled,
    onboardingEmailEnabled,
    welcomeEmail,
  }: EmailConfigFormData) => {
    const { downloads, image, text } = welcomeEmail;

    const formData = new FormData();

    // Construct form data for submission. We use form data as our email
    // configuration can include image data
    if (image) {
      formData.append('welcome_email_image_raw', image);
    }

    if (text) {
      formData.append('welcome_email_text', text);
    }

    formData.append(
      'welcome_email_downloads',
      JSON.stringify(
        // Remove empty JSON values
        decamelizeKeys(pickBy(downloads, (value) => value && value.length))
      )
    );

    formData.append(
      'enable_automated_weekly_emails',
      boolToString(automatedEmailEnabled)
    );
    formData.append(
      'enable_pre_start_email',
      boolToString(onboardingEmailEnabled)
    );
    formData.append('enable_welcome_email', boolToString(welcomeEmailEnabled));

    setIsUpdating({ ...isUpdating, emailConfiguration: true });
    await dispatch(courseActions.update(parseInt(courseId), formData));
    setIsUpdating({ ...isUpdating, emailConfiguration: false });
  };

  const handleUploadTrailer = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];
    const chunkUpload = createChunkUpload(file.name, file.size);

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;
    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalise
    // the upload process
    if (payload && 'file' in payload) {
      const { file, filename } = payload;

      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video to 'link'
      // the uploaded file to a video object.
      await dispatch(
        courseActions.update(parseInt(courseId), {
          video: file,
          originalFilename: filename,
        })
      );
    }
  };

  const classTypes: { [key in IClassType]: string } = {
    cohort: 'Cohort',
    intake: 'Intake',
    open: 'Open',
  };

  const isCourseLoading = courseLoading && !course;
  const isUnitListLoading = unitListLoading && !units;

  return (
    <>
      <ScreenWrapper>
        <Flex flexDirection="column" flex={1}>
          <Flex
            flexDirection={{ base: 'column', xl: 'row' }}
            mb={{ base: 0, xl: 'defaultMargin' }}
          >
            <Flex
              flex={1}
              flexDirection="column"
              marginRight="defaultMargin"
              marginBottom={{ base: 'defaultMargin', xl: 0 }}
            >
              <SectionTitle title="Course Overview" />
              <Flex flex={1}>
                <FormContext {...methods}>
                  <OverviewCard
                    // @ts-ignore - ToDo
                    onSave={handleSaveOverview}
                    isDisabled={isEditingDisabled}
                    onCancel={handleCancel}
                    landscape={course?.imageLandscape}
                    portrait={course?.imagePortrait}
                    isUpdating={isUpdating.overview}
                    isLoading={isCourseLoading}
                  >
                    <OverviewInput
                      id="title"
                      name="title"
                      label="Title"
                      isDisabled={isEditingDisabled}
                      defaultValue={course?.title}
                      isLoading={isCourseLoading}
                      validation={{
                        required: {
                          value: true,
                          message: 'Please enter a title.',
                        },
                      }}
                      tooltip="course_title"
                    />
                    <OverviewInput
                      id="subtitle"
                      name="subtitle"
                      label="Subtitle"
                      isDisabled={isEditingDisabled}
                      defaultValue={course?.subtitle}
                      isLoading={isCourseLoading}
                      tooltip="course_subtitle"
                    />
                    <OverviewVideoInput
                      isDisabled={isEditingDisabled}
                      onPlay={onOpen}
                      onUpload={handleUploadTrailer}
                      isPlayDisabled={!course?.video}
                      isLoading={isCourseLoading}
                      isUpdating={courseUI.loading}
                    />
                  </OverviewCard>
                </FormContext>
              </Flex>
            </Flex>
            <Flex
              flexDirection="column"
              flex={1}
              marginY={{ base: 'defaultMargin', xl: 0 }}
            >
              <SectionTitle title="Further Details" />
              <FurtherDetailsCard
                isDisabled={isEditingDisabled}
                categories={categoryOptions}
                classTypes={classTypes}
                onSave={handleSaveFurtherDetails}
                onCancel={handleCancel}
                courseDuration={course?.duration}
                accessDuration={course?.accessDuration}
                price={course?.price}
                isUpdating={isUpdating.furtherDetails}
                isLoading={isCourseLoading || !categoryOptions}
                category={course?.category}
                classType={course?.classType}
              />
            </Flex>
          </Flex>
          <Flex flexDirection="column" marginY="defaultMargin">
            <SectionTitle title="Description" />
            <FormCard
              isDisabled={isEditingDisabled}
              onSave={handleSaveDescription}
              onCancel={() => {}}
              isUpdating={isUpdating.description}
              isLoading={isCourseLoading}
              items={[
                {
                  id: 'summary',
                  name: 'summary',
                  label: 'Short summary',
                  helpText: '',
                  errorMessage: 'Please enter a summary',
                  defaultValue: course?.summaryText,
                  richEditor: true,
                  labelAlign: 'flex-start',
                  tooltip: 'course_short_summary',
                },
                {
                  id: 'description',
                  name: 'description',
                  label: 'Description',
                  helpText: '',
                  errorMessage: 'Please enter a description',
                  defaultValue: course?.description,
                  richEditor: true,
                  labelAlign: 'flex-start',
                  tooltip: 'course_description',
                },
              ]}
            />
          </Flex>
          <Flex flexDirection="column" marginY="defaultMargin">
            <SectionTitle title="Email Configuration" />
            <EmailConfig
              welcomeEmail={{
                image: course?.welcomeEmailImageRaw,
                text: course?.welcomeEmailText,
                downloads: course?.welcomeEmailDownloads,
                enabled: course?.enableWelcomeEmail,
              }}
              automatedEmailEnabled={course?.enableAutomatedWeeklyEmails}
              onboardingEmailEnabled={course?.enablePreStartEmail}
              // Specify which downloads are configurable on emails
              downloadOptions={{
                welcomePackUrl: 'Welcome Pack',
                healthSafetyUrl: 'Health & Safety Booklet',
                equipmentDownloadUrl: 'Equipment Download',
              }}
              // Build a map of unit ID-title combinations for normal units
              unitOptions={normalUnits.reduce((acc, unit) => {
                acc[unit.id] = unit.title;
                return acc;
              }, {} as { [key: string]: string })}
              handleSendEmailSubmit={handleSendTestEmail}
              handleSubmit={handleEmailConfigurationSave}
              isLoading={courseUI.loading}
              // TODO:
              // @ts-ignore
              isUpdating={isUpdating.emailConfiguration}
              isDisabled={isEditingDisabled}
            />
          </Flex>
          <Flex flexDirection="column" marginY="defaultMargin">
            <SectionTitle title="Introduction & Summary" />
            <Card direction="column" padding={0}>
              <Item
                id="introduction"
                title={intro?.title}
                label="Intro"
                isLoading={isCourseLoading || isUnitListLoading}
                allowHover={false}
                border="1px"
                linkTo={
                  intro
                    ? navRoutes.cms.unit.path(courseId, intro.id.toString())
                    : null
                }
              />
              <Item
                id="summary"
                title={outro?.title}
                label="Outro"
                isLoading={isCourseLoading || isUnitListLoading}
                allowHover={false}
                border="1px"
                linkTo={
                  outro
                    ? navRoutes.cms.unit.path(courseId, outro.id.toString())
                    : null
                }
              />
            </Card>
          </Flex>
          <Flex flexDirection="column" marginY="defaultMargin">
            <SectionTitle title="Units" />
            <Card direction="column" padding={0}>
              <Draggable
                data={
                  // During loading, render 2 empty items
                  isCourseLoading || isUnitListLoading
                    ? [
                        {
                          id: '0',
                          title: 'loading',
                          label: '',
                          index: 0,
                          unitType: '',
                        },
                        {
                          id: '1',
                          title: 'loading',
                          label: '',
                          index: 1,
                          unitType: '',
                        },
                      ]
                    : orderBy([...normalUnits, ...assessmentUnits], 'index')
                }
                onDragEnd={handleUnitReorder}
                // Disable drag during loading
                dragEnabled={
                  !isCourseLoading && !isUnitListLoading && !isEditingDisabled
                }
              >
                {(props) => (
                  <Item
                    {...props}
                    padding={3}
                    border="1px"
                    isLoading={isCourseLoading || isUnitListLoading}
                    linkTo={navRoutes.cms.unit.path(
                      courseId,
                      props.id.toString()
                    )}
                  />
                )}
              </Draggable>
              {!isCourseLoading && !isUnitListLoading && !isEditingDisabled && (
                <Flex border="1px" borderColor="border.muted">
                  <AddItem
                    label="Add Item"
                    onSave={handleCreateUnit}
                    isUpdating={isUpdating.addUnit}
                  />
                </Flex>
              )}
            </Card>
          </Flex>
        </Flex>
        {course?.video && (
          <ModalVideo video={course.video} isOpen={isOpen} onClose={onClose} />
        )}
      </ScreenWrapper>
      {isEditingDisabled && (
        <FixedFooter
          text={
            hasEditPermissions
              ? 'This course is locked for editing.'
              : 'You do not have the required permissions to make changes'
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  const { courseId } = ownProps.match.params;
  const {
    organisation: { currentTeam },
  } = state;

  const course = state.cms.course.course[courseId];
  const units = course
    ? getUnitsForCourse(state.cms.unit.unitList, course.id)
    : {};

  return {
    course,
    units,
    currentTeam,
    categoryOptions: getCategoryOptions(state),
    courseUI: state.ui.course.course,
    unitUI: state.ui.unit.unitList,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseEditScreen);
