import { decamelizeKeys } from 'humps';
import {
  CMSCostRecordListSchema,
  CMSLicenseListSchema,
  CMSLicenseSchema,
  CMSEnrolmentListSchema,
  CMSEnrolmentSchema,
} from 'redux/schemas/cms';

import { callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { EnrolmentAT } from 'redux/actionTypes/cms';

import { Dispatch } from 'types';
import {
  CostRecordListAction,
  CreateCostRecordAction,
  LicenseListAction,
  CreateLicenseAction,
  FetchLicenseAction,
  PatchLicenseAction,
  CostRecord,
  License,
  LicensePost,
  EnrolmentListAction,
  CreateEnrolmentAction,
  FetchEnrolmentAction,
  PatchEnrolmentAction,
  Enrolment,
  EnrolmentPost,
} from 'types/cms';

export const listCostRecords = () => {
  return callAPIWithTeam<CostRecordListAction>({
    types: [
      EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_REQUEST,
      EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_SUCCESS,
      EnrolmentAT.CMS_FETCH_COST_RECORD_LIST_FAILURE,
    ],
    endpoint: API.organisation.costRecords,
    method: 'GET',
    schema: [CMSCostRecordListSchema],
    path: 'results',
    meta: {
      toast: {
        error: { message: 'Could not retrieve records', source: 'api' },
      },
    },
  });
};

export const createCostRecord = (data: Partial<CostRecord>) => {
  return callAPIWithTeam<CreateCostRecordAction>({
    types: [
      EnrolmentAT.CMS_CREATE_COST_RECORD_REQUEST,
      EnrolmentAT.CMS_CREATE_COST_RECORD_SUCCESS,
      EnrolmentAT.CMS_CREATE_COST_RECORD_FAILURE,
    ],
    endpoint: API.organisation.costRecords,
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSCostRecordListSchema,
  });
};

export const listLicenses = () => {
  return callAPIWithTeam<LicenseListAction>({
    types: [
      EnrolmentAT.CMS_FETCH_LICENSE_LIST_REQUEST,
      EnrolmentAT.CMS_FETCH_LICENSE_LIST_SUCCESS,
      EnrolmentAT.CMS_FETCH_LICENSE_LIST_FAILURE,
    ],
    endpoint: API.organisation.licenses,
    method: 'GET',
    schema: [CMSLicenseListSchema],
    path: 'results',
    meta: {
      toast: {
        error: { message: 'Could not retrieve agreements', source: 'api' },
      },
    },
  });
};

export const createLicense = (data: Partial<LicensePost>) => {
  return callAPIWithTeam<CreateLicenseAction>({
    types: [
      EnrolmentAT.CMS_CREATE_LICENSE_REQUEST,
      EnrolmentAT.CMS_CREATE_LICENSE_SUCCESS,
      EnrolmentAT.CMS_CREATE_LICENSE_FAILURE,
    ],
    endpoint: API.organisation.licenses,
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSLicenseListSchema,
  });
};

export const retrieveLicense = (id: number) => {
  return callAPIWithTeam<FetchLicenseAction>({
    types: [
      EnrolmentAT.CMS_FETCH_LICENSE_REQUEST,
      EnrolmentAT.CMS_FETCH_LICENSE_SUCCESS,
      EnrolmentAT.CMS_FETCH_LICENSE_FAILURE,
    ],
    endpoint: API.organisation.updateLicense(id),
    method: 'GET',
    schema: CMSLicenseSchema,
  });
};

export const updateLicense = (
  id: number,
  data: Partial<License> | FormData
) => {
  return callAPIWithTeam<PatchLicenseAction>({
    types: [
      EnrolmentAT.CMS_PATCH_LICENSE_REQUEST,
      EnrolmentAT.CMS_PATCH_LICENSE_SUCCESS,
      EnrolmentAT.CMS_PATCH_LICENSE_FAILURE,
    ],
    endpoint: API.organisation.updateLicense(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSLicenseSchema,
    meta: {
      toast: {
        error: 'Could not retrieve this agreement',
      },
    },
  });
};

export const listEnrolments =
  ({
    fetchNextPage,
    url,
    queryParams,
  }: {
    fetchNextPage?: boolean;
    url?: string | undefined;
    // queryParams format: a=1 or a=1&b=2
    queryParams?: string;
  }) =>
  async (dispatch: Dispatch) => {
    let endpoint = url || API.organisation.enrolments;
    if (queryParams) {
      endpoint = endpoint.includes('?')
        ? `${endpoint}&${queryParams}`
        : `${endpoint}?${queryParams}`;
    }

    return dispatch(
      callAPIWithTeam<EnrolmentListAction>({
        types: [
          EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_REQUEST,
          EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_SUCCESS,
          EnrolmentAT.CMS_FETCH_ENROLMENT_LIST_FAILURE,
        ],
        endpoint,
        method: 'GET',
        path: 'results',
        schema: [CMSEnrolmentListSchema],
        fetchNextPage: fetchNextPage
          ? async (url) => {
              await dispatch(listEnrolments({ fetchNextPage: true, url }));
            }
          : null,
        meta: {
          toast: {
            error: { message: 'Could not retrieve enrolments', source: 'api' },
          },
        },
      })
    );
  };

export const createEnrolment = (data: Partial<EnrolmentPost>) => {
  return callAPIWithTeam<CreateEnrolmentAction>({
    types: [
      EnrolmentAT.CMS_CREATE_ENROLMENT_REQUEST,
      EnrolmentAT.CMS_CREATE_ENROLMENT_SUCCESS,
      EnrolmentAT.CMS_CREATE_ENROLMENT_FAILURE,
    ],
    endpoint: API.organisation.enrolments,
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSEnrolmentListSchema,
  });
};

export const retrieveEnrolment = (id: number) => {
  return callAPIWithTeam<FetchEnrolmentAction>({
    types: [
      EnrolmentAT.CMS_FETCH_ENROLMENT_REQUEST,
      EnrolmentAT.CMS_FETCH_ENROLMENT_SUCCESS,
      EnrolmentAT.CMS_FETCH_ENROLMENT_FAILURE,
    ],
    endpoint: API.organisation.updateEnrolment(id),
    method: 'GET',
    schema: CMSEnrolmentSchema,
  });
};

export const updateEnrolment = (
  id: number,
  data: Partial<Enrolment> | FormData
) => {
  return callAPIWithTeam<PatchEnrolmentAction>({
    types: [
      EnrolmentAT.CMS_PATCH_ENROLMENT_REQUEST,
      EnrolmentAT.CMS_PATCH_ENROLMENT_SUCCESS,
      EnrolmentAT.CMS_PATCH_ENROLMENT_FAILURE,
    ],
    endpoint: API.organisation.updateEnrolment(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSEnrolmentSchema,
    meta: {
      toast: {
        error: 'Could not retrieve this enrolment',
      },
    },
  });
};
