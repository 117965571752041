import { IDynamicTable } from 'components/Common/DynamicList';
import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { FormContext, useForm } from 'react-hook-form';
import orderBy from 'lodash/orderBy';
import isEmpty from 'lodash/isEmpty';

import { Card, Flex, useDisclosure } from '@workshop/ui';

import navRoutes from 'navigation/Routes';
import { hooks } from 'utils';
import { UNIT_TYPE, SESSION_TYPE } from 'constants/courses';

import { unitActions, sessionActions } from 'redux/actions/cms';
import { getSessionsForUnit } from 'redux/selectors/course';
import { useHasAnyPermission } from 'redux/selectors/organisation';

import { ModalVideo } from 'components/ModalVideo';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import {
  OverviewCard,
  OverviewInput,
  OverviewVideoInput,
} from 'components/OverviewCard';
import { FurtherDetailsUnitCard } from 'components/FurtherDetailsCard';
import { FormCard } from 'components/FormCard';
import { Draggable, IDraggableData } from 'components/Draggable';
import { Item, AddItem } from 'components/ListItem';
import { SectionTitle, FixedFooter } from 'components/Common';
import { DownloadsCard } from 'components/DownloadsCard';
import { IAddItem } from 'components/ListItem/AddItem';

import { GlobalState } from 'types';
import { PERMISSION_SLUGS, CompleteUploadChunkAction } from 'types/common';
import {
  AssessmentUnitFormData,
  FurtherDetailsUnitFormData,
  OverviewUnitFormData,
  IUnit,
  IUnitType,
  IStartUnit,
  ISessionListItem,
  DescriptionUnitFormData,
} from 'types/cms';

import {
  formatSessionList,
  getFormattedDownloadData,
  DraggableSession,
  buildUnitStrings,
} from './dataUtils';
import organisation from 'redux/actionTypes/common/organisation';

// TODO: Retrieve from API
const unitTypesOptions: {
  [key in IUnitType]: string;
} = {
  intro: 'Intro',
  outro: 'Outro',
  normal: 'Normal',
  transition: 'Transition',
  assessment: 'Assessment',
  practice: 'Practice',
};

const unitStartsOptions: { [key in IStartUnit]: string } = {
  w: 'Weeks',
  m: 'Months',
  d: 'Days',
};

const helpText =
  'This video will play before the unit introduction and should be used to give the learner an update on their progress in context to the whole course.';

// Routing Props
interface MatchParams {
  courseId: string;
  unitId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const UnitEditScreen: React.FC<Props> = ({
  unit,
  sessions,
  unitUI,
  match: { params },
  history,
}) => {
  const { courseId, unitId } = params;

  const dispatch = useDispatch();
  const methods = useForm();
  const { onOpen, isOpen, onClose } = useDisclosure();

  // Determine whether the user has editing permissions and whether the
  // unit is open for editing
  const hasEditPermissions = useHasAnyPermission([
    PERMISSION_SLUGS.can_edit_content,
  ]);

  const isEditingDisabled =
    !hasEditPermissions || (unit && unit.isLockedForEditing);

  // Init our chunk upload generator
  const createChunkUpload = hooks.useChunkUpload(isEditingDisabled);

  // Initialise some local state which can be used to control the UI
  // during data update requests to the API
  const [isUpdating, setIsUpdating] = useState({
    overview: false,
    furtherDetails: false,
    description: false,
    assessment: false,
    studentDownloads: false,
    teacherDownloads: false,
    requirements: false,
    addSession: false,
  });

  // The unit type dictates how the `UnitEdit` screen is rendered/what
  // functionality is available within the interface
  const [selectedUnitType, setUnitType] = useState(unit?.unitType);

  // On mount, hit the API
  const { unit: unitLoading, sessionList: sessionListLoading } =
    hooks.useLoadingDataState(
      {
        unit: { actions: [() => unitActions.retrieve(parseInt(unitId))] },
        sessionList: {
          actions: [
            () => sessionActions.list(parseInt(courseId), parseInt(unitId)),
          ],
        },
      },
      [unitId, courseId]
    );

  // If the unit has an intro session then the handling of session
  // labels & indexes changes
  const hasIntroSession = !!Object.values(sessions).find(
    (session) => session.moduleType === SESSION_TYPE.intro
  );

  // Collect & format the list of session data by session type
  const {
    normal: normalSessions,
    intro: introSessions,
    outro: outroSessions,
  } = formatSessionList(courseId, sessions, hasIntroSession);

  // A unit should only have 1 (or none) intro session and 1 (or none)
  // outro session, so extract them
  const [intro] = introSessions;
  const [outro] = outroSessions;

  const handleSaveUnitOverview = async (data: OverviewUnitFormData) => {
    const { landscape, portrait, title, prefix } = data;
    const formData = new FormData();

    if (landscape) {
      formData.append('image', landscape);
    }
    if (portrait) {
      formData.append('image_portrait', portrait);
    }
    formData.append('title', title);
    formData.append('prefix', prefix);

    setIsUpdating({ ...isUpdating, overview: true });
    await dispatch(unitActions.update(parseInt(unitId), formData));
    setIsUpdating({ ...isUpdating, overview: false });
  };

  const handleFurtherDetailsSubmit = async (
    data: FurtherDetailsUnitFormData
  ) => {
    const formData: Partial<IUnit> = {
      startOffsetValue: data.startValue,
      startOffsetUnit: data.startUnit,
      unitType: data.unitType,
    };
    setIsUpdating({ ...isUpdating, furtherDetails: true });
    await dispatch(unitActions.update(parseInt(unitId), formData));
    setIsUpdating({ ...isUpdating, furtherDetails: false });
  };

  const handleAssessmentDetailsSubmit = async (
    data: AssessmentUnitFormData
  ) => {
    const formData: Partial<IUnit> = {
      startText: data.messageBeforeAssessment,
      completeText: data.messageAfterAssessment,
    };
    setIsUpdating({ ...isUpdating, assessment: true });
    await dispatch(unitActions.update(parseInt(unitId), formData));
    setIsUpdating({ ...isUpdating, assessment: false });
  };

  const handleDescriptionRequirementsSubmit = async (
    data: DescriptionUnitFormData
  ) => {
    const formData: Partial<IUnit> = {
      summary: data.summary,
      fullKitList: data.requirements,
    };
    setIsUpdating({ ...isUpdating, requirements: true });
    await dispatch(unitActions.update(parseInt(unitId), formData));
    setIsUpdating({ ...isUpdating, requirements: false });
  };

  const handleDownloadsSubmit = async (
    data: IDynamicTable,
    teacherOnly: boolean
  ) => {
    const otherDownloads = unit.downloads.filter(
      (d) => d.teacherOnly === !teacherOnly
    );
    const formData: Partial<IUnit> = {
      downloads: [...getFormattedDownloadData(data), ...otherDownloads],
    };
    setIsUpdating({
      ...isUpdating,
      ...(teacherOnly
        ? { teacherDownloads: true }
        : { studentDownloads: true }),
    });
    await dispatch(unitActions.update(parseInt(unitId), formData));
    setIsUpdating({
      ...isUpdating,
      ...(teacherOnly
        ? { teacherDownloads: false }
        : { studentDownloads: false }),
    });
  };

  const handleCreateSession = async (data: IAddItem) => {
    const formData: Partial<ISessionListItem> = {
      title: data.inputText,
      moduleType: 'normal',
      moduleFormat: 'guided',
    };
    setIsUpdating({ ...isUpdating, addSession: true });
    await dispatch(
      sessionActions.create(unit.course, parseInt(unitId), formData)
    );
    setIsUpdating({ ...isUpdating, addSession: false });
  };

  const handleSessionReorder = async (
    data: IDraggableData<DraggableSession>
  ) => {
    data.forEach((item, idx) => {
      if (item.hasChanged) {
        // If there is an intro session, offset by 2, not 1. `idx` is 0-based whereas
        // our session indexes are 1-based. Only normal sessions can be re-ordered which
        // is why a step with `idx` 0 will either have an index of 1 (no intro session)
        // or an index of 2 (with intro session)
        const offset = hasIntroSession ? 2 : 1;
        dispatch(
          sessionActions.update(parseInt(item.id), { index: idx + offset })
        );
      }
    });
  };

  const isUnitLoading = unitLoading && isEmpty(unit);
  const isSessionListLoading = sessionListLoading && isEmpty(sessions);

  const handleUploadTrailer = async (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files) return;

    const file = files[0];

    const chunkUpload = createChunkUpload(file.name, file.size);

    const response = await chunkUpload.startUpload<CompleteUploadChunkAction>(
      file
    );

    // If chunked uploads are disabled (e.g. due to permissions) then calling
    // `startUpload` will result in a void response
    if (!response) return;

    const { payload } = response;
    // Only run the remaining code if the upload was successful. A successful
    // upload will contain the 'file' property which we then use to finalise
    // the upload process
    if (payload && 'file' in payload) {
      const { file, filename } = payload;

      // The `file` included in the successful upload response gives us the full
      // path to the uploaded file. We want to add this to our video to 'link'
      // the uploaded file to a video object.
      await dispatch(
        unitActions.update(parseInt(unitId), {
          video: file,
          originalFilename: filename,
        })
      );
    }
  };

  const { sectionTitle, videoLabel } = buildUnitStrings(selectedUnitType);

  const isNotIntroOutro =
    selectedUnitType !== UNIT_TYPE.intro &&
    selectedUnitType !== UNIT_TYPE.outro;

  const downloadsSection = (
    <>
      <Flex flexDirection="column" marginY="defaultMargin">
        <SectionTitle title="Student Resources" />
        <DownloadsCard
          items={unit?.downloads.filter((d) => !d.teacherOnly)}
          isUpdating={isUpdating.studentDownloads}
          isLoading={isUnitLoading}
          isDisabled={isEditingDisabled}
          onSave={async (data) => await handleDownloadsSubmit(data, false)}
          helpText="Enter a URL to a downloadable file such as a PDF document, e.g. https://docs.google.com/document/d/[DOCUMENT_ID]/export?format=pdf"
        />
      </Flex>
      <Flex flexDirection="column" marginY="defaultMargin">
        <SectionTitle title="Teacher Resources" />
        <DownloadsCard
          teacherOnly
          items={unit?.downloads.filter((d) => !!d.teacherOnly)}
          isUpdating={isUpdating.teacherDownloads}
          isLoading={isUnitLoading}
          isDisabled={isEditingDisabled}
          onSave={async (data) => await handleDownloadsSubmit(data, true)}
          helpText="These resources will only be available to mentors via their course preview."
        />
      </Flex>
    </>
  );

  return (
    <>
      <ScreenWrapper>
        <Flex flexDirection="column" flex={1}>
          <Flex
            flexDirection={{ base: 'column', xl: 'row' }}
            mb={{ base: 0, xl: 'defaultMargin' }}
          >
            <Flex
              flexDirection="column"
              marginRight="defaultMargin"
              marginBottom={{ base: 'defaultMargin', xl: 0 }}
            >
              <SectionTitle title={sectionTitle} />
              <Flex flex={1}>
                <FormContext {...methods}>
                  <OverviewCard
                    // @ts-ignore - TODO
                    onSave={handleSaveUnitOverview}
                    isDisabled={isEditingDisabled}
                    landscape={unit?.image}
                    portrait={unit?.imagePortrait}
                    isUpdating={isUpdating.overview}
                    isLoading={isUnitLoading}
                    helpText={isNotIntroOutro ? helpText : null}
                  >
                    <OverviewInput
                      id="prefix"
                      name="prefix"
                      label="Prefix"
                      isDisabled={isEditingDisabled}
                      defaultValue={unit?.prefix}
                      isLoading={isUnitLoading}
                      tooltip="unit_prefix"
                    />
                    <OverviewInput
                      id="title"
                      name="title"
                      label="Title"
                      isDisabled={isEditingDisabled}
                      defaultValue={unit?.title}
                      isLoading={isUnitLoading}
                      validation={{
                        required: {
                          value: true,
                          message: 'Please enter a title.',
                        },
                      }}
                      tooltip="unit_title"
                    />
                    <OverviewVideoInput
                      isDisabled={isEditingDisabled}
                      onPlay={onOpen}
                      onUpload={handleUploadTrailer}
                      isPlayDisabled={!unit?.video}
                      isLoading={isUnitLoading}
                      isUpdating={unitUI.loading}
                      videoLabel={videoLabel}
                    />
                  </OverviewCard>
                </FormContext>
              </Flex>
            </Flex>
            {isNotIntroOutro ? (
              <Flex
                flexDirection="column"
                flex={1}
                marginY={{ base: 'defaultMargin', xl: 0 }}
              >
                <SectionTitle title="Further Details" />
                <FurtherDetailsUnitCard
                  unitTypesOptions={unitTypesOptions}
                  startUnitOptions={unitStartsOptions}
                  startValue={unit?.startOffsetValue}
                  startUnit={unit?.startOffsetUnit}
                  unitType={unit?.unitType}
                  setUnitType={setUnitType}
                  onSave={handleFurtherDetailsSubmit}
                  onCancel={() => {}}
                  isDisabled={isEditingDisabled}
                  isUpdating={isUpdating.furtherDetails}
                  isLoading={isUnitLoading}
                />
              </Flex>
            ) : null}
          </Flex>
          {selectedUnitType === 'assessment' && (
            <Flex flexDirection="column" marginY="defaultMargin">
              <SectionTitle title="Assessment Details" />
              <FormCard
                onSave={handleAssessmentDetailsSubmit}
                onCancel={() => {}}
                isDisabled={isEditingDisabled}
                isUpdating={isUpdating.assessment}
                isLoading={isUnitLoading}
                items={[
                  {
                    id: 'messageBeforeAssessment',
                    name: 'messageBeforeAssessment',
                    label: 'Message before assessment',
                    helpText:
                      'This text will appear in a popup that asks the learner if they are sure they’d like to start their assessment. Use this opportunity to recommend ways to prepare for the assessment and to warn the learner that on starting the assessment, all other units will be locked until the assessment is complete.',
                    errorMessage:
                      'There must be a message before an assessment',
                    defaultValue: unit?.startText,
                    tooltip: 'unit_assessment_message_before',
                  },
                  {
                    id: 'messageAfterAssessment',
                    name: 'messageAfterAssessment',
                    label: 'Message after assessment',
                    helpText:
                      'This text will appear in a popup after they have completed the assessment. Use this opportunity to congratulate the learner for completing the assessment and to let them know when they can expect to find out the results of their assessment (e.g. “within 1 week”).',
                    errorMessage:
                      'There must be a message after the assessment',
                    defaultValue: unit?.completeText,
                    tooltip: 'unit_assessment_message_after',
                  },
                ]}
              />
            </Flex>
          )}
          {isNotIntroOutro ? (
            <>
              <Flex flexDirection="column" marginY="defaultMargin">
                <SectionTitle title="Description & Requirements" />
                <FormCard
                  onSave={handleDescriptionRequirementsSubmit}
                  onCancel={() => {}}
                  isDisabled={isEditingDisabled}
                  isUpdating={isUpdating.requirements}
                  isLoading={isUnitLoading}
                  items={[
                    {
                      id: 'summary',
                      name: 'summary',
                      label: 'Summary',
                      defaultValue: unit?.summary,
                      tooltip: 'unit_summary',
                    },
                    {
                      id: 'requirements',
                      name: 'requirements',
                      label: 'Requirements',
                      richEditor: true,
                      defaultValue: unit?.fullKitList,
                      tooltip: 'unit_requirements',
                    },
                  ]}
                />
              </Flex>
              {downloadsSection}
              <Flex flexDirection="column" marginY="defaultMargin">
                <SectionTitle title="Introduction & Summary" />
                <Card direction="column" padding={0}>
                  <Item
                    id="introduction"
                    title={intro?.title}
                    label="Intro"
                    onClick={() => {}}
                    isLoading={isUnitLoading || isSessionListLoading}
                    allowHover={false}
                    border="1px"
                    linkTo={
                      intro
                        ? navRoutes.cms.session.path(
                            courseId,
                            unitId,
                            intro.id.toString()
                          )
                        : null
                    }
                  />
                  {unit?.unitType === 'assessment' ? null : (
                    <Item
                      id="summary"
                      title={outro?.title}
                      label="Outro"
                      onClick={() => {}}
                      isLoading={isUnitLoading || isSessionListLoading}
                      allowHover={false}
                      border="1px"
                      linkTo={
                        outro
                          ? navRoutes.cms.session.path(
                              courseId,
                              unitId,
                              outro.id.toString()
                            )
                          : null
                      }
                    />
                  )}
                </Card>
              </Flex>
              <Flex flexDirection="column" mt="defaultMargin" mb={24}>
                <SectionTitle title="Sessions" />
                <Card direction="column" padding={0}>
                  <Draggable
                    data={
                      // During loading, render 2 empty items
                      isUnitLoading || isSessionListLoading
                        ? [
                            {
                              id: '0',
                              title: 'loading',
                              label: '',
                              index: 0,
                              moduleType: '',
                            },
                            {
                              id: '1',
                              title: 'loading',
                              label: '',
                              index: 1,
                              moduleType: '',
                            },
                          ]
                        : orderBy(normalSessions, 'index')
                    }
                    onDragEnd={handleSessionReorder}
                    // Disable drag during loading
                    dragEnabled={
                      !isUnitLoading &&
                      !isSessionListLoading &&
                      !isEditingDisabled
                    }
                  >
                    {(props) => (
                      <Item
                        {...props}
                        padding={3}
                        border="1px"
                        isLoading={isUnitLoading || isSessionListLoading}
                        linkTo={navRoutes.cms.session.path(
                          courseId,
                          unitId,
                          props.id.toString()
                        )}
                      />
                    )}
                  </Draggable>
                  {!isUnitLoading &&
                    !isSessionListLoading &&
                    !isEditingDisabled && (
                      <Flex>
                        <AddItem
                          label="Add Item"
                          onSave={handleCreateSession}
                          isUpdating={isUpdating.addSession}
                        />
                      </Flex>
                    )}
                </Card>
              </Flex>
            </>
          ) : (
            <Flex flexDirection="column">{downloadsSection}</Flex>
          )}
        </Flex>
        {unit?.video && (
          <ModalVideo video={unit.video} isOpen={isOpen} onClose={onClose} />
        )}
      </ScreenWrapper>
      {isEditingDisabled && (
        <FixedFooter
          text={
            hasEditPermissions
              ? 'This unit is locked for editing.'
              : 'You do not have the required permissions to make changes'
          }
        />
      )}
    </>
  );
};

const mapStateToProps = (state: GlobalState, ownProps: OwnProps) => {
  const { unitId } = ownProps.match.params;

  const unit = state.cms.unit.unit[unitId];
  const sessions = unit
    ? getSessionsForUnit(state.cms.session.sessionList, unit.id)
    : {};

  return {
    unit,
    sessions,
    unitUI: state.ui.unit.unit,
    sessionUI: state.ui.session.sessionList,
  };
};

const connector = connect(mapStateToProps);

export default connector(UnitEditScreen);
