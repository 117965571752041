import React from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import {
  Flex,
  Box,
  Text,
  useColorModeValue,
  PopoverContent,
  PopoverHeader,
  PopoverBody,
  PopoverArrow,
  PopoverCloseButton,
  MdIcon,
} from '@workshop/ui';

import { hooks, capitalize } from 'utils';
import {
  getModuleCompletionStatus,
  getCohortSubcategoryId,
} from 'utils/learner';

import { PROGRESS_STATUS } from 'constants/courses';
import { NAV_HEIGHT } from 'containers/AppHeader';

import { SectionTitle } from 'components/Common';
import { ScheduleTimeline } from 'components/ScheduleTimeline';
import { ModulesList } from 'components/ModulesList';
import { ModuleListItemProps } from 'components/ModulesList/ModuleListItem';

import {
  courseProgressActions,
  userLibraryActions,
  workshopRelationshipActions,
  courseScheduleActions,
  courseActions,
  journalActions,
} from 'redux/actions/learner';
import { GlobalState } from 'types';

import { getUserLibraryCourses } from 'redux/selectors/profile';

// Routing Props
interface MatchParams {}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Schedule = ({
  courses,
  units,
  courseProgress,
  moduleProgress,
  courseSchedules,
  unitSchedules,
  moduleSchedules,
  modules,
  history,
  journalEntries,
  cohorts,
}: Props) => {
  const {
    course: courseLoading,
    profile: profileLoading,
    journal: journalLoading,
  } = hooks.useLoadingDataState({
    course: { actions: [courseProgressActions.list] },
    profile: {
      actions: [userLibraryActions.retrieve, workshopRelationshipActions.list],
    },
    journal: { actions: [journalActions.retrieveJournalEntries] },
  });
  const { schedules: schedulesLoading } = hooks.useLoadingDataState(
    {
      schedules: {
        actions: courses.map(
          (c) => () => courseScheduleActions.retrieve(c.slug)
        ),
      },
    },
    [courses.length]
  );
  const dispatch = useDispatch();

  // TODO: Use organisation color as source for courses from that org
  const colors = hooks.useColors('#0F5FF0');
  const palette = useColorModeValue(colors?.light, colors?.dark);
  const oppositePalette = useColorModeValue(colors?.dark, colors?.light);

  const isLoading =
    courseLoading || profileLoading || schedulesLoading || journalLoading;

  const colorCombinations = [
    {
      bgColor: palette?.primaryContainer,
      bgAccentColor: oppositePalette?.primary,
      textColor: oppositePalette?.onPrimary,
    },
    {
      bgColor: palette?.secondaryContainer,
      bgAccentColor: oppositePalette?.secondary,
      textColor: oppositePalette?.onSecondary,
    },
    {
      bgColor: palette?.tertiaryContainer,
      bgAccentColor: oppositePalette?.tertiary,
      textColor: oppositePalette?.onTertiary,
    },
    {
      bgColor: palette?.errorContainer,
      bgAccentColor: oppositePalette?.error,
      textColor: oppositePalette?.onError,
    },
    {
      bgColor: palette?.surfaceVariant,
      bgAccentColor: oppositePalette?.outline,
      textColor: palette?.onSurfaceVariant,
    },
  ];

  const completeColorCombo = {
    bgColor: useColorModeValue(
      'var(--chakra-colors-green-100)',
      'var(--chakra-colors-green-600)'
    ),
    bgAccentColor: useColorModeValue(
      'var(--chakra-colors-green-300)',
      'var(--chakra-colors-green-500)'
    ),
    textColor: 'var(--chakra-colors-text-success)',
  };

  const primaryColorCombo = {
    bgColor: useColorModeValue(
      'var(--chakra-colors-blue-100)',
      'var(--chakra-colors-blue-600)'
    ),
    bgAccentColor: useColorModeValue(
      'var(--chakra-colors-blue-300)',
      'var(--chakra-colors-blue-500)'
    ),
    textColor: 'var(--chakra-colors-text-primary)',
  };

  const groups = isLoading
    ? []
    : Object.values(courseSchedules)
        .sort((a, b) => moment(b.startDate).diff(moment(a.startDate)))
        .map((s) => {
          const courseImage = courses.find(
            (c) => c.slug === s.course
          )?.imageLandscapeThumbnail;
          return {
            id: s.course,
            image: courseImage,
            height: 70,
          };
        });

  // Create a list of courses to be displayed across the timeline
  const courseItems = isLoading
    ? []
    : courses.map((c) => {
        const cProgress = courseProgress[c.slug];
        const progressStatus =
          cProgress?.status === PROGRESS_STATUS.inProgress
            ? 'In Progress'
            : capitalize(cProgress?.status);

        const courseSchedule = courseSchedules[c.slug];
        const startDate = courseSchedule
          ? moment(courseSchedule.startDate)
          : moment();
        const endDate = moment(startDate).add(c.duration, 'week');

        let numSessionsCompleted = 0;
        let numSessionsScheduled = 0;
        let numSessionsLocked = 0;
        let courseComplete = false;
        let courseExpired = false;
        const moduleItemsToSchedule: ModuleListItemProps[] = [];
        const moduleItemsMissingUpload: ModuleListItemProps[] = [];
        if (cProgress?.status === PROGRESS_STATUS.complete) {
          courseComplete = true;
        }
        if (
          Boolean(
            cProgress?.expiryDate &&
              moment(cProgress.expiryDate).diff(moment(), 'days') <= 0
          )
        ) {
          courseExpired = true;
        }
        courseSchedule?.unitSchedules.forEach((unitScheduleId) => {
          if (courseComplete) return;
          const unitSchedule = unitSchedules[unitScheduleId];
          const unit = units[unitSchedule.unit];
          const isFutureUnit = moment().isBefore(
            moment(unitSchedule.weekCommencing)
          );
          unitSchedule.moduleSchedules.forEach((moduleScheduleId) => {
            const moduleSchedule = moduleSchedules[moduleScheduleId];
            const progress = moduleProgress[moduleSchedule.module];
            const yesterday = moment().subtract(1, 'day');
            const isScheduled =
              moduleSchedule.scheduled &&
              !moment(moduleSchedule.scheduledTime).isBefore(yesterday);
            if (isScheduled) {
              numSessionsScheduled += 1;
              return;
            }
            if (isFutureUnit) {
              numSessionsLocked += 1;
              return;
            }
            const module = modules[moduleSchedule.module];
            if (!module) return;

            const uploads = journalEntries.filter(
              (je) =>
                je.destinationContentType === 'module' &&
                module.id === je.destinationObjectId
            );
            const moduleCompletionStatus = getModuleCompletionStatus({
              module,
              uploads,
              progress,
            });
            const cohort = cohorts[c.slug];
            const discourseCategoryId =
              getCohortSubcategoryId(cohort, c) || undefined;

            const tags = [
              `module-${module.index - 1}`,
              `unit-${unit.index - 1}`,
            ];

            if (moduleCompletionStatus === 'complete') {
              numSessionsCompleted += 1;
              return;
            }
            const moduleItem = {
              imageUrl: module.imageThumbnail,
              isLoading: false,
              linkTo: navRoutes.learner.module.path(c.slug, module.slug),
              showScheduleBtn: true,
              title: module.title,
              progress,
              schedule: moduleSchedule,
              courseSlug: c.slug,
              isTop: false,
              isBottom: false,
              uploads,
              uploadMissing: moduleCompletionStatus === 'uploadMissing',
              discourseCategoryId,
              tags,
            };
            if (moduleCompletionStatus === 'uploadMissing') {
              moduleItemsMissingUpload.push(moduleItem);
              return;
            }
            moduleItemsToSchedule.push(moduleItem);
          });
        });

        return {
          id: c.slug,
          group: c.slug,
          title: c.title,
          subtitles: [
            `${c.duration} weeks`,
            c.creatorInformation.name,
            progressStatus,
          ],
          start: startDate,
          end: endDate,
          ...(courseComplete
            ? completeColorCombo
            : colorCombinations?.length > 0
            ? colorCombinations[c.id % colorCombinations.length]
            : {}),
          onPopoverOpen: () => {
            requestAnimationFrame(() => {
              dispatch(courseActions.retrieve(c.slug));
            });
          },
          popoverContent: (
            <PopoverContent width="sm">
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>
                <Flex
                  cursor="pointer"
                  alignItems="center"
                  color="common.primary"
                  onClick={() => history.push(`courses/${c.slug}`)}
                  _hover={{ color: 'common.primaryDark' }}
                >
                  <Text fontWeight="bold">{c.title}</Text>
                  <MdIcon boxSize="icon" name="KeyboardArrowRight" ml={1} />
                </Flex>
              </PopoverHeader>
              <PopoverBody
                paddingX={0}
                paddingY={1}
                maxHeight="40vh"
                overflowY="scroll"
                {...(courseComplete
                  ? {
                      background: 'background.success',
                    }
                  : {})}
              >
                {courseComplete ? (
                  <Flex
                    alignItems="center"
                    paddingX="defaultPadding"
                    paddingY={1}
                  >
                    <MdIcon
                      name="TaskAlt"
                      boxSize="icon"
                      color="text.success"
                    />
                    <Text color="text.success" ml={2}>
                      Course complete
                    </Text>
                  </Flex>
                ) : (
                  <>
                    {numSessionsCompleted > 0 ? (
                      <Flex
                        alignItems="center"
                        paddingX="defaultPadding"
                        paddingY={1}
                      >
                        <MdIcon
                          name="TaskAlt"
                          boxSize="icon"
                          color="common.progress"
                        />
                        <Text ml={2}>{`${numSessionsCompleted} session${
                          numSessionsCompleted === 1 ? '' : 's'
                        } complete`}</Text>
                      </Flex>
                    ) : null}
                    {numSessionsScheduled > 0 ? (
                      <Flex
                        alignItems="center"
                        paddingX="defaultPadding"
                        paddingY={1}
                      >
                        <MdIcon
                          name="TaskAlt"
                          boxSize="icon"
                          color="common.progress"
                        />
                        <Text ml={2}>{`${numSessionsScheduled} session${
                          numSessionsScheduled === 1 ? '' : 's'
                        } scheduled`}</Text>
                      </Flex>
                    ) : null}
                    {moduleItemsMissingUpload.length > 0 ? (
                      <>
                        <Flex
                          alignItems="center"
                          paddingX="defaultPadding"
                          paddingY={1}
                        >
                          <MdIcon
                            name="RadioButtonUnchecked"
                            boxSize="icon"
                            color="common.muted"
                          />
                          <Text ml={2}>{`${
                            moduleItemsMissingUpload.length
                          } post${
                            moduleItemsMissingUpload.length === 1 ? '' : 's'
                          } required:`}</Text>
                        </Flex>
                        <ModulesList
                          isLoading={false}
                          moduleItems={moduleItemsMissingUpload}
                          compact
                          isExpired={courseExpired}
                        />
                      </>
                    ) : null}
                    {moduleItemsToSchedule.length > 0 ? (
                      <>
                        <Flex
                          alignItems="center"
                          paddingX="defaultPadding"
                          paddingY={1}
                        >
                          <MdIcon
                            name="RadioButtonUnchecked"
                            boxSize="icon"
                            color="common.muted"
                          />
                          <Text ml={2}>{`${
                            moduleItemsToSchedule.length
                          } session${
                            moduleItemsToSchedule.length === 1 ? '' : 's'
                          } to schedule:`}</Text>
                        </Flex>
                        <ModulesList
                          isLoading={false}
                          moduleItems={moduleItemsToSchedule}
                          compact
                          isExpired={courseExpired}
                        />
                      </>
                    ) : null}
                    {numSessionsLocked > 0 ? (
                      <Flex
                        alignItems="center"
                        paddingX="defaultPadding"
                        paddingY={1}
                      >
                        <MdIcon
                          name="RadioButtonUnchecked"
                          boxSize="icon"
                          color="common.muted"
                        />
                        <Text ml={2}>{`${numSessionsLocked} session${
                          numSessionsLocked === 1 ? '' : 's'
                        } remaining`}</Text>
                      </Flex>
                    ) : null}
                  </>
                )}
              </PopoverBody>
            </PopoverContent>
          ),
        };
      });

  // Create a list of completed and scheduled sessions
  const scheduleItems = isLoading
    ? []
    : Object.values(moduleSchedules)
        .map((moduleSchedule) => {
          const progress = moduleProgress[moduleSchedule.module];
          if (progress?.status === PROGRESS_STATUS.complete) {
            const dateCompleted = progress.dateCompleted || new Date();
            return {
              module: moduleSchedule.module,
              start: moment(dateCompleted).startOf('day'),
              end: moment(dateCompleted).endOf('day'),
            };
          }
          const yesterday = moment().subtract(1, 'day');
          const isScheduled =
            moduleSchedule.scheduled &&
            !moment(moduleSchedule.scheduledTime).isBefore(yesterday);
          if (isScheduled) {
            return {
              module: moduleSchedule.module,
              start: moment(moduleSchedule.scheduledTime).startOf('day'),
              end: moment(moduleSchedule.scheduledTime).endOf('day'),
            };
          }
          return null;
        })
        .filter((item) => item !== null);

  // Organise sessions by date and sub-organise sessions by complete vs scheduled vs upload missing
  const scheduleItemsByDate = scheduleItems.reduce(
    (
      obj: {
        [date: string]: {
          id: string;
          title: string;
          start: moment.Moment;
          end: moment.Moment;
          group: number;
          bgColor: string;
          bgAccentColor: string;
          textColor: string;
          completeModuleItems: ModuleListItemProps[];
          scheduledModuleItems: ModuleListItemProps[];
          uploadMissingModuleItems: ModuleListItemProps[];
          courseSlugs: string[];
        };
      },
      value
    ) => {
      if (value) {
        const key = value.start.toISOString();
        if (obj[key] == null) {
          obj[key] = {
            id: key,
            title: key,
            start: value.start,
            end: value.end,
            group: 1, // Add all of these items to the first row (group) of the timeline
            ...completeColorCombo,
            completeModuleItems: [],
            scheduledModuleItems: [],
            uploadMissingModuleItems: [],
            courseSlugs: [],
          };
        }
        const progress = moduleProgress[value.module];
        if (progress?.status !== PROGRESS_STATUS.complete) {
          obj[key] = { ...obj[key], ...primaryColorCombo };
        }

        const moduleSchedule = moduleSchedules[value.module];
        const unitSchedule = unitSchedules[moduleSchedule.unit];
        const unit = units[moduleSchedule.unit];

        const courseSlug = unitSchedule?.course;
        if (!obj[key].courseSlugs.includes(courseSlug)) {
          obj[key].courseSlugs.push(courseSlug);
        }

        const cohort = cohorts[courseSlug];
        const course = courses.find((c) => c.slug === courseSlug);
        const discourseCategoryId = course
          ? getCohortSubcategoryId(cohort, course) || undefined
          : undefined;

        const module = modules[value.module];
        if (module) {
          const uploads = journalEntries.filter(
            (je) =>
              je.destinationContentType === 'module' &&
              module.id === je.destinationObjectId
          );
          const moduleCompletionStatus = getModuleCompletionStatus({
            module,
            uploads,
            progress,
          });
          const tags = [`module-${module.index - 1}`, `unit-${unit.index - 1}`];

          const moduleItem = {
            imageUrl: module.imageThumbnail,
            isLoading: false,
            linkTo: navRoutes.learner.module.path(courseSlug, module.slug),
            showScheduleBtn: true,
            title: module.title,
            progress,
            schedule: moduleSchedule,
            courseSlug,
            isTop: false,
            isBottom: false,
            uploads,
            uploadMissing: moduleCompletionStatus === 'uploadMissing',
            discourseCategoryId,
            tags,
          };
          if (moduleCompletionStatus === 'complete') {
            obj[key].completeModuleItems.push(moduleItem);
          } else if (moduleCompletionStatus === 'uploadMissing') {
            obj[key].uploadMissingModuleItems.push(moduleItem);
          } else {
            obj[key].scheduledModuleItems.push(moduleItem);
          }
        }
      }
      return obj;
    },
    {}
  );

  // Create popups for each day with lists of sessions that have been scheduled or completed
  const scheduleDateItems = Object.values(scheduleItemsByDate).map((item) => ({
    ...item,
    popoverContent: (
      <PopoverContent width="sm">
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>
          <Text fontWeight="bold">{item.start.format('Do MMMM YYYY')}</Text>
        </PopoverHeader>
        <PopoverBody
          paddingX={0}
          paddingY={1}
          maxHeight="40vh"
          overflowY="scroll"
        >
          {item.scheduledModuleItems.length > 0 ? (
            <>
              <Flex alignItems="center" paddingX="defaultPadding" paddingY={1}>
                <MdIcon
                  name="RadioButtonUnchecked"
                  boxSize="icon"
                  color="common.muted"
                />
                <Text ml={2}>{`${item.scheduledModuleItems.length} session${
                  item.scheduledModuleItems.length === 1 ? '' : 's'
                } scheduled:`}</Text>
              </Flex>
              <ModulesList
                isLoading={false}
                moduleItems={item.scheduledModuleItems}
                compact
              />
            </>
          ) : null}
          {item.uploadMissingModuleItems.length > 0 ? (
            <>
              <Flex alignItems="center" paddingX="defaultPadding" paddingY={1}>
                <MdIcon
                  name="RadioButtonUnchecked"
                  boxSize="icon"
                  color="common.muted"
                />
                <Text ml={2}>{`${item.uploadMissingModuleItems.length} post${
                  item.uploadMissingModuleItems.length === 1 ? '' : 's'
                } required:`}</Text>
              </Flex>
              <ModulesList
                isLoading={false}
                moduleItems={item.uploadMissingModuleItems}
                compact
              />
            </>
          ) : null}
          {item.completeModuleItems.length > 0 ? (
            <>
              <Flex alignItems="center" paddingX="defaultPadding" paddingY={1}>
                <MdIcon name="TaskAlt" boxSize="icon" color="common.progress" />
                <Text ml={2}>{`${item.completeModuleItems.length} session${
                  item.completeModuleItems.length === 1 ? '' : 's'
                } completed:`}</Text>
              </Flex>
              <ModulesList
                isLoading={false}
                moduleItems={item.completeModuleItems}
                compact
              />
            </>
          ) : null}
        </PopoverBody>
      </PopoverContent>
    ),
    onPopoverOpen: () => {
      requestAnimationFrame(() => {
        item.courseSlugs.forEach((slug) =>
          dispatch(courseActions.retrieve(slug))
        );
      });
    },
  }));

  // Combine all dated items into one for the timeline
  const items = [...courseItems, ...scheduleDateItems];

  return (
    <Box position="relative" flex={1} minH={`calc(140vh - ${NAV_HEIGHT}px)`}>
      <Box
        position="absolute"
        top={0}
        left={0}
        right={0}
        bottom={0}
        overflowY="scroll"
      >
        <ScheduleTimeline
          isLoading={isLoading}
          groups={groups}
          // @ts-ignore
          items={items}
        />
        <Box position="absolute" top="70px" left={{ base: 0, md: 3 }}>
          <SectionTitle
            title="Sessions"
            color="text.muted"
            tooltipInfo="schedule_sessions"
          />
        </Box>
        <Box position="absolute" top="180px" left={{ base: 0, md: 3 }}>
          <SectionTitle
            title="Classes"
            color="text.muted"
            tooltipInfo="schedule_classes"
          />
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  courses: getUserLibraryCourses(state),
  units: state.learner.courses.units,
  courseProgress: state.learner.courseProgress.courses,
  moduleProgress: state.learner.courseProgress.modules,
  courseSchedules: state.learner.courseSchedule.courses,
  unitSchedules: state.learner.courseSchedule.units,
  moduleSchedules: state.learner.courseSchedule.modules,
  modules: state.learner.courses.modules,
  journalEntries: state.learner.journal.journalEntries,
  cohorts: state.learner.courses.cohorts,
});

const connector = connect(mapStateToProps);

export default connector(Schedule);
