export const getVideoDimensions = async (url: string) => {
  return new Promise<{ height: number; width: number }>((resolve) => {
    // create the video element
    const video = document.createElement('video');

    // place a listener on it
    video.addEventListener(
      'loadedmetadata',
      () => {
        // retrieve dimensions
        const height = video.videoHeight;
        const width = video.videoWidth;

        // send back result
        resolve({ height, width });
      },
      false
    );

    // start download meta-datas
    video.src = url;
  });
};

export default {
  getVideoDimensions,
};
