import React from 'react';
import sanitizeHtml from 'sanitize-html';

import { Box, Flex, Text } from '@workshop/ui';

import {
  CheckListState,
  ModuleProgress,
  ModuleQuestion,
  ModuleStep,
  ModuleVideoClip,
  Prompt,
  SessionFormat,
} from 'types/learner';

import { SESSION_STEP_TYPE } from 'constants/courses';

import {
  GuidedStep,
  PromptStep,
  MCQStep,
  SessionStepperStep,
} from 'components/SessionPlayer/SessionStepper';
import { SessionPlayerStep } from 'components/SessionPlayer';
import { CheckListProps } from 'components/SessionPlayer/CheckList';

const generatePromptSubStep = (
  prompt: Prompt,
  promptProps?: {
    discourseCategoryId?: number;
    moduleProgressId: number;
    tags: string[];
    isAssessment: boolean;
    onSubmitSuccess: () => void;
  }
): PromptStep => ({
  id: prompt.id,
  help: prompt.tip
    .split('\\n')
    .filter((a) => a.length)
    .map((a) => (
      <>
        <Box>{a}</Box>
        <br />
      </>
    )),
  promptProps,
  question: prompt.title,
  subStepType: 'prompt',
  title: prompt.label,
  responseType: prompt.responseType,
});

const generateQuestionSubSteps = (
  questionIds: number[],
  moduleQuestions: { [key: number]: ModuleQuestion },
  moduleId: number,
  onSubmit: (answers: number[]) => Promise<void>
): MCQStep[] =>
  questionIds
    .filter((id) => moduleQuestions[id])
    .map((id) => {
      const { choices, explanation, content: question } = moduleQuestions[id];

      const answers = choices.map((answer) => ({
        id: answer.id,
        content: answer.content,
        isCorrect: answer.correct,
      }));

      return {
        subStepType: 'mcq',
        id: `${moduleId}_${id}`,
        multipleChoiceQuestion: {
          id,
          answers,
          explanation,
          question,
          onSubmit,
        },
      };
    });

const generateGuidedSubSteps = (videoClips: ModuleVideoClip[]): GuidedStep[] =>
  videoClips.map(({ id, orientation, summary, videoHls, video }) => ({
    id,
    clipSrc: videoHls || video,
    orientation,
    summary,
    subStepType: 'guided',
    clipMediaType: 'video',
  }));

export const generatePracticeSteps = (
  steps: ModuleStep[],
  exerciseText: string,
  imagePortraitMobile: string
): SessionPlayerStep[] =>
  steps.map((step) => ({
    id: step.id,
    title: ({ color }) => (
      <Flex color={color} alignItems="center">
        <Text fontWeight="semibold" ml={2} flex={1} lineHeight="1rem">
          {step.title}
        </Text>
      </Flex>
    ),
    notes: step.notes,
    stepType: 'normal',
    unlocked: true,
    subSteps: [
      {
        subStepType: 'guided',
        clipMediaType: 'image',
        clipSrc: imagePortraitMobile,
        id: 0,
        summary: () => (
          <div
            dangerouslySetInnerHTML={{ __html: sanitizeHtml(exerciseText) }}
          />
        ),
      },
    ],
  }));

interface GenerateStepsArgs {
  checklist: CheckListProps | undefined;
  exerciseText: string;
  imagePortraitMobile: string;
  moduleFormat: SessionFormat;
  moduleProgress?: ModuleProgress;
  moduleQuestions: { [key: number]: ModuleQuestion };
  steps: ModuleStep[];
  onSubmitAnswers?: (id: number) => (answers: number[]) => Promise<void>;
  promptProps?: {
    discourseCategoryId?: number;
    moduleProgressId: number;
    tags: string[];
    isAssessment: boolean;
    onSubmitSuccess: () => void;
  };
  unlocked?: boolean;
}

/**
 * Transform a list of raw module steps (from the global state)
 * into a list of steps for the SessionPlayer to consume
 */
export const generateSteps = ({
  checklist,
  exerciseText,
  imagePortraitMobile,
  moduleFormat,
  moduleProgress,
  moduleQuestions,
  steps,
  onSubmitAnswers = () => async () => {},
  promptProps,
  unlocked = false,
}: GenerateStepsArgs): SessionPlayerStep[] => {
  if (moduleFormat === 'practice') {
    return generatePracticeSteps(steps, exerciseText, imagePortraitMobile);
  }

  const hasIntro = steps.find((s) => s.stepType === SESSION_STEP_TYPE.intro);

  return steps.map(
    (
      {
        id,
        notes,
        prompt,
        stepType,
        title: stepTitle,
        videoClips,
        questions = [],
      },
      idx
    ) => {
      const isIntorOrOutro = stepType === 'intro' || stepType === 'outro';

      const title: React.FC<{ color?: string }> = ({ color }) =>
        isIntorOrOutro ? (
          <Text color={color} fontWeight="semibold">
            {stepType === 'intro' ? 'Introduction' : 'Summary'}
          </Text>
        ) : (
          <Flex color={color} alignItems="center">
            <Text>{`Step ${hasIntro ? idx : idx + 1}:`}</Text>
            <Text fontWeight="semibold" ml={2} flex={1} noOfLines={1}>
              {stepTitle}
            </Text>
          </Flex>
        );

      /**
       * Base details for the current step - this is only missing the list
       * of substeps, which will be defined below based on the step type
       */
      const sessionPlayerStep = {
        notes,
        stepType,
        title,
        id,
        unlocked:
          unlocked || (moduleProgress && idx + 1 <= moduleProgress.currentStep),
      };

      /**
       * Start by generating a list of guided sub-steps
       * (these are relevant relgardless of the step type)
       */
      let subSteps: SessionStepperStep[] = generateGuidedSubSteps(videoClips);

      /**
       * If this is the intro step and we have a checklist to work with,
       * return early after appending the checklist to the list of sub-steps
       */
      if (stepType === 'intro' && checklist) {
        subSteps = [
          ...subSteps,
          {
            id: 'checklist',
            subStepType: 'checklist',
            title: '',
            ...checklist,
          },
        ];

        return {
          ...sessionPlayerStep,
          subSteps,
        };
      }

      /**
       * This is the intro but contains a prompt - add a prompt substep
       * then return early.
       * The prompt itself won't have an onsubmit, as typically the prompt
       * will show again on the last step, where the answer gets submitted
       * to the backend (see below)
       */
      if (stepType === 'intro' && prompt && prompt.responseType === 'none') {
        subSteps = [...subSteps, generatePromptSubStep(prompt)];

        return {
          ...sessionPlayerStep,
          subSteps,
        };
      }

      /**
       * Generate MCQ steps and add them to the list of sub-steps.
       * These questions will be displayed after all the guided steps.
       */
      const questionSteps = generateQuestionSubSteps(
        questions,
        moduleQuestions,
        id,
        onSubmitAnswers(id)
      );

      subSteps = [...subSteps, ...questionSteps];

      if (prompt) {
        const promptStep = generatePromptSubStep(prompt, promptProps);
        subSteps = [...subSteps, promptStep];
      }

      return {
        ...sessionPlayerStep,
        subSteps,
      };
    }
  );
};

export const parseChecklistStateJson = (state: string): CheckListState => {
  let userCheckListState = { completed: false };

  try {
    userCheckListState = JSON.parse(state);
    // If it's still a string (double encoded by the backend),
    // then try to parse it again as a last ditch attempt.

    // N.B this is a hack for if the backend provides incorrect
    // data. Ideally this shouldnt be needed.
    if (typeof userCheckListState === 'string') {
      userCheckListState = JSON.parse(state);
    }
    return userCheckListState;
  } catch (err) {
    return userCheckListState;
  }
};
