import { CohortAT, CourseAT, EnrolmentAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import { CohortState, CohortUIState } from 'types/common';

const initialState: CohortState = {};

export const cohortReducer = (
  state = initialState,
  action: Action
): CohortState => {
  let cohortId: number | null = null;
  let newState = {} as CohortState;

  switch (action.type) {
    case CohortAT.CMS_LIST_COHORTS_REQUEST:
    case CohortAT.CMS_UPDATE_COHORT_REQUEST:
      return state;

    case CohortAT.CMS_LIST_COHORTS_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.cohorts,
      };

    case CohortAT.CMS_UPDATE_COHORT_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.cohorts,
      };

    case CohortAT.CMS_LIST_COHORTS_FAILURE:
    case CohortAT.CMS_UPDATE_COHORT_FAILURE:
      return state;

    case CourseAT.CMS_COHORT_LIST_SUCCESS:
      return { ...state, ...action.payload.entities.cohorts };

    case CohortAT.CMS_CREATE_COHORT_SUCCESS:
      return {
        ...state,
        [action.payload.id]: action.payload,
      };

    case CohortAT.CMS_DELETE_COHORT_SUCCESS:
      cohortId = action.meta.cohortId as number;

      newState = Object.keys(state)
        .filter((k) => parseInt(k) !== cohortId)
        .reduce(
          (acc, key) => ({ ...acc, [key]: state[key] }),
          {} as CohortState
        );

      return newState;

    case EnrolmentAT.CMS_CREATE_ENROLMENT_SUCCESS:
      const enrolmentId = action.payload.result;
      cohortId = action.payload.entities.enrolments[enrolmentId].currentCohort;
      return {
        ...state,
        [cohortId]: {
          ...state[cohortId],
          numEnrolments: state[cohortId].numEnrolments + 1,
        },
      };

    // case CohortAT.CMS_LIST_COHORT_CP_SUCCESS:
    //   cohortId = action.meta.cohortId as number;

    //   newState = Object.keys(state).reduce((acc, k) => {
    //     const cohort = state[k];
    //     const courseProgress =
    //       parseInt(k) === cohortId
    //         ? action.payload.results
    //         : cohort.courseProgress;

    //     return { ...acc, [k]: { ...cohort, courseProgress } };
    //   }, {} as CohortState);

    // return newState;

    default:
      return state;
  }
};

const initialUIState: CohortUIState = {
  loading: false,
  error: false,
  errorPayload: null,
  errorMessage: null,
  success: false,
};

export const cohortUIReducer = (
  state = initialUIState,
  action: Action
): CohortUIState => {
  switch (action.type) {
    case CohortAT.CMS_LIST_COHORTS_REQUEST:
    case CohortAT.CMS_UPDATE_COHORT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CohortAT.CMS_LIST_COHORTS_SUCCESS:
      return {
        ...state,
        ...initialUIState,
      };
    case CohortAT.CMS_UPDATE_COHORT_SUCCESS:
    case CohortAT.CMS_LIST_COHORTS_FAILURE:
    case CohortAT.CMS_UPDATE_COHORT_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};
