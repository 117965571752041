import React, { useEffect, useState } from 'react';
import { useForm, useFieldArray } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';

import {
  COURSE_PUBLISH_STATUS,
  ACCESS_TYPES,
  COHORT_TYPES,
  PRIVATE_CLASS_SIZE_LIMIT,
} from 'constants/courses';

import { Divider, Flex, MdIcon, Text, Button, Stack, Box } from '@workshop/ui';

import {
  LabelInput,
  LabelSelect,
  LabelDatePicker,
  StepsModal,
} from 'components/Common';
import { Props as EditModalProps } from 'components/Common/EditModal';
import { ClassTypeItem } from 'components/CohortAllocation';

import { courseUtils } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { cohortActions } from 'redux/actions/cms';
import { useCurrentTeamProfile } from 'redux/selectors';
import { getLicensedCourses } from 'redux/selectors/course';

import { GlobalState } from 'types';
import { TeamMember, Organisation, AccessType, SocialType } from 'types/common';

export interface CreateCohortFormData {
  accessType: AccessType;
  course: number;
  isPrivate: boolean;
  isTest: boolean;
  isAnonymous: boolean;
  label: string;
  socialType: SocialType;
  spacesTotal: number;
  startDate: Date | null;
  mentors: {
    mentorId: string;
    isAssessor: boolean;
    isSubstitute: boolean;
    subDetails?: {
      subStart: Date | undefined;
      subEnd: Date | undefined;
    };
  }[];
}

const labelStyleProps = { whiteSpace: 'nowrap' } as const;

interface CreateCohortModalProps extends EditModalProps {
  availableMentors: TeamMember[];
  onCreateCohort: (id: number) => void;
}

const CreateCohortModal: React.FC<CreateCohortModalProps> = (props) => {
  const {
    availableMentors,
    isOpen,
    modalSize,
    title,
    onClose,
    onCreateCohort,
  } = props;

  const [isUpdating, setIsUpdating] = useState(false);
  const defaultClassType = COHORT_TYPES[0];
  const [selectedClassType, setSelectedClassType] = useState(defaultClassType);

  const dispatch = useDispatch();

  const currentTeam = useCurrentTeamProfile() as Organisation;

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < 689;

  const courseList = useSelector(
    (state: GlobalState) => state.cms.course.courseList
  );

  const liveCourses = Object.values(courseList).filter(
    (c) =>
      c.organisation === currentTeam.id &&
      c.status === COURSE_PUBLISH_STATUS.published
  );

  const courseOptions = liveCourses.reduce(
    (acc, curr) => ({ ...acc, [curr.id.toString()]: curr.title }),
    {} as { [key: string]: string }
  );

  const licensedCourses = useSelector((state: GlobalState) =>
    getLicensedCourses(
      state.learner.courses.courses.detail,
      state.cms.enrolment.license,
      currentTeam.id
    )
  );

  const licensedCourseOptions = licensedCourses
    ? Object.values(licensedCourses).reduce(
        (acc, curr) => ({
          ...acc,
          [curr.id.toString()]: `${curr.title} (from ${curr.organisation.name})`,
        }),
        {} as { [key: string]: string }
      )
    : {};

  const mentorOptions = availableMentors.reduce(
    (acc, curr) => ({ ...acc, [curr.user.id]: curr.user.name }),
    {} as { [key: string]: string }
  );
  const mentorOptionsKeys = Object.keys(mentorOptions);

  const allOptions = {
    ...licensedCourseOptions,
    ...courseOptions,
  };

  const firstCourseOption =
    Object.entries(allOptions).length > 0
      ? parseInt(
          Object.entries(allOptions).sort(([aKey, aValue], [bKey, bValue]) =>
            aValue.localeCompare(bValue)
          )[0][0]
        )
      : undefined;

  const { handleSubmit, control, errors, register, setValue, watch, reset } =
    useForm<CreateCohortFormData>({
      mode: 'onChange',
      defaultValues: {
        course: firstCourseOption,
        label: '',
        spacesTotal: undefined,
        startDate: new Date(),
        socialType: defaultClassType.socialType,
        accessType: defaultClassType.accessType,
        isAnonymous: defaultClassType.isAnonymous,
        // If only 1 mentor is available, add them by default
        mentors:
          mentorOptionsKeys.length === 1
            ? [
                {
                  mentorId: mentorOptionsKeys[0],
                  isAssessor: true,
                  isSubstitute: false,
                },
              ]
            : [{ mentorId: '', isAssessor: true, isSubstitute: false }],
      },
    });

  const {
    fields: mentors,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'mentors',
  });

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleOnSave = async (e?: React.FormEvent<HTMLFormElement>) => {
    setIsUpdating(true);
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    const submit = await handleSubmit(async ({ mentors, ...data }) => {
      const res = await dispatch(
        cohortActions.createCohort({
          ...data,
          organisation: currentTeam.id,
          course: data.course.toString(),
          startDate: moment(startDate?.toISOString()).format('YYYY-MM-DD'),
          onSale: false,
          isPrivate: true,
          isTest: false,
          privateChatDisabled: true,
          spacesTotal: courseUtils.hasClassSizeLimit({
            socialType: data.socialType,
            isAnonymous: data.isAnonymous,
          })
            ? data.spacesTotal
            : 0,
          mentors: mentors
            .filter((m) => m.mentorId)
            .map((m) => ({
              assessor: true,
              isSubstitute: m.isSubstitute,
              mentor: parseInt(m.mentorId),
              subStartDate:
                m.isSubstitute && m.subDetails?.subStart
                  ? moment(m.subDetails?.subStart?.toISOString()).format(
                      'YYYY-MM-DD'
                    )
                  : undefined,
              expiryDate:
                m.isSubstitute && m.subDetails?.subEnd
                  ? moment(m.subDetails?.subEnd?.toISOString()).format(
                      'YYYY-MM-DD'
                    )
                  : undefined,
            })),
        })
      );
      if (res?.payload?.hasOwnProperty('id')) {
        // Overwrite the default course values set on the backend (see "save" function in Cohort model)
        // TODO: Use default course accessType & socialType values to pre-select cohort type in flow
        await dispatch(
          // @ts-ignore
          cohortActions.updateCohort(res.payload.id, {
            accessType: data.accessType,
            socialType: data.socialType,
          })
        );
        // @ts-ignore
        onCreateCohort(res.payload.id);
      }
    });

    await submit(e);

    setIsUpdating(false);

    handleOnClose();
  };

  useEffect(() => {
    register('course', { required: true });
    register('startDate', { required: true });
    register('label');
    register('spacesTotal');
    register('socialType');
    register('accessType');
    register('isAnonymous');
  }, [register]);

  useEffect(() => {
    setValue('socialType', selectedClassType.socialType);
    setValue('accessType', selectedClassType.accessType);
    setValue('isAnonymous', selectedClassType.isAnonymous);
  }, [selectedClassType.id]);

  const courseValue = watch('course');
  const startDate = watch('startDate');
  const mentorValues = watch('mentors');
  const nonSubMentors = mentorValues.filter((m) => !m.isSubstitute);
  const accessTypeValue = watch('accessType');
  const socialTypeValue = watch('socialType');
  const isAnonymousValue = watch('isAnonymous');
  const spacesTotalValue = watch('spacesTotal');

  const isValid = mentorValues.find((val) => val.mentorId);
  const hasClassSizeLimit = courseUtils.hasClassSizeLimit({
    socialType: socialTypeValue,
    isAnonymous: isAnonymousValue,
  });

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    heading: title,
    loading: isUpdating,
    submitting: isUpdating,
  };

  return (
    <StepsModal
      {...modalProps}
      hideStepLabels={!isMobile}
      onCompleteStep={async (stepIndex: number) => {
        if (stepIndex === 3) {
          handleOnSave();
        }
      }}
      steps={[
        {
          label: 'Select Course',
          icon: <MdIcon name="Class" />,
          nextButtonText: 'Next',
          content: (
            <Box pt={6} pb={{ base: 6, md: 0 }}>
              <LabelSelect
                isDisabled={false}
                label="Course"
                onChange={(e) => setValue('course', parseInt(e.target.value))}
                error={!!errors.course}
                defaultValue={undefined}
                options={allOptions}
                labelStyleProps={labelStyleProps}
                labelPosition={isMobile ? 'top' : 'inline'}
              />
            </Box>
          ),
        },
        {
          label: 'Select Type',
          icon: <MdIcon name="Style" />,
          nextButtonText: 'Next',
          content: (
            <Box pt={6} pb={{ base: 6, md: 0 }}>
              <Stack direction="column" spacing="defaultMargin">
                {/* Only show 'live rooms' option (isAnonymous == true) if this course is licenced */}
                {COHORT_TYPES.filter((t) =>
                  Boolean(
                    licensedCourses &&
                      Object.values(licensedCourses).find(
                        (c) => c.id === courseValue
                      )
                  )
                    ? true
                    : !t.isAnonymous
                ).map((classType) => (
                  <ClassTypeItem
                    key={`classType-${classType.id}`}
                    classType={classType}
                    selectedClassType={selectedClassType}
                    setSelectedClassType={setSelectedClassType}
                    showDivider={classType.isAnonymous}
                    isSelectable
                  />
                ))}
              </Stack>
            </Box>
          ),
        },
        {
          label: 'Enter Details',
          icon: <MdIcon name="Info" />,
          nextButtonText: 'Next',
          nextButtonDisabled: hasClassSizeLimit
            ? !spacesTotalValue ||
              spacesTotalValue <= 0 ||
              spacesTotalValue > PRIVATE_CLASS_SIZE_LIMIT
            : false,
          content: (
            <Box pt={6} pb={{ base: 6, md: 0 }}>
              {/* Main section */}
              <Flex flexDir="column">
                <Flex flexDir="column" flex={1}>
                  <LabelDatePicker
                    id="startDate"
                    label={
                      accessTypeValue === ACCESS_TYPES.scheduled
                        ? 'Start Date'
                        : 'Launch Date'
                    }
                    onChange={(date) => {
                      setValue('startDate', date);
                    }}
                    date={startDate}
                    labelStyleProps={{ whiteSpace: 'nowrap' }}
                    labelPosition={isMobile ? 'top' : 'inline'}
                  />
                </Flex>
                <Flex px={2} />
                <Flex flexDir="column" flex={1}>
                  <LabelInput
                    label="Label"
                    onChange={(e) => setValue('label', e.target.value)}
                    helpText="(Optional) Add a short label to name your class or to help students differentiate between classes starting on the same date."
                    labelStyleProps={labelStyleProps}
                    labelPosition={isMobile ? 'top' : 'inline'}
                  />
                  {hasClassSizeLimit ? (
                    <LabelInput
                      label="Class Size Limit"
                      onChange={(e) =>
                        setValue('spacesTotal', parseInt(e.target.value))
                      }
                      placeholder={`Enter a number between 1 and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                      helpText="The maximum number of students that will be able to join this class. We recommend setting a limit of 10 students for the best group-learning experience."
                      inputType="number"
                      error={
                        spacesTotalValue <= 0 ||
                        spacesTotalValue > PRIVATE_CLASS_SIZE_LIMIT
                      }
                      errorMessage={`Please enter a number between 1 and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                      labelStyleProps={labelStyleProps}
                      labelPosition={isMobile ? 'top' : 'inline'}
                    />
                  ) : null}
                </Flex>
              </Flex>
            </Box>
          ),
        },
        {
          label: 'Pick Mentors',
          icon: <MdIcon name="School" />,
          nextButtonText: 'Create Class',
          nextButtonDisabled: !isValid || isUpdating,
          content: (
            <Box pt={6} pb={{ base: 6, md: 0 }}>
              {/* Mentors section */}
              <Flex flexDir="column">
                <Flex flexDir="row" mb={2}>
                  <Flex w="95%">
                    <Text color="text.muted">Class Mentor(s)</Text>
                  </Flex>
                  <Flex w="5%" />
                </Flex>
                {mentors.map((item, idx) => {
                  const mentor = mentorValues[idx] || item;
                  const isSub = mentor.isSubstitute;
                  const isOnlyPermMentor = !isSub && nonSubMentors.length < 2;
                  const isLastMentor = idx + 1 === mentors.length;
                  return (
                    <Flex flexDir="column" key={item.id}>
                      <Divider mb="4" />
                      <Flex flexDir="row" alignItems="center">
                        <Flex w="95%">
                          <LabelSelect
                            name={`mentors[${idx}].mentorId` as const}
                            registerInputRef={register()}
                            defaultValue={item.mentorId}
                            options={{ '': '-', ...mentorOptions }}
                          />
                        </Flex>
                        <Flex w="5%" justifyContent="flex-end">
                          {!isOnlyPermMentor && (
                            <MdIcon
                              color="red.400"
                              cursor="pointer"
                              name="RemoveCircle"
                              onClick={() => remove(idx)}
                              mb="defaultMargin"
                            />
                          )}
                        </Flex>
                      </Flex>
                      {isLastMentor && <Divider mb="4" />}
                    </Flex>
                  );
                })}
              </Flex>
              <Flex flex={1} justifyContent="flex-end">
                <Button
                  icon="Add"
                  variant="ghost"
                  onClick={() =>
                    append({
                      mentorId: '',
                      isAssessor: true,
                      isSubstitute: false,
                    })
                  }
                >
                  Add Mentor
                </Button>
              </Flex>
            </Box>
          ),
        },
      ]}
    />
  );
};

export default CreateCohortModal;
