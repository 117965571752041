import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Flex, Heading, Paragraph } from '@workshop/ui';

interface Props extends RouteComponentProps {
  name: string;
}

const PageForbiddenScreen: React.FC<Props> = (props) => {
  return (
    <Flex flex="1 0" alignItems="center" py={10} flexDir="column">
      <Heading as="h3">403 Unauthorized</Heading>
      <Paragraph fontSize="lg">
        You do not have permission view this page
      </Paragraph>
    </Flex>
  );
};

export default PageForbiddenScreen;
