import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';

import { GlobalState } from 'types';
import { Module } from 'types/learner';

import { courseActions, stepQuestionActions } from 'redux/actions/learner';

import { hooks, getParamFromUrl } from 'utils';

import { Flex } from '@workshop/ui';

import { SessionPlayer } from 'components/SessionPlayer';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { generateSteps } from 'screens/learner/Session/src/utils';

// Routing Props
interface MatchParams {
  courseId?: string;
  moduleId?: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {
  session?: Module;
  asComponent?: boolean;
}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Session: React.FC<Props> = ({
  courseId,
  location,
  history,
  module,
  moduleQuestions,
  asComponent,
}) => {
  const stepId = getParamFromUrl(location, 'step');

  /** ---------------- DATA LOADING ---------------- */
  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: courseId ? [() => courseActions.retrieve(courseId)] : [],
      },
    },
    [courseId]
  );

  const {
    checkList: [moduleChecklist],
    steps: moduleSteps = [],
    moduleFormat = 'guided',
    exerciseText = '',
    imagePortraitMobile = '',
  } = module || { checkList: [] };

  /**
   * Build an array containing the question IDs for all the steps included in this module
   * Fetch all these questions via the API
   */
  const questionIds = moduleSteps.reduce(
    (acc: number[], { questions }) =>
      questions && questions.length ? [...acc, ...questions] : [...acc],
    []
  );

  const { questionsLoading } = hooks.useLoadingDataState(
    {
      questionsLoading: {
        actions: questionIds.length
          ? [() => stepQuestionActions.list(questionIds)]
          : [],
        startLoading: !Boolean(courseLoading),
      },
    },
    [questionIds?.length, courseLoading]
  );

  if (courseLoading || questionsLoading) {
    if (asComponent) {
      return (
        <Flex flexDirection="column" mb={4} position="relative">
          <SessionPlayer
            loading
            onCompleteSession={async () => null}
            onSetOrienation={async () => null}
            onUnlockStep={async () => null}
            steps={[]}
            navigationStep={null}
            navigateToStep={() => null}
          />
        </Flex>
      );
    }
    return (
      <ScreenWrapper>
        <Flex flexDirection="column" mb={4} position="relative">
          <SessionPlayer
            loading
            onCompleteSession={async () => null}
            onSetOrienation={async () => null}
            onUnlockStep={async () => null}
            steps={[]}
            navigationStep={null}
            navigateToStep={() => null}
          />
        </Flex>
      </ScreenWrapper>
    );
  }

  if (!module) {
    /** TODO : improve this, maybe redirect? */

    /** Returning early if no module found (e.g typo in module slug) */
    return null;
  }

  /** ---------------- CHECKLIST DATA ---------------- */
  const checkListItems = moduleChecklist?.items.map(
    ({ id, title: content, slug }) => {
      return {
        id,
        isChecked: true,
        content,
        slug,
      };
    }
  );

  const checklist = checkListItems
    ? {
        items: checkListItems,
        title: moduleChecklist.title,
      }
    : undefined;

  /** ---------------- STEPS DATA ---------------- */
  const steps = generateSteps({
    checklist,
    imagePortraitMobile,
    exerciseText,
    moduleFormat,
    moduleQuestions,
    steps: moduleSteps,
    unlocked: true,
  });

  const sessionPlayerComponent = (
    <Flex flexDirection="column" mb={4} position="relative">
      <SessionPlayer
        previewModeEnabled
        onCompleteSession={async () => null}
        onSetOrienation={async () => null}
        onUnlockStep={async () => null}
        requirements={checklist}
        showRequirements={Boolean(checklist)}
        steps={steps}
        navigationStep={stepId}
        pathname={location.pathname}
        navigateToStep={(idx: number) => {
          let currentSearchParams = new URLSearchParams(location.search);
          currentSearchParams.set('step', idx.toString());
          history.push({
            pathname: location.pathname,
            search: currentSearchParams.toString(),
          });
        }}
      />
    </Flex>
  );

  if (asComponent) return sessionPlayerComponent;

  return <ScreenWrapper>{sessionPlayerComponent}</ScreenWrapper>;
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const courseId = props.match?.params.courseId;
  const moduleId = props.match?.params.moduleId;

  const {
    courses: { moduleQuestions, modules: moduleState },
  } = state.learner;

  const module =
    props.session ||
    Object.values(moduleState).find((m) => m.id.toString() === moduleId);

  return {
    courseId,
    module,
    moduleQuestions,
  };
};

const connector = connect(mapStateToProps);

export default connector(Session);
