import React, { useRef, useState, useEffect } from 'react';
import { MessageList, MessageBoxProps } from 'react-chat-elements';
import moment from 'moment';
import { useSpring } from 'react-spring';
import classnames from 'classnames';
import {
  Box,
  Button,
  Flex,
  MdIcon,
  Text,
  Textarea,
  Spinner,
} from '@workshop/ui';
import { Post } from 'discourse-js';
import { parse } from 'node-html-parser';

import { AnimatedFlex } from 'components/Common';
import { UserAvatar } from 'components/UserAvatar';
import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { uiAction } from 'redux/actions/common';

import 'react-chat-elements/dist/main.css';
import './ChatWidget.css';

interface Message extends Omit<MessageBoxProps, 'date' | 'text'> {
  date: string;
  failedToSend?: boolean;
  fromUser?: boolean;
  isSending?: boolean;
  messageId?: number;
  /** The text stored in discourse */
  text: React.ReactNode;
  /** The text as a string - used for sending retry */
  textString?: string;
  user: {
    name: string;
    avatar?: string;
  };
}

interface ConversationWidgetProps {
  details: {
    userId?: number;
    discourseGroupTopicId?: number;
    title: string;
    conversationUser?: {
      username: string;
      isMentor?: boolean;
      avatar?: string;
    };
  };
  loadMorePosts?: (() => Promise<any>) | null;
  expanded?: boolean;
  isLoading?: boolean;
  messages: Message[];
  topicId?: number;
  unreadMessage?: boolean;
  isSetFullscreen?: boolean;
  onSubmit: (
    message: string,
    title: string,
    topicId?: number,
    conversationUserName?: string
  ) => Promise<Post | null>;
  onClose?: (userId?: number, discourseGroupTopicId?: number) => void;
  onToggleExpanded?: (topicId: number | undefined) => void;
  onToggleFullscreen?: (topicId: number | undefined) => void;
  onLoadTopic: (topicId: number) => Promise<any>;
}

/**
 * Convert a list of Message object into a list of MessageBoxProps objects
 * to be consumed by the MessageList component from react-chat-elements
 */
const getMessageListData = (messages: Message[]): MessageBoxProps[] =>
  messages.map((message, idx) => {
    const {
      date,
      failedToSend,
      fromUser,
      isSending,
      user: { name, avatar },
    } = message;

    const dateString = moment(date).format('h:mm a');

    const nextMsgDiffUser =
      idx + 1 < messages.length && messages[idx + 1].user.name !== name;

    const marginBottom = failedToSend || nextMsgDiffUser;

    // Show the date if
    // - this is the first message in the list OR
    // - the previous message ways on a different day
    const showDate =
      idx === 0 || !moment(messages[idx - 1].date).isSame(moment(date), 'day');

    const isLastMsg = idx + 1 >= messages.length;

    const nextMsgDiffDay =
      !isLastMsg && !moment(messages[idx + 1].date).isSame(moment(date), 'day');

    // Only show the avatar if:
    // - the message is from another user (i.e, not the user currently logged in)
    // AND:
    // - this is the last message in the list OR
    // - the next message in the list is from someone else OR
    // - we're showing the date for this message AND
    // - next message is for a different day
    const showAvatar =
      !fromUser &&
      (isLastMsg || nextMsgDiffUser || (showDate && nextMsgDiffDay));

    const userAvatar = showAvatar ? avatar || name : undefined;
    const letterItem =
      showAvatar && !avatar
        ? {
            id: name,
            letter: (
              <Flex
                backgroundColor="background.primary"
                w="25px"
                h="25px"
                borderRadius="20px"
                justifyContent="center"
                alignItems="center"
                fontSize="12px"
              >
                {name[0]}
              </Flex>
            ),
          }
        : undefined;

    /**
     * The react-chat-element library uses the following function to render
     * any extra custom content alongside each message
     *
     * We use to to render the spinner when sending or the date of the message
     */
    const renderAddCmp = () => {
      if (isSending) {
        return <Spinner className="spinner" size="xs" color="common.primary" />;
      }

      if (showDate) {
        return (
          <Box className="message-group-date" textAlign="center">
            <Text fontSize="xs" color="text.muted" marginY={2}>
              {moment(date).format('MMM D, YYYY')}
            </Text>
          </Box>
        );
      }

      return null;
    };

    return {
      ...message,
      avatar: userAvatar,
      className: classnames({
        'margin-bottom': marginBottom,
        'failed-message': failedToSend,
        'is-sending': isSending,
      }),
      date: undefined,
      dateString,
      letterItem,
      position: fromUser ? ('right' as const) : ('left' as const),
      type: 'text' as const,
      renderAddCmp,
      text: message.text || '',
    };
  });

const generateTempId = () => parseInt(Math.random().toString().split('.')[1]);

const ConversationWidget: React.FC<ConversationWidgetProps> = ({
  details: { title, userId, discourseGroupTopicId, conversationUser },
  expanded: isExpanded = false,
  isLoading: isLoadingProp,
  messages: messagesProps,
  topicId,
  unreadMessage,
  isSetFullscreen,
  onClose = () => null,
  onLoadTopic,
  onToggleExpanded = () => null,
  onSubmit,
  onToggleFullscreen,
  loadMorePosts,
}) => {
  const dispatch = hooks.useDispatch();

  const [topicLoaded, setTopicLoaded] = useState(false);
  const [loadingPosts, setLoadingPosts] = useState(false);
  const [messages, setMessages] = useState(messagesProps);
  const [currentMessage, setCurrentMessage] = useState<string | null>(null);
  const [sentMessages, setSentMessages] = useState<{ [key: string]: Message }>(
    {}
  );

  const bottomContainerRef = useRef<HTMLDivElement>(null);

  const windowDimensions = useWindowDimensions();
  const fullscreeenHeight = windowDimensions.height - 280;

  const isFullscreen = isSetFullscreen && isExpanded;

  const animatedStyle = useSpring({
    config: { duration: 100 },
    height: isFullscreen
      ? `${fullscreeenHeight}px`
      : isExpanded
      ? '350px'
      : '0px',
    opacity: isExpanded ? 1 : 0,
  });

  const isLoading = isLoadingProp || !topicLoaded;

  useEffect(() => {
    /**
     * Scroll to the bottom of the container for the MessageList
     * when the widget gets expanded, and when a new message is sent/received.
     */
    if (isExpanded) {
      bottomContainerRef.current?.scrollIntoView();
    }
  }, [isExpanded, Object.keys(sentMessages).length, isLoading]);

  const loadTopic = async () => {
    topicId && (await onLoadTopic(topicId));
    setTopicLoaded(true);
  };

  useEffect(() => {
    if (isLoadingProp || !isExpanded) return;

    if (!userId && !discourseGroupTopicId) return;

    loadTopic();
  }, [isExpanded, isLoadingProp, topicId, userId, discourseGroupTopicId]);

  useEffect(() => {
    /**
     * Update the list of messages for this conversation after we're done sending
     * (so that the list of messages displayed always comes fresh from the redux store)
     */
    const isSending = Object.values(sentMessages).find((m) => m.isSending);
    if (isSending) return;

    if (messagesProps.length !== messages.length) {
      setMessages(messagesProps);
    }
  }, [messagesProps.length, sentMessages]);

  /**
   * A message can appear in both sentMessages and the list of messages coming from props
   * The sentMessages refers to any message that was sent using this ChatWidget and is used
   * as a placeholder until the message is successfully sent and gets returned by the backend
   * into the messages prop.
   *
   * Therefore the following filter is necessary to prevent a message from showing twice, i.e,
   * we only keep the message that came from the messages prop if possible.
   */
  const allMessages = [
    ...messages,
    ...Object.values(sentMessages).filter(
      ({ messageId }) => !messages.find((m) => m.messageId === messageId)
    ),
  ]
    .sort((a, b) => (moment(a.date).isBefore(b.date) ? -1 : 1))
    .sort((a, b) =>
      Boolean(a.failedToSend) === Boolean(b.failedToSend)
        ? 0
        : a.failedToSend
        ? 1
        : -1
    );

  const handleSubmit = async () => {
    if (!currentMessage) return;

    const tempMessageId = generateTempId();

    setSentMessages((state) => ({
      ...state,
      [tempMessageId]: {
        date: moment().format(),
        fromUser: true,
        isSending: true,
        messageId: tempMessageId,
        id: tempMessageId,
        text: currentMessage,
        textString: currentMessage,
        user: { name: '' },
      },
    }));

    setCurrentMessage(null);

    const post = await onSubmit(
      currentMessage,
      title,
      topicId,
      conversationUser?.username
    );

    setSentMessages((state) => ({
      ...state,
      [tempMessageId]: {
        ...state[tempMessageId],
        failedToSend: !Boolean(post),
        isSending: false,
        messageId: post?.id || tempMessageId,
        id: post?.id || tempMessageId,
      },
    }));
  };

  const handleRetry = async (message: Message) => {
    if (!message.textString || !message.messageId) return;

    const messageId = message.messageId;
    if (!sentMessages[message.messageId]) return;

    setSentMessages((state) => ({
      ...state,
      [messageId]: {
        ...state[messageId],
        date: moment().format(),
        fromUser: true,
        isSending: true,
        text: message.text,
        failedToSend: false,
      },
    }));

    const post = await onSubmit(
      message.textString,
      title,
      topicId,
      conversationUser?.username
    );

    setSentMessages((state) => ({
      ...state,
      [messageId]: {
        ...state[messageId],
        failedToSend: !Boolean(post),
        isSending: false,
        messageId: post?.id || messageId,
      },
    }));
  };

  const messageListData = getMessageListData(allMessages);

  const firstIncomingMessage = allMessages.find((m) => !m.fromUser);

  const avatar = discourseGroupTopicId ? (
    <UserAvatar size="2xs" userId={discourseGroupTopicId} name={title} />
  ) : conversationUser ? (
    <UserAvatar
      size="2xs"
      userId={conversationUser.username.length}
      name={conversationUser.username}
      avatarPicture={conversationUser.avatar}
    />
  ) : firstIncomingMessage?.user.avatar ? (
    <UserAvatar
      size="2xs"
      userId={firstIncomingMessage.user.name.length}
      name={firstIncomingMessage.user.name}
      avatarPicture={firstIncomingMessage.user.avatar}
    />
  ) : null;

  const handleToggleIsExpanded = () => {
    onToggleExpanded(topicId);

    if (!userId && !discourseGroupTopicId) return;

    // Collapse/Expand the widget by switching
    // the isExpanded state in the redux store
    const actionData = {
      title,
      username: conversationUser?.username,
      isExpanded: !isExpanded,
    } as const;

    let action;

    if (discourseGroupTopicId) {
      action = uiAction.toggleUserMessaging({
        ...actionData,
        discourseGroupTopicId,
      });
    } else if (userId) {
      action = uiAction.toggleUserMessaging({
        ...actionData,
        userId,
      });
    }

    action && dispatch(action);
  };

  const handleLoadMorePosts = async () => {
    if (!loadMorePosts || loadingPosts) return;

    setLoadingPosts(true);
    await loadMorePosts();
    setLoadingPosts(false);
  };

  return (
    <Flex
      borderTopRadius="lg"
      boxShadow="lg"
      flexDir="column"
      w={isFullscreen ? '900px' : '300px'}
      maxWidth="90vw"
      backgroundColor="background.default"
    >
      <Flex
        alignItems="center"
        cursor="pointer"
        paddingX={2}
        paddingY={2}
        onClick={handleToggleIsExpanded}
      >
        <Flex position="relative">
          {avatar}
          {!isExpanded && unreadMessage && (
            <Box
              w="12px"
              h="12px"
              borderRadius="10px"
              backgroundColor="red.300"
              position="absolute"
              top="-4px"
              right="-4px"
            />
          )}
        </Flex>
        <Text ml={2} flex={1} fontWeight="semibold" fontSize="sm">
          {title}
        </Text>
        {isExpanded && onToggleFullscreen ? (
          <Box
            pr={2}
            onClick={(e) => {
              e.stopPropagation();
              onToggleFullscreen(isFullscreen ? undefined : topicId);
            }}
            _hover={{ opacity: 0.75 }}
          >
            <MdIcon
              color="common.primary"
              cursor="pointer"
              name={isFullscreen ? 'CloseFullscreen' : 'OpenInFull'}
            />
          </Box>
        ) : null}
        <MdIcon
          color="common.primary"
          cursor="pointer"
          name="Cancel"
          _hover={{ opacity: 0.75 }}
          onClick={(e) => {
            e.stopPropagation();
            onClose(userId, discourseGroupTopicId);
          }}
        />
      </Flex>
      <Flex
        flexDir="column"
        style={{ scrollbarWidth: 'none' }}
        className={
          isFullscreen ? 'fs-message-list-container' : 'message-list-container'
        }
      >
        <AnimatedFlex
          style={animatedStyle}
          flexDirection="column"
          overflowY="scroll"
          backgroundColor="background.tint1"
          position="relative"
          maxHeight="90vh"
        >
          {conversationUser?.isMentor && (
            <Flex backgroundColor="background.info">
              <Text
                color="text.info"
                fontSize="xs"
                margin={0}
                paddingX={2}
                paddingY={1}
              >
                When directly contacting your mentor you can expect a response
                within 3 working days
              </Text>
            </Flex>
          )}
          {(isLoading || loadingPosts) && (
            <Flex
              justifyContent="center"
              alignItems="center"
              pt={4}
              position="absolute"
              top="-10px"
              width="100%"
              zIndex={1}
            >
              <Spinner color="common.primary" />
            </Flex>
          )}
          {!messageListData.length && !Boolean(isLoading) && (
            <Text
              pt={2}
              paddingX={4}
              fontSize="sm"
              color="text.muted"
            >{`This is the beginning of your conversation with ${title}.`}</Text>
          )}
          <MessageList
            className="message-list"
            dataSource={messageListData}
            lockable
            // toBottomHeight={'100%'}
            onClick={(messageBox) => {
              const failedMessage = Object.values(sentMessages).find(
                (m) => m.id === messageBox.id
              );

              if (failedMessage) handleRetry(failedMessage);
            }}
            onScroll={(e) => {
              if (e.currentTarget.scrollTop <= 100) {
                handleLoadMorePosts();
              }
            }}
          />
          <Box ref={bottomContainerRef} />
        </AnimatedFlex>
        {isExpanded && (
          <Flex padding={2} backgroundColor="background.default">
            <Textarea
              placeholder="Write a message..."
              value={currentMessage || ''}
              onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                setCurrentMessage(e.target.value)
              }
              onKeyDown={(e) =>
                !isFullscreen &&
                !e.shiftKey &&
                e.key === 'Enter' &&
                handleSubmit() &&
                e.preventDefault()
              }
              mr={2}
              rows={isFullscreen ? 5 : 1}
            />
            <Button isDisabled={!currentMessage?.length} onClick={handleSubmit}>
              Send
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

interface ChatWidgetProps {
  conversations: Omit<ConversationWidgetProps, 'onSubmit' | 'onLoadTopic'>[];
  isLoading?: boolean;
  onSubmit: (
    message: string,
    title: string,
    topicId?: number,
    conversationUser?: string
  ) => Promise<Post | null>;
  onClose?: (userId?: number, discourseGroupTopicId?: number) => void;
  onToggleExpanded?: (topicId: number | undefined) => void;
  onLoadTopic: (topicId: number) => Promise<any>;
}

const ChatWidget: React.FC<ChatWidgetProps> = ({
  conversations,
  isLoading = false,
  onSubmit,
  onClose,
  onToggleExpanded,
  onLoadTopic,
}) => {
  const [fullscreenTopic, setFullscreenTopic] = useState<number | undefined>(
    undefined
  );
  const sortedConversations = [
    ...conversations.filter((c) => c.topicId !== fullscreenTopic),
    ...conversations.filter((c) => c.topicId === fullscreenTopic),
  ];
  return (
    <Flex
      position="fixed"
      bottom={0}
      right={0}
      alignItems="flex-end"
      zIndex={1200}
    >
      {sortedConversations.map((conversation) => (
        <Box marginX={2} key={conversation.details.title}>
          <ConversationWidget
            {...conversation}
            isLoading={isLoading}
            onSubmit={onSubmit}
            onClose={onClose}
            onToggleExpanded={onToggleExpanded}
            onLoadTopic={onLoadTopic}
            onToggleFullscreen={setFullscreenTopic}
            isSetFullscreen={fullscreenTopic === conversation.topicId}
          />
        </Box>
      ))}
    </Flex>
  );
};

export default ChatWidget;
