import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import moment from 'moment';
import sanitizeHtml from 'sanitize-html';

import navRoutes from 'navigation/Routes';

import {
  Box,
  Button,
  Flex,
  LinkBox,
  LinkOverlay,
  LinkButton,
  MdIcon,
  Skeleton,
  Text,
  CircularProgress,
  useTheme,
} from '@workshop/ui';
import { DateTimePicker } from 'components/SelectCard';
import { NewPostPopup } from 'components/NewPostPopup';
import { ActivityPopup } from 'components/ActivityPopup';

import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { PROGRESS_STATUS } from 'constants/courses';
import { DATETIME_FORMAT } from 'constants/common';

import { moduleActions } from 'redux/actions/learner';

import { Action } from 'types';
import {
  ModuleProgress,
  ModuleSchedule,
  JournalEntry,
  ModuleType,
} from 'types/learner';
export interface ModuleListItemProps {
  id?: number;
  imageUrl?: string;
  imageComponent?: React.ReactNode;
  isLoading: boolean;
  linkTo?: string;
  openBtnLabel?: string;
  showImage?: boolean;
  showScheduleBtn?: boolean;
  title: string;
  content?: string;
  onOpenActions?: Action[];
  onOpen?: () => void;
  progress?: ModuleProgress;
  schedule?: ModuleSchedule;
  courseSlug?: string;
  isTop?: boolean;
  isBottom?: boolean;
  compact?: boolean;
  uploadMissing?: boolean;
  uploads?: JournalEntry[];
  discourseCategoryId?: number;
  tags?: string[];
  moduleType?: ModuleType;
  isAssessment?: boolean;
  canLaunchLiveRoom?: boolean;
}

const noop = () => null;

const ModuleListItem: React.FC<ModuleListItemProps> = ({
  id,
  imageUrl,
  imageComponent = null,
  isLoading,
  linkTo,
  openBtnLabel,
  showImage = true,
  showScheduleBtn = true,
  title,
  content,
  onOpenActions = [],
  onOpen = noop,
  progress,
  schedule,
  courseSlug,
  isTop = false,
  isBottom = false,
  compact = false,
  uploadMissing = false,
  uploads = [],
  discourseCategoryId,
  tags,
  isAssessment = false,
  canLaunchLiveRoom = false,
}) => {
  const [newPostPopupOpen, setNewPostPopupOpen] = useState(false);
  const [activityPopupOpen, setActivityPopupOpen] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();
  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile =
    windowDimensions.width < parseInt(theme.breakpoints.md, 10) || compact;
  const percentageComplete = progress
    ? progress.status === PROGRESS_STATUS.complete
      ? 100
      : progress.status === PROGRESS_STATUS.inProgress
      ? ((progress.stepsTotal - progress.stepsRemaining) /
          progress.stepsTotal) *
        100
      : 0
    : 0;

  // On assessments, we show the number of steps completed/submitted in this module
  let numStepsSubmitted = 0;
  let stepIcons = [];
  if (isAssessment && progress) {
    numStepsSubmitted =
      progress.status === PROGRESS_STATUS.complete
        ? progress.stepsTotal - 1
        : Math.max(progress.currentStep - 2, 0);
    for (let stepIdx = 0; stepIdx < progress.stepsTotal - 1; stepIdx++) {
      stepIcons.push(
        <MdIcon
          key={`step-icon-${title}-${stepIdx}`}
          name={stepIdx < numStepsSubmitted ? 'CheckCircle' : 'Lens'}
          color={
            stepIdx < numStepsSubmitted ? 'common.progress' : 'background.tint2'
          }
          ml={1}
        />
      );
    }
  }

  const yesterday = moment().subtract(1, 'day');
  const isScheduled =
    schedule?.scheduled && !moment(schedule?.scheduledTime).isBefore(yesterday);

  const newUploads = uploads
    .sort((a, b) => moment(a.created).diff(moment(b.created)))
    .slice(0, 3)
    .reverse();

  const isDisabled =
    isAssessment && progress?.status === PROGRESS_STATUS.complete;

  const hasImage = Boolean(showImage && !isAssessment);

  return (
    <>
      <LinkBox
        width="100%"
        cursor={!isDisabled ? 'pointer' : 'not-allowed'}
        borderTopRadius={{ base: 0, md: isTop ? 'lg' : 0 }}
        borderBottomRadius={{ base: 0, md: isBottom ? 'lg' : 0 }}
        _hover={
          !isDisabled
            ? { textDecoration: 'none', backgroundColor: 'background.tint1' }
            : {}
        }
        {...((onOpenActions.length || onOpen) && !isDisabled
          ? {
              onClick: () =>
                onOpenActions.length
                  ? onOpenActions.map((action) => dispatch(action))
                  : onOpen(),
            }
          : {})}
      >
        <LinkOverlay
          cursor={!isDisabled ? 'pointer' : 'not-allowed'}
          href={!isDisabled ? linkTo : '#'}
        >
          <Flex alignItems="center">
            {hasImage && (
              <Box
                width={{ base: 'image.md', md: 'image.lg' }}
                height="image.md"
                padding="defaultPadding"
              >
                <Skeleton isLoaded={!isLoading} width="100%" height="100%">
                  <Flex
                    display="flex"
                    justifyContent="center"
                    alignItems="center"
                    borderRadius="sm"
                    backgroundImage={
                      isLoading || !imageUrl ? '' : `url(${imageUrl})`
                    }
                    backgroundSize="cover"
                    backgroundPosition="center"
                    width="100%"
                    height="100%"
                    backgroundColor={!imageUrl ? 'background.tint3' : 'initial'}
                    position="relative"
                  >
                    {!imageUrl && (
                      <MdIcon
                        name="CropOriginal"
                        color="icon.default"
                        boxSize={8}
                      />
                    )}
                    {percentageComplete > 0 ? (
                      <Box position="absolute" bottom={1} left={1}>
                        {percentageComplete === 100 && !uploadMissing ? (
                          <MdIcon
                            color="background.success"
                            boxSize="icon"
                            name="Done"
                            backgroundColor="common.progress"
                            borderRadius="full"
                          />
                        ) : (
                          <CircularProgress
                            value={percentageComplete}
                            color="common.progress"
                            trackColor="background.defaultTransparent"
                            thickness="20px"
                            size="icon"
                            capIsRound
                          />
                        )}
                      </Box>
                    ) : null}
                  </Flex>
                </Skeleton>
              </Box>
            )}
            {imageComponent}
            <Flex
              flex={isLoading ? 0.5 : 1}
              alignItems="center"
              mr={isLoading ? 4 : 'defaultPadding'}
              pl={hasImage ? 0 : 5}
              pt={isLoading ? 'defaultPadding' : 0}
              py={hasImage || isLoading ? 0 : 'defaultPadding'}
              alignSelf={isLoading ? 'flex-start' : 'center'}
            >
              <Flex
                flex={1}
                flexDirection="column"
                height="100%"
                justifyContent="center"
                padding={isLoading ? 2 : 0}
              >
                <Skeleton isLoaded={!isLoading} mt={isLoading ? 1 : 0}>
                  <Flex alignItems="center">
                    <Text
                      fontWeight="semibold"
                      height={isLoading ? 4 : 'auto'}
                      noOfLines={2}
                      dangerouslySetInnerHTML={{ __html: sanitizeHtml(title) }}
                    />
                    <Text ml={1}>{content}</Text>
                  </Flex>
                </Skeleton>

                {isScheduled && schedule ? (
                  <Flex alignItems="center">
                    <MdIcon name="Today" color="common.primary" mr={1} />
                    <Text color="common.primary" noOfLines={1} fontSize="sm">
                      {isMobile
                        ? moment(schedule.scheduledTime).calendar(
                            DATETIME_FORMAT
                          )
                        : `Scheduled for ${moment(
                            schedule.scheduledTime
                          ).calendar(DATETIME_FORMAT)}`}
                    </Text>
                  </Flex>
                ) : null}
              </Flex>
              {!isLoading && (
                <Flex alignItems="center">
                  {percentageComplete === 100 && uploadMissing && (
                    <>
                      {!compact && (
                        <Box
                          justifyContent="center"
                          px={2}
                          py={1}
                          borderRadius="sm"
                          backgroundColor="background.warning"
                          mr={2}
                          display={{ base: 'none', sm: 'block' }}
                        >
                          <Text
                            fontSize="xs"
                            color="text.warning"
                            fontWeight="semibold"
                            textAlign="center"
                          >
                            Post Required
                          </Text>
                        </Box>
                      )}
                      <Box
                        onClick={(e) => {
                          e.preventDefault();
                          setNewPostPopupOpen(true);
                        }}
                      >
                        <Button
                          icon="PostAdd"
                          padding={0}
                          borderRadius="full"
                          height={7}
                          minWidth={7}
                          colorScheme="orange"
                        />
                      </Box>
                    </>
                  )}
                  {canLaunchLiveRoom && (
                    <Button
                      variant="outline"
                      onClick={(e) => {
                        e.preventDefault();
                        history.push({
                          pathname: navRoutes.cms.createRoom.path(),
                          search: id ? `session=${id}` : '',
                        });
                      }}
                      fontSize="xs"
                      icon="TapAndPlay"
                      p={2}
                      height={8}
                    >
                      Launch Room
                    </Button>
                  )}
                  {isAssessment && progress && (
                    <Flex flexDirection="column" alignItems="flex-end">
                      <Flex>{stepIcons}</Flex>
                      <Text fontSize="sm" color="text.muted" mt={1}>
                        {`${numStepsSubmitted} of ${
                          progress.stepsTotal - 1
                        } Steps Submitted`}
                      </Text>
                    </Flex>
                  )}
                  {!isAssessment && newUploads?.length > 0 && (
                    <Button
                      onClick={(e) => {
                        e.preventDefault();
                        setActivityPopupOpen(true);
                      }}
                      ml={3}
                      height={7}
                      minWidth={7}
                      background="transparent!important"
                      _hover={{ opacity: 0.5 }}
                    >
                      {newUploads.map((je, index) => (
                        <Box
                          key={`new-upload-${index}`}
                          borderRadius="full"
                          height={7}
                          minWidth={7}
                          backgroundImage={`url(${je.migratedImageUrl})`}
                          backgroundSize="cover"
                          backgroundPosition="center"
                          position="absolute"
                          transform={`translateX(-${index * 5}px)`}
                          opacity={
                            index === newUploads.length - 3
                              ? 0.3
                              : index === newUploads.length - 2
                              ? 0.6
                              : 1
                          }
                        />
                      ))}
                    </Button>
                  )}
                  {showScheduleBtn && schedule && percentageComplete !== 100 && (
                    <Box onClick={(e) => e.preventDefault()} ml={2}>
                      <DateTimePicker
                        selected={
                          isScheduled ? new Date(schedule.scheduledTime) : null
                        }
                        onSet={(datetime: string) => {
                          if (schedule && courseSlug) {
                            dispatch(
                              moduleActions.updateSchedule(
                                courseSlug,
                                schedule.id,
                                {
                                  scheduledTime: datetime,
                                }
                              )
                            );
                          }
                        }}
                        minDate={new Date()}
                      />
                    </Box>
                  )}
                  {openBtnLabel && !isMobile ? (
                    <>
                      {linkTo ? (
                        <LinkOverlay
                          as={LinkButton}
                          secondary
                          href={linkTo}
                          fontSize="sm"
                        >
                          {openBtnLabel}
                        </LinkOverlay>
                      ) : (
                        <Button
                          secondary
                          onClick={() =>
                            onOpenActions.length
                              ? onOpenActions.map((action) => dispatch(action))
                              : onOpen()
                          }
                          fontSize="sm"
                        >
                          {openBtnLabel}
                        </Button>
                      )}
                    </>
                  ) : !isDisabled ? (
                    <MdIcon
                      ml={3}
                      name="KeyboardArrowRight"
                      color="icon.muted"
                      boxSize="icon"
                    />
                  ) : (
                    <MdIcon
                      ml={3}
                      name="LockOutline"
                      color="icon.disabled"
                      boxSize="icon"
                    />
                  )}
                </Flex>
              )}
            </Flex>
          </Flex>
        </LinkOverlay>
      </LinkBox>
      {((percentageComplete === 100 && uploadMissing) ||
        newUploads?.length > 0) &&
        progress &&
        courseSlug &&
        tags && (
          <NewPostPopup
            isOpen={newPostPopupOpen}
            onClose={() => setNewPostPopupOpen(false)}
            discourseCategoryId={discourseCategoryId}
            courseSlug={courseSlug}
            moduleProgressId={progress.id}
            tags={tags}
            moduleTitle={title}
          />
        )}
      {newUploads?.length > 0 && (
        <ActivityPopup
          isOpen={activityPopupOpen}
          onClose={() => setActivityPopupOpen(false)}
          topicsList={uploads
            .sort((a, b) => moment(a.created).diff(moment(b.created)))
            .map((e) => ({
              id: e.discourseTopicId,
              createdAt: e.created,
              imageUrl: e.imageMobile || e.migratedImageUrl,
              likeCount: e.likeCount,
              postsCount: e.postCount,
              courseSlug: e.courseSlug,
            }))}
          titleBold="My Posts"
          titleMuted={` for ${title}`}
          footer={
            <Flex justifyContent="flex-end">
              <Button
                onClick={() => setActivityPopupOpen(false)}
                size="sm"
                variant="ghost"
              >
                Close
              </Button>
              <Button
                onClick={() => {
                  setActivityPopupOpen(false);
                  setNewPostPopupOpen(true);
                }}
                size="sm"
                icon="PostAdd"
                ml={2}
              >
                New Post
              </Button>
            </Flex>
          }
        />
      )}
    </>
  );
};

export default ModuleListItem;
