import React from 'react';

import {
  Box,
  Card,
  Flex,
  Heading,
  MdIcon,
  Paragraph,
  Skeleton,
  Divider,
} from '@workshop/ui';
import { ModulesList } from 'components/ModulesList';
import { ModuleListItemProps } from 'components/ModulesList/ModuleListItem';

interface UnitOverviewCardProps {
  imageUrl: string | null;
  isLoading: boolean;
  modules: ModuleListItemProps[];
  title: string;
  prefix: string;
  // TODO : the showImage prop might not be needed
  // i.e, it looks like we never set it to `false` anywhere
  // in the code base (needs investigation before removing)
  showImage?: Boolean;
}

const UnitOverviewCard: React.FC<UnitOverviewCardProps> = ({
  imageUrl,
  isLoading,
  modules,
  title,
  prefix,
  showImage = true,
}) => {
  return (
    <Card padding={0} overflow="initial">
      <Box flex={1}>
        <Flex padding="defaultPadding" alignItems="center">
          {showImage && (
            <Flex
              width={{ base: 'image.lg', md: 'image.2xl', xl: 'image.3xl' }}
              height={{ base: 'image.lg', md: 'image.xl', xl: 'image.2xl' }}
              mr={[2]}
              borderRadius="md"
              backgroundImage={isLoading || !imageUrl ? '' : `url(${imageUrl})`}
              backgroundSize="cover"
              backgroundPosition="center"
              overflow="hidden"
            >
              <Flex
                display="flex"
                width="100%"
                height="100%"
                justifyContent="center"
                alignItems="center"
                backgroundColor={
                  !imageUrl || isLoading ? 'background.tint3' : 'initial'
                }
              >
                {(!imageUrl || isLoading) && (
                  <MdIcon name="CropOriginal" color="icon.muted" boxSize={20} />
                )}
              </Flex>
            </Flex>
          )}
          <Flex flexDirection="column" flex={1}>
            <Flex flexDirection="column" padding="defaultPadding" width="100%">
              <Skeleton
                isLoaded={!isLoading}
                loadingStyle={{
                  width: '50%',
                  height: '1em',
                  marginBottom: 2,
                }}
              >
                <Paragraph mb={1}>{prefix}</Paragraph>
              </Skeleton>
              <Skeleton
                isLoaded={!isLoading}
                loadingStyle={{
                  width: '80%',
                  height: '1em',
                }}
              >
                <Heading as="h3" fontWeight="bold" fontSize="xl" mb={2}>
                  {title}
                </Heading>
              </Skeleton>
            </Flex>
          </Flex>
        </Flex>
        {modules.length > 0 && (
          <Box h="100%" position="relative">
            <Divider />
            <ModulesList isLoading={isLoading} moduleItems={modules} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default UnitOverviewCard;
