import moment from 'moment';

import { Flex, Text, Button, Divider } from '@workshop/ui';

import { License } from 'types/cms';

const LicensesTable = ({
  licenses,
  isMobile,
  type,
  onSelectLicense,
}: {
  licenses: License[];
  isMobile: boolean;
  type: 'created' | 'received';
  onSelectLicense: (license: License) => void;
}) => (
  <Flex flexDir="column" px={{ base: 4, md: 0 }} mb={4}>
    {licenses.length > 0 ? (
      <>
        <Flex flexDir="row" my="6">
          <Flex w={{ base: '30%', md: '20%' }} pr={4}>
            <Text fontSize="sm" fontWeight="semibold" color="text.muted">
              Date Created
            </Text>
          </Flex>
          <Flex w={{ base: '35%', md: '20%' }} pr={4}>
            <Text fontSize="sm" fontWeight="semibold" color="text.muted">
              {type === 'created' ? 'Created For' : 'Created By'}
            </Text>
          </Flex>
          <Flex
            display={{ base: 'none', md: 'flex' }}
            w={{ base: '0%', md: '30%' }}
            pr={4}
          >
            <Text fontSize="sm" fontWeight="semibold" color="text.muted">
              Course(s)
            </Text>
          </Flex>
          <Flex w={{ base: '30%', md: '20%' }}>
            <Text fontSize="sm" fontWeight="semibold" color="text.muted">
              Enrolments
            </Text>
          </Flex>
          <Flex w={{ base: '5%', md: '10%' }}></Flex>
        </Flex>
        {licenses.map((item, idx) => {
          const isLastItem = idx + 1 === licenses.length;
          return (
            <Flex flexDir="column" key={`license-${item.id}`}>
              <Divider mb="4" />
              <Flex flexDir="row" alignItems="center" pb={4}>
                <Flex w={{ base: '30%', md: '20%' }} pr={4}>
                  <Text fontSize="sm">
                    {moment(item.created).format(
                      isMobile ? "D MMM [']YY" : 'Do MMMM YYYY'
                    )}
                  </Text>
                </Flex>
                <Flex w={{ base: '35%', md: '20%' }} pr={4}>
                  <Text fontSize="sm" noOfLines={2}>
                    {type === 'created'
                      ? item.licensee.name
                      : item.licensor.name}
                  </Text>
                </Flex>
                <Flex
                  display={{ base: 'none', md: 'flex' }}
                  w={{ base: '0%', md: '30%' }}
                  pr={4}
                >
                  <Text fontSize="sm" noOfLines={2}>
                    {item.courses.map(
                      (course, courseIdx) =>
                        `${course.title}${
                          courseIdx + 1 === item.courses.length ? '' : ', '
                        }`
                    )}
                  </Text>
                </Flex>
                <Flex flex={1} pr={4}>
                  <Text fontSize="sm">{`${
                    item.totalEnrolments - item.enrolments.length
                  } of ${item.totalEnrolments} remaining`}</Text>
                </Flex>
                <Flex pr={{ base: 0, sm: 4 }} justifyContent="flex-end">
                  <Button
                    size="sm"
                    variant="ghost"
                    icon="MoreHoriz"
                    onClick={() => onSelectLicense(item)}
                  >
                    {isMobile ? '' : 'More'}
                  </Button>
                </Flex>
              </Flex>
              {isLastItem && <Divider mb="4" />}
            </Flex>
          );
        })}
      </>
    ) : (
      <Flex flexDir="column" my="6">
        <Divider mb="4" />
        <Flex pb={4}>
          <Text fontSize="sm" color="text.muted">
            None Available
          </Text>
        </Flex>
        <Divider mb="4" />
      </Flex>
    )}
  </Flex>
);
export default LicensesTable;
