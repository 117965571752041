import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';

import { ISessionFormat } from 'constants/courses';

import { EditCard, LabelInput, LabelSelect } from 'components/Common';

import { FurtherDetailsSessionFormData } from 'types/cms';

interface FurtherDetailsProps extends FurtherDetailsSessionFormData {
  moduleFormatOptions: { [key in ISessionFormat]: string };
  setModuleFormat: React.Dispatch<React.SetStateAction<ISessionFormat>>;
  onSave: (formData: FurtherDetailsSessionFormData) => void;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
  error?: boolean;
}

const noop = () => null;
const FurtherDetailsCard = ({
  moduleFormatOptions,
  moduleFormat,
  onSave,
  setModuleFormat,
  onCancel = noop,
  sessionDuration,
  isDisabled = false,
  isUpdating = false,
  isLoading = false,
}: FurtherDetailsProps) => {
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState,
    reset,
    watch,
  } = useForm<FurtherDetailsSessionFormData>();

  const onSubmit = handleSubmit((formData) => {
    onSave(formData);
  });

  const watchModuleFormat = watch('moduleFormat');

  useEffect(() => {
    setModuleFormat(watchModuleFormat);
  }, [watchModuleFormat, setModuleFormat]);

  useEffect(() => {
    // Reset the state as default after state change (successful update)
    reset({ moduleFormat, sessionDuration });
  }, [reset, moduleFormat, sessionDuration]);

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        // TODO: Reset Select
        reset({ moduleFormat, sessionDuration });
      }}
      saveDisabled={Boolean(!formState.dirty || errors.sessionDuration)}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
    >
      <LabelSelect
        id="moduleFormat"
        name="moduleFormat"
        label="Format"
        error={!!errors.moduleFormat}
        registerInputRef={register({
          required: true,
        })}
        defaultValue={moduleFormat}
        options={moduleFormatOptions}
        isDisabled={isDisabled}
        isLoading={isLoading}
        tooltip="session_format"
      />
      <LabelInput
        id="sessionDuration"
        name="sessionDuration"
        label="Duration"
        error={!!errors.sessionDuration}
        registerInputRef={register({
          required: true,
        })}
        defaultValue={sessionDuration}
        unit="minutes"
        isDisabled={isDisabled}
        isLoading={isLoading}
        tooltip="session_duration"
      />
    </EditCard>
  );
};

export default FurtherDetailsCard;
