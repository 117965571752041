import React, { useState, useEffect } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';

import navRoutes from 'navigation/Routes';

import { GlobalState } from 'types';
import { Unit } from 'types/learner';

import {
  courseActions,
  cohortActions,
  courseProgressActions,
  moduleActions,
  stepQuestionActions,
  userCheckListActions,
} from 'redux/actions/learner';
import { getSlugs, getModules, getUnits } from 'redux/selectors/course';

import { hooks, getParamFromUrl } from 'utils';
import {
  getCohortSubcategoryId,
  getCourseCompletionProgress,
  getUnitLockedStatus,
} from 'utils/learner';

import { Flex } from '@workshop/ui';

import { SessionPlayer } from 'components/SessionPlayer';
import { Orientation } from 'components/VideoClipsPlayer/types';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { PROGRESS_STATUS, UNIT_TYPE } from 'constants/courses';

import { CompleteSessionPopup } from '../CompleteSessionPopup';
import { generateSteps, parseChecklistStateJson } from './utils';

const getContainerStyle = (orientation: Orientation | null) => ({
  flexDirection: 'column' as const,
  mb: 4,
  position: 'relative' as const,
});

// Routing Props
interface MatchParams {
  courseSlug: string;
  moduleSlug: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Session: React.FC<Props> = ({
  cohort,
  course,
  courseSlug,
  location,
  history,
  module,
  moduleSlug,
  moduleProgress,
  moduleQuestions,
  unit,
  userCheckList,
  unitPercentageComplete,
  coursePercentageComplete,
  isLocked,
}) => {
  const dispatch = useDispatch();
  const [orientation, setOrientation] = useState<Orientation | null>(null);
  const [completePopupOpen, setCompletePopupOpen] = useState(false);
  const [assessmentStep, setAssessmentStep] = useState(0);
  const currentRoute = hooks.useCurrentRoute();
  const isAssessment = currentRoute?.slug === 'assessmentModule';

  const stepId = isAssessment
    ? assessmentStep.toString()
    : getParamFromUrl(location, 'step');

  /** ---------------- DATA LOADING ---------------- */
  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: [
          () => courseActions.retrieve(courseSlug),
          () => courseProgressActions.retrieve(courseSlug),
          () => cohortActions.retrieve(courseSlug),
        ],
      },
    },
    [courseSlug]
  );

  useEffect(() => {
    if (!courseLoading && isLocked) {
      history.push(navRoutes.learner.course.path(courseSlug));
    }
  }, [courseLoading, isLocked, isAssessment, unit?.unitType]);

  const {
    checkList: [moduleChecklist],
    steps: moduleSteps = [],
    moduleFormat,
    exerciseText,
    imagePortraitMobile,
  } = module || { checkList: [] };

  /**
   * Build an array containing the question IDs for all the steps included in this module
   * Fetch all these questions via the API
   */
  const questionIds = moduleSteps.reduce(
    (acc: number[], { questions }) =>
      questions && questions.length ? [...acc, ...questions] : [...acc],
    []
  );

  const { questionsLoading } = hooks.useLoadingDataState(
    {
      questionsLoading: {
        actions: questionIds.length
          ? [() => stepQuestionActions.list(questionIds)]
          : [],
        startLoading: !Boolean(courseLoading),
      },
    },
    [questionIds?.length, courseLoading]
  );

  const containerStyles = getContainerStyle(orientation);

  if (courseLoading || questionsLoading) {
    return (
      <ScreenWrapper>
        <Flex {...containerStyles}>
          <SessionPlayer
            loading
            onCompleteSession={async () => null}
            onSetOrienation={(o) => setOrientation(o)}
            onUnlockStep={async () => null}
            steps={[]}
            navigationStep={null}
            navigateToStep={() => null}
          />
        </Flex>
      </ScreenWrapper>
    );
  }

  if (!module) {
    /** TODO : improve this, maybe redirect? */

    /** Returning early if no module found (e.g typo in module slug) */
    return null;
  }

  /** ---------------- CHECKLIST DATA ---------------- */
  const userCheckListState =
    userCheckList && parseChecklistStateJson(userCheckList.state);

  const checkListItems = moduleChecklist?.items.map(
    ({ id, title: content, slug }) => {
      const itemState = userCheckListState && userCheckListState[slug];

      const isChecked = Boolean(
        itemState && typeof itemState !== 'boolean' && itemState.completed
      );

      return {
        id,
        isChecked,
        content,
        slug,
      };
    }
  );

  const toggleCheckListItem = (itemSlug: string, completed: boolean) =>
    userCheckList &&
    dispatch(
      userCheckListActions.update({
        id: userCheckList.id.toString(),
        checkList: userCheckList.checkList.toString(),
        state: JSON.stringify({ [itemSlug]: { completed } }),
      })
    );

  const checkAllItems = () => {
    if (!userCheckList || !userCheckListState) return;

    const completedList: { [key: string]: { completed: boolean } } = {};

    Object.keys(userCheckListState).forEach((key) => {
      if (key === 'completed') return;
      completedList[key] = { completed: true };
    });

    dispatch(
      userCheckListActions.update({
        id: userCheckList.id.toString(),
        checkList: userCheckList.checkList.toString(),
        state: JSON.stringify(completedList),
      })
    );
  };

  const checklist = checkListItems
    ? {
        items: checkListItems,
        title: moduleChecklist.title,
        onLastItemChecked: checkAllItems,
        onItemChecked: (slug: string) => toggleCheckListItem(slug, true),
        onItemUnchecked: (slug: string) => toggleCheckListItem(slug, false),
      }
    : undefined;

  const onSubmitAnswers = (id: number) => async (submitAnswers: number[]) => {
    await dispatch(stepQuestionActions.submitAnswers(id, submitAnswers));
  };

  const onSubmitPrompt = () => {
    if (isAssessment) {
      const nextIdx = stepId ? parseInt(stepId) + 1 : 1;
      const isLastStep = nextIdx >= steps.length;
      if (isLastStep) {
        onCompleteSession();
      } else {
        onUnlockStep(nextIdx);
        navigateToStep(nextIdx);
      }
    }
  };

  const promptProps = {
    discourseCategoryId: getCohortSubcategoryId(cohort, course) || undefined,
    moduleProgressId: moduleProgress.id,
    tags: ['response', `module-${module.index - 1}`, `unit-${unit.index - 1}`],
    isAssessment,
    onSubmitSuccess: onSubmitPrompt,
  };

  /** ---------------- STEPS DATA ---------------- */
  const steps = generateSteps({
    checklist,
    imagePortraitMobile,
    exerciseText,
    moduleFormat,
    moduleProgress,
    moduleQuestions,
    steps: moduleSteps,
    onSubmitAnswers,
    promptProps,
  });

  /** ---------------- ACTIONS ---------------- */
  const onUnlockStep = async (currentStep: number) => {
    const { status: progressStatus, stepsRemaining: progressStepsRemaining } =
      moduleProgress;

    const status =
      progressStatus === PROGRESS_STATUS.upcoming
        ? PROGRESS_STATUS.inProgress
        : progressStatus;

    const stepsRemaining = progressStepsRemaining - 1;
    const body = {
      currentStep: currentStep + 1,
      stepsRemaining,
      status,
    };

    await dispatch(
      moduleActions.updateUserProgress(courseSlug, moduleSlug, body)
    );
  };

  // TODO: When we complete a session we typically ask the user how they
  // found the session (give it a rating) and then ask them to take an
  // upload.
  //
  // On the existing react web app we send a request which triggers a
  // push notification to be sent to the users device which when they
  // click will open the app and allow them to take a photo and upload
  // it.
  //
  // Let's discuss these 2 points and create 2 separate issues to get
  // the work completed
  const onCompleteSession = async () => {
    if (moduleProgress.status !== PROGRESS_STATUS.complete) {
      const body = {
        currentStep: steps.length,
        stepsRemaining: 0,
        status: PROGRESS_STATUS.complete,
      };

      await dispatch(
        moduleActions.updateUserProgress(courseSlug, moduleSlug, body)
      );
      requestAnimationFrame(() => setCompletePopupOpen(true));
    } else {
      history.push(`/courses/${courseSlug}/browse/`);
    }
  };

  const navigateToStep = (idx: number) => {
    if (isAssessment) {
      setAssessmentStep(idx);
      return;
    }
    let currentSearchParams = new URLSearchParams(location.search);
    currentSearchParams.set('step', idx.toString());
    history.push({
      pathname: location.pathname,
      search: currentSearchParams.toString(),
    });
  };

  return (
    <ScreenWrapper>
      <Flex {...containerStyles}>
        <SessionPlayer
          onCompleteSession={onCompleteSession}
          onSetOrienation={(o) => setOrientation(o)}
          onUnlockStep={onUnlockStep}
          requirements={checklist}
          showRequirements={Boolean(checklist)}
          steps={steps}
          navigationStep={stepId}
          sessionIsComplete={moduleProgress.status === PROGRESS_STATUS.complete}
          pathname={location.pathname}
          navigateToStep={navigateToStep}
          isAssessment={isAssessment}
        />
        {completePopupOpen && (
          <CompleteSessionPopup
            isOpen={completePopupOpen}
            onClose={() => setCompletePopupOpen(false)}
            unitTitle={
              unit.prefix ? `${unit.prefix}: ${unit.title}` : unit.title
            }
            unitPercentageComplete={unitPercentageComplete}
            coursePercentageComplete={coursePercentageComplete}
            discourseCategoryId={
              getCohortSubcategoryId(cohort, course) || undefined
            }
            courseSlug={courseSlug}
            moduleId={module.id}
            moduleProgressId={moduleProgress.id}
            tags={[`module-${module.index - 1}`, `unit-${unit.index - 1}`]}
            requiresUpload={module.requiresUpload}
            isAssessment={isAssessment}
          />
        )}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseSlug, moduleSlug } = props.match.params;

  const {
    courses: {
      cohorts: cohortState,
      moduleQuestions,
      courses: { detail: coursesState },
      modules: moduleState,
      units: unitState,
    },
    courseProgress: {
      courses: courseProgressState,
      units: unitProgressState,
      modules: moduleProgressState,
    },
    courseSchedule: { units: unitScheduleState },
    userCheckList: userCheckListState,
    journal: { journalEntries },
  } = state.learner;
  const { activeAssessment } = state.user.userProfile;

  const course = coursesState[courseSlug];
  const module = moduleState[moduleSlug];
  const moduleProgress = moduleProgressState[moduleSlug];

  // Course Data
  const courseUnits = course ? getUnits(unitState, course.units) : null;

  type U = Unit;
  type M = 'modules';
  const courseModuleSlugs = getSlugs<Pick<U, M>, M>(courseUnits, 'modules');

  const courseModules = courseModuleSlugs
    ? getModules(moduleState, courseModuleSlugs)
    : null;

  // Unit Data
  const unit = module && unitState[module.unit];
  const unitProgress = module && unitProgressState[module.unit];
  const unitSchedule = module && unitScheduleState[module.unit];

  const unitPercentageComplete =
    unitProgress && unitProgress.moduleCount > 0
      ? unitProgress.status === PROGRESS_STATUS.complete
        ? 100
        : unitProgress.status === PROGRESS_STATUS.inProgress
        ? ((unitProgress.moduleCount - unitProgress.modulesRemaining) /
            unitProgress.moduleCount) *
          100
        : 0
      : 0;

  let coursePercentageComplete = 0;
  if (courseModules && courseUnits) {
    const { totalProgress } = getCourseCompletionProgress({
      courseModules,
      courseUnits,
      moduleProgress: moduleProgressState,
      unitProgress: unitProgressState,
      journalEntries,
    });
    coursePercentageComplete = totalProgress;
  }

  // Cohort data
  const cohort = cohortState[courseSlug];

  // Checklist data
  const { checkList } = module || { checkList: [] };

  const checkListId = checkList && checkList.length && checkList[0].id;

  const userCheckListId =
    checkListId &&
    Object.keys(userCheckListState).find(
      (ucl) => userCheckListState[parseInt(ucl)].checkList === checkListId
    );

  const userCheckList =
    userCheckListId && userCheckListState[parseInt(userCheckListId)];

  const courseProgress = courseProgressState[courseSlug];
  const courseIsExpired = Boolean(
    courseProgress?.expiryDate &&
      moment(courseProgress.expiryDate).diff(moment(), 'days') <= 0
  );

  // The session is locked based on cohort & schedule data,
  // and also when it is an inactive assessment or is an assessment
  // which is already complete
  const isLocked =
    courseIsExpired ||
    getUnitLockedStatus(cohort, unitProgress, unitSchedule) ||
    (unit?.unitType === UNIT_TYPE.assessment && !activeAssessment) ||
    (activeAssessment && activeAssessment.id !== unit?.id) ||
    (unit?.unitType === UNIT_TYPE.assessment &&
      unitProgress?.status === PROGRESS_STATUS.complete);

  return {
    cohort,
    course,
    courseSlug,
    module,
    moduleProgress,
    moduleQuestions,
    moduleSlug,
    unit,
    userCheckList,
    unitPercentageComplete,
    coursePercentageComplete,
    isLocked,
  };
};

const connector = connect(mapStateToProps);

export default connector(Session);
