import React, { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';

import { Button, Flex, Text, MdIcon } from '@workshop/ui';

interface Props {
  label?: string;
  name: string;
  accept?: string | string[];
  onDrop?: (name: string, acceptedFiles: File[]) => void;
}

const FileUpload: React.FC<Props> = ({
  label = 'Drag Files Here',
  name,
  accept,
  onDrop = () => {},
}) => {
  const innerOnDrop = useCallback(
    (acceptedFiles) => {
      onDrop(name, acceptedFiles);
    },
    [name, onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: innerOnDrop,
    accept,
  });

  return (
    <Flex
      bg="background.tint3"
      flexDir="column"
      alignItems="center"
      p={3}
      py={8}
      borderRadius="md"
      flex={1}
      cursor="pointer"
      {...getRootProps()}
    >
      <MdIcon color="text.muted" name="CloudUpload" boxSize={10} />
      <Text fontSize="sm" fontWeight="semibold" color="text.muted" mb="3">
        {label}
      </Text>
      <input
        id="file-upload"
        type="file"
        style={{ display: 'none' }}
        {...getInputProps()}
      />
      <Button size="sm">Select from File</Button>
    </Flex>
  );
};

export default FileUpload;
