import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import moment from 'moment';

import { Flex, Box, Text } from '@workshop/ui';

import { ProgressCardsList } from 'screens/learner/Library/CardsList';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { SectionTitle } from 'components/Common';
import { Loading } from 'components/Loading';

import { PROGRESS_STATUS } from 'constants/courses';

import {
  courseProgressActions,
  userLibraryActions,
  workshopRelationshipActions,
} from 'redux/actions/learner';
import { GlobalState } from 'types';
import { UserWorkshopRelationship } from 'types/learner';

import {
  getUserLibraryWorkshops,
  getUserLibraryCourses,
} from 'redux/selectors/profile';
import { hooks } from 'utils';

// TODO: Create a WorkshopModel and move this function
/**
 * Returns the current progress for a given UserWorkshop
 * - returns a number between 0 and 100 (i.e, a percentage)
 * @param userWorkshop: UserWorkshop | undefined
 */
const calculateWorkshopProgress = (
  userWorkshop: UserWorkshopRelationship | undefined
): number => {
  if (!userWorkshop) return 0;

  const {
    currentLesson,
    currentLessonStep,
    currentLessonStepsTotal,
    lessonsTotal,
    status,
  } = userWorkshop;

  if (status === 'complete') return 1;

  if (!lessonsTotal) return 0;

  const lessonNumerator = currentLesson - 1;
  const lessonFraction =
    lessonNumerator === 0 ? 0 : lessonNumerator / lessonsTotal;

  const stepNumerator = currentLessonStep - 1;
  const stepFraction =
    !currentLessonStepsTotal || stepNumerator === 0
      ? 0
      : stepNumerator / currentLessonStepsTotal;

  return Math.min(lessonFraction + stepFraction / lessonsTotal, 1) * 100;
};

// Routing Props
interface MatchParams {}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const Library = ({
  courses,
  courseProgress,
  unitProgressState,
  courseSchedules,
  userWorkshops,
  workshops,
  history,
}: Props) => {
  const { course: courseLoading, profile: profileLoading } =
    hooks.useLoadingDataState({
      course: { actions: [courseProgressActions.list] },
      profile: {
        actions: [
          userLibraryActions.retrieve,
          workshopRelationshipActions.list,
        ],
      },
    });

  const isLoading = courseLoading || profileLoading;

  const courseItems = isLoading
    ? []
    : courses
        .filter((c) => !!courseProgress[c.slug])
        .map((c) => {
          const progress = courseProgress[c.slug];
          const schedule = courseSchedules[c.slug];

          let numModules = 0;
          let numRemainingModules = 0;
          progress?.unitProgress.forEach((up) => {
            numModules += unitProgressState[up].moduleCount;
            numRemainingModules += unitProgressState[up].modulesRemaining;
          });

          const coursePercentageComplete =
            numModules > 0 && progress
              ? progress.status === PROGRESS_STATUS.complete
                ? 100
                : progress.status === PROGRESS_STATUS.inProgress
                ? ((numModules - numRemainingModules) / numModules) * 100
                : 0
              : 0;

          const showProgress = coursePercentageComplete > 0;
          let progressDescription =
            progress.status === PROGRESS_STATUS.inProgress
              ? 'In Progress'
              : progress.status === PROGRESS_STATUS.complete
              ? 'Complete'
              : 'Upcoming';

          if (
            progress.status === PROGRESS_STATUS.upcoming &&
            schedule?.startDate
          ) {
            progressDescription = moment().isAfter(moment(schedule.startDate))
              ? 'Ready to Start'
              : `Starting ${moment(schedule.startDate).format('MMM Do')}`;
          }

          const { expiryDate } = progress;

          let expiryDescription = '';
          let isExpired = false;
          if (!!expiryDate) {
            const expiringSoon = moment(expiryDate).diff(moment(), 'week') <= 2;
            const expired = moment(expiryDate).diff(moment(), 'days') <= 0;
            if (expiringSoon && !expired) {
              expiryDescription = `Access Ends ${moment(expiryDate).format(
                'MMM Do'
              )}`;
            } else if (expired) {
              expiryDescription = 'Access Expired';
              isExpired = true;
            }
          }

          return {
            id: c.slug,
            body: '',
            title: c.title,
            subtitle: c.subtitle,
            imageUrl: c.imageLandscapeThumbnail,
            linkTo: `/courses/${c.slug}`,
            progress: coursePercentageComplete,
            showProgress,
            progressDescription,
            expiryDescription,
            isExpired,
            onClick: () => history.push(`courses/${c.slug}/browse`),
            orgInfo: c.creatorInformation,
          };
        });

  const courseItemsByOrg = courseItems.reduce(
    (
      obj: {
        [orgId: string]: {
          id: number;
          name: string;
          courses: typeof courseItems;
        };
      },
      value
    ) => {
      const key = value.orgInfo.id.toString();
      if (obj[key] == null) {
        obj[key] = {
          id: value.orgInfo.id,
          name: value.orgInfo.name,
          courses: [],
        };
      }

      obj[key].courses.push(value);
      return obj;
    },
    {}
  );

  return (
    <ScreenWrapper>
      {isLoading ? (
        <Loading />
      ) : Object.values(courseItemsByOrg).length > 0 ? (
        Object.values(courseItemsByOrg).map((org) => {
          return (
            <Box flex={1} key={`library-org-${org.id}`}>
              <SectionTitle title={org.name} />
              <Flex
                flexDirection="column"
                position="relative"
                mb="defaultMargin"
              >
                {/* {isLoading ? null : (
          <Button
            alignItems="center"
            backgroundColor="background.default"
            color="text.default"
            display="flex"
            position="fixed"
            textTransform="initial"
            top="60px"
            variant="outline"
            zIndex={1100}
          >
            <Link to={navRoutes.learner.wishlist.path()}>
              <Box display="flex" alignItems="center">
                <MdIcon name="FavoriteBorder" mr={2} boxSize={6} />
                Wishlist
              </Box>
            </Link>
          </Button>
        )} */}
                <ProgressCardsList
                  isLoading={isLoading}
                  items={org.courses}
                  noItemsMessage="You are not currently in any classes"
                />
              </Flex>
            </Box>
          );
        })
      ) : (
        <Text color="text.muted">You are not currently in any classes</Text>
      )}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  courses: getUserLibraryCourses(state),
  courseProgress: state.learner.courseProgress.courses,
  unitProgressState: state.learner.courseProgress.units,
  courseSchedules: state.learner.courseSchedule.courses,
  userWorkshops: state.learner.userWorkshops,
  workshops: getUserLibraryWorkshops(state),
});

const connector = connect(mapStateToProps);

export default connector(Library);
