import React, { useState, useRef } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { connect, useDispatch, ConnectedProps } from 'react-redux';
import moment from 'moment';

import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';
import { GlobalState } from 'types';

import { discourseUrl } from 'constants/env';
import { journalActions } from 'redux/actions/learner';
import { discourseActions } from 'redux/actions/common';
import { errorToastMessage } from 'redux/actions/common/ui';

import { Flex, Heading, Input, Text, Skeleton, useTheme } from '@workshop/ui';

import { UserAvatar } from 'components/UserAvatar';
import { SectionTitle } from 'components/Common';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { TopicsList, PartialTopic } from 'screens/learner/ClassActivity';

// TODO : figure out what to do with all this commented out code
// (i.e do we want to show the UserStreak on this page)

// import { UserStreak } from 'screens/learner/Profile/UserStreak';
// import { formatProgressData } from '../';

interface MatchParams {}

interface OwnProps extends RouteComponentProps<MatchParams> {}

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends OwnProps, PropsFromRedux {}

const Profile: React.FC<Props> = ({
  journalEntries,
  userDetails,
  userUI,
  discourseUser,
  // weeklyGoalProgress,
}) => {
  const dispatch = useDispatch();
  const [avatarLoading, setAvatarLoading] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const {
    journal: journalLoading,
    discourseUser: discourseUserLoading,
    // goals: goalsLoading,
  } = hooks.useLoadingDataState(
    {
      journalEntries: { actions: [journalActions.retrieveJournalEntries] },
      discourseUser: {
        actions: [() => discourseActions.getUser(userDetails.username)],
        startLoading: Boolean(userDetails.username),
      },
      // goals: { actions: [goalActions.list] },
    },
    []
  );

  // const formattedProgressData = formatProgressData(weeklyGoalProgress);
  const isLoading = journalLoading || discourseUserLoading;

  const topicsList: PartialTopic[] = journalEntries
    .filter((e) => Boolean(e.imageMobile) || Boolean(e.migratedImageUrl))
    .sort((a, b) => (moment(a.created).isBefore(moment(b.created)) ? 1 : -1))
    .map((e) => ({
      id: e.discourseTopicId,
      createdAt: e.created,
      imageUrl: e.imageMobile || e.migratedImageUrl,
      likeCount: e.likeCount,
      postsCount: e.postCount,
      originalPoster: {
        id: userDetails.id,
        username: userDetails.username,
        name: userDetails.name,
        avatarTemplate: discourseUser?.avatarTemplate || '',
      },
      courseSlug: e.courseSlug,
    }));

  // Partition topics into 2 arrays based on the index.
  const [topicsColumnLeft, topicsColumnRight] = topicsList.reduce<
    [PartialTopic[], PartialTopic[]]
  >(
    ([left, right], curr, i) =>
      i % 2 === 0 ? [[...left, curr], right] : [left, [...right, curr]],
    [[], []]
  );

  const journalEntriesMessage = topicsList.length
    ? `${topicsList.length} post${topicsList.length > 1 ? 's' : ''}`
    : '';

  const onAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files?.length) return;

    // TODO : fix ts definition for createUpload body in discourse-js
    const upload = await dispatch(
      discourseActions.createUpload({
        // @ts-ignore
        'files[]': files[0],
        type: 'avatar',
        ...(discourseUser.id ? { user_id: discourseUser.id } : {}),
      })
    );

    if (!upload) {
      dispatch(
        errorToastMessage(
          'Failed to upload image. Please refresh the page and try again.'
        )
      );
      return;
    }

    const success = await dispatch(
      discourseActions.pickAvatar(discourseUser.username, upload.id)
    );

    if (!success) {
      dispatch(
        errorToastMessage(
          'Failed to set avatar. Please refresh the page and try again.'
        )
      );
      return;
    }

    dispatch(discourseActions.getUser(userDetails.username));
  };

  return (
    <ScreenWrapper justifyContent="center">
      {/* <Box position="fixed" marginTop={-60} zIndex={1000}>
          <UserStreak
            isLoading={goalsLoading}
            streakType="week"
            streakItems={formattedProgressData.streakItems}
            streakValue={formattedProgressData.streakValue}
          />
        </Box> */}
      <Flex alignItems="center" marginX={{ base: 'defaultMargin', md: 0 }}>
        <Input
          ref={fileInputRef}
          id="userAvatar"
          type="file"
          accept="image/*"
          style={{ display: 'none' }}
          onChange={async (e) => {
            setAvatarLoading(true);
            await onAvatarChange(e);
            setAvatarLoading(false);
          }}
          name="userAvatar"
        />
        <UserAvatar
          canEdit
          name={userDetails.name}
          userId={userDetails.id}
          avatarPicture={
            Boolean(discourseUser?.avatarTemplate)
              ? `${discourseUrl}${discourseUser.avatarTemplate.replace(
                  '{size}',
                  '240'
                )}`
              : ''
          }
          onClick={() => fileInputRef.current?.click()}
          isLoading={avatarLoading}
        />
        <Flex flexDirection="column" ml={4}>
          <Skeleton isLoaded={!userUI.loading}>
            <Heading as="h3" fontSize="xl">
              {userDetails.name || 'loading details...'}
            </Heading>
          </Skeleton>
          <Skeleton isLoaded={!isLoading} loadingStyle={{ height: 5 }}>
            {!!journalEntriesMessage && (
              <Text fontWeight="semibold" color="text.muted" minHeight={5}>
                {journalEntriesMessage}
              </Text>
            )}
          </Skeleton>
        </Flex>
      </Flex>
      <Flex mt={8} flexDirection="column">
        <SectionTitle title="My Posts" />
        {topicsList.length ? (
          <>
            {isMobile ? (
              <TopicsList topics={topicsList} />
            ) : (
              <Flex>
                <Flex flexDir="column" mr="defaultMargin" flex={1}>
                  <TopicsList
                    topics={topicsColumnRight}
                    isLoading={isLoading}
                  />
                </Flex>
                <Flex flexDir="column" flex={1}>
                  <TopicsList topics={topicsColumnLeft} isLoading={isLoading} />
                </Flex>
              </Flex>
            )}
          </>
        ) : (
          <Skeleton isLoaded={!isLoading} mx={{ base: 'defaultMargin', md: 0 }}>
            <Flex justifyContent="flex-start" alignItems="center">
              <Text color="common.muted">
                This is where you'll be able to see all the posts you upload
                throughout your courses 📷
              </Text>
            </Flex>
          </Skeleton>
        )}
      </Flex>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  journalEntries: state.learner.journal.journalEntries,
  userUI: state.ui.user,
  userDetails: state.user.userDetails,
  weeklyGoalProgress: state.learner.goal.progress,
  discourseUser: state.user.discourseUser.user,
});

const connector = connect(mapStateToProps);

export default connector(Profile);
