import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useSpring } from 'react-spring';
import sanitizeHtml from 'sanitize-html';

import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  MdIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  Spinner,
  useTheme,
} from '@workshop/ui';

import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  SessionStepper,
  SessionStepperStep,
} from 'components/SessionPlayer/SessionStepper';
import { CheckList, CheckListProps } from 'components/SessionPlayer/CheckList';
import { Orientation } from 'components/VideoClipsPlayer/types';
import { AddItem, IAddItem } from 'components/ListItem';
import { AnimatedFlex } from 'components/Common';

export type SessionStepType = 'intro' | 'outro' | 'normal';
export interface SessionPlayerStep {
  notes?: string;
  stepType?: SessionStepType;
  subSteps: SessionStepperStep[];
  title: React.FC<{ color?: string }>;
  id: number | string;
  unlocked?: boolean;
}

interface SessionPlayerProps {
  loading?: boolean;
  onCompleteSession: () => Promise<any>;
  onSetOrienation: (o: Orientation) => void;
  onUnlockStep: (step: number) => Promise<any>;
  requirements?: CheckListProps;
  showRequirements?: boolean;
  steps: SessionPlayerStep[];
  pathname?: string;
  navigationStep: string | null;
  previewModeEnabled?: boolean;
  isEditable?: boolean;
  sessionIsComplete?: boolean;
  isAssessment?: boolean;
  onSaveClipSummary?: (clipId: string, summary: string) => Promise<any>;
  handleVideoUpload?: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  handleAddClip?: (id: string) => Promise<void>;
  handleAddStep?: (data: IAddItem) => Promise<void>;
  navigateToStep: (idx: number) => void;
}

interface NavigationStep extends Omit<SessionPlayerStep, 'title'> {
  title: React.FC<{ color?: string }>;
}

interface SessionsStepNavigationProps {
  currentStepIdx?: number;
  header?: React.ReactNode;
  loading?: boolean;
  onOpenNotes: (idx: number) => void;
  maxUnlockedIdx: number;
  setCurrentStepIdx: (idx: number) => void;
  steps: NavigationStep[];
  previewModeEnabled?: boolean;
  isEditable?: boolean;
  handleAddStep?: (data: IAddItem) => Promise<void>;
  showRequirements?: boolean;
  openRequirements?: () => void;
  disableNavigation?: boolean;
}

interface StepNavItemProps {
  title: NavigationStep['title'];
  notes: SessionPlayerStep['notes'];
  idx: number;
  currentStepIdx?: number;
  setCurrentStepIdx: (idx: number) => void;
  maxUnlockedIdx: number;
  onOpenNotes: (idx: number) => void;
  previewModeEnabled?: boolean;
  isDisabled?: boolean;
}

const StepNavItem: React.FC<StepNavItemProps> = ({
  title: StepTitle,
  notes,
  idx,
  currentStepIdx,
  setCurrentStepIdx,
  maxUnlockedIdx,
  onOpenNotes,
  previewModeEnabled,
  isDisabled = false,
}) => {
  const isUnlocked = idx <= maxUnlockedIdx;
  const isCurrentStep = idx === currentStepIdx;

  const cursor = isUnlocked ? 'pointer' : 'not-allowed';
  const onClick = () => (isUnlocked ? setCurrentStepIdx(idx) : null);

  let iconName = isUnlocked ? 'Check' : 'Lock';
  let iconColor = isUnlocked ? 'text.success' : 'icon.muted';
  let iconBackground = isUnlocked ? 'background.success' : 'transparent';
  let stepBackground = isCurrentStep ? 'background.primary' : 'transparent';

  let textColor = isCurrentStep
    ? 'white'
    : isUnlocked
    ? 'text.default'
    : 'text.muted';
  let hoverBackground = isUnlocked ? 'background.tint2' : stepBackground;

  if (previewModeEnabled) {
    iconName = 'PlayCircleOutline';
    iconColor = 'icon.muted';
    iconBackground = 'transparent';
  }

  if (isCurrentStep) {
    iconName = 'PlayArrow';
    iconColor = 'background.primary';
    iconBackground = 'icon.primary';
    textColor = 'text.primary';
    stepBackground = 'background.primary';
    hoverBackground = 'background.primaryDark';
  }

  return (
    <Box
      key={idx}
      display="flex"
      alignItems="center"
      backgroundColor={stepBackground}
      paddingX={4}
      paddingY={2}
      style={{ flexFlow: 'row wrap' }}
      {...(!isDisabled
        ? {
            _hover: {
              backgroundColor: hoverBackground,
            },
            onClick,
            cursor,
          }
        : {
            cursor: 'not-allowed',
          })}
    >
      <Flex
        alignItems="center"
        backgroundColor={iconBackground}
        borderRadius="16px"
        justifyContent="center"
        mr={2}
        boxSize={6}
      >
        <MdIcon name={iconName} color={iconColor} />
      </Flex>
      <Box flex={1} marginY={1}>
        <StepTitle color={textColor} />
      </Box>
      {isUnlocked && notes && (
        <Button
          secondary
          height={6}
          minW={6}
          padding={0}
          onClick={(e) => {
            e.stopPropagation();
            onOpenNotes(idx);
          }}
          icon="InfoOutline"
        />
      )}
    </Box>
  );
};

const SessionStepNavigation: React.FC<SessionsStepNavigationProps> = ({
  currentStepIdx,
  header,
  loading = false,
  maxUnlockedIdx,
  onOpenNotes,
  setCurrentStepIdx,
  steps,
  previewModeEnabled = false,
  isEditable = false,
  handleAddStep = () => null,
  showRequirements,
  openRequirements = () => null,
  disableNavigation = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [animatedContentStyle, set] = useSpring(() => ({
    config: { duration: 200 },
    transform: 'translate3D(0,16px,0)',
    opacity: 0,
    display: 'none',
  }));
  useEffect(() => {
    set({
      transform: isOpen ? 'translate3D(0,56px,0)' : 'translate3D(0,16px,0)',
      opacity: isOpen ? 1 : 0,
      display: isOpen ? 'block' : 'none',
    });
  }, [isOpen]);
  const currentStep = steps[currentStepIdx || 0];

  const StepTitle = currentStep ? currentStep.title : () => null;

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isSmallWindow =
    windowDimensions.width < parseInt(theme.breakpoints['2xl'], 10);

  return (
    <Card
      backgroundColor="background.default"
      flexDirection="column"
      height="100%"
      padding={0}
      borderBottomRadius={{ base: isOpen ? '0!important' : 'auto', lg: 'auto' }}
    >
      <Box
        cursor={{ base: 'pointer', '2xl': 'default' }}
        padding={4}
        {...(isSmallWindow
          ? {
              onClick: () => setIsOpen(!isOpen),
              _hover: {
                backgroundColor: 'background.tint2',
              },
            }
          : {})}
      >
        {header || (
          <Flex>
            {isSmallWindow ? (
              <Flex flex={1} alignItems="center">
                <MdIcon
                  name={isOpen ? 'ExpandLess' : 'ExpandMore'}
                  color="icon.default"
                  mr={4}
                  boxSize="icon"
                />
                <StepTitle />
              </Flex>
            ) : (
              <Text flex={1} fontWeight="semibold">
                Steps
              </Text>
            )}
            {showRequirements && (
              <Button
                borderRadius="15px"
                height={6}
                minW={6}
                p={2}
                secondary
                onClick={(e) => {
                  e.stopPropagation();
                  e.nativeEvent.stopImmediatePropagation();
                  openRequirements();
                }}
                icon="ShoppingCart"
                fontSize="xs"
              >
                Requirements
              </Button>
            )}
          </Flex>
        )}
      </Box>
      <AnimatedFlex
        direction="column"
        roundedBottom={{ base: 'lg', '2xl': 'none' }}
        {...(isSmallWindow ? { style: animatedContentStyle } : {})}
        position={{ base: 'absolute', '2xl': 'relative' }}
        width={{ base: '100%', md: '60%', lg: '100%' }}
        backgroundColor={{ base: 'background.default', '2xl': 'transparent' }}
        boxShadow={{ base: 'lg', '2xl': 'none' }}
        zIndex={2}
      >
        <Divider mt={0} mb={0} borderColor="border.default" />
        <Flex
          position="relative"
          height={{ base: '100%', '2xl': 0 }}
          pb={{ base: 0, '2xl': '156%' }}
          overflow="scroll"
        >
          <Flex
            flex={1}
            position={{ base: 'relative', '2xl': 'absolute' }}
            top={0}
            right={0}
            bottom={0}
            left={0}
            flexDirection="column"
          >
            {loading
              ? null
              : steps.map(({ title, notes }, idx) => (
                  <StepNavItem
                    key={`stepnavitem-${currentStepIdx}-${idx}`}
                    title={title}
                    notes={notes}
                    idx={idx}
                    currentStepIdx={currentStepIdx}
                    setCurrentStepIdx={(idx) => {
                      setIsOpen(false);
                      setCurrentStepIdx(idx);
                    }}
                    maxUnlockedIdx={maxUnlockedIdx}
                    onOpenNotes={onOpenNotes}
                    previewModeEnabled={previewModeEnabled}
                    isDisabled={disableNavigation}
                  />
                ))}
            {isEditable && (
              <Flex>
                <AddItem
                  label="Add Step"
                  onSave={handleAddStep}
                  flexDir="column"
                />
              </Flex>
            )}
          </Flex>
        </Flex>
      </AnimatedFlex>
    </Card>
  );
};

const SessionPlayer: React.FC<SessionPlayerProps> = ({
  pathname,
  loading = false,
  navigationStep,
  requirements,
  showRequirements,
  steps,
  previewModeEnabled = false,
  isEditable = false,
  sessionIsComplete = false,
  isAssessment = false,
  onCompleteSession,
  onSetOrienation,
  onUnlockStep,
  onSaveClipSummary,
  handleVideoUpload,
  handleAddClip,
  handleAddStep,
  navigateToStep,
}) => {
  const [completeSessionLoading, setCompleteSessionLoading] = useState(false);
  const [currentStepIdx, setCurrentStepIdx] = useState<number | null>(null);
  const [maxUnlockedIdx, setMaxUnlockedIdx] = useState(0);
  const [displayRequirement, setDisplayRequirements] = useState(false);
  const [notesStepIdx, setNotesStepIdx] = useState<number | null>(null);
  const [intitialCheckDone, setInitialCheckDone] = useState(false);

  const unlockedSteps = JSON.stringify(steps.map((s) => s.unlocked));

  const noPath = !pathname;
  useEffect(() => {
    if (loading || intitialCheckDone || !steps.length || noPath) {
      return;
    }

    const lastUnlockedStep = [...steps].reverse().find((s) => s.unlocked);
    const lastUnlockedStepIdx = lastUnlockedStep
      ? steps.indexOf(lastUnlockedStep)
      : 0;

    const currentNavigationStep = parseInt(navigationStep || '0');

    if (previewModeEnabled || sessionIsComplete) {
      navigateToStep(currentNavigationStep);
    } else if (currentNavigationStep > lastUnlockedStepIdx || isAssessment) {
      navigateToStep(lastUnlockedStepIdx);
    }

    setInitialCheckDone(true);
  }, [steps.length]);

  useEffect(() => {
    const lastUnlockedStep = [...steps].reverse().find((s) => s.unlocked);
    const lastUnlockedStepIdx = lastUnlockedStep
      ? steps.indexOf(lastUnlockedStep)
      : 0;

    if (lastUnlockedStepIdx > maxUnlockedIdx) {
      setMaxUnlockedIdx(lastUnlockedStepIdx);
    }

    if (noPath || navigationStep) return;

    if (previewModeEnabled || sessionIsComplete) {
      navigateToStep(parseInt(navigationStep || '0'));
    } else {
      navigateToStep(lastUnlockedStepIdx);
    }
  }, [unlockedSteps]);

  useEffect(() => {
    if (!navigationStep || loading || !intitialCheckDone) return;

    setCurrentStepIdx(parseInt(navigationStep));
  }, [navigationStep, intitialCheckDone]);

  if (loading) {
    return (
      <Flex
        height={{ base: 'auto', '2xl': '100%' }}
        flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
        alignItems="center"
      >
        <Box
          width={{ base: '100%', '2xl': '71.5%' }}
          mr={{ base: 0, '2xl': 4 }}
        >
          <SessionStepper
            loading
            sessionSubsteps={[]}
            currentSessionStepId={null}
            title="Loading..."
          />
        </Box>
        <Box
          width={{ base: '100%', md: '60%', lg: '100%', '2xl': '28.5%' }}
          height={{ base: 'auto', '2xl': '100%' }}
          mb={{ base: 'defaultMargin', '2xl': 0 }}
        >
          <SessionStepNavigation
            currentStepIdx={0}
            header={<Spinner size="xs" color="icon.muted" />}
            loading
            maxUnlockedIdx={0}
            onOpenNotes={() => null}
            setCurrentStepIdx={() => null}
            steps={[]}
          />
        </Box>
      </Flex>
    );
  }

  const currentStep = steps[currentStepIdx || 0];
  const { title: StepTitle, subSteps, notes } = currentStep;

  const isLastStep =
    currentStepIdx !== null ? currentStepIdx + 1 >= steps.length : false;

  const updatedSubsteps = subSteps.map((s, idx) => {
    const isLastSubstep = idx + 1 >= subSteps.length;
    const isLastSessionSubStep = isLastStep && isLastSubstep;

    const onClickNext = async () => {
      if (s.onClickNext) await s.onClickNext();

      /** Only move on to the next step when clicking next if:
       * - this is not the last step
       * - this is the last substep
       * */
      if (isLastStep || !isLastSubstep) return;

      if (currentStepIdx === maxUnlockedIdx) {
        await onUnlockStep(maxUnlockedIdx + 1);
        setMaxUnlockedIdx(maxUnlockedIdx + 1);
      }
      const nextIdx = currentStepIdx !== null ? currentStepIdx + 1 : 1;
      navigateToStep(nextIdx);
    };

    return {
      ...s,
      notes,
      onClickNext,
      footer:
        isLastSessionSubStep && !previewModeEnabled ? (
          <Button
            flex={1}
            marginX={1}
            // TODO: Allow next button & complete button to unlock on 'required'
            // prompt submit (removing need for "isAssessment" checks)
            isDisabled={isAssessment && !sessionIsComplete}
            onClick={async () => {
              setCompleteSessionLoading(true);
              await onCompleteSession();
              setCompleteSessionLoading(false);
            }}
          >
            {completeSessionLoading ? (
              <Spinner size="xs" speed="0.75s" />
            ) : sessionIsComplete ? (
              'Close Session'
            ) : (
              'Complete Session'
            )}
          </Button>
        ) : null,
      showNextBtn: !isLastSessionSubStep,
      showPrevBtn: !isLastSessionSubStep && !isAssessment,
    };
  });

  // TODO: Decide whether the requirements checklist shown in the modal
  // should be interactable or rendered as all checked.
  const requirementItems =
    requirements &&
    requirements.items.map((i) => ({
      ...i,
      isChecked: true,
    }));

  const notesToDisplay =
    notesStepIdx !== null ? steps[notesStepIdx].notes : null;

  return (
    <Flex
      height={{ base: 'auto', '2xl': '100%' }}
      flexDirection={{ base: 'column-reverse', '2xl': 'row' }}
      alignItems="center"
    >
      <Box width={{ base: '100%', '2xl': '71.5%' }} mr={{ base: 0, '2xl': 4 }}>
        <SessionStepper
          onSetOrienation={onSetOrienation}
          sessionSubsteps={updatedSubsteps}
          currentSessionStepId={currentStep.id.toString()}
          currentSessionStepType={currentStep.stepType}
          title={<StepTitle />}
          isEditable={isEditable}
          onSaveClipSummary={onSaveClipSummary}
          handleVideoUpload={handleVideoUpload}
          handleAddClip={handleAddClip}
        />
      </Box>
      <Box
        width={{ base: '100%', md: '60%', lg: '100%', '2xl': '28.5%' }}
        height={{ base: 'auto', '2xl': '100%' }}
        mb={{ base: 'defaultMargin', '2xl': 0 }}
      >
        <SessionStepNavigation
          currentStepIdx={currentStepIdx || 0}
          maxUnlockedIdx={maxUnlockedIdx}
          onOpenNotes={(idx) => setNotesStepIdx(idx)}
          setCurrentStepIdx={navigateToStep}
          steps={steps}
          previewModeEnabled={previewModeEnabled}
          isEditable={isEditable}
          handleAddStep={handleAddStep}
          showRequirements={showRequirements}
          openRequirements={() => setDisplayRequirements(true)}
          disableNavigation={isAssessment}
        />
      </Box>

      {/* ------ REQUIREMENTS MODAL ------ */}
      {requirementItems && (
        <Modal
          isOpen={displayRequirement}
          onClose={() => setDisplayRequirements(false)}
        >
          <ModalOverlay />
          <ModalContent borderRadius="md">
            <ModalHeader>Requirements</ModalHeader>
            <ModalCloseButton />
            <ModalBody maxH="75vh" overflow="scroll" pb={4}>
              <CheckList items={requirementItems} />
            </ModalBody>
          </ModalContent>
        </Modal>
      )}

      {/* ------ NOTES MODAL ------ */}
      <Modal
        isOpen={Boolean(notesToDisplay)}
        onClose={() => setNotesStepIdx(null)}
        size="xl"
      >
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>Notes</ModalHeader>
          <ModalCloseButton />
          <ModalBody maxH="75vh" overflow="scroll" pb={4}>
            {notesToDisplay && (
              <Box
                dangerouslySetInnerHTML={{
                  __html: sanitizeHtml(notesToDisplay, {
                    allowedTags: ['p', 'img', 'br'],
                  }),
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default SessionPlayer;
