import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { animated, useTransition } from 'react-spring';
import { RouteComponentProps } from 'react-router';

import { profileActions } from 'redux/actions/common';
import {
  useIsUserLoading,
  useDismissedInformationCards,
} from 'redux/selectors';

import { getParamFromUrl } from 'utils';

import { HomeCard } from 'screens/common/Home';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { Box } from '@workshop/ui';

import { InformationCard } from 'components/Common';
import { CreateOrgPopup } from 'components/CreateOrgPopup';

import { WelcomePopup } from './WelcomePopup';

interface HomeScreenProps extends RouteComponentProps {}

const HomeScreen: React.FC<HomeScreenProps> = ({ location }) => {
  const isLoading = useIsUserLoading();
  const dismissedInformationCards = useDismissedInformationCards();
  const dispatch = useDispatch();

  const transitions = useTransition(!isLoading, null, {
    from: { opacity: 0 },
    enter: { opacity: 1 },
    leave: { opacity: 0 },
  });

  const popupParam = getParamFromUrl(location, 'p');
  const [orgPopupIsOpen, setOrgPopupIsOpen] = useState(popupParam === 'new');
  const [welcomePopupIsOpen, setWelcomePopupIsOpen] = useState(false);

  useEffect(() => {
    if (
      !isLoading &&
      (popupParam === 'welcome' ||
        !dismissedInformationCards.includes('welcome_popup'))
    ) {
      setWelcomePopupIsOpen(true);
    }
  }, [dismissedInformationCards.length, isLoading, popupParam]);

  return (
    <ScreenWrapper>
      <Box mx="auto" px={{ base: 'defaultMargin', md: 0 }}>
        <InformationCard id="home_screen" maxWidth="500px" />
      </Box>
      {transitions.map(
        ({ item, key, props }) =>
          item && (
            <animated.div key={key} style={props}>
              <HomeCard onNewOrgClick={() => setOrgPopupIsOpen(true)} />
            </animated.div>
          )
      )}
      {orgPopupIsOpen && (
        <CreateOrgPopup
          isOpen={orgPopupIsOpen}
          onClose={() => setOrgPopupIsOpen(false)}
        />
      )}
      <WelcomePopup
        isOpen={welcomePopupIsOpen}
        onClose={(openCreateOrg?: boolean) => {
          setWelcomePopupIsOpen(false);
          dispatch(
            profileActions.updateUserProfile(
              {
                dismissedInformationCards: [
                  ...dismissedInformationCards,
                  'welcome_popup',
                ],
              },
              { toast: { success: false, error: false } }
            )
          );
          if (openCreateOrg) {
            setOrgPopupIsOpen(true);
          }
        }}
      />
    </ScreenWrapper>
  );
};

export default HomeScreen;
