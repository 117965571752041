import { FRONT_ENV, BACKEND_ENV } from 'constants/env';

export const ROLES = {
  admin: 'admin',
  owner: 'owner',
  mentor: 'mentor',
  teamManager: 'team_manager',
  finance: 'finance',
  marketing: 'marketing',
  contentManager: 'content_manager',
} as const;

const isRemote = FRONT_ENV === 'production' || BACKEND_ENV === 'dev';

// (Replace IDs after ":" with local values if testing)
export const ORG_IDS = {
  workshop: isRemote ? 14 : 2,
  leiths: isRemote ? 8 : 1,
  lak: isRemote ? 46 : 42,
};

// URL path for each bespoke org landing page
export const ORG_LANDING_PAGES = {
  lak: '/leiths-academy-kitchen',
};

// List of org IDs that are not allowed to manually enrol students
export const ENROLMENT_BLACKLIST = [ORG_IDS.leiths];

// List of org IDs that are allowed to create courses
export const CREATE_COURSE_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
  ORG_IDS.lak,
];

// List of org IDs that are allowed to access advanced cohort options
export const ADVANCED_COHORT_OPTIONS_WHITELIST = [
  ORG_IDS.leiths,
  ORG_IDS.workshop,
];
