import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Flex,
  Text,
  MdIcon,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  Button,
  Progress,
} from '@workshop/ui';

import { useUploadList } from 'redux/selectors/background';
import { backgroundActions } from 'redux/actions/common';

import { STATUS } from 'constants/background';
import { ChunkUploadStatus } from 'types/common';

interface Props {
  id: string;
  upload: {
    progress: number;
    filename: string;
    filesize: number;
    status: ChunkUploadStatus;
  };
  displayText?: boolean;
  light?: boolean;
}

export const UploadStatus: React.FC<Props> = ({
  id,
  upload,
  displayText = true,
  light = false,
}) => {
  const dispatch = useDispatch();

  const cancelUpload = (id: string) => {
    dispatch(backgroundActions.cancelChunkUpload(id));
  };

  const { filename, progress, status, filesize: rawFilesize } = upload;

  const fileSize = rawFilesize / 1000000;
  const uploadBytes = `${(fileSize * (progress / 100)).toFixed(
    2
  )}MB / ${fileSize.toFixed(2)}MB`;

  const isCancelled = status === STATUS.cancelled;

  return (
    <Flex
      flex={1}
      mt={2}
      alignItems="stretch"
      flexDir="column"
      opacity={isCancelled ? 0.5 : 1}
      pointerEvents={isCancelled ? 'none' : 'auto'}
    >
      {displayText && (
        <Flex>
          <Flex flex={1}>
            <Text fontSize="sm" color={light ? 'white' : 'text.default'}>
              {filename}
            </Text>
          </Flex>
          <Flex ml={2}>
            <Text fontSize="sm" color={light ? 'white' : 'text.default'}>
              {uploadBytes}
            </Text>
          </Flex>
        </Flex>
      )}
      <Flex>
        <Flex justifyContent="center" alignItems="center" mr={2}>
          <MdIcon
            name="CloudUpload"
            color={light ? 'white' : 'common.primary'}
            boxSize="icon"
          />
        </Flex>
        <Flex flex={1} flexDirection="column">
          <Progress value={progress} my={2} borderRadius="md" />
        </Flex>
        <Flex alignItems="center" justifyContent="flex-start" ml={2}>
          {upload.progress === 100 ? (
            <MdIcon
              name="Check"
              bg="green.500"
              color="white"
              borderRadius="lg"
              boxSize="icon"
            />
          ) : (
            <MdIcon
              name="Close"
              bg="red.500"
              color="white"
              borderRadius="lg"
              cursor="pointer"
              boxSize="icon"
              onClick={() => cancelUpload(id)}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const UploadStatusModal = () => {
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const removeUpload = () => dispatch(backgroundActions.clearChunkUploads());
  const uploadList = useUploadList();
  return (
    <>
      <Modal isOpen={showModal} onClose={() => setShowModal(false)}>
        <ModalOverlay />
        <ModalContent borderRadius="md">
          <ModalHeader>Active Uploads</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {Object.keys(uploadList).length === 0 && (
              <Flex justifyContent="center" alignItems="center">
                <Text>You have no active uploads</Text>
              </Flex>
            )}
            <Flex p={2} flexDir="column">
              {Object.keys(uploadList).map((key) => {
                const upload = uploadList[key];
                return (
                  <Flex key={key} my={2}>
                    <UploadStatus id={key} upload={upload} />
                  </Flex>
                );
              })}
            </Flex>
          </ModalBody>

          <ModalFooter>
            <Button mr={2} onClick={removeUpload}>
              Clear
            </Button>
            <Button
              colorScheme="blue"
              mr="defaultMargin"
              onClick={() => setShowModal(false)}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Button
        secondary
        leftIcon={<MdIcon name="ArrowUpward" color="inherit" />}
        fontSize="sm"
        ml="defaultMargin"
        onClick={() => setShowModal(true)}
      >
        Upload Status
      </Button>
    </>
  );
};

export default UploadStatusModal;
