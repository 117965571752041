import { COHORT_TYPES, SOCIAL_TYPES } from 'constants/courses';

import { Cohort } from 'types/common';

const getCohortType = (cohort: Cohort) => {
  return COHORT_TYPES.find((c) =>
    cohort.isAnonymous
      ? c.isAnonymous
      : c.accessType === cohort.accessType && c.socialType === cohort.socialType
  );
};

const hasClassSizeLimit = (cohort: Partial<Cohort>) =>
  cohort.socialType === SOCIAL_TYPES.private && !cohort.isAnonymous;

export default {
  getCohortType,
  hasClassSizeLimit,
};
