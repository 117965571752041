import React, { useState } from 'react';

import {
  Box,
  Text,
  Flex,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  chakra,
} from '@workshop/ui';

import { NewPostCard } from 'components/NewPostCard';

interface NewPostPopupProps {
  isOpen: boolean;
  onClose: () => void;
  discourseCategoryId?: number;
  courseSlug: string;
  moduleProgressId: number;
  tags: string[] | null;
  moduleTitle: string;
}

const NewPostPopup: React.FC<NewPostPopupProps> = ({
  isOpen,
  onClose,
  discourseCategoryId,
  courseSlug,
  moduleProgressId,
  tags,
  moduleTitle,
}) => {
  const [postSuccess, setPostSuccess] = useState(false);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      size="lg"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent marginX={2} marginY="20vh">
        <ModalHeader px={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Text fontWeight="bold" pr={6}>
              New Post
              <chakra.span
                fontWeight="semibold"
                color="text.muted"
              >{` for ${moduleTitle}`}</chakra.span>
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingTop={0} paddingBottom={4} px={{ base: 4, md: 6 }}>
          <Box>
            {postSuccess ? (
              <>
                <Box
                  borderRadius="md"
                  background="background.tint3"
                  padding={2}
                  paddingTop={3}
                  flexDirection="column"
                >
                  <Text textAlign="center" fontWeight="semibold" mt={3} mb={1}>
                    Post Submitted 🙌
                  </Text>
                  <Text textAlign="center" mb={3}>
                    You can head to your profile to review your posts
                  </Text>
                </Box>
                <Flex mt={4} justifyContent="flex-end">
                  <Button size="sm" onClick={onClose}>
                    Done
                  </Button>
                </Flex>
              </>
            ) : (
              <>
                <NewPostCard
                  onSubmitSuccess={() => setPostSuccess(true)}
                  discourseCategoryId={discourseCategoryId}
                  fullWidth
                  buttonsPosition="bottom"
                  moduleProgressId={moduleProgressId}
                  tags={tags}
                  isTitleRequired={false}
                />
              </>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewPostPopup;
