import React from 'react';
import { FieldErrors } from 'react-hook-form';

import { LabelInput } from 'components/Common';
import { Tooltip } from 'components/IconTooltip';

import { OverviewFormData } from 'types/cms';

const OverviewInput = ({
  id,
  name,
  label,
  register,
  validation,
  errors,
  defaultValue,
  isDisabled = false,
  isLoading,
  tooltip,
}: {
  id: string;
  name: string;
  label: string;
  validation?: Object;
  // TODO: Type `register` and make register/errors injected props
  // https://stackoverflow.com/questions/57124810/typescript-type-for-child-component-of-a-higher-order-component-that-adds-a-prop
  register?: any;
  errors?: FieldErrors<OverviewFormData>;
  defaultValue: string;
  isDisabled?: boolean;
  isLoading: boolean;
  tooltip?: Tooltip;
}) => (
  <LabelInput
    isDisabled={isDisabled}
    registerInputRef={validation ? register(validation) : register}
    id={id}
    name={name}
    label={label}
    defaultValue={defaultValue}
    // @ts-ignore
    error={!!errors[name]}
    // @ts-ignore
    errorMessage={errors[name]?.message}
    isLoading={isLoading}
    tooltip={tooltip}
  />
);

export default OverviewInput;
