import React, { useState, useEffect, useRef } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';
import moment from 'moment';

import isEmail from 'validator/lib/isEmail';

import { errorToastMessage } from 'redux/actions/common/ui';
import {
  useIsUserLoading,
  useUser,
  useUserProfile,
  useDiscourseUser,
  useTeams,
  useTeamProfiles,
  useRoles,
  useUserLibraryCourses,
} from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { getFlagEmoji, hooks } from 'utils';

import { profileActions, discourseActions } from 'redux/actions/common';
import { userLibraryActions } from 'redux/actions/learner';

import { COUNTRY_OPTIONS } from 'constants/settings';
import { discourseUrl } from 'constants/env';

import {
  Box,
  Flex,
  Text,
  MdIcon,
  Input,
  Link,
  Stack,
  Image,
  Divider,
} from '@workshop/ui';
import { StepsModal } from 'components/Common';
import { LabelInput, LabelSelect, LabelCheckbox } from 'components/Common';
import { UserAvatar } from 'components/UserAvatar';

interface WelcomePopupProps {
  isOpen: boolean;
  onClose: (openCreateOrg?: boolean) => void;
}

export const WelcomePopup: React.FC<WelcomePopupProps> = ({
  isOpen,
  onClose,
}) => {
  const { library: libraryLoading } = hooks.useLoadingDataState({
    library: {
      actions: [userLibraryActions.retrieve],
    },
  });

  const userLoading = useIsUserLoading();

  const isLoading = userLoading || libraryLoading;

  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < 689;

  const fileInputRef = useRef<HTMLInputElement>(null);

  const user = useUser();
  const userProfile = useUserProfile();
  const { country, dateOfBirth } = userProfile;

  const userTeams = useTeams();
  const teamProfiles = useTeamProfiles();
  const roles = useRoles();

  const courses = useUserLibraryCourses();

  const [userName, setUserName] = useState(user?.name);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [avatarLoading, setAvatarLoading] = useState(false);

  const discourseUser = useDiscourseUser();

  const dispatch = useDispatch();

  const userFormMethods = useForm<{
    name: string;
    country: string;
    dateOfBirth: string;
    parentalConsent: boolean;
  }>({
    defaultValues: {
      name: userName,
      country,
      dateOfBirth,
    },
  });

  const {
    register: userRegister,
    handleSubmit: handleUpdateUserSubmit,
    errors: userErrors,
    clearError: clearUserError,
    setValue: setUserValue,
    watch,
  } = userFormMethods;

  const countryValue = watch('country');

  useEffect(() => {
    if (countryValue === '-') {
      setUserValue('country', '');
    }
    if (countryValue.charAt(countryValue.length - 1) === '1') {
      setUserValue('country', countryValue.slice(0, 2));
    }
  }, [countryValue]);

  const dobValue = watch('dateOfBirth');
  const userAge = dobValue
    ? moment(moment()).diff(dobValue, 'years')
    : undefined;

  const parentalConsentValue = watch('parentalConsent');

  const onAvatarChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();

    const files = e?.target?.files;

    if (!files?.length) return;

    // TODO : fix ts definition for createUpload body in discourse-js
    const upload = await dispatch(
      discourseActions.createUpload({
        // @ts-ignore
        'files[]': files[0],
        type: 'avatar',
        ...(discourseUser?.id ? { user_id: discourseUser.id } : {}),
      })
    );

    if (!upload) {
      dispatch(
        errorToastMessage(
          'Failed to upload image. Please refresh the page and try again.'
        )
      );
      return;
    }

    const success = await dispatch(
      discourseActions.pickAvatar(discourseUser.username, upload.id)
    );

    if (!success) {
      dispatch(
        errorToastMessage(
          'Failed to set avatar. Please refresh the page and try again.'
        )
      );
      return;
    }

    dispatch(discourseActions.getUser(user.username));
  };

  const onUpdateUserSubmit = handleUpdateUserSubmit(async (data) => {
    clearUserError();
    if (data.dateOfBirth) {
      if (Boolean(userAge && userAge < 13)) {
        dispatch(
          errorToastMessage('You must be over 13 years old to continue.')
        );
        throw new Error();
      }
    }
    setIsSubmitting(true);
    const response = await dispatch(
      profileActions.updateUserProfile({
        name: data.name,
        // @ts-ignore
        profile: {
          country: data.country,
          ...(data.dateOfBirth ? { dateOfBirth: data.dateOfBirth } : {}),
        },
      })
    );
    if (response.error) {
      setIsSubmitting(false);
      throw new Error();
    }
    setIsSubmitting(false);
  });

  const finalStepIndex = userTeams.length > 0 && courses.length > 0 ? 3 : 2;

  return (
    <StepsModal
      heading="Welcome to Workshop!"
      isOpen={isOpen}
      onClose={onClose}
      disableClose
      hideStepLabels={!isMobile}
      modalSize="2xl"
      onCompleteStep={async (stepIndex: number) => {
        if (stepIndex === finalStepIndex) {
          const hasNoContent = courses.length === 0 && userTeams.length === 0;
          onClose(hasNoContent);
        }
        if (stepIndex === 1) {
          try {
            await onUpdateUserSubmit();
          } catch {
            return 'error';
          }
        }
      }}
      steps={[
        {
          label: 'Welcome',
          icon: <MdIcon name="WavingHand" />,
          nextButtonText: 'Next',
          content: (
            <Stack direction="column" spacing={4} py={4}>
              <Flex
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Image
                  src="https://d1bqmh4sljm7sh.cloudfront.net/marketing-assets/learning-on-workshop.png"
                  w="100%"
                  maxWidth="300px"
                  objectFit="contain"
                />
                <Flex
                  alignItems="center"
                  flex={1}
                  padding={4}
                  borderRadius="md"
                >
                  <Text>
                    <b>Learning on Workshop</b> means getting hands-on from the
                    start, so you can do less watching, and more doing. Courses
                    are designed to make it easy for you to get real feedback
                    and make real connections.
                  </Text>
                </Flex>
              </Flex>
              <Flex py={4}>
                <Divider />
              </Flex>
              <Flex
                alignItems="center"
                flexDirection={{ base: 'column', md: 'row-reverse' }}
              >
                <Image
                  src="https://d1bqmh4sljm7sh.cloudfront.net/marketing-assets/teach-on-workshop.png"
                  w="100%"
                  maxWidth="300px"
                  objectFit="contain"
                />
                <Flex
                  alignItems="center"
                  flex={1}
                  padding={4}
                  borderRadius="md"
                >
                  <Text>
                    <b>Teaching on Workshop</b> is simple. Create step by step
                    video courses in minutes, without needing to pay a penny
                    until you enrol your first student.
                  </Text>
                </Flex>
              </Flex>
            </Stack>
          ),
        },
        {
          label: 'My Profile',
          icon: <MdIcon name="AccountCircle" />,
          nextButtonText: 'Next',
          nextButtonDisabled:
            !userName ||
            !userAge ||
            Boolean(userAge && userAge < 13) ||
            Boolean(userAge && userAge < 18 && !parentalConsentValue),
          content: (
            <Box py={4}>
              <FormContext {...userFormMethods}>
                <Box>
                  <Input
                    ref={fileInputRef}
                    id="userAvatar"
                    type="file"
                    accept="image/*"
                    style={{ display: 'none' }}
                    onChange={async (e) => {
                      setAvatarLoading(true);
                      await onAvatarChange(e);
                      setAvatarLoading(false);
                    }}
                    name="userAvatar"
                  />
                  <UserAvatar
                    canEdit
                    name={user.name}
                    userId={user.id}
                    avatarPicture={
                      Boolean(discourseUser?.avatarTemplate)
                        ? `${discourseUrl}${discourseUser.avatarTemplate.replace(
                            '{size}',
                            '240'
                          )}`
                        : ''
                    }
                    onClick={() => fileInputRef.current?.click()}
                    isLoading={avatarLoading}
                    mr="auto"
                    ml="auto"
                    mb={4}
                    size="md"
                  />
                  <Box>
                    <LabelInput
                      id="name"
                      name="name"
                      label="Take a second to set up your profile..."
                      placeholder="What's your name?"
                      labelPosition="top"
                      error={Boolean(userErrors.name)}
                      errorMessage="Please enter your name"
                      registerInputRef={userRegister({ required: true })}
                      value={userName}
                      onChange={(e) => {
                        setUserValue('name', e.target.value);
                        setUserName(e.target.value);
                      }}
                      isDisabled={isSubmitting || isLoading}
                    />
                    <LabelSelect
                      id="country"
                      name="country"
                      placeholder="Where are you from?"
                      color={countryValue ? 'text.default' : 'text.muted'}
                      error={Boolean(userErrors.country)}
                      errorMessage="This information is required."
                      isLoading={isLoading}
                      registerInputRef={userRegister}
                      options={Object.keys(COUNTRY_OPTIONS).reduce(
                        (acc, key) => ({
                          ...acc,
                          [key]:
                            key === '-'
                              ? '-'
                              : `${getFlagEmoji(key.slice(0, 2))} ${
                                  COUNTRY_OPTIONS[key]
                                }`,
                        }),
                        {}
                      )}
                      defaultValue={
                        country ? COUNTRY_OPTIONS[country] : undefined
                      }
                      isDisabled={isSubmitting || isLoading}
                      unsorted
                    />
                    <LabelInput
                      id="dateOfBirth"
                      name="dateOfBirth"
                      label="When were you born?"
                      placeholder="Please enter your birth date"
                      labelPosition="top"
                      error={Boolean(userErrors.name)}
                      errorMessage="Please enter your birth date"
                      registerInputRef={userRegister}
                      isDisabled={isSubmitting || isLoading}
                      inputType="date"
                    />
                    {Boolean(userAge && userAge >= 13 && userAge < 18) && (
                      <LabelCheckbox
                        id="parentalConsent"
                        name="parentalConsent"
                        labelPosition="top"
                        label="Please confirm that you have consent from your parent or guardian to use this platform by ticking this box:"
                        registerInputRef={userRegister}
                        isDisabled={false}
                        tooltip="age_consent"
                      />
                    )}
                    {Boolean(userAge !== undefined && userAge < 13) && (
                      <Text color="text.error">
                        You must be over 13 years old to continue. Please see
                        our{' '}
                        <Link
                          color="text.info"
                          href="https://www.workshop.ws/terms/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Terms of Service
                        </Link>{' '}
                        for more information.
                      </Text>
                    )}
                  </Box>
                </Box>
              </FormContext>
            </Box>
          ),
        },
        ...(userTeams.length > 0
          ? [
              {
                label: 'My Channels',
                icon: <MdIcon name="Groups" />,
                nextButtonText: 'Next',
                content: (
                  <Box py={4}>
                    <Text mb={4}>
                      Your channels are home to the courses you make and the
                      classes you run. As a member of a channel, you may have a
                      specific role to play in the team.
                    </Text>
                    <Text fontWeight="semibold" mb={4}>{`My Channel${
                      userTeams.length === 1 ? '' : 's'
                    }`}</Text>

                    {userTeams.map((userTeam) => {
                      const teamProfile = teamProfiles[userTeam.team];
                      // Extract the user's roles for the current team we're iterating over
                      const roleIds =
                        userTeams.find(({ team }) => team === userTeam.team)
                          ?.roles || [];
                      const userTeamRoles = roleIds.map(
                        (roleId) => roles[roleId].name
                      );
                      return (
                        <Flex
                          key={`team-${userTeam.id}`}
                          flex={1}
                          flexDir="column"
                          backgroundColor="background.tint3"
                          borderRadius="md"
                          mt={2}
                          overflow="hidden"
                          position="relative"
                        >
                          <Flex p={4} alignItems="center">
                            <UserAvatar
                              name={teamProfile?.name}
                              userId={teamProfile?.id || 1}
                              avatarPicture={teamProfile?.logoDark || ''}
                              size="sm"
                            />
                            <Box pl={4} flex={1}>
                              <Text fontWeight="semibold">
                                {teamProfile?.name}
                              </Text>
                              <Flex alignItems="center">
                                <Text color="text.muted" fontSize="sm">
                                  {userTeamRoles.map((r, idx) =>
                                    idx + 1 < userTeamRoles.length
                                      ? `${r}, `
                                      : r
                                  )}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Box>
                ),
              },
            ]
          : []),
        ...(courses.length > 0
          ? [
              {
                label: 'My Classes',
                icon: <MdIcon name="Workspaces" />,
                nextButtonText: 'Go',
                content: (
                  <Box py={4}>
                    <Text mb={4}>
                      {`Here${
                        courses.length === 1
                          ? "'s your class"
                          : ' are your classes'
                      }. When you enrol on a course, you
                      join a class of likeminded people to share your learning
                      journey with.`}
                    </Text>
                    <Text fontWeight="semibold" mb={4}>
                      My Classes
                    </Text>
                    {courses.map((course) => {
                      return (
                        <Flex
                          key={`course-${course.id}`}
                          flex={1}
                          flexDir="column"
                          backgroundColor="background.tint3"
                          borderRadius="md"
                          mt={2}
                          overflow="hidden"
                          position="relative"
                        >
                          <Flex p={4} alignItems="center">
                            <Flex
                              backgroundImage={`url(${course.imageLandscapeThumbnail})`}
                              backgroundSize="cover"
                              backgroundPosition="center"
                              backgroundColor="background.tint2"
                              height={{ base: 'image.md', md: 'image.md' }}
                              width={{ base: 'image.md', md: 'image.lg' }}
                              borderRadius="sm"
                            />
                            <Box pl={4} flex={1}>
                              <Text fontWeight="semibold">{course.title}</Text>
                              <Flex alignItems="center">
                                <Text color="text.muted" fontSize="sm">
                                  {course.creatorInformation.name}
                                </Text>
                              </Flex>
                            </Box>
                          </Flex>
                        </Flex>
                      );
                    })}
                  </Box>
                ),
              },
            ]
          : []),
        ...(courses.length === 0 && userTeams.length === 0
          ? [
              {
                label: 'Create a Channel',
                icon: <MdIcon name="AddBusiness" />,
                nextButtonText: 'Create Channel',
                content: (
                  <Box
                    my={4}
                    p={4}
                    borderRadius="md"
                    backgroundColor="background.tint3"
                  >
                    <Text>
                      <b>Create a channel to get started</b>. This will become
                      the home of your courses and classes.
                    </Text>
                  </Box>
                ),
              },
            ]
          : []),
      ]}
    />
  );
};
