import React from 'react';
import { Message } from 'react-hook-form';

import {
  Flex,
  FormLabel,
  Text,
  FormControl,
  FormHelperText,
  FormErrorMessage,
  Skeleton,
  FormLabelProps,
  BoxProps,
  Button,
} from '@workshop/ui';

interface LabelWrapperProps {
  errorMessage?: Message;
  helpText?: string | React.ReactNode;
  helpTextPosition?: 'underline' | 'inline' | 'top';
  labelStyleProps?: FormLabelProps;
  inputId?: string;
  isInvalid?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  label?: string;
  labelPosition?: 'top' | 'inline';
  labelAlign?: 'center' | 'flex-start';
  labelButtonText?: string;
  labelButtonOnClick?: () => void;
  unit?: string;
  loadingStyle?: BoxProps;
}

const LabelWrapper: React.FC<LabelWrapperProps> = ({
  children,
  errorMessage,
  helpText,
  helpTextPosition = 'underline',
  inputId,
  isInvalid,
  isLoading = false,
  isRequired,
  label,
  labelPosition = 'inline',
  labelStyleProps = {},
  labelAlign = 'center',
  labelButtonText,
  labelButtonOnClick,
  unit,
  loadingStyle,
}) => {
  return (
    // minHeight = 2 x default margin
    <Skeleton
      isLoaded={!isLoading}
      minHeight={6}
      mb="defaultMargin"
      loadingStyle={loadingStyle}
      flex={1}
    >
      <Flex flexShrink={0} flexDirection="column">
        <FormControl isInvalid={isInvalid} isRequired={isRequired}>
          {label && labelPosition === 'top' && (
            <Flex
              flexDirection={{ base: 'column', sm: 'row' }}
              flex={1}
              minWidth={24}
              alignItems={{ base: 'flex-start', sm: 'center' }}
            >
              <FormLabel
                htmlFor={inputId}
                color="text.muted"
                fontSize="md"
                flex={1}
                {...labelStyleProps}
              >
                {label.trim() ? `${label}` : label}
              </FormLabel>
              {labelButtonText && labelButtonOnClick && (
                <Button
                  variant="outline"
                  size="xs"
                  onClick={labelButtonOnClick}
                  ml={{ base: 0, sm: 2 }}
                  mb={2}
                >
                  {labelButtonText}
                </Button>
              )}
            </Flex>
          )}
          <Flex flexDirection="row" alignItems={labelAlign}>
            {label && labelPosition === 'inline' && (
              <Flex flexDirection="column" flex={1} minWidth={28}>
                <FormLabel
                  htmlFor={inputId}
                  color="text.muted"
                  fontSize="md"
                  mb={0}
                  {...labelStyleProps}
                >
                  {label.trim() ? `${label}:` : label}
                </FormLabel>
              </Flex>
            )}
            <Flex flexDirection="row" flex={4}>
              <Flex flexDirection="row" alignItems="center" flex={1}>
                <Flex flexDirection="column" flex={2}>
                  {helpText && helpTextPosition === 'top' && (
                    <Flex mb={2}>
                      <FormHelperText
                        id={inputId}
                        fontSize="xs"
                        color="text.muted"
                        whiteSpace="pre-wrap"
                      >
                        {helpText}
                      </FormHelperText>
                    </Flex>
                  )}
                  {children}
                </Flex>
                {unit && (
                  <Flex flexDirection="column" flex={1}>
                    <Text
                      fontFamily="body"
                      fontSize="xs"
                      color="text.muted"
                      marginLeft={2}
                    >
                      {unit}
                    </Text>
                  </Flex>
                )}
              </Flex>
              {helpText && helpTextPosition === 'inline' && (
                <Flex flex={3} marginLeft={4} alignItems="center">
                  <FormHelperText
                    id={inputId}
                    fontSize="xs"
                    color="text.muted"
                    whiteSpace="pre-wrap"
                  >
                    {helpText}
                  </FormHelperText>
                </Flex>
              )}
            </Flex>
          </Flex>
          {helpText && helpTextPosition === 'underline' && (
            <Flex flexDirection="row">
              {label && labelPosition === 'inline' && (
                <Flex flexDirection="column" flex={1} minWidth={28} />
              )}
              <Flex flexDirection="column" flex={4}>
                <FormHelperText
                  id={inputId}
                  fontFamily="body"
                  fontSize="xs"
                  color="text.muted"
                  whiteSpace="pre-wrap"
                >
                  {helpText}
                </FormHelperText>
              </Flex>
            </Flex>
          )}
          <Flex flexDirection="row">
            {label && labelPosition === 'inline' && (
              <Flex flexDirection="column" flex={1} minWidth={28} />
            )}
            <Flex flexDirection="column" flex={4}>
              <FormErrorMessage
                color="text.error"
                fontSize="xs"
                marginY={2}
                fontFamily="body"
              >
                {errorMessage}
              </FormErrorMessage>
            </Flex>
          </Flex>
        </FormControl>
      </Flex>
    </Skeleton>
  );
};

export default LabelWrapper;
