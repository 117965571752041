const BackgroundAT = {
  START_CHUNK_UPLOAD: 'START_CHUNK_UPLOAD',
  CREATE_CHUNK_UPLOAD: 'CREATE_CHUNK_UPLOAD',
  UPDATE_CHUNK_UPLOAD: 'UPDATE_CHUNK_UPLOAD',
  CANCEL_CHUNK_UPLOAD: 'CANCEL_CHUNK_UPLOAD',
  CLEAR_CHUNK_UPLOADS: 'CLEAR_CHUNK_UPLOADS',

  COMPLETE_UPLOAD_CHUNK_REQUEST: 'COMPLETE_UPLOAD_CHUNK_REQUEST',
  COMPLETE_UPLOAD_CHUNK_SUCCESS: 'COMPLETE_UPLOAD_CHUNK_SUCCESS',
  COMPLETE_UPLOAD_CHUNK_FAILURE: 'COMPLETE_UPLOAD_CHUNK_FAILURE',
} as const;

export default {
  ...BackgroundAT,
};
