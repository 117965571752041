import React, { useState, useEffect } from 'react';
import {
  Box,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Step,
  Steps,
  useSteps,
  Skeleton,
  Button,
  Text,
  ScaleFade,
} from '@workshop/ui';

import { Loading } from 'components/Loading';

interface StepsModalStep {
  label: string;
  icon: React.ReactElement;
  content: React.ReactElement;
  nextButtonText?: string;
  showSkipButton?: boolean;
  nextButtonDisabled?: boolean;
}

interface StepsModalProps {
  steps: StepsModalStep[];
  isOpen: boolean;
  onClose: () => void;
  onCompleteStep?: (stepIndex: number) => Promise<void | 'error'>;
  onSkipStep?: (stepIndex: number) => void;
  loading?: boolean;
  submitting?: boolean;
  heading: string;
  stepSize?: string;
  modalSize?: string;
  hideStepLabels?: boolean;
  forceHorizontalSteps?: boolean;
  disableClose?: boolean;
  disablePrev?: boolean;
}

const StepsModal: React.FC<StepsModalProps> = ({
  steps,
  isOpen,
  onClose,
  onCompleteStep = () => null,
  onSkipStep = () => null,
  loading = false,
  submitting = false,
  heading,
  stepSize = 'sm',
  modalSize = 'lg',
  hideStepLabels = false,
  forceHorizontalSteps = false,
  disableClose = false,
  disablePrev = false,
}) => {
  const { nextStep, prevStep, activeStep, reset } = useSteps({
    initialStep: 0,
  });
  const [stepLoading, setStepLoading] = useState(false);

  useEffect(() => {
    if (isOpen) {
      reset();
    }
  }, [isOpen]);

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      size={modalSize}
      closeOnOverlayClick={!disableClose}
      closeOnEsc={!disableClose}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent marginX={2} marginY="20vh">
        <ModalHeader px={{ base: 4, md: 6 }}>
          <Text fontWeight="bold">{heading}</Text>
        </ModalHeader>
        {!disableClose && <ModalCloseButton />}
        <ModalBody paddingTop={0} px={{ base: 4, md: 6 }}>
          {submitting ? (
            <Loading />
          ) : (
            <Skeleton isLoaded={!loading}>
              <Steps
                size={stepSize}
                activeStep={activeStep}
                responsive={!forceHorizontalSteps}
                textAlign="left"
                justifyContent="flex-start"
              >
                {steps.map(({ label, icon, content }, index) => (
                  <Step
                    label={
                      !hideStepLabels
                        ? label
                        : index === activeStep
                        ? label
                        : ''
                    }
                    icon={() => icon}
                    key={label}
                  >
                    <ScaleFade in={index === activeStep}>{content}</ScaleFade>
                  </Step>
                ))}
              </Steps>
            </Skeleton>
          )}
        </ModalBody>
        <ModalFooter>
          {activeStep === steps.length ? null : (
            <Flex width="100%" justify="flex-end">
              {!disablePrev && (
                <Button
                  isDisabled={activeStep === 0 || loading || stepLoading}
                  mr={2}
                  onClick={prevStep}
                  size="sm"
                  secondary
                >
                  Prev
                </Button>
              )}
              <Box flex={1} />
              {steps[activeStep]?.showSkipButton && (
                <Button
                  isDisabled={activeStep === 0 || loading || stepLoading}
                  mr={2}
                  onClick={() => {
                    onSkipStep(activeStep);
                    nextStep();
                  }}
                  size="sm"
                  secondary
                >
                  Skip
                </Button>
              )}
              <Button
                size="sm"
                onClick={async () => {
                  setStepLoading(true);
                  const completeStatus = await onCompleteStep(activeStep);
                  setStepLoading(false);
                  if (completeStatus !== 'error') {
                    nextStep();
                  }
                }}
                isLoading={loading || stepLoading}
                disabled={steps[activeStep]?.nextButtonDisabled}
              >
                {steps[activeStep]?.nextButtonText
                  ? steps[activeStep].nextButtonText
                  : activeStep === steps.length - 1
                  ? 'Finish'
                  : 'Next'}
              </Button>
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default StepsModal;
