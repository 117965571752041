import React from 'react';
import { animated, AnimatedValue, ForwardedProps } from 'react-spring';
import { MdIcon, MdIconProps } from '@workshop/ui';

// Type copied over from react-spring
type OverwriteKeys<A, B> = { [K in keyof A]: K extends keyof B ? B[K] : A[K] };
type AnimatedStyle = AnimatedValue<
  ForwardedProps<OverwriteKeys<object, React.CSSProperties>>
>;

const AnimatedMdIcon = animated(MdIcon);

interface Props extends MdIconProps {
  isDisabled?: boolean;
  style?: AnimatedStyle;
  onClick?: (...args: any) => void;
}
const AnimatedButton: React.FC<Props> = ({
  isDisabled = false,
  onClick,
  style = {},
  ...props
}) => {
  const btnProps = {
    isDisabled,
    cursor: onClick && !isDisabled ? 'pointer' : 'initial',
    style,
    onClick,
    ...props,
  };
  return <AnimatedMdIcon {...btnProps} />;
};

export default AnimatedButton;
