import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box } from '@workshop/ui';

import { Organisation, PERMISSION_SLUGS } from 'types/common';

import { useHasPermission } from 'redux/selectors/organisation';
import { EditCard, LabelInput, LabelTextArea } from 'components/Common';

interface AddressDetailsCardProps {
  address?: string;
  city?: string;
  postCode?: string;
  isLoading?: boolean;
  onSubmit: (args: Partial<Organisation>) => Promise<any>;
}

const AddressDetailsCard: React.FC<AddressDetailsCardProps> = (props) => {
  const isEditingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_organisation
  );

  const { isLoading = false, onSubmit } = props;

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<Partial<Organisation>>({
    defaultValues: {
      address: props.address,
      city: props.city,
      postCode: props.postCode,
    },
  });

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    reset({
      address: props.address,
      city: props.city,
      postCode: props.postCode,
    });
  }, [props.address, props.city, props.postCode]);

  const onSubmitData = handleSubmit(async (data) => {
    setSubmitting(true);
    await onSubmit(data);

    reset(data, { dirty: false });
    setSubmitting(false);
  });

  return (
    <EditCard
      onSave={onSubmitData}
      onCancel={() => {
        clearError();

        reset({
          address: props.address || '',
          city: props.city || '',
          postCode: props.postCode || '',
        });
      }}
      isDisabled={isEditingDisabled}
      isUpdating={submitting}
      saveDisabled={Boolean(!dirty || !isEmpty(errors))}
    >
      <Box flex={1}>
        <LabelTextArea
          id="address"
          name="address"
          isDisabled={isEditingDisabled}
          label="Address"
          error={Boolean(errors.address)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelInput
          id="city"
          name="city"
          isDisabled={isEditingDisabled}
          label="Town/City"
          error={Boolean(errors.city)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelInput
          id="postCode"
          name="postCode"
          isDisabled={isEditingDisabled}
          label="Post code"
          error={Boolean(errors.postCode)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
      </Box>
    </EditCard>
  );
};

export default AddressDetailsCard;
