import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import uniqBy from 'lodash/uniqBy';
import { Button, Flex, MdIcon, Text } from '@workshop/ui';

import { useUploadList } from 'redux/selectors/background';
import { backgroundActions } from 'redux/actions/common';

import { FileUpload } from 'components/FileUpload';
import { UploadStatus } from 'components/AppHeader';

/** Format the given bites number into a file size in MB */
const getFormattedFileSize = (size: number) =>
  `${(size / 1000000).toFixed(1)}MB`;

interface VideoClipsUploadProps {
  onUploadClips: (files: File[]) => Promise<boolean>;
  onClearError: () => void;
  error?: string | null;
}

const VideoClipFile: React.FC<{
  name: string;
  size: number;
  isLoading: boolean;
  isDisabled?: boolean;
  onClick: () => void;
}> = ({ isDisabled = false, name, size, isLoading, onClick }) => (
  <Flex
    bg="white"
    p={2}
    borderRadius="md"
    mt={1}
    alignItems="center"
    boxShadow="md"
  >
    <Text flex={1}>{name}</Text>
    <Text>{getFormattedFileSize(size)}</Text>
    <MdIcon
      color={isLoading ? 'neutral.300' : 'red.300'}
      name="RemoveCircle"
      ml={2}
      boxSize="18px"
      cursor={isLoading ? 'default' : 'pointer'}
      onClick={isDisabled ? () => null : onClick}
    />
  </Flex>
);

const VideoClipsUpload: React.FC<VideoClipsUploadProps> = ({
  onUploadClips,
  onClearError,
  error: errorProps = null,
}) => {
  const [error, setError] = useState<string | null>(errorProps);
  const [files, setFiles] = useState<File[] | null>(null);
  const [isSubmitting, setSubmitting] = useState(false);

  const dispatch = useDispatch();

  const uploadList = useUploadList();

  const onDrop = (newFiles: File[], files: File[]) => {
    setError(null);

    if (newFiles.find((f) => !f.type.includes('video'))) {
      setError('Please select video files only');
      return;
    }
    setFiles(uniqBy([...files, ...newFiles], 'name'));
  };

  useEffect(() => {
    // If errorProp changes, update the error state to display
    // this error in place of any locally set error
    if (error !== errorProps) setError(errorProps);
  }, [errorProps]);

  const clearError = () => {
    onClearError();
    setError(null);
  };

  // Function for handling resetting of the form
  const reset = () => {
    setFiles(null);
    clearError();
    dispatch(backgroundActions.clearChunkUploads());
  };

  return (
    <Flex flexDir="column">
      {files?.length ? (
        <>
          <FileUpload
            name="video-clips-upload"
            label="Drag Videos Here"
            accept="video/mp4,video/x-m4v,video/*"
            onDrop={(name, newFiles) => onDrop(newFiles, files)}
          />
          <Flex
            bg="background.tint3"
            flexDir="column"
            borderRadius="md"
            p={3}
            mt={2}
          >
            <Text color="text.muted" fontWeight="semibold" mb={2}>
              Video Clips to Upload
            </Text>
            {files
              .sort((a, b) => (a.name > b.name ? 1 : -1))
              .map(({ name, size }, id) => {
                const upload = Object.values(uploadList).find(
                  ({ filename }) => filename === name
                );
                return upload && !error ? (
                  <UploadStatus
                    key={`${id}-${name}`}
                    id={`${id}-${name}`}
                    upload={upload}
                  />
                ) : (
                  <VideoClipFile
                    key={`${id}-${name}`}
                    name={name}
                    size={size}
                    isLoading={isSubmitting}
                    isDisabled={isSubmitting}
                    onClick={() =>
                      setFiles(files.filter((f) => f.name !== name))
                    }
                  />
                );
              })}
          </Flex>
        </>
      ) : (
        <FileUpload
          name="video-clips-upload"
          label="Drag Videos Here"
          accept="video/mp4,video/x-m4v,video/*"
          onDrop={(name, newFiles) => onDrop(newFiles, [])}
        />
      )}

      {/* Buttons */}
      {files?.length ? (
        <Flex mt={3} justifyContent="flex-end">
          <Button isDisabled={isSubmitting} mr={2} onClick={reset} secondary>
            Cancel
          </Button>
          <Button
            isLoading={isSubmitting}
            isDisabled={isSubmitting}
            onClick={async () => {
              setSubmitting(true);
              clearError();
              dispatch(backgroundActions.clearChunkUploads());

              const success = await onUploadClips(files);
              setSubmitting(false);

              // If the upload completed successfully, reset the form, otherwise
              // leave the files loaded
              if (success) {
                reset();
              }
            }}
          >
            Upload Files
          </Button>
        </Flex>
      ) : null}

      {/* Errors */}
      {error && (
        <Text
          color="text.error"
          w="100%"
          textAlign="center"
          mt={1}
          fontSize="xs"
        >
          {error}
        </Text>
      )}
    </Flex>
  );
};

export default VideoClipsUpload;
