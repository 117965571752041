import { UnitListState, IUnitType } from 'types/cms';

export interface DraggableUnit {
  id: string;
  title: string;
  label: string;
  index: number;
  unitType: string;
}

type FormattedData = {
  [key in IUnitType]: DraggableUnit[];
};

export const formatUnitList = (units: UnitListState): FormattedData => {
  return Object.keys(units).reduce<FormattedData>(
    (acc, unitId) => {
      const unit = units[unitId];

      acc[unit.unitType] = [
        ...acc[unit.unitType],
        {
          id: unitId,
          title: unit?.title,
          label: unit?.prefix,
          index: unit?.index,
          unitType: unit?.unitType,
        },
      ];

      return acc;
    },
    {
      normal: [],
      intro: [],
      outro: [],
      transition: [],
      assessment: [],
      practice: [],
    }
  );
};
