import React from 'react';
import { useForm } from 'react-hook-form';

import { LabelInput, LabelTextArea } from 'components/Common';
import { EditModal } from 'components/Common/EditModal';

interface CreateCourseFormData {
  title: string;
  subtitle: string;
  summaryText: string;
}

interface Props {
  title: string;
  isOpen: boolean;
  isUpdating?: boolean;
  onClose: () => void;
  onSave: (formData: CreateCourseFormData) => void;
  onCancel?: () => void;
  error?: boolean;
}

const noop = () => null;
const CreateCourseModal = ({
  title = 'Create Course',
  isOpen = false,
  isUpdating = false,
  onClose,
  onSave,
  onCancel = noop,
  error = false,
}: Props) => {
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<CreateCourseFormData>();

  const onSubmit = (e?: React.FormEvent<HTMLFormElement>) => {
    // react-hook-form calls `preventDefault` on the event internally,
    // but we have to call `stopPropagation` to prevent the modal from
    // closing.
    if (e && e.stopPropagation) {
      // prevent any outer forms from receiving the event too
      e.stopPropagation();
    }

    handleSubmit((formData) => {
      onSave(formData);
    })(e);
  };
  const helpText = '';

  return (
    <EditModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        onClose();
        clearError();
        reset();
      }}
      saveDisabled={Boolean(!dirty || errors.title)}
      isUpdating={isUpdating}
    >
      <LabelInput
        id="title"
        name="title"
        label="Title"
        error={!!errors.title}
        errorMessage={errors.title?.message}
        registerInputRef={register({
          required: {
            value: true,
            message: 'Please give your course a title.',
          },
        })}
      />
      <LabelInput
        id="subtitle"
        name="subtitle"
        label="Subtitle"
        error={!!errors.subtitle}
        errorMessage={errors.subtitle?.message}
        registerInputRef={register({
          required: {
            value: true,
            message:
              'Please enter a subtitle for your course – e.g. "24-Week Professional Course".',
          },
        })}
      />
      <LabelTextArea
        id="summaryText"
        name="summaryText"
        label="Short summary"
        error={!!errors.summaryText}
        errorMessage={errors.summaryText?.message}
        registerInputRef={register({
          required: 'Please enter a brief description of your course.',
        })}
        helpText={helpText}
      />
    </EditModal>
  );
};

export default CreateCourseModal;
