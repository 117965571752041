import React, { useState, useEffect, useRef } from 'react';
import moment, { Moment } from 'moment';
import Timeline, {
  CustomMarker,
  TimelineItemBase,
  TimelineGroupBase,
} from 'react-calendar-timeline';
import './styles/scheduleTimeline.css';

import {
  Image,
  Flex,
  Box,
  Text,
  Popover,
  PopoverTrigger,
  Slide,
} from '@workshop/ui';
import { Loading } from 'components/Loading';

const keys = {
  groupIdKey: 'id',
  groupTitleKey: 'title',
  groupRightTitleKey: 'rightTitle',
  itemIdKey: 'id',
  itemTitleKey: 'title',
  itemDivTitleKey: 'title',
  itemGroupKey: 'group',
  itemTimeStartKey: 'start',
  itemTimeEndKey: 'end',
  groupLabelKey: 'title',
};

interface ScheduleTimelineItem extends TimelineItemBase<any> {
  id: number | string;
  group: number | string;
  title: string;
  subtitles?: string[];
  start: Moment | number;
  end: Moment | number;
  canMove?: boolean;
  canResize?: boolean;
  bgColor?: string;
  bgAccentColor?: string;
  textColor?: string;
  popoverContent?: React.ReactNode;
  onPopoverOpen?: () => void;
}

interface ScheduleTimelineProps {
  groups: {
    id: number | string;
    image?: string;
    title?: React.ReactNode;
    rightTitle?: React.ReactNode;
    height: number;
  }[];
  items: ScheduleTimelineItem[];
  isLoading?: boolean;
}

const getOffscreenGroups = (
  items: ScheduleTimelineItem[],
  timelineStart: Moment | number,
  timelineEnd: Moment | number
) => {
  return items
    .filter((item) => {
      const offScreen =
        moment(item.end).isBefore(timelineStart) ||
        moment(item.start).isAfter(timelineEnd);
      return offScreen;
    })
    .map((item) => {
      const leftOfScreen = moment(item.end).isBefore(timelineStart);
      return {
        groupId: item.group,
        direction: leftOfScreen ? 'left' : 'right',
        startTime: item.start,
      };
    });
};

const ScheduleTimeline: React.FC<ScheduleTimelineProps> = ({
  groups,
  items,
  isLoading = false,
}) => {
  const [visibleTimeStart, setVisibleTimeStart] = useState(
    moment().add(-1, 'day').valueOf()
  );
  const [visibleTimeEnd, setVisibleTimeEnd] = useState(
    moment().add(29, 'day').valueOf()
  );
  const [offscreenGroups, setOffscreenGroups] = useState(
    getOffscreenGroups(items, visibleTimeStart, visibleTimeEnd)
  );
  const [timelineGroups, setTimelineGroups] = useState<TimelineGroupBase[]>([
    {
      id: 0,
      title: '',
      height: 95,
    },
    {
      id: 1,
      title: '',
      height: 70,
    },
    {
      id: 2,
      title: '',
      height: 35,
    },
    // @ts-ignore
    ...groups,
    {
      id: 3,
      // @ts-ignore
      title: '',
      height: 70,
    },
  ]);
  useEffect(() => {
    if (groups?.length > 0) {
      setOffscreenGroups(
        getOffscreenGroups(items, visibleTimeStart, visibleTimeEnd)
      );
    }
  }, [groups?.length]);
  useEffect(() => {
    const newGroups = groups.map((group) => {
      const offScreenGroup = offscreenGroups.find(
        ({ groupId }) => group.id === groupId
      );
      const onClick = () => {
        if (offScreenGroup) {
          setVisibleTimeStart(
            moment(offScreenGroup.startTime).add(-1, 'day').valueOf()
          );
          setVisibleTimeEnd(
            moment(offScreenGroup.startTime).add(29, 'day').valueOf()
          );
        }
      };

      const item = items.find((i) => i.group === group.id);

      const sidebarButton = (
        <Box cursor="pointer" onClick={onClick}>
          <Flex height={67} alignItems="flex-end">
            <Slide
              direction={
                offScreenGroup?.direction === 'left' ? 'left' : 'right'
              }
              in={!!offScreenGroup}
            >
              <Box
                padding={1}
                backgroundColor={item?.bgColor}
                borderTopLeftRadius={
                  offScreenGroup?.direction === 'right' ? 'full' : 0
                }
                borderBottomLeftRadius={
                  offScreenGroup?.direction === 'right' ? 'full' : 0
                }
                borderTopRightRadius={
                  offScreenGroup?.direction === 'left' ? 'full' : 0
                }
                borderBottomRightRadius={
                  offScreenGroup?.direction === 'left' ? 'full' : 0
                }
              >
                <Image
                  boxSize="28px"
                  src={group.image}
                  objectFit="cover"
                  borderRadius="full"
                />
              </Box>
            </Slide>
          </Flex>
        </Box>
      );

      return {
        ...group,
        title: offScreenGroup?.direction === 'left' ? sidebarButton : null,
        rightTitle:
          offScreenGroup?.direction === 'right' ? sidebarButton : null,
      };
    });
    setTimelineGroups([
      {
        id: 0,
        title: '',
        height: 95,
      },
      {
        id: 1,
        title: '',
        height: 70,
      },
      {
        id: 2,
        title: '',
        height: 35,
      },
      ...newGroups,
      {
        id: 3,
        title: '',
        height: 70,
      },
    ]);
  }, [JSON.stringify(offscreenGroups)]);

  return (
    <>
      {/* @ts-ignore */}
      <Timeline
        groups={timelineGroups}
        items={items}
        keys={keys}
        itemTouchSendsClick={false}
        // @ts-ignore
        showCursorLine
        canMove={false}
        canResize={false}
        itemRenderer={({
          item,
          // @ts-ignore
          timelineContext,
          itemContext,
          getItemProps,
          getResizeProps,
        }) => {
          const { left: leftResizeProps, right: rightResizeProps } =
            getResizeProps();
          const backgroundColor = itemContext.selected
            ? itemContext.dragging
              ? 'red'
              : item.bgAccentColor
            : item.bgColor;
          const borderColor = itemContext.resizing ? 'red' : backgroundColor;

          setOffscreenGroups(
            getOffscreenGroups(
              items,
              timelineContext.visibleTimeStart,
              timelineContext.visibleTimeEnd
            )
          );

          const group = groups.find((g) => g.id === item.group);

          if (item.group === 1) {
            return (
              <Popover onOpen={item.onPopoverOpen} key={`popover-${item.id}`}>
                <PopoverTrigger>
                  <Box
                    height="70px!important"
                    marginTop="-5px!important"
                    {...getItemProps({
                      style: {
                        backgroundColor,
                        color: item.textColor,
                        borderColor,
                      },
                    })}
                  ></Box>
                </PopoverTrigger>
                {item.popoverContent}
              </Popover>
            );
          }

          return (
            <Popover onOpen={item.onPopoverOpen} key={`popover-${item.id}`}>
              <PopoverTrigger>
                <Box
                  height="auto!important"
                  borderRadius="full"
                  {...getItemProps({
                    style: {
                      backgroundColor,
                      color: item.textColor,
                      borderColor,
                      marginTop: itemContext.dimensions.height + 5,
                    },
                  })}
                >
                  {itemContext.useResizeHandle ? (
                    <div {...leftResizeProps} />
                  ) : null}

                  <Flex
                    position="sticky"
                    left={3}
                    marginTop={`-${itemContext.dimensions.height + 5}px`}
                    height={`${itemContext.dimensions.height + 5}px`}
                    width="max-content"
                  >
                    <Text fontSize="sm" fontWeight="semibold">
                      {itemContext.title}
                    </Text>
                    {item.subtitles?.map((s) => (
                      <>
                        <Text
                          key={`${item.id}-${s}`}
                          fontSize="sm"
                          marginLeft={2}
                          marginRight={2}
                        >
                          {'|'}
                        </Text>
                        <Text fontSize="sm">{s}</Text>
                      </>
                    ))}
                  </Flex>

                  <Box>
                    <Flex
                      padding={1}
                      position="sticky"
                      left={1}
                      width="max-content"
                    >
                      <Image
                        boxSize="28px"
                        src={group?.image}
                        borderRadius="full"
                        objectFit="cover"
                      />
                    </Flex>
                  </Box>

                  {itemContext.useResizeHandle ? (
                    <div {...rightResizeProps} />
                  ) : null}
                </Box>
              </PopoverTrigger>
              {item.popoverContent}
            </Popover>
          );
        }}
        visibleTimeStart={visibleTimeStart}
        visibleTimeEnd={visibleTimeEnd}
        minZoom={30 * 24 * 60 * 60 * 1000}
        maxZoom={200 * 24 * 60 * 60 * 1000}
        sidebarWidth={36}
        rightSidebarWidth={36}
        onTimeChange={(visibleStart, visibleEnd, updateScrollCanvas) => {
          updateScrollCanvas(visibleStart, visibleEnd);
          setVisibleTimeStart(visibleStart);
          setVisibleTimeEnd(visibleEnd);
        }}
      >
        <CustomMarker date={new Date().valueOf()}>
          {({ styles, date }) => {
            const customStyles = {
              ...styles,
              backgroundColor: '',
              width: '',
            };
            return (
              <Box>
                <Box
                  width="8px"
                  height="8px"
                  marginLeft="-3px"
                  borderRadius="full"
                  backgroundColor="common.primary"
                  style={customStyles}
                />
                <Box
                  borderLeftStyle="dashed"
                  borderLeftWidth={1}
                  borderLeftColor="common.primary"
                  style={customStyles}
                />
              </Box>
            );
          }}
        </CustomMarker>
      </Timeline>
      {isLoading ? <Loading /> : null}
    </>
  );
};

export default ScheduleTimeline;
