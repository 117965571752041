import React from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  ModalFooter,
  ModalProps,
  Flex,
  Text,
} from '@workshop/ui';
import { useSpring } from 'react-spring';
import { AnimatedButton } from 'components/Common/index';

interface EditCardProps {
  error?: boolean;
  isUpdating?: boolean;
  onCancel: (e?: React.FormEvent<HTMLFormElement>) => void;
  onSave: (e?: React.FormEvent<HTMLFormElement>) => void;
  saveDisabled?: boolean;
}

interface EditModalProps {
  /**
   * This enables/prevents the modal closing on clicking the overlay. If using React Hook Form
   * this must be disabled to prevent handleSubmit closing the modal automatically
   */
  closeOnOverlayClick?: boolean;
  isOpen: boolean;
  modalSize?: ModalProps['size'];
  onClose: () => void;
  saveLabel?: string;
  cancelLabel?: string;
  showFooter?: boolean;
  subTitle?: string | React.ReactElement;
  title: string;
}

export interface Props extends EditModalProps, EditCardProps {}

export const EditModal: React.FC<Props> = ({
  children,
  closeOnOverlayClick = true,
  isOpen,
  isUpdating = false,
  modalSize,
  onCancel,
  onClose,
  onSave,
  saveDisabled = false,
  saveLabel = 'Save',
  cancelLabel = 'Cancel',
  showFooter = true,
  subTitle,
  title,
}) => {
  const btnStyle = useSpring({
    opacity: saveDisabled ? 0.5 : 1,
  });
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      closeOnOverlayClick={closeOnOverlayClick}
      motionPreset="slideInBottom"
      size={modalSize}
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent marginX={2}>
        <ModalHeader
          fontWeight="bold"
          letterSpacing="title"
          display={{ base: 'block', md: 'flex' }}
          alignItems="center"
          px={{ base: 4, md: 6 }}
        >
          <Text>{title}</Text>
          {subTitle && (
            <Text
              fontSize="md"
              fontWeight="semibold"
              color="text.muted"
              ml={{ base: 0, md: 4 }}
              mt={{ base: 2, md: 0 }}
              flex={1}
            >
              {subTitle}
            </Text>
          )}
        </ModalHeader>

        <ModalCloseButton />
        <ModalBody px={{ base: 4, md: 6 }}>{children}</ModalBody>
        {showFooter && (
          <ModalFooter px={{ base: 4, md: 6 }} pb={{ base: 4, md: 5 }}>
            <Flex
              flexDirection="row"
              alignContent="center"
              justifyContent="flex-end"
            >
              {onCancel && (
                <AnimatedButton
                  isDisabled={saveDisabled}
                  onClick={onCancel}
                  secondary
                >
                  {cancelLabel}
                </AnimatedButton>
              )}
              {!!saveLabel && (
                <AnimatedButton
                  isDisabled={saveDisabled}
                  isLoading={isUpdating}
                  style={btnStyle}
                  onClick={onSave}
                >
                  {saveLabel}
                </AnimatedButton>
              )}
            </Flex>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
