import { decamelize } from 'humps';
import { callAPI, callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { CurrentView } from 'types/common';
import { OrganisationAT } from 'redux/actionTypes/common';

import {
  MemberInvitationSchema,
  PermissionSchema,
  RoleSchema,
  TeamMembershipSchema,
} from 'redux/schemas';

import {
  FetchInvitationAction,
  FetchInvitationListAction,
  FetchMembershipListAction,
  FetchOrganisationProfileAction,
  FetchTeamMembershipAction,
  MyTeamsAction,
  Organisation,
  PatchTeamMembershipAction,
  PermissionAction,
  RoleAction,
  SendInvitationAction,
  SetCurrentTeam,
  TeamMember,
  MemberInvitationFormData,
  SetCurrentView,
  AcceptInvitationAction,
  CreateOrganisationAction,
} from 'types/common';

export const listRoles = () => {
  return callAPI<RoleAction>({
    types: [
      OrganisationAT.FETCH_ROLE_REQUEST,
      OrganisationAT.FETCH_ROLE_SUCCESS,
      OrganisationAT.FETCH_ROLE_FAILURE,
    ],
    endpoint: API.organisation.roles,
    method: 'GET',
    schema: [RoleSchema],
    path: 'results',
  });
};

export const listPermissions = () => {
  return callAPI<PermissionAction>({
    types: [
      OrganisationAT.FETCH_PERMISSION_REQUEST,
      OrganisationAT.FETCH_PERMISSION_SUCCESS,
      OrganisationAT.FETCH_PERMISSION_FAILURE,
    ],
    endpoint: API.organisation.permissions,
    method: 'GET',
    schema: [PermissionSchema],
    path: 'results',
  });
};

export const fetchMyTeams = () => {
  return callAPI<MyTeamsAction>({
    types: [
      OrganisationAT.FETCH_MY_TEAMS_REQUEST,
      OrganisationAT.FETCH_MY_TEAMS_SUCCESS,
      OrganisationAT.FETCH_MY_TEAMS_FAILURE,
    ],
    endpoint: API.organisation.myTeams,
    method: 'GET',
    path: 'results',
  });
};

export const listTeamMembers = () => {
  return callAPIWithTeam<FetchMembershipListAction>({
    types: [
      OrganisationAT.FETCH_TEAM_MEMBER_LIST_REQUEST,
      OrganisationAT.FETCH_TEAM_MEMBER_LIST_SUCCESS,
      OrganisationAT.FETCH_TEAM_MEMBER_LIST_FAILURE,
    ],
    endpoint: API.organisation.teamMembers(),
    method: 'GET',
    schema: [TeamMembershipSchema],
    path: 'results',
    meta: {
      toast: {
        error: 'An error occurred loading your your Team Members.',
      },
    },
  });
};

export const fetchTeamMember = (teamMembershipId: number) => {
  return callAPIWithTeam<FetchTeamMembershipAction>({
    types: [
      OrganisationAT.FETCH_TEAM_MEMBER_REQUEST,
      OrganisationAT.FETCH_TEAM_MEMBER_SUCCESS,
      OrganisationAT.FETCH_TEAM_MEMBER_FAILURE,
    ],
    endpoint: API.organisation.teamMembers(teamMembershipId),
    method: 'GET',
    schema: TeamMembershipSchema,
  });
};

export const updateTeamMember = (
  teamMembershipId: number,
  data: Partial<TeamMember>
) => {
  return callAPIWithTeam<PatchTeamMembershipAction>({
    types: [
      OrganisationAT.PATCH_TEAM_MEMBER_REQUEST,
      OrganisationAT.PATCH_TEAM_MEMBER_SUCCESS,
      OrganisationAT.PATCH_TEAM_MEMBER_FAILURE,
    ],
    endpoint: API.organisation.teamMembers(teamMembershipId),
    method: 'PATCH',
    schema: TeamMembershipSchema,
    body: data,
    meta: {
      toast: { success: 'Successfully updated permissions.' },
    },
  });
};

export const deleteTeamMember = (teamId: number, teamMembershipId: number) => {
  return callAPIWithTeam<PatchTeamMembershipAction>({
    types: [
      OrganisationAT.DELETE_TEAM_MEMBER_REQUEST,
      OrganisationAT.DELETE_TEAM_MEMBER_SUCCESS,
      OrganisationAT.DELETE_TEAM_MEMBER_FAILURE,
    ],
    endpoint: API.organisation.teamMembers(teamMembershipId),
    meta: {
      teamId,
      memberId: teamMembershipId,
      toast: { success: 'Successfully removed team member.' },
    },
    method: 'DELETE',
  });
};

export const fetchOrganisationProfile = (id: number) => {
  return callAPI<FetchOrganisationProfileAction>({
    types: [
      OrganisationAT.FETCH_ORGANISATION_REQUEST,
      OrganisationAT.FETCH_ORGANISATION_SUCCESS,
      OrganisationAT.FETCH_ORGANISATION_FAILURE,
    ],
    endpoint: API.organisation.organisationProfile(id),
    method: 'GET',
  });
};

export const updateOrganisationProfile = (
  id: number,
  data: Partial<Organisation>
) => {
  const formData = new FormData();

  Object.keys(data).forEach((k) => {
    if (k === 'logo' && ['string', 'undefined'].includes(typeof data[k])) {
      return;
    }

    const key = k as never;
    formData.append(decamelize(k), data[key]);
  });

  return callAPI<FetchOrganisationProfileAction>({
    types: [
      OrganisationAT.UPDATE_ORGANISATION_REQUEST,
      OrganisationAT.UPDATE_ORGANISATION_SUCCESS,
      OrganisationAT.UPDATE_ORGANISATION_FAILURE,
    ],
    endpoint: API.organisation.organisationProfile(id),
    body: formData,
    method: 'PATCH',
  });
};

export const sendMemberInvitation = (data: MemberInvitationFormData) => {
  return callAPIWithTeam<SendInvitationAction>({
    types: [
      OrganisationAT.SEND_INVITATION_REQUEST,
      OrganisationAT.SEND_INVITATION_SUCCESS,
      OrganisationAT.SEND_INVITATION_FAILURE,
    ],
    endpoint: API.organisation.teamMemberInvitation(),
    body: data,
    method: 'POST',
    meta: { toast: { error: true, success: 'Invitation sent' } },
  });
};

export const resendMemberInvitation = (id: number) => {
  return callAPIWithTeam<SendInvitationAction>({
    types: [
      OrganisationAT.RESEND_INVITATION_REQUEST,
      OrganisationAT.RESEND_INVITATION_SUCCESS,
      OrganisationAT.RESEND_INVITATION_FAILURE,
    ],
    endpoint: API.organisation.resendInvitation(id),
    method: 'POST',
    meta: {
      toast: { error: true, success: 'Invitation successfully resent' },
    },
  });
};

export const listMemberInvitation = () => {
  return callAPIWithTeam<FetchInvitationListAction>({
    types: [
      OrganisationAT.FETCH_INVITATION_LIST_REQUEST,
      OrganisationAT.FETCH_INVITATION_LIST_SUCCESS,
      OrganisationAT.FETCH_INVITATION_LIST_FAILURE,
    ],
    endpoint: API.organisation.teamMemberInvitation(),
    method: 'GET',
  });
};

export const retrieveMemberInvitation = (invitationKey: string) => {
  return callAPI<FetchInvitationAction>({
    types: [
      OrganisationAT.FETCH_INVITATION_REQUEST,
      OrganisationAT.FETCH_INVITATION_SUCCESS,
      OrganisationAT.FETCH_INVITATION_FAILURE,
    ],
    endpoint: API.organisation.invitation(invitationKey),
    method: 'GET',
    schema: MemberInvitationSchema,
    meta: { toast: false },
  });
};

export const acceptMemberInvitation = (invitationKey: string) => {
  return callAPI<AcceptInvitationAction>({
    types: [
      OrganisationAT.ACCEPT_INVITATION_REQUEST,
      OrganisationAT.ACCEPT_INVITATION_SUCCESS,
      OrganisationAT.ACCEPT_INVITATION_FAILURE,
    ],
    endpoint: API.organisation.invitation(invitationKey),
    method: 'POST',
    schema: MemberInvitationSchema,
    meta: { toast: { success: false } },
  });
};

export const setCurrentTeam = (team: number | null): SetCurrentTeam => ({
  type: OrganisationAT.SET_CURRENT_TEAM,
  payload: team,
  error: false,
});

export const setCurrentView = (view: CurrentView): SetCurrentView => ({
  type: OrganisationAT.SET_CURRENT_VIEW,
  payload: view,
  error: false,
});

export const createOrganisation = (data: Partial<Organisation>) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    const key = k as never;
    formData.append(decamelize(k), data[key]);
  });
  return callAPI<CreateOrganisationAction>({
    types: [
      OrganisationAT.CREATE_ORGANISATION_REQUEST,
      OrganisationAT.CREATE_ORGANISATION_SUCCESS,
      OrganisationAT.CREATE_ORGANISATION_FAILURE,
    ],
    endpoint: API.organisation.create,
    method: 'POST',
    body: formData,
  });
};
