import React, { useEffect, useState } from 'react';

import { videoUtils } from 'utils';

import {
  Button,
  Card,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Textarea,
  Box,
  MdIcon,
} from '@workshop/ui';

import { VideoClipsPlayer } from 'components/VideoClipsPlayer';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onSaveSummary?: (summary: string) => Promise<any>;
  summary?: string;
  video?: string;
  isDisabled?: boolean;
  autoplay?: boolean;
  isEditable?: boolean;
  onEdit?: (e: React.ChangeEvent<HTMLInputElement>, id: string) => void;
}

const ModalVideo: React.FC<ModalProps> = ({
  isOpen,
  onClose,
  onSaveSummary,
  summary: summaryProps,
  video = '',
  isDisabled = false,
  autoplay = true,
  isEditable = false,
  onEdit = () => null,
}) => {
  const [summary, setSummary] = useState<string | undefined>(summaryProps);
  const [orientation, setOrientation] = useState<'portrait' | 'landscape'>(
    'portrait'
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const saveDisabled = isDisabled;

  useEffect(() => {
    const getVideoOrientation = async (video: string) => {
      const videoDimensions = await videoUtils.getVideoDimensions(video);
      if (videoDimensions.width > videoDimensions.height) {
        setOrientation('landscape');
      } else {
        setOrientation('portrait');
      }
    };
    getVideoOrientation(video);
  }, [video]);

  useEffect(() => {
    setSummary(summaryProps);
  }, [summaryProps]);

  return (
    <Modal
      size={orientation === 'landscape' ? '5xl' : 'sm'}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="lg"
        {...(orientation === 'portrait' ? { maxW: '45vh' } : {})}
      >
        <ModalBody padding={0}>
          <Card padding="defaultPadding" overflow="hidden" flexDir="column">
            <Flex borderRadius="md" overflow="hidden">
              <VideoClipsPlayer
                autoplay={autoplay}
                autoPlayNext
                isEditable={isEditable}
                onEdit={onEdit}
                clips={[
                  {
                    type: 'video',
                    id: '',
                    data: {
                      autoplay: true,
                      clip: { src: video },
                    },
                  },
                ]}
                orientation={orientation}
              />
            </Flex>
            {onSaveSummary && (
              <>
                <Textarea
                  mt="defaultMargin"
                  isDisabled={isDisabled}
                  defaultValue={summary}
                  onChange={(e: React.ChangeEvent<HTMLTextAreaElement>) =>
                    setSummary(e.currentTarget.value)
                  }
                />
                <Flex mt="defaultMargin" justifyContent="flex-end">
                  <Button
                    isLoading={isUpdating}
                    isDisabled={saveDisabled || !summary || isUpdating}
                    onClick={async () => {
                      if (!summary) return;
                      setIsUpdating(true);
                      await onSaveSummary(summary);
                      setIsUpdating(false);
                    }}
                  >
                    Save
                  </Button>
                </Flex>
              </>
            )}
          </Card>
        </ModalBody>
        <Box
          position="absolute"
          pl={4}
          pb={4}
          pt={4}
          top="-40px"
          right={0}
          cursor="pointer"
          onClick={onClose}
          _hover={{ opacity: 0.8 }}
        >
          <MdIcon name="Close" boxSize="icon" color="white" />
        </Box>
      </ModalContent>
    </Modal>
  );
};

export default ModalVideo;
