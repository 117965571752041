import React from 'react';

import { SESSION_TYPE } from 'constants/courses';

import { Box, Flex, Divider, Text } from '@workshop/ui';
import { HeaderTag } from 'components/AppHeader';

import ModuleListItem, { ModuleListItemProps } from './ModuleListItem';

export interface ModulesListProps {
  isLoading: boolean;
  compact?: boolean;
  moduleItems: ModuleListItemProps[];
  isAssessment?: boolean;
  liveRoomsAvailable?: boolean;
  isExpired?: boolean;
}

const ModulesList: React.FC<ModulesListProps> = ({
  isLoading = false,
  compact = false,
  moduleItems,
  isAssessment = false,
  liveRoomsAvailable = false,
  isExpired = false,
}) => {
  return (
    <Box
      position="relative"
      maxHeight={isExpired ? '200px' : 'none'}
      overflow={isExpired ? 'hidden' : 'visible'}
      borderRadius={isExpired ? 'lg' : 'none'}
    >
      {isLoading ? (
        <Box pt="defaultPadding">
          <ModuleListItem imageUrl="" isLoading title="" compact={compact} />
        </Box>
      ) : (
        moduleItems.map((item, idx) => (
          <Box key={idx}>
            <ModuleListItem
              isTop={idx === 0}
              isBottom={idx === moduleItems.length - 1}
              compact={compact}
              isAssessment={isAssessment}
              canLaunchLiveRoom={
                liveRoomsAvailable && item.moduleType === SESSION_TYPE.normal
              }
              {...item}
              {...(isExpired
                ? {
                    showScheduleBtn: false,
                    linkTo: '#',
                    onOpen: undefined,
                    onOpenActions: undefined,
                  }
                : {})}
            />
            {idx === moduleItems.length - 1 ? null : <Divider margin={0} />}
          </Box>
        ))
      )}
      {isExpired && (
        <Flex
          position="absolute"
          top={0}
          right={0}
          bottom={0}
          left={0}
          backgroundColor="background.defaultTransparent"
          zIndex={2}
          p={6}
          justifyContent="center"
          alignItems="center"
        >
          <HeaderTag
            title="Access Expired"
            bg="background.error"
            color="text.error"
          />
        </Flex>
      )}
    </Box>
  );
};

export default ModulesList;
