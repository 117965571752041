import React from 'react';

import {
  Box,
  Text,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  chakra,
} from '@workshop/ui';

import { Loading } from 'components/Loading';

import { TopicsList, PartialTopic } from 'screens/learner/ClassActivity';

interface ActivityPopupProps {
  isOpen: boolean;
  onClose: () => void;
  titleBold?: string;
  titleMuted?: string;
  topicsList: PartialTopic[];
  footer?: React.ReactElement;
  isLoading?: boolean;
  openTopicsInNewTab?: boolean;
  isCms?: boolean;
}

const ActivityPopup: React.FC<ActivityPopupProps> = ({
  isOpen,
  onClose,
  titleBold = '',
  titleMuted = '',
  topicsList,
  footer = null,
  isLoading = false,
  openTopicsInNewTab = false,
  isCms = false,
}) => {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      motionPreset="slideInBottom"
      size="lg"
    >
      <ModalOverlay backdropFilter="blur(10px)" />
      <ModalContent
        marginX={2}
        marginY="20vh"
        backgroundColor="background.tint1"
      >
        <ModalHeader px={{ base: 4, md: 6 }}>
          <Flex alignItems="center">
            <Text fontWeight="bold" pr={6}>
              {titleBold}
              <chakra.span fontWeight="semibold" color="text.muted">
                {titleMuted}
              </chakra.span>
            </Text>
          </Flex>
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody paddingTop={0} paddingBottom={4} px={{ base: 4, md: 6 }}>
          {isLoading ? (
            <Box pb={4}>
              <Loading />
            </Box>
          ) : (
            <Box>
              <TopicsList
                topics={topicsList}
                openTopicsInNewTab={openTopicsInNewTab}
                isCms={isCms}
              />
            </Box>
          )}
          {footer}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default ActivityPopup;
