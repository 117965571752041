import React, { useEffect, useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Flex, Box } from '@workshop/ui';

import { Organisation, PERMISSION_SLUGS } from 'types/common';

import { useHasPermission } from 'redux/selectors/organisation';
import {
  EditCard,
  LabelInput,
  LabelTextArea,
  LabelWrapper,
} from 'components/Common';
import { ImageUpload } from 'components/Common';

interface Props {
  introduction?: string;
  contactEmail?: string;
  contactNumber?: string;
  logo?: string;
  logoDark?: string;
  name?: string;
  websiteUrl?: string;
  isLoading?: boolean;
  onSubmit: (args: Partial<Organisation>) => Promise<any>;
}

const OrganisationDetailsCard: React.FC<Props> = (props) => {
  const { isLoading = false, onSubmit } = props;

  const isEditingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_organisation
  );

  const formMethods = useForm<Partial<Organisation>>({
    defaultValues: {
      introduction: props.introduction,
      contactEmail: props.contactEmail,
      contactNumber: props.contactNumber,
      logo: props.logo,
      logoDark: props.logoDark,
      name: props.name,
      websiteUrl: props.websiteUrl,
    },
  });

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    getValues,
    reset,
    setValue,
    unregister,
  } = formMethods;

  const { logo, logoDark } = getValues();

  const [logoPreview, setLogoPreview] = useState<string | undefined>(
    typeof props.logo === 'string' ? props.logo : undefined
  );

  const [logoDarkPreview, setLogoDarkPreview] = useState<string | undefined>(
    typeof props.logoDark === 'string' ? props.logoDark : undefined
  );

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  useEffect(() => {
    register('logo');
    register('logoDark');

    return () => {
      unregister('logo');
      unregister('logoDark');
    };
  }, [register, unregister]);

  useEffect(() => {
    // Avoiding memory leaks
    return () => {
      logoPreview && URL.revokeObjectURL(logoPreview);
    };
  }, [logoPreview]);

  useEffect(() => {
    // Avoiding memory leaks
    return () => {
      logoDarkPreview && URL.revokeObjectURL(logoDarkPreview);
    };
  }, [logoDarkPreview]);

  useEffect(() => {
    reset({
      introduction: props.introduction,
      contactEmail: props.contactEmail,
      contactNumber: props.contactNumber,
      logo: props.logo,
      logoDark: props.logoDark,
      name: props.name,
      websiteUrl: props.websiteUrl,
    });
    clearError();
  }, [
    props.introduction,
    props.contactEmail,
    props.contactEmail,
    props.contactNumber,
    props.logo,
    props.logoDark,
    props.name,
    props.websiteUrl,
  ]);

  const onSubmitData = handleSubmit(async (data) => {
    setIsSubmitting(true);
    const { logo, logoDark, ...otherData } = data;
    let newData = { ...otherData };
    if (logo && typeof logo === 'object') {
      // @ts-ignore
      newData = { ...newData, logo };
    }
    if (logoDark && typeof logoDark === 'object') {
      // @ts-ignore
      newData = { ...newData, logoDark };
    }
    await onSubmit(newData);
    reset(data, { dirty: false });
    clearError();
    setIsSubmitting(false);
    register('logo');
  });

  const onImageDrop = (inputName: string, acceptedFiles: File[]) => {
    const file = acceptedFiles[0];
    setValue(inputName, file);

    if (inputName === 'logo') {
      setLogoPreview(URL.createObjectURL(file));
    }

    if (inputName === 'logo_dark') {
      setLogoDarkPreview(URL.createObjectURL(file));
    }
  };

  return (
    <EditCard
      onSave={onSubmitData}
      onCancel={() => {
        clearError();

        reset({
          introduction: props.introduction || '',
          contactEmail: props.contactEmail || '',
          contactNumber: props.contactNumber || '',
          name: props.name || '',
          websiteUrl: props.websiteUrl || '',
        });

        setValue('logo', props.logo || '');
        setLogoPreview(typeof props.logo === 'string' ? props.logo : '');
      }}
      isUpdating={isSubmitting}
      isDisabled={isEditingDisabled}
      saveDisabled={Boolean(!dirty || !isEmpty(errors))}
    >
      <FormContext {...formMethods}>
        <Flex flexDirection="column">
          <Box flex={1}>
            <LabelInput
              id="name"
              isDisabled={isEditingDisabled}
              name="name"
              label="Name"
              error={Boolean(errors.name)}
              errorMessage="This information is required."
              isLoading={isLoading}
              registerInputRef={register({ required: true })}
            />
            <LabelInput
              id="websiteUrl"
              isDisabled={isEditingDisabled}
              name="websiteUrl"
              label="Website"
              error={Boolean(errors.websiteUrl)}
              errorMessage="This information is required."
              isLoading={isLoading}
              registerInputRef={register()}
            />
            <LabelInput
              id="contactEmail"
              isDisabled={isEditingDisabled}
              name="contactEmail"
              label="Contact Email"
              error={Boolean(errors.contactEmail)}
              errorMessage="This information is required."
              helpText="A public email address where potential students can reach your organisation."
              isLoading={isLoading}
              registerInputRef={register()}
            />
            <LabelInput
              id="contactNumber"
              isDisabled={isEditingDisabled}
              name="contactNumber"
              label="Contact Number"
              error={Boolean(errors.contactNumber)}
              errorMessage="This information is required."
              helpText="A public phone number where potential students can reach your organisation."
              isLoading={isLoading}
              registerInputRef={register()}
            />
          </Box>

          <LabelTextArea
            id="introduction"
            isDisabled={isEditingDisabled}
            name="introduction"
            label="About"
            error={Boolean(errors.introduction)}
            errorMessage="This information is required."
            helpText="A short description about your organisation and what you're teaching online."
            isLoading={isLoading}
            registerInputRef={register()}
          />
          <LabelWrapper label="Profile Picture" labelAlign="flex-start">
            <Flex
              backgroundColor="background.tint1"
              borderRadius="full"
              borderWidth={1}
              borderColor="border.default"
              width="100px"
            >
              <ImageUpload
                id="logoDark"
                backgroundColor="transparent"
                backgroundSize="cover"
                borderRadius="50px"
                height="100px"
                image={logoDarkPreview || logoDark || ''}
                name="logoDark"
                onDrop={onImageDrop}
                width="100%"
                isDisabled={isEditingDisabled}
                hideText
              />
            </Flex>
          </LabelWrapper>
          <LabelWrapper
            label="Logo"
            labelAlign="flex-start"
            helpText="This will be shown over images and dark backgrounds, a white PNG logo with a transparent background works well here."
          >
            <Flex
              backgroundColor="neutral.700"
              padding={2}
              borderRadius="sm"
              maxWidth="300px"
            >
              <ImageUpload
                id="logo"
                backgroundColor="transparent"
                hoverBg="neutral.600"
                borderColor="transparent"
                textColor="#fff"
                backgroundSize="contain"
                borderRadius={0}
                height="60px"
                image={logoPreview || logo || ''}
                name="logo"
                onDrop={onImageDrop}
                width="100%"
                isDisabled={isEditingDisabled}
              />
            </Flex>
          </LabelWrapper>
        </Flex>
      </FormContext>
    </EditCard>
  );
};

export default OrganisationDetailsCard;
