import React from 'react';
import { Message } from 'react-hook-form';

import {
  Flex,
  Input,
  InputGroup,
  InputRightElement,
  InputProps,
  BoxProps,
  FormLabelProps,
} from '@workshop/ui';
import { IconTooltip, Tooltip } from 'components/IconTooltip';
import LabelWrapper from './LabelWrapper';

interface LabelInputProps extends InputProps {
  error?: boolean;
  errorMessage?: Message;
  helpText?: string | React.ReactNode;
  helpTextPosition?: 'underline' | 'inline' | 'top';
  inputType?: string;
  isLoading?: boolean;
  label?: string;
  labelPosition?: 'top' | 'inline';
  labelButtonText?: string;
  labelButtonOnClick?: () => void;
  registerInputRef?: RegisterFunc<HTMLInputElement>;
  unit?: string;
  childrenAfterInput?: boolean;
  tooltip?: Tooltip;
  rightIcon?: React.ReactElement;
  loadingStyle?: BoxProps;
  labelStyleProps?: FormLabelProps;
}

const noop = () => null;

const LabelInput: React.FC<LabelInputProps> = ({
  backgroundColor = 'background.default',
  children,
  childrenAfterInput = true,
  defaultValue = '',
  error = false,
  errorMessage,
  helpText,
  helpTextPosition,
  id,
  inputType = 'text',
  isDisabled = false,
  isLoading = false,
  label,
  labelPosition = 'inline',
  labelStyleProps = {},
  labelButtonText,
  labelButtonOnClick,
  name,
  value,
  onBlur,
  onChange = noop,
  onPaste = noop,
  placeholder,
  registerInputRef,
  unit,
  tooltip,
  rightIcon,
  loadingStyle,
  flex,
  ...rest
}) => {
  if (!!value && !!defaultValue) {
    console.error(
      'Should not provide `value` and `defaultValue` at the same time to `LabelInput`'
    );
  }

  // Don't allow both `value` and `defaultValue` value to be set on the Input component
  // as this is invalid behaviour. Allow `value` to take precedence.
  const valueProps = value ? { value } : { defaultValue };

  return (
    <LabelWrapper
      errorMessage={errorMessage}
      helpText={helpText}
      helpTextPosition={helpTextPosition}
      inputId={id}
      isInvalid={error}
      isLoading={isLoading}
      label={label}
      labelPosition={labelPosition}
      labelButtonText={labelButtonText}
      labelButtonOnClick={labelButtonOnClick}
      unit={unit}
      loadingStyle={loadingStyle}
      labelStyleProps={labelStyleProps}
    >
      <Flex>
        {!childrenAfterInput && children}
        {!isLoading && (
          <InputGroup flex={1}>
            <Input
              ref={registerInputRef}
              id={id}
              backgroundColor={backgroundColor}
              isDisabled={isDisabled}
              fontSize="md"
              name={name}
              onBlur={onBlur}
              onChange={onChange}
              onPaste={onPaste}
              placeholder={placeholder}
              type={inputType}
              {...valueProps}
              {...rest}
            />
            {tooltip && !rightIcon && (
              <InputRightElement>
                <IconTooltip tooltip={tooltip} />
              </InputRightElement>
            )}
            {rightIcon && <InputRightElement children={rightIcon} />}
          </InputGroup>
        )}

        {childrenAfterInput && children}
      </Flex>
    </LabelWrapper>
  );
};

export default LabelInput;
