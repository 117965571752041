import React, { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box, Skeleton } from '@workshop/ui';

import { RichTextEditor } from 'components/RichTextEditor';
import LabelWrapper from 'components/Common/LabelWrapper';
import { EditCard, LabelTextArea } from 'components/Common';
import { Tooltip } from 'components/IconTooltip';

interface ValueRichText {
  [key: string]: string;
}

interface FormItem {
  id: string;
  name: string;
  label: string;
  helpText?: string;
  unit?: string;
  errorMessage?: string;
  defaultValue?: string;
  richEditor?: boolean;
  labelAlign?: 'center' | 'flex-start';
  tooltip?: Tooltip;
}

interface FormCardProps {
  items: Array<FormItem>;
  onSave: (formData: any) => void;
  onCancel?: () => void;
  isUpdating?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const noop = () => null;

const FormCard: React.FC<FormCardProps> = ({
  items,
  onSave,
  onCancel = noop,
  isUpdating,
  isLoading = false,
  isDisabled = false,
}) => {
  const { register, handleSubmit, errors, clearError, formState, reset } =
    useForm<{ [key: string]: string }>();

  const [valueRichText, setValueRichText] = useState<ValueRichText>({});

  const onSubmit = handleSubmit((formData) => {
    let completeFormData = { ...formData };
    if (!isEmpty(valueRichText)) {
      Object.keys(valueRichText).forEach((textName, idx) => {
        completeFormData[textName] = valueRichText[textName];
      });
    }
    return onSave(completeFormData);
  });

  const defaultValues = useMemo(
    () =>
      items.reduce((acc: { [key: string]: string }, item) => {
        if (item.defaultValue) {
          acc[item.name] = item.defaultValue;
        }
        return acc;
      }, {}),
    [items]
  );

  useEffect(() => {
    // Reset the state as default after state change (successful update)
    reset(defaultValues);
    setEnable(false);
  }, [reset, defaultValues]);

  const [enable, setEnable] = useState(false);
  const [resetTextEditor, setResetTextEditor] = useState(false);

  // TODO: use react-hook-forms - see StepEdit
  const handleChangeRichTextEditor = (name: string, value: string) => {
    setValueRichText({
      ...valueRichText,
      [name]: value,
    });
    setResetTextEditor(false);
    setEnable(true);
  };

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        reset();
        setResetTextEditor(true);
        setEnable(false);
      }}
      saveDisabled={
        Boolean(!formState.dirty || Object.keys(errors).length > 0) && !enable
      }
      isDisabled={isDisabled}
      isUpdating={isUpdating}
    >
      {isLoading ? (
        <Skeleton my="10px" height="50px" />
      ) : (
        items.map((item: FormItem) => {
          return item.richEditor ? (
            <LabelWrapper
              key={`form-card-labelwrapper${item.id}`}
              label={item.label}
              labelAlign={item.labelAlign || 'center'}
            >
              <Box height="300px">
                <RichTextEditor
                  isDisabled={isDisabled}
                  name={item.name}
                  defaultValue={item.defaultValue}
                  onChange={handleChangeRichTextEditor}
                  reset={resetTextEditor}
                  tooltip={item.tooltip}
                />
              </Box>
            </LabelWrapper>
          ) : (
            <LabelTextArea
              registerInputRef={register({
                required: {
                  value: true,
                  message: item.errorMessage || 'This field is required',
                },
              })}
              key={item.id}
              id={item.name}
              name={item.name}
              label={item.label}
              helpText={item.helpText}
              unit={item.unit}
              error={!!errors[item.name]}
              errorMessage={errors[item.name]?.message}
              tooltip={item.tooltip}
              isDisabled={isDisabled}
            />
          );
        })
      )}
    </EditCard>
  );
};

export default FormCard;
