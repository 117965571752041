import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { Flex, Stack, VStack } from '@workshop/ui';

import AccountBasics from './AccountBasics';
import RedeemCoupon from './RedeemCoupon';
import SubscriptionManagement from './SubscriptionManagement';
import AccountManagement from './AccountManagement';
import PaymentSettings from './PaymentSettings';
import SelectTheme from './SelectTheme';

import { AppleResponse } from 'components/SocialAuth/AppleButton';
import { FacebookResponse } from 'components/SocialAuth/FacebookButton';
import { SectionTitle } from 'components/Common';

import { GlobalState } from 'types';
import { ProviderTypes, PROVIDERS } from 'types/common';
import { profileActions, authActions } from 'redux/actions/common';

import { hooks } from 'utils';

import { COUNTRY_OPTIONS } from 'constants/settings';

const BR = '%0D%0A'; // Break Line in email link

type PropsFromRedux = ConnectedProps<typeof connector>;

interface SettingsProps extends PropsFromRedux {}

const SettingsScreen: React.FC<SettingsProps> = ({
  userDetails,
  userCoupons,
  userSubscriptions,
  socialProviders,
  socialConnections,
  paymentSettings,
  userProfile,
}) => {
  const { email, name, unverifiedEmail } = userDetails;
  const { country } = userProfile;
  const dispatch = useDispatch();

  const [socialConnectionLoadingState, setSocialConnectionLoadingState] =
    useState<Record<ProviderTypes, boolean>>({
      facebook: false,
      apple: false,
    });

  const {
    userCoupons: userCouponsLoading,
    userSubscriptions: userSubscriptionsLoading,
    socialProviders: socialProvidersLoading,
    socialConnections: socialConnectionsLoading,
    paymentSettings: paymentSettingsLoading,
  } = hooks.useLoadingDataState(
    {
      userCoupons: { actions: [() => profileActions.coupons.fetch()] },
      userSubscriptions: {
        actions: [() => profileActions.subscriptions.fetch()],
      },
      socialProviders: {
        actions: [() => authActions.social.fetchProviders()],
      },
      socialConnections: {
        actions: [() => authActions.social.fetchConnections()],
      },
      paymentSettings: {
        actions: [() => profileActions.payment.fetch()],
      },
    },
    []
  );

  const handleSubmitAccountBasics = async (values: {
    email: string;
    name: string;
    country: string;
  }) => {
    const data: {
      name: string;
      profile: { country: string };
      email?: string;
    } = {
      name: values.name,
      profile: { country: values.country },
    };

    if (
      email.toLowerCase() !== values.email.toLowerCase() &&
      unverifiedEmail?.toLowerCase() !== values.email.toLowerCase()
    ) {
      // Only process the email if changed to prevent email verification errors
      data['email'] = values.email;
    }

    return await dispatch(profileActions.updateUserProfile(data));
  };

  const handleResendVerificationEmail = async () => {
    if (unverifiedEmail)
      return await dispatch(
        profileActions.emailVerification.resend(unverifiedEmail)
      );
  };

  const handleCancelEmailChange = async () => {
    if (unverifiedEmail)
      return await dispatch(profileActions.emailVerification.cancel());
  };

  const handleResetPassword = async () =>
    await dispatch(authActions.password.reset({ data: { email } }));

  const handleRedeemCoupon = async (coupon: { code: string }) =>
    await dispatch(profileActions.coupons.redeem(coupon));

  const handleDeleteAccount = async (deleteType: 'DELETE' | 'DEACTIVATE') => {
    // The delete account API handles both deletion and deactivation depending on
    // the "type" parameter passed through
    return await dispatch(profileActions.account.delete(deleteType)).then(
      (res) => {
        if (!res.error) {
          // On successful deletion or deactivation of User account we log them out
          dispatch(authActions.tokenDeleteRequest());
        }
      }
    );
  };

  const handleConnectSocialAccount = async (
    params:
      | {
          provider: 'facebook';
          response: FacebookResponse;
        }
      | {
          provider: 'apple';
          response: AppleResponse;
        }
  ) => {
    let data: {
      accessToken?: string;
      idToken?: string;
    } = {};

    if (!PROVIDERS.includes(params.provider)) return;

    if (params.provider === 'facebook') {
      if ('status' in params.response) {
        // TODO: Handle error
        return;
      }
      const { accessToken } = params.response;
      data.accessToken = accessToken;
    }

    if (params.provider === 'apple') {
      if ('error' in params.response) {
        // TODO: Handle error - should be handled by onError
        return;
      }

      // TODO: TODO: Don't know how to handle
      if ('code' in params.response) return;

      const { authorization } = params.response;
      const { code, id_token } = authorization;
      data = {
        accessToken: code,
        idToken: id_token,
      };
    }

    setSocialConnectionLoadingState((prev) => ({
      ...prev,
      [params.provider]: true,
    }));

    await dispatch(authActions.socialConnect(data, params.provider));

    setSocialConnectionLoadingState((prev) => ({
      ...prev,
      [params.provider]: false,
    }));
  };

  const handleRemoveSocialConnection = async (id: string, provider: string) => {
    setSocialConnectionLoadingState((prev) => ({ ...prev, [provider]: true }));
    await dispatch(authActions.social.removeConnection(id, provider));
    setSocialConnectionLoadingState((prev) => ({ ...prev, [provider]: false }));
  };

  const handleUpdateCardDetails = async (id: string) =>
    await dispatch(profileActions.payment.update(id));

  const isLoading =
    userCouponsLoading ||
    userSubscriptionsLoading ||
    socialProvidersLoading ||
    socialConnectionsLoading ||
    paymentSettingsLoading;

  return (
    <ScreenWrapper>
      <Stack spacing="defaultMargin">
        <Flex flex={1} flexDir="column">
          <VStack alignItems="stretch" spacing={5}>
            <Flex flexDir="column">
              <SectionTitle title="Theme" />
              <SelectTheme />
            </Flex>
            <Flex flexDir="column">
              <SectionTitle title="Account Basics" />
              <AccountBasics
                onSubmit={handleSubmitAccountBasics}
                onCancelEmailVerification={handleCancelEmailChange}
                onResendVerificationEmail={handleResendVerificationEmail}
                countryOptions={COUNTRY_OPTIONS}
                email={email}
                unverifiedEmail={unverifiedEmail}
                name={name}
                country={country}
                isLoading={isLoading}
              />
            </Flex>
            <Flex flexDir="column">
              <SectionTitle title="Redeem Coupon" />
              <RedeemCoupon
                onSubmit={handleRedeemCoupon}
                userCoupons={userCoupons}
                isLoading={isLoading}
              />
            </Flex>
            <Flex flexDir="column">
              <SectionTitle title="Payment Settings" />
              <PaymentSettings
                isLoading={isLoading}
                onSubmit={handleUpdateCardDetails}
                paymentSettings={paymentSettings}
              />
            </Flex>
            <Flex flexDir="column">
              <SectionTitle title="Subscription Management" />
              <SubscriptionManagement
                activeSubscriptions={userSubscriptions}
                isLoading={isLoading}
              />
            </Flex>
            <Flex flexDir="column">
              <SectionTitle title="Account Management" />
              <AccountManagement
                isLoading={isLoading}
                requestDataLink={`mailto:hello@workshop.ws?subject=Request of user data&body=Hi,${BR}${BR}
                I would like to request a copy of my Workshop user data.${BR}${BR}
                My email address is: ${email || ''}.${BR}${BR}
                Thanks,${BR}${name || ''}`}
                socialProviders={socialProviders}
                socialConnections={socialConnections}
                socialConnectionLoadingState={socialConnectionLoadingState}
                handleDeleteAccount={handleDeleteAccount}
                handleResetPassword={handleResetPassword}
                handleConnect={handleConnectSocialAccount}
                removeSocialConnection={handleRemoveSocialConnection}
              />
            </Flex>
          </VStack>
        </Flex>
      </Stack>
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  userDetails: state.user.userDetails,
  userCoupons: state.user.userCoupons,
  userSubscriptions: state.user.userSubscriptions,
  socialProviders: state.auth.socialProviders,
  socialConnections: state.auth.socialConnections,
  paymentSettings: state.user.userPaymentSettings,
  userProfile: state.user.userProfile,
});

const connector = connect(mapStateToProps);

export default connector(SettingsScreen);
