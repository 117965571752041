import React from 'react';

import { Select, Flex, Input } from '@workshop/ui';
import LabelWrapper from './LabelWrapper';

type SelectOption = {
  [key: string]: string;
};

interface LabelSelectProps {
  id: string;
  isDisabled?: boolean;
  name: string[];
  label: string;
  registerInputRef?: RegisterFunc<HTMLInputElement>;
  registerSelectRef?: RegisterFunc<HTMLSelectElement>;
  options: SelectOption;
  error?: boolean;
  errorMessage?: string;
  helpText?: string;
  unit?: string;
  additionalText?: string;
  defaultValue?: string | number;
  isLoading?: boolean;
}

// TODO: Rework this component - maybe extend LabelInput to accept multiple
// inputs
const LabelInputSelect: React.FC<LabelSelectProps> = ({
  id,
  isDisabled = false,
  name,
  label,
  registerInputRef,
  registerSelectRef,
  options,
  error = false,
  errorMessage,
  helpText,
  unit,
  defaultValue = '',
  isLoading = false,
}) => (
  <LabelWrapper
    label={label}
    helpText={helpText}
    unit={unit}
    isInvalid={error}
    errorMessage={errorMessage}
    isLoading={isLoading}
  >
    <Flex justifyContent="center" flex={1}>
      <Flex flex="2 0" mr="defaultMargin">
        <Input
          id={id}
          ref={registerInputRef}
          isDisabled={isDisabled}
          name={name[0]}
          isInvalid={error}
          // ToDo : improve minW
          minW="50px"
        />
      </Flex>
      <Flex flex="3 0" justifyContent="flex-stretch">
        <Select
          id={id}
          ref={registerSelectRef}
          isDisabled={isDisabled}
          name={name[1]}
          marginRight={2}
          defaultValue={defaultValue}
          minW="100px"
        >
          {Object.keys(options).map((option: string, idx) => (
            <option key={idx} value={option}>
              {options[option]}
            </option>
          ))}
        </Select>
      </Flex>
    </Flex>
  </LabelWrapper>
);

export default LabelInputSelect;
