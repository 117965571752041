import { AlertDialogButton } from '@workshop/ui/button/src/Button';
import React from 'react';
import { useForm } from 'react-hook-form';

import {
  Card,
  Box,
  Flex,
  Text,
  Heading,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  MdIcon,
  Paragraph,
} from '@workshop/ui';

import { LabelInput } from 'components/Common';
import { Draggable, IDraggableData } from 'components/Draggable';
import {
  AddItem,
  Item,
  IAddItem,
  MultipleChoiceForm,
  MCQFormData,
} from 'components/ListItem';
import { RichTextEditor } from 'components/RichTextEditor';
import { IconTooltip } from 'components/IconTooltip';

import { IStepListItem, VideoClipListState, IStepQuestion } from 'types/cms';
import { VideoClipItem } from './VideoClipItem';

import { DraggableStep, DraggableClip } from './dataUtils';

interface Props {
  clipData: DraggableClip[];
  expandedClipData: VideoClipListState;
  stepData: DraggableStep[];
  expandedStep: string | null;
  expandedStepData: IStepListItem | null;
  questions?: IStepQuestion[];

  /** BOOLEAN CONTROLS */
  isClipLoading: boolean;
  isDraggable?: boolean;
  isDisabled?: boolean;
  isTextEditingDisabled?: boolean;
  isLoading: boolean;
  isStepListUpdating: boolean;
  isStepUpdating: boolean;
  saveDisabled: boolean;

  /** BEHAVIOUR CONTROLS */
  canAddStep?: boolean;
  canDeleteStep?: boolean;
  canAddClip?: boolean;
  canDeleteClip?: boolean;

  /** DISPLAY CONTROLS */
  shouldShowQuestions: boolean;
  shouldShowTitleAndNotes?: boolean;

  /** FORM */
  stepRegister: ReturnType<typeof useForm>['register'];
  stepSetValue: ReturnType<typeof useForm>['setValue'];

  /** HANDLERS */
  handleAddClip: () => void;
  handleAddStep: (data: IAddItem) => Promise<void>;
  handleAddVideoToClip: (
    e: React.ChangeEvent<HTMLInputElement>,
    id: string
  ) => void;
  handleCancelUpdateStep: () => void;
  handleCreateQuestion: (args: MCQFormData, stepId: number) => Promise<any>;
  handleDeleteClip: (id: string) => void;
  handleDeleteStep: (id: string) => void;
  handleUpdateQuestion: (
    args: MCQFormData,
    stepId: number,
    questionId: number
  ) => Promise<any>;
  handleUpdateStep: (id: string) => Promise<void>;
  isThumbnailUpdating: (id: string) => boolean;
  onClipDragEnd: (data: IDraggableData<DraggableClip>) => void;
  onDragEnd: (data: IDraggableData<DraggableStep>) => void;
  onStepExpanded: (id: string) => Promise<void>;
  onSaveClipSummary: (clipId: string, summary: string) => Promise<any>;
}

const StepEdit: React.FC<Props> = ({
  clipData,
  expandedClipData,
  stepData,
  expandedStep,
  expandedStepData,
  questions,
  isClipLoading = false,
  isDraggable = true,
  isDisabled = false,
  isTextEditingDisabled = false,
  isLoading = false,
  isStepListUpdating = false,
  isStepUpdating = false,
  saveDisabled,
  canAddStep = true,
  canDeleteStep = true,
  canAddClip = true,
  canDeleteClip = true,
  shouldShowQuestions = false,
  shouldShowTitleAndNotes = true,
  stepRegister,
  stepSetValue,
  handleAddClip,
  handleAddStep,
  handleAddVideoToClip,
  handleCancelUpdateStep,
  handleCreateQuestion,
  handleDeleteClip,
  handleDeleteStep,
  handleUpdateQuestion,
  handleUpdateStep,
  isThumbnailUpdating = () => false,
  onClipDragEnd,
  onDragEnd,
  onStepExpanded,
  onSaveClipSummary,
}) => {
  // Manually register the react-hook-form field for RichTextField
  stepRegister('supportingNotes');
  return (
    <Card direction="column" padding={0}>
      <Draggable
        dragEnabled={isDraggable && !expandedStep && !isLoading && !isDisabled}
        data={
          // During loading, render 2 empty items
          isLoading
            ? [
                {
                  id: '0',
                  title: 'loading',
                  label: '',
                  index: 1,
                  stepType: '',
                  onClick: () => {},
                },
                {
                  id: '1',
                  title: 'loading',
                  label: '',
                  index: 2,
                  stepType: '',
                  onClick: () => {},
                },
              ]
            : stepData
        }
        onDragEnd={onDragEnd}
      >
        {(props) => {
          const { id: stepId, title, label } = props;
          return (
            <>
              <Item
                id={stepId}
                title={title}
                label={label}
                padding={3}
                border="1px"
                onClick={() => onStepExpanded(stepId)}
                draggable={isDraggable}
                expandable
                expanded={expandedStep === stepId}
                isLoading={isLoading}
              />
              {expandedStep === stepId && (
                <Flex flex={1} flexDirection="column">
                  {clipData.length === 0 ? (
                    <Flex p={4} justifyContent="center" color="text.muted">
                      <Paragraph margin={0}>
                        This step currently contains no clips
                      </Paragraph>
                    </Flex>
                  ) : (
                    <Flex
                      px={3}
                      pt={3}
                      pb={2}
                      flexDirection="column"
                      backgroundColor="background.tint3"
                    >
                      <Box>
                        <Draggable
                          data={
                            // During loading, render an empty item
                            isClipLoading ? [{ id: '0' }] : clipData
                          }
                          onDragEnd={onClipDragEnd}
                          dragEnabled={!isDisabled}
                        >
                          {(props) => {
                            const { id, index } = props;
                            const videoClip = expandedClipData[id];
                            return (
                              <VideoClipItem
                                id={id.toString()}
                                index={index + 1}
                                image={videoClip?.videoThumbnail}
                                video={videoClip?.video}
                                filename={videoClip?.originalFilename}
                                summary={videoClip?.summary}
                                isDisabled={isDisabled}
                                isTextEditingDisabled={isTextEditingDisabled}
                                isLoading={isClipLoading}
                                isThumbnailUpdating={isThumbnailUpdating(
                                  id.toString()
                                )}
                                onUpload={(e) =>
                                  handleAddVideoToClip(e, id.toString())
                                }
                                onRemove={() => handleDeleteClip(id.toString())}
                                onSaveSummary={(summary) =>
                                  onSaveClipSummary(id.toString(), summary)
                                }
                                canDeleteClip={canDeleteClip}
                              />
                            );
                          }}
                        </Draggable>
                      </Box>
                    </Flex>
                  )}
                  <Flex p={3} flexDirection="column">
                    {!isClipLoading && !isDisabled && canAddClip && (
                      <Box
                        backgroundColor="background.tint3"
                        borderRadius="md"
                        border="1px"
                        borderColor="border.muted"
                        mb={2}
                      >
                        <AddItem
                          label="Add Clip"
                          onSave={() => {}}
                          onClick={handleAddClip}
                          isUpdating={isClipLoading}
                        />
                      </Box>
                    )}
                    <Flex flexDirection="column">
                      {shouldShowTitleAndNotes && (
                        <>
                          <LabelInput
                            isDisabled={isDisabled}
                            id="title"
                            name="title"
                            label="Step Title"
                            registerInputRef={stepRegister({
                              required: true,
                            })}
                            defaultValue={expandedStepData?.title}
                            tooltip="step_item_title"
                          />
                          <Text color="text.muted" marginBottom="defaultMargin">
                            Supporting Notes:
                          </Text>
                          {/* TODO: Replace this with RichTextEditor */}
                          <Box height="200px">
                            <RichTextEditor
                              isDisabled={isDisabled}
                              name="supportingNotes"
                              placeholder="Supporting Notes for this Step"
                              defaultValue={expandedStepData?.notes}
                              onChange={(name, markup) =>
                                stepSetValue(name, markup)
                              }
                            />
                            <Box position="absolute" bottom={2} right={2}>
                              <IconTooltip tooltip="step_item_support_notes" />
                            </Box>
                          </Box>

                          <Text fontSize="xs" marginTop={1} color="text.muted">
                            (Optional) These notes appear below the videos in
                            this step and can be used to provide further
                            explanations and links
                          </Text>
                        </>
                      )}
                      {shouldShowQuestions ? (
                        <>
                          {questions?.length && !isDisabled ? (
                            <Heading
                              fontWeight="semibold"
                              as="h3"
                              size="sm"
                              mt={6}
                            >
                              Multiple Choice Questions:
                            </Heading>
                          ) : null}
                          {!!questions?.length && (
                            <Accordion allowToggle allowMultiple my={4}>
                              {questions.map((q, idx) => (
                                <AccordionItem>
                                  <AccordionButton>
                                    <Box flex="1" textAlign="left">
                                      {q.content}
                                    </Box>
                                    <AccordionIcon />
                                  </AccordionButton>
                                  <AccordionPanel p={0}>
                                    <Box
                                      backgroundColor="background.tint3"
                                      borderRadius={4}
                                      my={2}
                                      p={2}
                                    >
                                      <MultipleChoiceForm
                                        isDisabled={isDisabled}
                                        defaultValues={{
                                          question: q.content,
                                          choices: q.choices.map((c) => ({
                                            text: c.content,
                                            isCorrect: c.correct,
                                            id: c.id,
                                          })),
                                          explanation: q.explanation,
                                        }}
                                        onSubmit={async (args) =>
                                          await handleUpdateQuestion(
                                            args,
                                            parseInt(stepId),
                                            q.id
                                          )
                                        }
                                      />
                                    </Box>
                                  </AccordionPanel>
                                </AccordionItem>
                              ))}
                            </Accordion>
                          )}
                          {!isDisabled && (
                            <>
                              <Heading fontWeight="semibold" as="h3" size="sm">
                                Add Question
                              </Heading>
                              <Box
                                backgroundColor="background.tint3"
                                borderRadius={4}
                                my={2}
                                p={2}
                              >
                                <MultipleChoiceForm
                                  resetAfterSubmit
                                  onSubmit={async (args) =>
                                    await handleCreateQuestion(
                                      args,
                                      parseInt(stepId)
                                    )
                                  }
                                />
                              </Box>
                            </>
                          )}
                        </>
                      ) : null}
                      {!isDisabled && (
                        <Flex
                          flex={1}
                          justifyContent="space-between"
                          alignItems="center"
                          mt={6}
                        >
                          {canDeleteStep ? (
                            <AlertDialogButton
                              alertHeader="Delete Step"
                              alertBody="Are you sure you would like to delete this step?"
                              submitBtnLabel="Delete"
                              submitBtnColor="red"
                              onSubmit={() => handleDeleteStep(stepId)}
                              onCancel={() => {}}
                              variant="solid"
                              colorScheme="red"
                              color="text.light"
                              marginRight={2}
                              size="sm"
                              leftIcon={
                                <MdIcon name="RemoveCircle" color="inherit" />
                              }
                            >
                              Delete Step
                            </AlertDialogButton>
                          ) : (
                            <Flex />
                          )}
                          <Box>
                            <Button
                              secondary
                              isDisabled={saveDisabled}
                              onClick={handleCancelUpdateStep}
                            >
                              Cancel
                            </Button>
                            <Button
                              type="submit"
                              onClick={() => handleUpdateStep(stepId)}
                              isDisabled={saveDisabled}
                              isLoading={isStepUpdating}
                              ml={2}
                            >
                              Save
                            </Button>
                          </Box>
                        </Flex>
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </>
          );
        }}
      </Draggable>
      {canAddStep && !isLoading && !isDisabled && (
        <Flex>
          <AddItem
            label="Add Step"
            onSave={handleAddStep}
            isUpdating={isStepListUpdating}
          />
        </Flex>
      )}
    </Card>
  );
};

export default StepEdit;
