import { createSelector } from 'reselect';

import { GlobalState } from 'types';

export const getCategories = (state: GlobalState) => state.category;

export const getCategoryOptions = createSelector(
  [getCategories],
  (categories) =>
    Object.entries(categories).reduce((acc, [key, category]) => {
      acc[key] = category.title;
      return acc;
    }, {} as { [key: string]: string })
);
