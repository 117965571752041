import React, { useState } from 'react';
import { useSpring } from 'react-spring';

import { Card, Flex, FlexProps } from '@workshop/ui';

import { AnimatedButton } from 'components/Common';

// TODO: Move this to a util file (not sure where yet)
const noop = () => null;

interface EditCardProps extends FlexProps {
  /**
   * Whether the EditCard is disabled for editing
   */
  isDisabled?: boolean;
  /**
   * Whether the EditCard can be saved
   */
  saveDisabled?: boolean;
  saveLabel?: string;
  onSave: () => void;
  onCancel: () => void;
  isUpdating?: boolean;
  error?: boolean;
}

const EditCard: React.FC<EditCardProps> = ({
  isDisabled = false,
  saveDisabled = false,
  onSave,
  onCancel = noop,
  isUpdating = false,
  children,
  saveLabel = 'Save',
  ...otherProps
}) => {
  const [isEditing, setIsEditing] = useState(false);
  const btnStyle = useSpring({
    config: { duration: 200 },
    opacity: saveDisabled ? 0 : isUpdating ? 0.5 : 1,
  });

  return (
    <Card
      flexDirection="column"
      padding="defaultPadding"
      onClick={() => !isEditing && setIsEditing(true)}
      {...otherProps}
    >
      {children}
      {!isDisabled && (
        <Flex
          flexDirection="row"
          alignContent="center"
          justifyContent="flex-end"
        >
          <AnimatedButton
            secondary
            isDisabled={saveDisabled || isUpdating}
            style={btnStyle}
            onClick={() => {
              setIsEditing(false);
              onCancel();
            }}
          >
            Cancel
          </AnimatedButton>
          <AnimatedButton
            colorScheme="blue"
            isLoading={isUpdating}
            isDisabled={saveDisabled || isUpdating}
            style={btnStyle}
            onClick={() => {
              setIsEditing(false);
              onSave();
            }}
          >
            {saveLabel}
          </AnimatedButton>
        </Flex>
      )}
    </Card>
  );
};

export default EditCard;
