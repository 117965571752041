import React from 'react';

import {
  Box,
  Flex,
  Text,
  TextProps,
  Checkbox,
  CheckboxProps,
  FormLabelProps,
} from '@workshop/ui';

import { IconTooltip, Tooltip } from 'components/IconTooltip';

import LabelWrapper from './LabelWrapper';

interface LabelCheckboxProps extends CheckboxProps {
  fontWeight?: TextProps['fontWeight'];
  helpText?: string;
  label?: string;
  labelPosition?: 'top' | 'inline';
  labelStyleProps?: FormLabelProps;
  registerInputRef?: RegisterFunc<HTMLInputElement>;
  tooltip?: Tooltip;
}

const LabelCheckbox: React.FC<LabelCheckboxProps> = ({
  fontWeight = 'bold',
  helpText,
  id,
  isDisabled = true,
  label,
  labelPosition = 'inline',
  labelStyleProps,
  registerInputRef,
  tooltip,
  ...checkboxProps
}) => {
  return (
    <LabelWrapper
      helpText={helpText}
      inputId={id}
      label={label}
      labelPosition={labelPosition}
      labelStyleProps={labelStyleProps}
    >
      <Checkbox
        {...checkboxProps}
        ref={registerInputRef}
        isDisabled={isDisabled}
      >
        <Flex flexDir="column" px={2}>
          {/* {label && labelPosition === 'right' && (
            <Text fontWeight={fontWeight}>{label}</Text>
          )} */}
          {helpText && (
            <Text fontSize="xs" color="text.muted">
              {helpText}
            </Text>
          )}
          {tooltip && (
            <Box ml={2}>
              <IconTooltip tooltip={tooltip} />
            </Box>
          )}
        </Flex>
      </Checkbox>
    </LabelWrapper>
  );
};

export default LabelCheckbox;
