import { decamelizeKeys } from 'humps';
import { CMSUnitListSchema, CMSUnitSchema } from 'redux/schemas/cms';

import { callAPI, callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { UnitAT } from 'redux/actionTypes/cms';

import {
  FetchUnitAction,
  UnitListAction,
  PatchUnitAction,
  IUnitListItem,
  IUnit,
} from 'types/cms';

export const list = (id: number) => {
  return callAPIWithTeam<UnitListAction>({
    types: [
      UnitAT.CMS_FETCH_UNIT_LIST_REQUEST,
      UnitAT.CMS_FETCH_UNIT_LIST_SUCCESS,
      UnitAT.CMS_FETCH_UNIT_LIST_FAILURE,
    ],
    endpoint: API.cms.courseUnits(id),
    method: 'GET',
    schema: [CMSUnitListSchema],
    path: 'results',
  });
};

export const create = (courseId: number, data: Partial<IUnitListItem>) => {
  return callAPIWithTeam<UnitListAction>({
    types: [
      UnitAT.CMS_CREATE_UNIT_REQUEST,
      UnitAT.CMS_CREATE_UNIT_SUCCESS,
      UnitAT.CMS_CREATE_UNIT_FAILURE,
    ],
    endpoint: API.cms.courseUnits(courseId),
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSUnitListSchema,
    meta: {
      toast: { success: 'Successfully created unit.' },
    },
  });
};

export const retrieve = (id: number) => {
  return callAPIWithTeam<FetchUnitAction>({
    types: [
      UnitAT.CMS_FETCH_UNIT_REQUEST,
      UnitAT.CMS_FETCH_UNIT_SUCCESS,
      UnitAT.CMS_FETCH_UNIT_FAILURE,
    ],
    endpoint: API.cms.unit(id),
    method: 'GET',
    schema: CMSUnitSchema,
  });
};

export const update = (id: number, data: Partial<IUnit> | FormData) => {
  return callAPIWithTeam<PatchUnitAction>({
    types: [
      UnitAT.CMS_PATCH_UNIT_REQUEST,
      UnitAT.CMS_PATCH_UNIT_SUCCESS,
      UnitAT.CMS_PATCH_UNIT_FAILURE,
    ],
    endpoint: API.cms.unit(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSUnitSchema,
  });
};
