import React, { useCallback, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { useDropzone } from 'react-dropzone';
import isEmpty from 'lodash/isEmpty';

import { Text, Flex, MdIcon, Box, FlexProps } from '@workshop/ui';

interface FileInputProps {
  id: string;
  backgroundColor?: FlexProps['backgroundColor'];
  hoverBg?: FlexProps['backgroundColor'];
  backgroundSize?: FlexProps['backgroundSize'];
  borderColor?: FlexProps['borderColor'];
  borderRadius?: FlexProps['borderRadius'];
  height: FlexProps['height'];
  image?: FlexProps['backgroundImage'];
  isDisabled?: boolean;
  label?: string;
  name: string;
  styleProps?: Partial<FlexProps>;
  textColor?: string;
  width: FlexProps['width'];
  hideText?: boolean;
  onDrop?: (name: string, acceptedFiles: File[]) => void;
}

const FileInput: React.FC<FileInputProps> = ({
  backgroundSize,
  borderRadius,
  name,
  label,
  width,
  height,
  textColor = 'text.muted',
  backgroundColor = 'background.default',
  hoverBg = 'background.primary',
  borderColor = 'border.default',
  image = '',
  hideText = false,
  isDisabled = false,
  styleProps = {},
  onDrop = () => {},
}) => {
  const { register, unregister } = useFormContext<{
    [key: string]: File;
  }>();

  const innerOnDrop = useCallback(
    (acceptedFiles) => {
      onDrop(name, acceptedFiles);
    },
    [name, onDrop]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: innerOnDrop,
    accept: 'image/*',
  });

  useEffect(() => {
    register(name);
    // Avoiding memory leaks
    return () => {
      unregister(name);
    };
  }, [register, unregister]);

  return (
    <Flex
      backgroundColor={backgroundColor}
      backgroundImage={image && `url(${image})`}
      backgroundSize={backgroundSize || 'cover'}
      backgroundRepeat="no-repeat"
      backgroundPosition="center"
      height={height}
      width={width}
      borderRadius={borderRadius !== undefined ? borderRadius : 'md'}
      border={image ? '' : '1px solid'}
      borderColor={borderColor}
      cursor={isDisabled ? 'not-allowed' : 'pointer'}
      _hover={{ backgroundColor: hoverBg }}
      {...styleProps}
    >
      <Flex
        flex="1"
        flexDirection="column"
        justifyContent="flex-end"
        position="relative"
        {...getRootProps()}
      >
        {!isDisabled && <input {...getInputProps()} />}
        <Text color={textColor} paddingX={2} paddingY={1}>
          {label}
        </Text>
        {isEmpty(image) && (
          <Flex
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
            position="absolute"
            bottom={0}
            left={0}
            right={0}
            top={0}
            w="100%"
            h="100%"
          >
            <MdIcon name="AddPhotoAlternate" color={textColor} boxSize={10} />
            {!hideText && (
              <Text
                color={textColor}
                fontSize="sm"
                mt={1}
                fontWeight="semibold"
              >
                Select image...
              </Text>
            )}
          </Flex>
        )}
        {!isDisabled && (
          <Box position="absolute" top={2} right={2}>
            <MdIcon name="Edit" color={textColor} />
          </Box>
        )}
      </Flex>
    </Flex>
  );
};

export default FileInput;
