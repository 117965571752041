import React, { useState } from 'react';
import { useForm, useFieldArray, Controller } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Range } from 'react-date-range';
import moment from 'moment';

import { hooks, courseUtils } from 'utils';

import { useCurrentTeam } from 'redux/selectors';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Divider,
  Flex,
  MdIcon,
  Skeleton,
  Tab,
  Tabs,
  TabList,
  TabPanel,
  TabPanels,
  Text,
  chakra,
  useTheme,
} from '@workshop/ui';

import {
  AnimatedButton,
  DateRangeInput,
  LabelCheckbox,
  LabelDatePicker,
  LabelInput,
  LabelSelect,
  ConfirmModal,
} from 'components/Common';
import {
  EditModal,
  Props as EditModalProps,
} from 'components/Common/EditModal';
import { IconTooltip } from 'components/IconTooltip';
import { EnrolStudentsModal } from 'components/CohortAllocation';
import { Loading } from 'components/Loading';
import { HeaderTag } from 'components/AppHeader';
import { ClassTypeItem } from 'components/CohortAllocation';

import { discourseActions } from 'redux/actions/common';
import { cohortActions, enrolmentActions } from 'redux/actions/cms';

import { GlobalState } from 'types';
import {
  AccessType,
  Cohort,
  CohortStatus,
  SocialType,
  TeamMember,
} from 'types/common';
import { License } from 'types/cms';

import { PRIVATE_CLASS_SIZE_LIMIT } from 'constants/courses';
import {
  ENROLMENT_BLACKLIST,
  ADVANCED_COHORT_OPTIONS_WHITELIST,
} from 'constants/organisation';

import './EditCohortModal.css';

export interface EditCohortFormData {
  accessType: AccessType;
  isPrivate: boolean;
  isTest: boolean;
  isAnonymous: boolean;
  onSale: boolean;
  label: string;
  socialType: SocialType;
  spacesTotal: number;
  privateChatDisabled: boolean;
  mentors: {
    mentorId: string;
    isAssessor: boolean;
    isSubstitute: boolean;
    subDetails?: {
      subStart: Date | undefined;
      subEnd: Date | undefined;
    };
  }[];
  status: CohortStatus;
}

const labelStyleProps = { whiteSpace: 'nowrap' } as const;

type CohortStudentItemProps = {
  completedUnits: number;
  isLoading: boolean;
  expiryDate: string;
  otherCourses: (string | undefined)[];
  totalUnits: number;
  courseProgressId?: number;
  currentCohort: Cohort;
  username: string;
  name?: string;
  userId?: number;
};

const CohortStudentItem: React.FC<CohortStudentItemProps> = ({
  completedUnits,
  isLoading,
  expiryDate,
  otherCourses,
  totalUnits,
  courseProgressId,
  currentCohort,
  username,
  name,
  userId,
}) => {
  const [isSaving, setIsSaving] = useState(false);

  const currentExpDate = expiryDate ? new Date(expiryDate) : undefined;
  const [newExpDate, setNewExpDate] = useState(currentExpDate);
  const [newCohort, setNewCohort] = useState('');
  const dispatch = useDispatch();

  const currentTeam = useCurrentTeam();

  const discourseMember = useSelector((state: GlobalState) =>
    Object.values(state.discourse.members).find((m) => m.username === username)
  );
  const enrolment = useSelector((state: GlobalState) =>
    Object.values(state.cms.enrolment.enrolment).find(
      (e) => e.currentCohort === currentCohort.id && e.user === userId
    )
  );

  // Class transfer options – cohorts must be for the same course,
  // be of the same type and have spaces available
  const classOptions = useSelector((state: GlobalState) =>
    Object.values(state.cms.cohort)
      .filter(
        (c) =>
          c.id !== currentCohort.id &&
          c.organisation === currentTeam &&
          c.course === currentCohort.course &&
          c.isTest === currentCohort.isTest &&
          c.isPrivate === currentCohort.isPrivate &&
          c.isAnonymous === currentCohort.isAnonymous &&
          c.accessType === currentCohort.accessType &&
          c.classType === currentCohort.classType &&
          c.organisation === currentCohort.organisation &&
          c.socialType &&
          currentCohort.socialType &&
          (c.spacesAvailable > 0 || !courseUtils.hasClassSizeLimit(c)) &&
          c.status === CohortStatus.live
      )
      .reduce(
        (acc, c) => ({
          ...acc,
          [c.id]: `${moment(c.startDate).format('Do MMMM YYYY')}${
            c.label ? ' - ' : ''
          }${c.label}${c.status === CohortStatus.draft ? ' (Draft)' : ''}`,
        }),
        {} as { [key: string]: string }
      )
  );

  const displayName = discourseMember?.name || name;

  const saveDisabled =
    isSaving ||
    !courseProgressId ||
    ((!newExpDate || moment(newExpDate).isSame(moment(currentExpDate))) &&
      !newCohort);

  const onSave = async () => {
    if (!courseProgressId) return;

    setIsSaving(true);
    if (newExpDate && !moment(newExpDate).isSame(moment(currentExpDate))) {
      await dispatch(
        cohortActions.updateCourseProgress(courseProgressId, {
          expiryDate: moment(newExpDate?.toISOString()).format('YYYY-MM-DD'),
        })
      );
    }
    if (newCohort && enrolment) {
      await dispatch(
        enrolmentActions.updateEnrolment(enrolment.id, {
          currentCohort: parseInt(newCohort),
        })
      );
      await Promise.all([
        dispatch(cohortActions.updateCohort(currentCohort.id, {})),
        dispatch(cohortActions.updateCohort(parseInt(newCohort), {})),
      ]);
    }
    setIsSaving(false);
  };

  return (
    <AccordionItem borderTop="default">
      {({ isExpanded }) => (
        <Flex
          flexDir="column"
          className={isExpanded ? 'student-expanded-panel' : ''}
        >
          <AccordionButton display="flex" px={0} py={3}>
            <Flex flex={1} pr={2}>
              <Text fontSize="sm" textAlign="left">
                {displayName}
              </Text>
            </Flex>
            <Flex flex={1} pr={2}>
              <Text
                fontSize="sm"
                textAlign="left"
              >{`${completedUnits}/${totalUnits} units complete`}</Text>
            </Flex>
            <Flex flex={1}>
              <Text fontSize="sm" textAlign="left">
                {currentExpDate
                  ? moment(currentExpDate).format('Do MMMM YYYY')
                  : 'Never'}
              </Text>
            </Flex>
            <Flex width={5} justifyContent="flex-end">
              <AccordionIcon color="text.muted" />
            </Flex>
          </AccordionButton>
          <AccordionPanel px={0} pb={4} overflow="visible">
            <Skeleton isLoaded={!isLoading} flexDir="column">
              <Divider mb="4" />
              <Box flex={1} maxWidth="400px">
                <LabelDatePicker
                  id="startDate"
                  label="Expiry Date"
                  onChange={(date) => {
                    if (!date) return;
                    setNewExpDate(date);
                  }}
                  date={newExpDate || null}
                />
              </Box>
              {Boolean(enrolment) && (
                <Box flex={1} maxWidth="400px">
                  <LabelSelect
                    id="classTransfer"
                    name="classTransfer"
                    label="Class Transfer"
                    defaultValue={undefined}
                    options={{ '': '-', ...classOptions }}
                    onChange={(e) => setNewCohort(e.target.value)}
                  />
                </Box>
              )}
              {Boolean(otherCourses?.length) && (
                <Flex flexDir="column" mt="4">
                  <Text mb="2">{`${displayName}'s Other Courses :`}</Text>
                  {otherCourses.map((courseTitle) => (
                    <Text
                      key={`otherCourse-${courseTitle}`}
                      my="0.5"
                      fontWeight="bold"
                    >
                      {courseTitle}
                    </Text>
                  ))}
                </Flex>
              )}
              <Flex
                flexDirection="row"
                alignContent="center"
                justifyContent="flex-end"
              >
                <AnimatedButton
                  isDisabled={saveDisabled}
                  isLoading={isSaving}
                  onClick={onSave}
                >
                  Save
                </AnimatedButton>
              </Flex>
            </Skeleton>
          </AccordionPanel>
        </Flex>
      )}
    </AccordionItem>
  );
};

interface EditCohortModalProps extends Omit<EditModalProps, 'title'> {
  availableMentors: TeamMember[];
  cohort: Cohort;
  isLicensed?: boolean;
  validLicenses?: License[];
}

const EditCohortModal: React.FC<EditCohortModalProps> = (props) => {
  const {
    availableMentors,
    cohort,
    isLicensed,
    validLicenses,
    isOpen,
    modalSize,
    onClose,
  } = props;

  const { enrolments: enrolmentsLoading } = hooks.useLoadingDataState(
    {
      enrolments: {
        actions: [
          () =>
            enrolmentActions.listEnrolments({
              fetchNextPage: true,
              queryParams: `cohort=${cohort.id}`,
            }),
        ],
      },
    },
    []
  );

  const [isUpdating, setIsUpdating] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [showEnrolStudents, setShowEnrolStudents] = useState(false);
  const [isDeleting, setIsDeleting] = React.useState(false);
  const [fetchedParticipantData, setFetchedParticipantData] = useState<
    string[]
  >([]);
  const [tabIdx, setTabIdx] = useState<number | null>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [participantDataLoaded, setParticipantDataLoaded] = useState(false);

  const dispatch = useDispatch();
  const currentTeam = useCurrentTeam();

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const courseProgress = useSelector((state: GlobalState) =>
    Object.values(state.cms.courseProgress)
  );

  const courses = useSelector((state: GlobalState) =>
    Object.values(state.cms.course.courseList)
  );

  const mentorOptions = availableMentors.reduce(
    (acc, curr) => ({ ...acc, [curr.user.id]: curr.user.name }),
    {} as { [key: string]: string }
  );

  const currentCohortType = courseUtils.getCohortType(cohort);

  const {
    handleSubmit,
    control,
    errors,
    register,
    formState,
    watch,
    reset,
    setValue,
    getValues,
  } = useForm<EditCohortFormData>({
    mode: 'onChange',
    defaultValues: {
      accessType: cohort.accessType,
      isPrivate: cohort.isPrivate,
      isTest: cohort.isTest,
      isAnonymous: cohort.isAnonymous,
      onSale: cohort.onSale,
      privateChatDisabled: cohort.privateChatDisabled,
      label: cohort.label,
      socialType: cohort.socialType,
      spacesTotal: cohort.spacesTotal,
      mentors: cohort.mentors
        .sort((a, b) => a.name.localeCompare(b.name))
        .map((m) => ({
          mentorId: m.id.toString(),
          isAssessor: m.assessor,
          isSubstitute: m.isSubstitute,
          subDetails:
            m.expiryDate && m.subStartDate
              ? {
                  subEnd: new Date(m.expiryDate),
                  subStart: new Date(m.subStartDate),
                }
              : undefined,
        })),
      status: cohort.status,
    },
  });

  const {
    fields: mentors,
    append,
    remove,
  } = useFieldArray({
    control,
    name: 'mentors',
  });

  /* ------------------------ HANDLERS ------------------------ */

  const handleOnClose = () => {
    reset();
    onClose();
  };

  const handleOnSave =
    (showConfirmModal: boolean) =>
    async (e?: React.FormEvent<HTMLFormElement>) => {
      if (showConfirmModal) {
        setShowStatusModal(true);
        return;
      }

      setIsUpdating(true);
      if (e && e.stopPropagation) {
        // prevent any outer forms from receiving the event too
        e.stopPropagation();
      }

      const submit = await handleSubmit(async ({ mentors, ...data }) => {
        await dispatch(
          cohortActions.updateCohort(cohort.id, {
            ...data,
            mentors: mentors.map((m) => ({
              assessor: m.isAssessor,
              isSubstitute: m.isSubstitute,
              mentor: parseInt(m.mentorId),
              subStartDate:
                m.subDetails?.subStart &&
                moment(m.subDetails?.subStart?.toISOString()).format(
                  'YYYY-MM-DD'
                ),
              expiryDate:
                m.subDetails?.subEnd &&
                moment(m.subDetails?.subEnd?.toISOString()).format(
                  'YYYY-MM-DD'
                ),
            })),
          })
        );
      });

      await submit(e);

      setIsUpdating(false);
    };

  const onExpandAccordion = async (idx: number | number[]) => {
    const participantUsername = cohort.participants[idx as number];

    if (fetchedParticipantData.includes(participantUsername)) {
      return;
    }

    setTimeout(async () => {
      await dispatch(cohortActions.listUserCourseProgress(participantUsername));
      setFetchedParticipantData((prev) => [...prev, participantUsername]);
    }, 500);
  };

  const mentorValues = watch('mentors');
  const nonSubMentors = mentorValues.filter((m) => !m.isSubstitute);
  const spacesTotal = watch('spacesTotal');

  const hasClassSizeLimit = courseUtils.hasClassSizeLimit(cohort);

  const isValid =
    mentorValues.find((val) => val.mentorId) &&
    (hasClassSizeLimit
      ? spacesTotal &&
        spacesTotal > 0 &&
        spacesTotal >= cohort.numParticipants &&
        spacesTotal <= PRIVATE_CLASS_SIZE_LIMIT
      : true);

  const values = getValues();

  // Check if the user is about to make the status live
  //  --> if it's the case, show them the confirmation modal
  const showConfirmModal =
    cohort.status === CohortStatus.draft && values.status === CohortStatus.live;

  const modalProps = {
    isOpen,
    modalSize,
    onCancel: handleOnClose,
    onClose: handleOnClose,
    onSave: handleOnSave(showConfirmModal),
    title: 'Manage Class',
    subTitle: (
      <>
        {`${cohort.courseDetails.title}`}
        {isLicensed ? (
          <>
            {`\xa0\xa0\xa0`}
            <HeaderTag
              as={chakra.span}
              display="inline"
              title={`From ${cohort.courseDetails.organisation.name}`}
              bg="background.info"
              color="text.info"
              letterSpacing="normal"
            />
          </>
        ) : (
          ''
        )}
        {`\xa0\xa0\xa0|\xa0\xa0\xa0${moment(cohort.startDate).format(
          'Do MMMM YYYY'
        )}${cohort.label ? ' - ' : ''}${cohort.label}`}
        {cohort.status === CohortStatus.draft ? (
          <>
            {`\xa0\xa0\xa0`}
            <HeaderTag
              as={chakra.span}
              display="inline"
              title="Draft"
              bg="background.tint2"
              color="text.muted"
              letterSpacing="normal"
            />
          </>
        ) : (
          ''
        )}
      </>
    ),
    isUpdating,
    saveDisabled:
      cohort.status === CohortStatus.draft
        ? !formState.dirty
        : !(formState.dirty && isValid) || isUpdating,
    showFooter: tabIdx !== 1,
    saveLabel: cohort.status === CohortStatus.draft ? 'Save Draft' : 'Save',
  };

  let totalLicensedSpacesAvailable = 0;
  validLicenses?.forEach((l) => {
    totalLicensedSpacesAvailable += l.totalEnrolments - l.enrolments.length;
  });

  const accessDuration =
    cohort.accessDuration || cohort.courseDetails.accessDuration;

  const numTempParticipants =
    cohort.numParticipants - cohort.participants.length;

  let participantsPanel = (
    <Flex mb={4}>
      {numTempParticipants > 0 ? null : (
        <Text color="text.muted">This class doesn't have any students yet</Text>
      )}
    </Flex>
  );

  if (cohort.participants.length) {
    participantsPanel =
      !participantDataLoaded || isLoading ? (
        <Flex justifyContent="center" alignItems="center" my="4">
          <Loading />
        </Flex>
      ) : (
        <Flex flexDir="column">
          <Flex flexDir="row" mb={4}>
            <Flex flex={1} pr={2}>
              <Text color="text.muted" textAlign="left">
                Student
              </Text>
            </Flex>
            <Flex flex={1} pr={2}>
              <Text color="text.muted" textAlign="left">
                Course Progress
              </Text>
            </Flex>
            <Flex flex={1}>
              <Text color="text.muted" textAlign="left">
                Access Expires
              </Text>
            </Flex>
            <Flex width={5} />
          </Flex>
          <Accordion allowToggle flex="1" onChange={onExpandAccordion}>
            {cohort.participants.map((username) => {
              const userCourseProgress = courseProgress.find(
                (cp) =>
                  cp.participantUsername === username &&
                  cp.course.toString() === cohort.courseDetails.id.toString()
              );

              const totalUnits = userCourseProgress?.unitProgress.length || 0;

              const completedUnits =
                userCourseProgress?.unitProgress.filter(
                  (up) => up.status === 'complete'
                ).length || 0;

              const expiryDate = userCourseProgress?.expiryDate;

              const otherCourseCourses = courseProgress
                .filter(
                  (cp) =>
                    cp.participantUsername === username &&
                    cp.course.toString() !== cohort.courseDetails.id.toString()
                )
                .map(
                  (cp) =>
                    courses.find(
                      (c) => c.id.toString() === cp.course.toString()
                    )?.title
                );

              return (
                <CohortStudentItem
                  completedUnits={completedUnits}
                  currentCohort={cohort}
                  courseProgressId={userCourseProgress?.id}
                  expiryDate={expiryDate || ''}
                  isLoading={
                    !fetchedParticipantData.includes(username) ||
                    enrolmentsLoading
                  }
                  otherCourses={otherCourseCourses}
                  totalUnits={totalUnits}
                  username={username}
                  name={userCourseProgress?.participantName}
                  userId={userCourseProgress?.user}
                />
              );
            })}
          </Accordion>
        </Flex>
      );
  }

  const hasExpired = moment(cohort.endDate).isBefore();

  return (
    <EditModal {...modalProps}>
      <ConfirmModal
        body="This means opening up your class for enrolments. Once a student enrols, the class cannot be moved back to ‘Draft’ status or be deleted."
        btnColor="green"
        btnLabel="Yes, Publish"
        title="Are You Sure You Want to Publish?"
        isOpen={showStatusModal}
        isLoading={isUpdating}
        onClose={() => setShowStatusModal(false)}
        onClick={handleOnSave(false)}
      />
      <ConfirmModal
        body="Are you sure you want to delete this class? This is not reversible."
        btnLabel="Yes, Delete"
        title="Confirm Class Deletion"
        isOpen={showDeleteModal}
        isLoading={isDeleting}
        onClose={() => setShowDeleteModal(false)}
        onClick={async () => {
          setIsDeleting(true);
          await dispatch(cohortActions.deleteCohort(cohort.id));

          setIsDeleting(false);
          handleOnClose();
        }}
      />
      <EnrolStudentsModal
        title="Enrol Students"
        isOpen={showEnrolStudents}
        onClose={() => setShowEnrolStudents(false)}
        onCancel={() => setShowEnrolStudents(false)}
        onSave={handleOnSave(false)}
        modalSize="lg"
        course={cohort.courseDetails.id}
        cohort={cohort.id}
        cohortIsTest={cohort.isTest}
        cohortIsAnonymous={cohort.isAnonymous}
        cohortSocialType={cohort.socialType}
        courseIsLicensed={isLicensed}
        licenses={validLicenses}
        cohortSpacesAvailable={cohort.spacesAvailable}
        licensedSpacesAvailable={totalLicensedSpacesAvailable}
        licensorName={cohort.courseDetails.organisation.name}
      />
      <Flex
        flexDir={{ base: 'column', md: 'row' }}
        alignItems={{ base: 'normal', md: 'center' }}
      >
        <Box display="none">
          <LabelSelect
            isDisabled
            id="status"
            name="status"
            label="Status"
            error={!!errors.status}
            registerInputRef={register()}
            defaultValue={undefined}
            options={CohortStatus}
            labelStyleProps={labelStyleProps}
            tooltip="cohort_status"
          />
        </Box>
        <Flex flex={1} />
      </Flex>
      {currentCohortType ? (
        <Box mb={6}>
          <ClassTypeItem classType={currentCohortType} isExpandable />
        </Box>
      ) : null}
      <Tabs
        variant="enclosed"
        onChange={async (idx) => {
          setTabIdx(idx);

          if (idx === 1 && !isLoading && !participantDataLoaded) {
            setIsLoading(true);

            await Promise.all([
              dispatch(cohortActions.listCohortCourseProgress(cohort.id)),
              dispatch(
                discourseActions.getGroupMembers(cohort.discourseGroupName)
              ),
            ]);

            setIsLoading(false);
            setParticipantDataLoaded(true);
          }
        }}
      >
        {!cohort.isAnonymous && cohort.status === CohortStatus.live && (
          <TabList mb="1em">
            <Tab>Class Details</Tab>
            <Tab>Student Access</Tab>
          </TabList>
        )}
        <TabPanels>
          <TabPanel p={0}>
            {/* Main section */}
            <Flex flexDir="column" flex={1}>
              <LabelInput
                id="label"
                name="label"
                label="Label"
                registerInputRef={register()}
                labelStyleProps={labelStyleProps}
                labelPosition={isMobile ? 'top' : 'inline'}
              />
              {hasClassSizeLimit ? (
                <LabelInput
                  id="spacesTotal"
                  name="spacesTotal"
                  label="Class Size Limit"
                  placeholder={`Enter a number between ${Math.max(
                    1,
                    cohort.numParticipants
                  )} and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                  inputType="number"
                  error={
                    spacesTotal <= 0 ||
                    spacesTotal < cohort.numParticipants ||
                    spacesTotal > PRIVATE_CLASS_SIZE_LIMIT
                  }
                  errorMessage={`Please enter a number between ${Math.max(
                    1,
                    cohort.numParticipants
                  )} and ${PRIVATE_CLASS_SIZE_LIMIT}`}
                  labelStyleProps={labelStyleProps}
                  registerInputRef={register()}
                  labelPosition={isMobile ? 'top' : 'inline'}
                />
              ) : null}
            </Flex>
            {/* Advanced settings (only for select orgs) */}
            {currentTeam &&
            ADVANCED_COHORT_OPTIONS_WHITELIST.includes(currentTeam) ? (
              <Accordion allowToggle flex="1">
                <AccordionItem borderTop="default">
                  <AccordionButton display="flex" py={3} px={0}>
                    <Flex flex={1}>
                      <Text textAlign="left">Advanced Settings</Text>
                    </Flex>
                    <AccordionIcon color="text.muted" />
                  </AccordionButton>
                  <AccordionPanel py={2} px={0}>
                    <Flex flexDir={{ base: 'column', md: 'row' }}>
                      <Flex flex={1}>
                        <LabelCheckbox
                          isDisabled={false}
                          id="isTest"
                          name="isTest"
                          label="Test class"
                          registerInputRef={register()}
                          defaultValue={undefined}
                          fontWeight="normal"
                          tooltip="test_cohort"
                        />
                      </Flex>
                      <Flex flex={1}>
                        <LabelCheckbox
                          isDisabled={false}
                          id="isPrivate"
                          name="isPrivate"
                          label="Private class"
                          registerInputRef={register()}
                          defaultValue={undefined}
                          fontWeight="normal"
                          tooltip="private_cohort"
                          labelStyleProps={labelStyleProps}
                        />
                      </Flex>
                      <Flex flex={1}>
                        <LabelCheckbox
                          isDisabled={false}
                          id="onSale"
                          name="onSale"
                          label="On sale"
                          registerInputRef={register()}
                          defaultValue={undefined}
                          fontWeight="normal"
                          // tooltip="on_sale_cohort"
                        />
                      </Flex>
                    </Flex>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            ) : null}

            {/* Mentors section */}
            <Flex flexDir="column">
              <Flex flexDir="row" mt={6} mb={2}>
                <Flex w={{ base: '55%', md: '65%' }}>
                  <Text color="text.muted">Class Mentor(s)</Text>
                </Flex>
                <Flex
                  w={{ base: '40%', md: '30%' }}
                  justifyContent="center"
                  alignItems="center"
                >
                  <Text color="text.muted" mr={2}>
                    Substitute
                  </Text>
                  <IconTooltip tooltip="sub_teacher" />
                </Flex>
                <Flex w="5%" />
              </Flex>
              {mentors.map((item, idx) => {
                const mentor = mentorValues[idx] || item;

                const isSub = mentor.isSubstitute;
                const subStart = mentor.subDetails?.subStart;
                const subEnd = mentor.subDetails?.subEnd;

                const isOnlyPermMentor = !isSub && nonSubMentors.length < 2;

                const isLastMentor = idx + 1 === mentors.length;

                return (
                  <Flex flexDir="column" key={`mentor-${item.id}`}>
                    <Divider mb="4" />
                    <Flex flexDir="row" alignItems="center">
                      <Flex w={{ base: '55%', md: '65%' }}>
                        <LabelSelect
                          name={`mentors[${idx}].mentorId` as const}
                          registerInputRef={register()}
                          defaultValue={item.mentorId}
                          options={{ '': '-', ...mentorOptions }}
                        />
                      </Flex>
                      <Flex
                        w={{ base: '40%', md: '30%' }}
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        mb="defaultMargin"
                      >
                        <Checkbox
                          name={`mentors[${idx}].isSubstitute` as const}
                          ref={register()}
                          defaultChecked={item.isSubstitute}
                          defaultValue={item.isSubstitute}
                          isDisabled={isOnlyPermMentor}
                        />
                        {isSub && (
                          <Flex
                            backgroundColor="background.tint3"
                            padding={1}
                            borderRadius="sm"
                            mt={2}
                            mx={2}
                          >
                            <Controller
                              name={`mentors[${idx}].subDetails` as const}
                              defaultValue={item.subDetails}
                              control={control}
                              onChange={(range: { range1: Range }[]) => ({
                                subStart: range[0].range1.startDate,
                                subEnd: range[0].range1.endDate,
                              })}
                              as={
                                <DateRangeInput
                                  dateRange={
                                    subStart && subEnd
                                      ? { startDate: subStart, endDate: subEnd }
                                      : undefined
                                  }
                                  size="xs"
                                />
                              }
                            />
                          </Flex>
                        )}
                      </Flex>
                      <Flex w="5%">
                        {!isOnlyPermMentor && (
                          <MdIcon
                            color="red.400"
                            cursor="pointer"
                            name="Cancel"
                            onClick={() => remove(idx)}
                          />
                        )}
                      </Flex>
                    </Flex>
                    {isLastMentor && <Divider mb="4" />}
                  </Flex>
                );
              })}
            </Flex>
            <Flex flex={1} justifyContent="flex-end">
              <Button
                icon="Add"
                variant="ghost"
                onClick={() =>
                  append({
                    mentorId: '',
                    isAssessor: true,
                    isSubstitute: false,
                  })
                }
              >
                Add Mentor
              </Button>
            </Flex>
            {cohort.status === CohortStatus.draft ? (
              <Flex
                flex={1}
                mt="4"
                alignItems={{ base: 'initial', md: 'row' }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Box>
                  <Button
                    colorScheme="red"
                    variant="outline"
                    onClick={() => {
                      setShowDeleteModal(true);
                    }}
                  >
                    <Text fontWeight="bold">Delete Class</Text>
                  </Button>
                </Box>
                <Flex flex={1} />
                <Flex
                  flexDirection={{ base: 'column', sm: 'row' }}
                  alignItems={{ base: 'flex-end', sm: 'center' }}
                  mt={{ base: 6, md: 0 }}
                >
                  <Text
                    fontSize="sm"
                    color="text.muted"
                    mr={{ base: 0, sm: 4 }}
                    flex={1}
                    textAlign="right"
                    mb={{ base: 2, sm: 0 }}
                  >
                    Publish your class to start enrolling students
                  </Text>
                  <Button
                    colorScheme="green"
                    onClick={() => {
                      setValue('status', CohortStatus.live);
                      setShowStatusModal(true);
                    }}
                    disabled={!isValid || isUpdating}
                  >
                    <Text fontWeight="bold">Publish Class</Text>
                  </Button>
                </Flex>
              </Flex>
            ) : (
              <Flex flex={1} mt="4" alignItems="center">
                <Flex flex={1} />
                {hasExpired && (
                  <HeaderTag
                    title="Class Expired"
                    bg="background.error"
                    color="text.error"
                    ml={6}
                  />
                )}
                <Flex ml={6} color="icon.muted" alignItems="center">
                  <MdIcon name="People" />
                  {cohort.isAnonymous ? (
                    <Text ml={1} fontWeight="bold">
                      {cohort.numEnrolments}
                    </Text>
                  ) : (
                    <>
                      {' '}
                      <Text ml={1} fontWeight="bold">
                        {cohort.numParticipants}
                      </Text>
                      {hasClassSizeLimit ? (
                        <Text>/{cohort.spacesTotal}</Text>
                      ) : null}
                    </>
                  )}
                </Flex>
                {/* Enrolling new students is allowed if:
                - The current organisation isn't on our pay-per-enrolment blacklist
                  AND
                - The cohort doesn't have a size limit and is not a test OR the cohort has spaces available
                  AND
                - The course is not licensed OR the course is licensed and valid licenses are available
                */}
                {currentTeam &&
                !ENROLMENT_BLACKLIST.includes(currentTeam) &&
                ((!hasClassSizeLimit && !cohort.isTest) ||
                  cohort.spacesAvailable > 0) &&
                (!isLicensed ||
                  (isLicensed && validLicenses && validLicenses.length > 0)) ? (
                  <Button
                    onClick={() => setShowEnrolStudents(true)}
                    icon="PersonAdd"
                    variant="outline"
                    ml={4}
                    // TODO: Remove the below once Stripe is hooked up
                    isDisabled={!isLicensed}
                  >
                    {`Enrol Students${!isLicensed ? ' (Coming Soon)' : ''}`}
                  </Button>
                ) : null}
              </Flex>
            )}
          </TabPanel>
          <TabPanel p={0}>
            <Flex flexDir="row" mb={4}>
              {accessDuration ? (
                <Flex flexDir="column" mr={6}>
                  <Flex flexDir="row">
                    <Text mr={2} color="text.muted">
                      Default Access Period:
                    </Text>
                    <Text fontWeight="bold" color="text.muted">
                      {`${accessDuration} week${
                        accessDuration === 1 ? '' : 's'
                      }`}
                    </Text>
                  </Flex>
                  <Text fontSize="xs" color="text.muted">
                    {`This access period is set on your ${
                      cohort.accessDuration ? 'class' : 'course'
                    } and determines the
                    default expiry date for students. Individual student access
                    can be extended beyond this date below.`}
                  </Text>
                </Flex>
              ) : null}
            </Flex>
            <Divider mb="6" />
            {participantsPanel}
            {numTempParticipants > 0 && (
              <Flex justifyContent="flex-start">
                <HeaderTag
                  title={`${numTempParticipants}${
                    cohort.participants.length > 0 ? ' more' : ''
                  } student${
                    numTempParticipants === 1 ? ' has' : 's have'
                  } not yet activated their account${
                    numTempParticipants === 1 ? '' : 's'
                  }`}
                  bg="background.warning"
                  color="text.warning"
                  mt={4}
                  mb={3}
                />
              </Flex>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>
    </EditModal>
  );
};

export default EditCohortModal;
