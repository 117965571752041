import React from 'react';
import moment from 'moment';
import moize from 'moize';
import isEmpty from 'lodash/isEmpty';

import { Card, MdIcon } from '@workshop/ui';

import navRoutes from 'navigation/Routes';

import { UNIT_TYPE } from 'constants/courses';
import { DATETIME_FORMAT } from 'constants/common';

import { getDisplayNotification, getOpenMessageActions } from 'utils/discourse';

import { FeatureCard } from 'components/Dashboard/FeatureCard';
import { DashboardCard } from 'components/Dashboard/DashboardCard';
import { NotificationCard } from 'components/Dashboard/NotificationCard';

import { ModulesList, ModulesListProps } from 'components/ModulesList';
import { UserAvatar } from 'components/UserAvatar';

import { DiscourseData, ProgressData, CourseState } from 'types/learner';

const ItemsListCard: React.FC<ModulesListProps> = (props) => (
  <Card display="flex" flex={1} flexDirection="column" padding={0}>
    <ModulesList {...props} />
  </Card>
);

// Defines the components used in our different sections
export const SECTION_COMPONENTS = {
  featureCard: {
    componentType: 'featureCard',
    component: FeatureCard,
    props: {} as React.ComponentProps<typeof FeatureCard>,
  },
  dashboardCard: {
    componentType: 'dashboardCard',
    component: DashboardCard,
    props: {} as React.ComponentProps<typeof DashboardCard>,
  },
  notificationCard: {
    componentType: 'notificationCard',
    component: NotificationCard,
    props: {} as React.ComponentProps<typeof NotificationCard>,
  },
  itemsListCard: {
    componentType: 'itemsListCard',
    component: ItemsListCard,
    props: {} as React.ComponentProps<typeof ItemsListCard>,
  },
  // imageList: {
  //   componentType: 'imageList',
  //   component: ImageList,
  //   props: [] as React.Component<typeof ImageList>[],
  // },
} as const;

export type ISectionItem = {
  id: string;
  priority: number;
  heading?: string;
} & ValueOf<typeof SECTION_COMPONENTS>;

export type ISectionData = ISectionItem[];

// All of our possible section types
export type SectionType =
  | 'upcomingCourse' // Not Implemented
  | 'currentCourse'
  | 'completedCourse' // Not Implemented
  | 'currentSession'
  | 'upcomingSession'
  | 'outstandingSession'
  | 'scheduledSession'
  | 'upcomingAssessment' // Not Implemented
  | 'outstandingAssessment' // Not Implemented
  | 'privateMessage'
  | 'groupMessage' // Not Implemented
  | 'mentorMessage' // Not Implemented
  | 'classActivity'
  | 'newPost' // Not Implemented
  | 'popularPost' // Not Implemented
  | 'practiceSession'; // Not Implemented

type ISection = Record<
  SectionType,
  {
    key: string;
    // TODO: Nothing is actually used here apart from `getSectionData`?
    title?: string;
    expand?: number;
    listDisplay?: 'horizontal' | 'vertical';
    display?: boolean;
    getSectionData: (data: ProgressData & DiscourseData) => ISectionData | [];
  }
>;

// Translate the input args into a single session slug. As such the function
// is memoized based on the `sessionSlug` provided.
//
// Assumes that 2 args are provided, where the first is of type `CourseState`
// and the second is of type `string`.
const ignoreFirstArg = (args: (CourseState | string)[]) => args.slice(1);

// Return the course name, slug & unit name for a given session
const getInfoFromSession = moize(
  (courses: CourseState, sessionSlug: string) => {
    const session = courses.modules[sessionSlug];

    const unitSlug = session.unit;
    const unit = courses.units[unitSlug];

    const courseSlug = unit.course;
    const course = courses.courses.summary[courseSlug];
    const courseName = course.title;

    return {
      course,
      courseName,
      courseSlug,
      unit,
      unitName: unit.title,
      unitSlug,
      unitType: unit.unitType,
      session,
    };
  },
  { transformArgs: ignoreFirstArg }
);

/*
 * The dashboard is composed of multiple sections, where each section has a goal/incentive
 * which is designed to be most relevant/useful to the student viewing the dashboard.
 *
 * As such, each dashboard for each student will vary based on which 'Act' the student
 * is assigned to, along with the student's current status of interaction with their content.
 *
 * A section contains all the data needed to display the section in the dashboard
 *
 * `getSectionData` simply maps the data from ProgressData and DiscourseData received from our
 * Dashboard Model to match our component props
 */
export const SECTION: ISection = {
  /* This section retrieve the course in progress
   * It takes the courses and scheduled units from progress data
   * It returns a Feature card with the course to resume or the unit to resume (if available)
   */
  currentCourse: {
    key: 'current-course',
    title: 'Current course',
    display: true,
    listDisplay: 'horizontal',
    expand: 2,
    getSectionData: ({ currentUnitSchedules, courseState }) => {
      if (
        !currentUnitSchedules ||
        isEmpty(currentUnitSchedules) ||
        !courseState ||
        isEmpty(courseState)
      )
        return [];

      const { courses, units } = courseState;

      return currentUnitSchedules.map((su, idx) => {
        const course = courses.summary[su.course];
        const unit = units[su.unit];

        const baseItem = {
          id: `current-courses-${
            unit?.id ? `unit-${unit.id}` : `course-${course.id}`
          }`,
          priority: 2,
          // Use the feature card to display
          ...SECTION_COMPONENTS.featureCard,
          // No heading on our current course features
          heading: 'Continue your course',
        };

        const props = unit
          ? {
              title: unit.prefix ? `${unit.prefix}: ${unit.title}` : unit.title,
              subtitle: `Up next on ${course.title}`,
              btnText: 'Open Unit',
              imageUrl: unit.imageThumbnail || course.imageLandscapeThumbnail,
              linkTo: navRoutes.learner.course.path(course.slug),
            }
          : {
              title: course.title,
              subtitle: 'Continue your course',
              btnText: 'Open Course',
              imageUrl: course.imageLandscapeThumbnail,
              linkTo: navRoutes.learner.course.path(course.slug),
            };
        return { ...baseItem, props };
      });
    },
  },
  /*
   * This section retrieve the session in progress
   * It takes the courses and the current sessions
   * It returns a Dashboard card with the first current sessions
   */
  currentSession: {
    key: 'current-session',
    title: 'Current Session',
    // @ts-ignore
    getSectionData: ({ courseState, currentSessions }) => {
      if (!courseState || !currentSessions || currentSessions.length === 0)
        return [];

      // Filter out 'assessment' sessions from the list of current sessions
      const filteredSessions = currentSessions.filter((progress) => {
        const { unitType } = getInfoFromSession(courseState, progress.module);
        return unitType !== UNIT_TYPE.assessment;
      });

      return filteredSessions.map((progress, idx) => {
        const { courseSlug, session, unitName } = getInfoFromSession(
          courseState,
          progress.module
        );

        return {
          id: `current-sessions-${session.id}`,
          priority: 3,
          // Use the feature card to display
          ...SECTION_COMPONENTS.featureCard,
          // No heading on our current course features
          heading: 'Continue your session',
          props: {
            title: session.title,
            prefix: unitName,
            imageUrl: session.imageThumbnail,
            btnText: 'Open Session',
            linkTo: navRoutes.learner.module.path(courseSlug, session.slug),
          },
        };
      });
    },
  },
  /* This section retrieve the upcoming sessions for the courses in progress
   * It takes the courses and scheduled sessions
   * It returns a Feature card component with the next (upcoming) session
   */
  upcomingSession: {
    key: 'upcoming-session',
    title: 'Upcoming sessions',
    display: true,
    getSectionData: ({ courseState, scheduledSessions, currentSessions }) => {
      if (
        isEmpty(scheduledSessions) ||
        !scheduledSessions ||
        isEmpty(courseState) ||
        !courseState
      )
        return [];

      // Init a default priority for this section
      let priority = 2;

      return scheduledSessions.map((schedule) => {
        const { courseSlug, session } = getInfoFromSession(
          courseState,
          schedule.module
        );
        const progress = currentSessions.find(
          ({ module }) => module === schedule.module
        );

        const scheduledTime = moment(schedule.scheduledTime);
        // If the session is scheduled for today
        const isToday = moment().diff(scheduledTime, 'hours') > -24;
        const subtitle = `Scheduled for ${scheduledTime.calendar(
          DATETIME_FORMAT
        )}`;

        const props = {
          title: session.title,
          subtitle: subtitle,
          btnText: 'Open Session',
          imageUrl: session.imageThumbnail,
          linkTo: navRoutes.learner.module.path(courseSlug, session.slug),
          progress,
          schedule,
        };

        // Set the priority based on the immediacy of the upcoming sessions
        priority = isToday ? 1 : 2;

        return {
          id: `upcoming-session-${schedule.id}`,
          priority: priority,
          // Use the feature card to display
          ...SECTION_COMPONENTS.featureCard,
          // No heading on our current course features
          heading: 'Up next',
          // Set prop data for this grid item
          props,
        };
      });
    },
  },
  /* This section retrieve the incomplete session
   * It takes the courses and the catchUps session (i.e. un-finished session)
   * It returns Dashboard cards representing list of un-finished sessions (max 3)
   */
  outstandingSession: {
    key: 'outstanding-session',
    title: 'outstandingSession',
    getSectionData: ({
      courseState,
      catchUp,
      currentSessions,
      scheduledSessions,
    }) => {
      if (!catchUp || isEmpty(catchUp) || !courseState) return [];

      return catchUp.courses
        .map((slug) => {
          const catchUpCourse = courseState.courses.summary[slug];

          const props = {
            isLoading: false,
            moduleItems: catchUp.sessions
              .filter((sessionSlug) => {
                const { courseSlug } = getInfoFromSession(
                  courseState,
                  sessionSlug
                );

                return slug === courseSlug;
              })
              .slice(0, 3)
              .map((sessionSlug) => {
                const { courseSlug } = getInfoFromSession(
                  courseState,
                  sessionSlug
                );
                const { unitName, session } = getInfoFromSession(
                  courseState,
                  sessionSlug
                );
                const progress = currentSessions.find(
                  ({ module }) => module === sessionSlug
                );

                return {
                  isLoading: false,
                  title: session.title,
                  subtitle: unitName,
                  imageUrl: session.imageThumbnail,
                  linkTo: navRoutes.learner.course.path(catchUpCourse.slug),
                  progress,
                  showScheduleBtn: false,
                  courseSlug,
                };
              }),
          };

          return {
            id: `outstanding-session-${slug}`,
            priority: 5,
            ...SECTION_COMPONENTS.itemsListCard,
            heading: `Catch up on ${catchUpCourse.title}`,
            props,
          };
        })
        .filter((a) => a.props.moduleItems.length);
    },
  },
  /* This section retrieve the scheduled session for the week
   * It takes the courses and the scheduled sessions from progress data
   * It returns a Dashboard Card with the next few scheduled sessions
   */
  scheduledSession: {
    key: 'scheduled-session',
    title: 'scheduledSession',
    getSectionData: ({ courseState, scheduledSessions }) => {
      if (
        isEmpty(scheduledSessions) ||
        !scheduledSessions ||
        isEmpty(courseState) ||
        !courseState ||
        scheduledSessions.length === 0
      )
        return [];

      return scheduledSessions.map((schedule, idx) => {
        const { courseSlug, session } = getInfoFromSession(
          courseState,
          schedule.module
        );

        const props = {
          title: session.title,
          subtitle: moment(schedule.scheduledTime).calendar(DATETIME_FORMAT),
          btnText: 'Open Session',
          imageUrl: session.imageThumbnail,
          linkTo: navRoutes.learner.module.path(courseSlug, session.slug),
        };

        return {
          id: `scheduled-session-${schedule.id}`,
          // Use the feature card to display
          ...SECTION_COMPONENTS.dashboardCard,
          // Only show headings on the first row
          heading:
            idx < 2 ? `Here's your schedule for the next week` : undefined,
          // Set prop data for this grid item
          props,
          priority: 4,
        };
      });
    },
  },
  /* This section return messages notifications
   * It takes private messages notifications
   * It returns a Notification Card with the private messages notifications
   */
  privateMessage: {
    key: 'private-message',
    title: 'privateMessage',
    // @ts-ignore
    getSectionData: ({
      privateMessageNotifications,
      discourseMembers,
      cohorts,
    }) => {
      if (privateMessageNotifications.length < 1) return [];

      const moduleItems = privateMessageNotifications.map(
        (notification, idx) => {
          const { showIcon, notifierName, content } = getDisplayNotification(
            notification,
            cohorts
          );

          const openMessageActions = getOpenMessageActions({
            notification,
            cohorts,
            discourseMembers,
          });

          return {
            isLoading: false,
            title: notifierName,
            content,
            showImage: false,
            imageComponent: (
              <UserAvatar
                userId={notifierName.length}
                name={showIcon ? '' : notifierName}
                size="2xs"
                marginLeft={2}
                fallbackIcon={
                  showIcon ? (
                    <MdIcon name="Mail" color="inherit" boxSize="14px" />
                  ) : null
                }
              />
            ),
            linkTo: '#',
            onOpenActions: openMessageActions,
            showScheduleBtn: false,
          };
        }
      );

      return {
        id: `private-messages`,
        priority: 1,
        ...SECTION_COMPONENTS.itemsListCard,
        heading: moduleItems.length === 1 ? 'New message' : 'Unread messages',
        props: {
          isLoading: false,
          moduleItems,
        },
      };
    },
  },
  /* This Section returns the Topics for the Class Activity
   */
  classActivity: {
    key: 'class-activity',
    title: 'classActivity',
    getSectionData: ({ topics, courseState }) => {
      if (!topics || !courseState || isEmpty(courseState)) return [];

      const { courses } = courseState;

      return topics
        .map((dashboardTopic) => {
          const course = courses.summary[dashboardTopic.course];
          const courseTitle = course.title;

          const props = {
            isLoading: false,
            moduleItems: dashboardTopic.topics.map((topic) => {
              // Grab the session title from the topic based on the generated
              // title format [Poster Name]: [Session Title] ([ID])
              const sessionTitleMatch =
                topic.fancyTitle.match(/(?:: )(.+)(?= \()/);
              return {
                title: sessionTitleMatch
                  ? sessionTitleMatch[1]
                  : topic.fancyTitle,
                imageUrl: topic.imageUrl || undefined,
                linkTo: navRoutes.learner.viewPost.path(
                  dashboardTopic.course,
                  topic.id
                ),
                isLoading: false,
                showScheduleBtn: false,
              };
            }),
          };

          return {
            id: `class-activity-${dashboardTopic.course}`,
            ...SECTION_COMPONENTS.itemsListCard,
            // Only show headings on the first row
            heading: `Recent activity in ${courseTitle}`,
            props,
            priority: 4,
          };
        })
        .slice(0, 3)
        .filter((a) => a.props.moduleItems.length);
    },
  },
  upcomingCourse: { key: 'upcoming-course', getSectionData: () => [] },
  completedCourse: { key: 'completed-course', getSectionData: () => [] },
  upcomingAssessment: {
    key: 'upcoming-assessment',
    getSectionData: () => [],
  },
  outstandingAssessment: {
    key: 'outstanding-assessment',
    getSectionData: () => [],
  },
  groupMessage: { key: 'group-message', getSectionData: () => [] },
  mentorMessage: { key: 'mentor-message', getSectionData: () => [] },
  newPost: { key: 'new-post', getSectionData: () => [] },
  popularPost: { key: 'popular-post', getSectionData: () => [] },
  practiceSession: { key: 'practice-session', getSectionData: () => [] },
};
