import React, { useEffect, useState, Children } from 'react';
import { useFormContext } from 'react-hook-form';

import { Flex, Text, Skeleton } from '@workshop/ui';

import { EditCard, ImageUpload, LabelWrapper } from 'components/Common';
import { OverviewFormData, OverviewUnitFormData } from 'types/cms';

type OverviewCardData = OverviewFormData | OverviewUnitFormData;

interface OverviewCardProps {
  onSave: (formData: OverviewCardData) => void;
  /**
   * Optional help text to display at the bottom of the overview card
   */
  helpText?: string | null;
  onCancel?: () => void;
  title?: string;
  subtitle?: string;
  landscape?: string;
  portrait?: string;
  isUpdating?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  error?: boolean;
  onlyTitle?: boolean;
  trailer?: boolean;
  updateImage?: boolean;
}

interface PreviewState {
  [key: string]: string | null;
}

const initialImagePreviewState = {
  landscape: null,
  portrait: null,
};

const OverviewCard: React.FC<OverviewCardProps> = ({
  onSave,
  onCancel = () => {},
  helpText,
  title = '',
  subtitle = '',
  landscape,
  portrait,
  isUpdating = false,
  isLoading = false,
  isDisabled = false,
  onlyTitle = false,
  trailer = true,
  updateImage = true,
  children,
}) => {
  // TODO: Why does this use form context whereas components
  // such as `FurtherDetailsSessionCard` and `FormCard` are
  // their own forms?
  //
  // Do we want the fields of `OverviewCard` to be accessed/
  // submitted by the parent, or should it be its own form?
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState,
    reset,
    setValue,
  } = useFormContext<OverviewCardData>();

  const onSubmit = handleSubmit((formData) => {
    onSave(formData);
    reset(formData);
  });

  // As we render the children components, we build an object
  // containing a copy of any default values which can be used
  // to reset any input values if the form is reset
  let defaultValues: { [key: string]: string } = {};

  const [imagePreview, setImagePreview] = useState<PreviewState>(
    initialImagePreviewState
  );

  const imagePreviewArray = Object.values(imagePreview);

  useEffect(() => {
    // Avoiding memory leaks
    return () => {
      imagePreviewArray.forEach(
        (preview) => preview && URL.revokeObjectURL(preview)
      );
    };
  }, [imagePreviewArray]);

  const onImageDrop = (name: string, acceptedFiles: File[]) => {
    setValue(name, acceptedFiles[0]);
    setImagePreview({
      ...imagePreview,
      [name]: URL.createObjectURL(acceptedFiles[0]),
    });
  };

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        setImagePreview(initialImagePreviewState);
        onCancel();
        clearError();
        reset(defaultValues);
      }}
      saveDisabled={
        isDisabled || !formState.dirty || Object.keys(errors).length > 0
      }
      isDisabled={isDisabled}
      isUpdating={isUpdating}
      flex={1}
    >
      <Flex flexShrink={0} flexDirection="row" marginBottom="defaultMargin">
        <Skeleton isLoaded={!isLoading} marginRight={2}>
          <ImageUpload
            isDisabled={isDisabled}
            id="landscape"
            name="landscape"
            label="Landscape Image"
            textColor={
              imagePreview.landscape || landscape ? 'text.light' : 'text.muted'
            }
            backgroundColor="background.tint3"
            height={200}
            width={350}
            image={imagePreview.landscape || landscape}
            styleProps={{
              marginRight: 2,
            }}
            onDrop={onImageDrop}
          />
        </Skeleton>
        <Skeleton isLoaded={!isLoading}>
          <ImageUpload
            isDisabled={isDisabled}
            id="portrait"
            name="portrait"
            label="Portrait Image"
            textColor={
              imagePreview.portrait || portrait ? 'text.light' : 'text.muted'
            }
            backgroundColor="background.tint3"
            height={200}
            width={110}
            image={imagePreview.portrait || portrait}
            onDrop={onImageDrop}
          />
        </Skeleton>
      </Flex>

      {Children.map(children, (child) => {
        // checking isValidElement is the safe way and avoids a typescript error too
        if (React.isValidElement(child)) {
          if (!defaultValues[child.props.name]) {
            defaultValues = {
              ...defaultValues,
              [child.props.name]: child.props.defaultValue || '',
            };
          }
          return React.cloneElement(child, { register, errors });
        }
        return child;
      })}

      {!isLoading && helpText && (
        <LabelWrapper>
          <Text maxWidth="25rem" fontSize="xs" color="text.muted">
            {helpText}
          </Text>
        </LabelWrapper>
      )}
    </EditCard>
  );
};

export default OverviewCard;
