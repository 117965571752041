import * as React from 'react';
import { Link } from 'react-router-dom';

import styled, { Text, Flex, MdIcon, Skeleton } from '@workshop/ui';

import { ListDataType } from 'components/ListItem';

const RenderIcon = ({
  hover,
  label,
}: {
  hover: boolean;
  fetching: boolean;
  label: string;
}) => {
  if (hover) {
    return <MdIcon name="Reorder" opacity={0.5} boxSize="1.125rem" />;
  }

  return (
    <Text fontSize="xs" fontWeight="semibold">
      {label}
    </Text>
  );
};

const Container = styled(Flex)<{ clickable: boolean }>`
  transition: background-color 0.2s;
  ${(props) =>
    props.clickable
      ? `
  &:hover {
    background-color:${
      // FIXME
      //@ts-ignore
      props.theme.colors.background.tint1
    }
  `
      : ''}
`;

const ItemIcon = styled(Flex)`
  transition: background-color 0.2s;
`;

interface ItemProps extends ListDataType {
  allowHover?: boolean;
  border?: string;
  draggable?: boolean;
  expandable?: boolean;
  expanded?: boolean;
  clickable?: boolean;
  linkTo?: string | null;
  padding?: number;
}

const Item: React.FC<ItemProps> = ({
  allowHover = true,
  border = 'none',
  children,
  complete,
  draggable = true,
  expandable = false,
  expanded = false,
  clickable = true,
  isLoading,
  label,
  linkTo,
  onClick = () => {},
  padding = 'defaultPadding',
  secondaryLabel,
  title,
}) => {
  const [hover, setHover] = React.useState(false);

  const item = (
    <Container
      flex={1}
      padding={padding}
      cursor={clickable ? 'pointer' : 'auto'}
      borderTop={border}
      borderBottom={border}
      borderColor="border.muted"
      backgroundColor="background.default"
      clickable={clickable}
      onClick={onClick}
      onMouseEnter={() => setHover(draggable)}
      onMouseLeave={() => setHover(false)}
      onTouchStart={() => setHover(draggable)}
      onTouchEnd={() => setHover(false)}
    >
      <Skeleton isLoaded={!isLoading} loadingStyle={{ width: '20%' }}>
        <Flex flex={1} alignItems="center">
          <ItemIcon
            backgroundColor={
              (hover && allowHover) || isLoading
                ? 'background.default'
                : 'background.tint3'
            }
            borderRadius="md"
            minWidth="65px"
            justifyContent="center"
            px={2}
            py={1}
            mr="defaultMargin"
          >
            <RenderIcon
              hover={hover && allowHover}
              fetching={isLoading}
              label={label}
            />
          </ItemIcon>
          <Text>{title}</Text>
        </Flex>
      </Skeleton>
      <Flex flex={1} justifyContent="flex-end" alignItems="center">
        {/* TODO: Replace with custom accordion from Chakra */}
        {expandable && (
          <>
            <Text color="text.muted" marginX={1}>
              {expanded ? 'Collapse' : 'Expand'}
            </Text>
            <Flex color="text.muted">
              {expanded ? (
                <MdIcon name="KeyboardArrowUp" />
              ) : (
                <MdIcon name="KeyboardArrowDown" />
              )}
            </Flex>
          </>
        )}
        {complete && <MdIcon name="CheckCircle" color="green.500" mr={2} />}
        {children ? (
          children
        ) : (
          <Skeleton isLoaded={!isLoading} loadingStyle={{ width: '10%' }}>
            <Text fontWeight="semibold" color="common.primary">
              {secondaryLabel}
            </Text>
          </Skeleton>
        )}
      </Flex>
    </Container>
  );

  return linkTo ? <Link to={linkTo}>{item}</Link> : item;
};

export default Item;
