const UnitListAT = {
  CMS_FETCH_UNIT_LIST_REQUEST: 'CMS_FETCH_UNIT_LIST_REQUEST',
  CMS_FETCH_UNIT_LIST_SUCCESS: 'CMS_FETCH_UNIT_LIST_SUCCESS',
  CMS_FETCH_UNIT_LIST_FAILURE: 'CMS_FETCH_UNIT_LIST_FAILURE',

  CMS_CREATE_UNIT_REQUEST: 'CMS_CREATE_UNIT_REQUEST',
  CMS_CREATE_UNIT_SUCCESS: 'CMS_CREATE_UNIT_SUCCESS',
  CMS_CREATE_UNIT_FAILURE: 'CMS_CREATE_UNIT_FAILURE',
} as const;

const UnitAT = {
  CMS_FETCH_UNIT_REQUEST: 'CMS_FETCH_UNIT_REQUEST',
  CMS_FETCH_UNIT_SUCCESS: 'CMS_FETCH_UNIT_SUCCESS',
  CMS_FETCH_UNIT_FAILURE: 'CMS_FETCH_UNIT_FAILURE',

  CMS_PATCH_UNIT_REQUEST: 'CMS_PATCH_UNIT_REQUEST',
  CMS_PATCH_UNIT_SUCCESS: 'CMS_PATCH_UNIT_SUCCESS',
  CMS_PATCH_UNIT_FAILURE: 'CMS_PATCH_UNIT_FAILURE',
} as const;

export default {
  ...UnitListAT,
  ...UnitAT,
};
