import React, { useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import moment from 'moment';

import { UNIT_TYPE } from 'constants/courses';

import { GlobalState } from 'types';
import { Unit } from 'types/learner';
import { CohortStatus } from 'types/common';

import {
  courseActions as learnerCourseActions,
  journalActions,
} from 'redux/actions/learner';
import { courseActions } from 'redux/actions/cms';
import {
  getSlugs,
  getModules,
  getUnits,
  getManagedCohorts,
} from 'redux/selectors/course';
import { hooks, capitalize } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import {
  Box,
  Card,
  Flex,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Text,
  chakra,
  useTheme,
} from '@workshop/ui';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';
import { ModuleListItem } from 'components/ModulesList';
import { ModalVideo } from 'components/ModalVideo';
import { Loading } from 'components/Loading';

import {
  UnitDetailsCard,
  getModulesForUnitDetailsCard,
} from 'screens/learner/BrowseCourse/src/UnitDetailsCard';
import {
  DownloadsModalBody,
  ChecklistModalBody,
  NotesModalBody,
} from 'screens/learner/BrowseCourse/src/CourseModals';
import { CourseModel } from 'models/learner';

// Routing Props
interface MatchParams {
  courseId: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const CoursePreview: React.FC<Props> = ({
  courseId,
  courseSlug,
  course,
  location,
  modules,
  units,
  liveRoomsAvailable,
}) => {
  const [videoForModal, setVideoForModal] = useState<string | null>(null);
  const [assessmentsVisible, setAssessmentsVisible] = useState(false);

  const [modalState, setModalState] = useState<{
    visible: boolean;
    modal: 'Notes' | 'Checklist' | 'Resources' | 'Teacher Resources' | null;
    unit: number | null;
  }>({ visible: false, modal: null, unit: null });

  const { courseLoading } = hooks.useLoadingDataState(
    {
      courseLoading: {
        actions: [
          () =>
            learnerCourseActions.retrieve(
              courseId,
              location.pathname,
              false,
              'teacher=true'
            ),
        ],
      },
      journalLoading: { actions: [journalActions.retrieveJournalEntries] },
    },
    [courseId]
  );

  const { cohorts: cohortsLoading } = hooks.useLoadingDataState(
    {
      cohorts: {
        actions: [() => courseActions.fetchMyCohorts({ fetchNextPage: true })],
      },
    },
    []
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const courseModel = new CourseModel({
    course: course || null,
    courseSchedule: null,
    courseProgress: null,
    modules,
    moduleSchedule: null,
    moduleProgress: null,
    units,
    unitSchedule: null,
    unitProgress: null,
    journalEntries: null,
    cohort: null,
  });

  const detailedUnits = courseModel.getDetailedUnits();
  const introUnit = courseModel.getIntroUnit();
  const outroUnit = courseModel.getOutroUnit();

  const introVideo = introUnit?.videoHls;
  const outroVideo = outroUnit?.videoHls;

  const unitForModal = modalState.unit
    ? detailedUnits.find((u) => u.id === modalState.unit)
    : null;

  if (!courseSlug || courseLoading) return <Loading />;

  return (
    <>
      <ScreenWrapper>
        <Flex flex={1} flexDirection="column" position="relative">
          {introUnit && introVideo && (
            <Card mb={2} padding={0}>
              <ModuleListItem
                isLoading={courseLoading}
                onOpen={() => setVideoForModal(introVideo)}
                openBtnLabel="Play Course Introduction"
                showImage={false}
                showScheduleBtn={false}
                title={introUnit.title}
              />
            </Card>
          )}
          {detailedUnits.map((unit, idx) => {
            const unitModules = getModulesForUnitDetailsCard({
              dataLoading: courseLoading,
              courseSlug,
              courseId,
              setVideoForModal,
              unit,
              isMobile,
              context: 'preview',
            });

            return unitModules.length ? (
              <Box
                key={unit.slug}
                mb={idx + 1 === detailedUnits.length ? 0 : 2}
              >
                <UnitDetailsCard
                  isLoading={courseLoading}
                  tag={unit.tag}
                  description={unit.description}
                  startDate={unit.startDate}
                  title={unit.title}
                  modules={unitModules}
                  onOpenChecklist={
                    unit.unitCheckLists.length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'Checklist',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  onOpenDownloads={
                    unit.downloads.filter((d) => !d.teacherOnly).length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'Resources',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  onOpenTeacherDownloads={
                    unit.downloads.filter((d) => !!d.teacherOnly).length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'Teacher Resources',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  onOpenNotes={
                    unit.unitNotes.length
                      ? () =>
                          setModalState({
                            visible: true,
                            modal: 'Notes',
                            unit: unit.id,
                          })
                      : undefined
                  }
                  percentageComplete={0}
                  locked={false}
                  isAssessment={unit.unitType === UNIT_TYPE.assessment}
                  assessmentStartText={unit.startText}
                  onStartAssessment={async () => setAssessmentsVisible(true)}
                  assessmentActive={assessmentsVisible}
                  isPreview
                  // Cohorts where 'isAnonymous === true' are available for live rooms.
                  // The cohort's number of enrolments determines how many people are
                  // allowed in a room, so this number must be over 0 for room to be launched.
                  // The cohort must also be live and not expired.
                  liveRoomsAvailable={liveRoomsAvailable}
                />
              </Box>
            ) : null;
          })}
          {outroUnit && outroVideo && (
            <Card mt={2} padding={0}>
              <ModuleListItem
                isLoading={courseLoading}
                onOpen={() => setVideoForModal(outroVideo)}
                openBtnLabel="Play Course Summary"
                showImage={false}
                showScheduleBtn={false}
                title={outroUnit.title}
              />
            </Card>
          )}
        </Flex>
      </ScreenWrapper>
      {/* TODO: Move modals to 'screens/learner/BrowseCourse/src/CourseModals' */}
      {/* MODAL FOR NOTES/CHECKLIST/DOWNLOADS */}
      <Modal
        size="lg"
        isOpen={modalState.visible}
        onClose={() =>
          setModalState({ visible: false, modal: null, unit: null })
        }
      >
        <ModalOverlay />
        <ModalContent borderRadius="md" overflow="hidden">
          <ModalHeader padding={4}>
            <Flex alignItems="center">
              <Text fontWeight="bold" pr={8}>
                {`${capitalize(modalState.modal)}`}
                <chakra.span
                  fontWeight="semibold"
                  color="text.muted"
                >{` for ${unitForModal?.title}`}</chakra.span>
              </Text>
            </Flex>
          </ModalHeader>
          <ModalCloseButton />
          {unitForModal && (
            <ModalBody padding={0}>
              {modalState.modal === 'Resources' ? (
                <DownloadsModalBody
                  downloads={unitForModal.downloads.filter(
                    (d) => !d.teacherOnly
                  )}
                  onClickVideo={(video: string) => {
                    setModalState({ visible: false, modal: null, unit: null });
                    setVideoForModal(video);
                  }}
                />
              ) : modalState.modal === 'Teacher Resources' ? (
                <DownloadsModalBody
                  downloads={unitForModal.downloads.filter(
                    (d) => !!d.teacherOnly
                  )}
                  onClickVideo={(video: string) => {
                    setModalState({ visible: false, modal: null, unit: null });
                    setVideoForModal(video);
                  }}
                />
              ) : modalState.modal === 'Checklist' ? (
                <ChecklistModalBody checklists={unitForModal.unitCheckLists} />
              ) : modalState.modal === 'Notes' ? (
                <NotesModalBody notes={unitForModal.unitNotes} />
              ) : null}
            </ModalBody>
          )}
        </ModalContent>
      </Modal>
      {/** MODAL FOR COURSE/UNIT INTROS/OUTROS */}
      <ModalVideo
        isOpen={Boolean(videoForModal)}
        onClose={() => setVideoForModal(null)}
        video={videoForModal || undefined}
        autoplay
      />
    </>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { courseId } = props.match.params;

  const {
    courses: { courses: courseState, units: unitState, modules: moduleState },
  } = state.learner;

  // Course Data
  const course = Object.values(courseState.detail).find(
    (c) => c.id.toString() === courseId
  );
  const courseSlug = course?.slug;
  const units = course ? getUnits(unitState, course.units) : null;

  type U = Unit;
  type M = 'modules';
  const moduleSlugs = getSlugs<Pick<U, M>, M>(units, 'modules');

  const modules = moduleSlugs ? getModules(moduleState, moduleSlugs) : null;

  const myCohorts = getManagedCohorts(state);
  const liveRoomsAvailable = !!myCohorts.find(
    (c) =>
      c.course === courseSlug &&
      c.isAnonymous &&
      c.numEnrolments > 0 &&
      c.status === CohortStatus.live &&
      moment(c.endDate).isSameOrAfter()
  );

  return {
    courseId,
    courseSlug,
    course,
    units,
    modules,
    liveRoomsAvailable,
  };
};

const connector = connect(mapStateToProps);

export default connector(CoursePreview);
