export const CONTENT_TYPES = {
  MODULE_PROGRESS: 'moduleprogress',
} as const;

export const ENTRY_TYPES = {
  COURSE: 'course',
  COURSE_ASSESSMENT: 'course_assessment',
};

export const DATETIME_FORMAT = {
  sameDay: '[Today at] LT',
  nextDay: '[Tomorrow at] LT',
  nextWeek: 'dddd [at] LT',
  lastDay: '[Yesterday at] LT',
  lastWeek: '[Last] dddd [at] LT',
  sameElse: 'MMM Do [at] LT',
};

export const DATE_FORMAT = {
  sameDay: '[Today]',
  nextDay: '[Tomorrow]',
  nextWeek: 'dddd',
  lastDay: '[Yesterday]',
  lastWeek: '[Last] dddd',
  sameElse: 'MMM Do YYYY',
};
