import React, { useState } from 'react';
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe, StripeError } from '@stripe/stripe-js';

import styled, {
  Text,
  Box,
  Flex,
  Card,
  Paragraph,
  MdIcon,
  Skeleton,
  useToast,
  UseToastOptions,
  Collapse,
  useDisclosure,
  useToken,
} from '@workshop/ui';

import { UserPaymentSettings } from 'types/common';
import { AnimatedButton } from 'components/Common';

import { stripePublicKey } from 'constants/env';

const stripePromise = loadStripe(stripePublicKey);

interface PaymentSettingsProps {
  isLoading?: boolean;
  onSubmit: (id: string) => void;
  paymentSettings?: UserPaymentSettings[];
}

interface UpdateCardFormProps {
  onSubmit: (id: string) => void;
  onToggle: () => void;
}

const errorToast = (error?: StripeError): UseToastOptions => {
  return {
    title: 'An error occurred',
    description:
      error?.message ||
      'Please contact Workshop at support@workshop.ws if you keep receiving this error',
    status: 'error',
    duration: 3000,
    isClosable: true,
  };
};

const CardIcon = styled(Box)`
  display: inline-block;
  text-align: left;
  width: 60px;
  height: 40px;
  background-color: #f1f3f9;
  border-radius: 5px;
  position: relative;
  overflow: hidden;
  box-sizing: border-box;

  &:before,
  &:after {
    content: '';
    position: absolute;
    box-sizing: border-box;
  }

  &.visa {
    background-color: #fff;
    border-top: solid #1e3f8e 13px;
    &:before {
      background-color: #1e3f8e;
      border: solid #fff 4px;
      border-width: 5px 10px;
      width: 60px;
      height: 14px;
      top: 0;
    }
    &:after {
      width: 100%;
      height: 13px;
      background-color: #df9600;
      bottom: 0;
    }
  }

  &.mastercard {
    background-color: #1d468f;
    &:before,
    &:after {
      width: 22px;
      height: 22px;
      border-radius: 14px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
    &:before {
      background-color: #db2142;
      left: 12px;
    }
    &:after {
      background-color: #fec627;
      right: 12px;
    }
  }
`;

const renderCardNumber = (paymentSettings?: UserPaymentSettings) => {
  if (paymentSettings) {
    const { last4, created } = paymentSettings;
    return (
      <Box>
        <Text fontSize="lg" mb={0}>
          &bull;&bull;&bull;&bull; - &bull;&bull;&bull;&bull; -
          &bull;&bull;&bull;&bull; - {last4}
        </Text>
        <Text fontSize="sm" color="text.muted">
          {`Added on ${created}`}
        </Text>
      </Box>
    );
  } else {
    return (
      <Flex alignItems="center">
        <Text fontWeight="semibold">No card associated with this account</Text>
      </Flex>
    );
  }
};

const UpdateCardForm: React.FC<UpdateCardFormProps> = ({
  onSubmit,
  onToggle,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();

  // Extract theme colors for use in Stripe card element
  const [textError, textMuted] = useToken('colors', [
    'text.error',
    'text.muted',
  ]);

  const [formState, setFormState] = useState<{
    error:
      | undefined
      | {
          type: 'validation_error';
          code: string;
          message: string;
        };
    complete: boolean;
  }>({ error: undefined, complete: false });

  const [isUpdating, setIsUpdating] = useState(false);

  const handleSubmit = async (event: React.SyntheticEvent) => {
    setIsUpdating(true);
    // Block native form submission.
    event.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      toast(errorToast());
      setIsUpdating(false);
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.
    const cardElement = elements.getElement(CardElement);

    if (!cardElement) {
      toast(errorToast());
      setIsUpdating(false);
      return;
    }

    // We create a new PaymentMethod for the user via Stripe and receive an ID within the
    // Stripe Response which can be passed to our backend
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error || !paymentMethod) {
      setIsUpdating(false);
      toast(errorToast(error));
      return;
    }

    await onSubmit(paymentMethod.id);
    setIsUpdating(false);
    // Hide the form
    onToggle();
  };

  return (
    <Flex flexDir="column" flex={1} marginTop={4}>
      <Text color="text.muted" marginBottom="defaultMargin">
        Enter Card Details:
      </Text>
      <form onSubmit={handleSubmit}>
        <Flex
          flexDir={{ base: 'row', xl: 'column' }}
          alignItems={{ base: 'center', xl: 'stretch' }}
        >
          <div style={{ flexBasis: '100%' }}>
            <CardElement
              onChange={(props) =>
                setFormState({ error: props.error, complete: props.complete })
              }
              options={{
                style: {
                  base: {
                    fontSize: '16px',
                    letterSpacing: '0.025em',
                    fontWeight: '400',
                    '::placeholder': {
                      color: textMuted,
                    },
                  },
                  invalid: {
                    color: textError,
                  },
                },
              }}
            />
          </div>
          <Flex my={{ base: 0, xl: 'defaultMargin' }}>
            <Flex flex={{ base: 0, xl: 1 }} alignItems="center">
              {formState.error && (
                <Text fontSize="sm" color="text.error">
                  {formState.error.message}
                </Text>
              )}
            </Flex>
            <AnimatedButton
              type="submit"
              isLoading={isUpdating}
              isDisabled={!formState.complete || Boolean(formState.error)}
              onClick={() => {}}
              size="sm"
            >
              Save Changes
            </AnimatedButton>
          </Flex>
        </Flex>
      </form>
      <Text fontSize="sm" color="text.muted" my="defaultMargin">
        By entering your card details, you authorise Workshop to send
        instructions to the financial institution that issued your card to take
        payments from your card account in accordance with the terms of your
        agreement with them.
      </Text>
      <Text fontSize="sm" color="text.muted" marginBottom={0}>
        N.B. Any update to your current payment method will be reflected on your
        next billing date.
      </Text>
    </Flex>
  );
};

const PaymentSettings: React.FC<PaymentSettingsProps> = ({
  isLoading,
  onSubmit,
  paymentSettings = [],
}) => {
  const { isOpen, onToggle } = useDisclosure();

  const [currentPaymentSettings] = paymentSettings;

  return (
    <Elements stripe={stripePromise}>
      <Card flexDir="column" padding="defaultPadding">
        <Skeleton
          isLoaded={!isLoading}
          loadingStyle={{ width: '75%', height: 6 }}
        >
          <Flex flex={1} alignItems="center">
            <CardIcon className={currentPaymentSettings?.brand} />
            <Flex flex={1} mx={4}>
              {renderCardNumber(currentPaymentSettings)}
            </Flex>
            <Flex
              as="button"
              alignItems="center"
              cursor="pointer"
              onClick={onToggle}
            >
              <Text fontWeight="semibold" color="common.primary" mr={2}>
                Add Card
              </Text>
              {isOpen ? (
                <MdIcon name="ArrowDropUp" color="common.primary" />
              ) : (
                <MdIcon name="ArrowDropDown" color="common.primary" />
              )}
            </Flex>
          </Flex>
        </Skeleton>
        <Collapse in={isOpen} animateOpacity>
          <Elements stripe={stripePromise}>
            <UpdateCardForm onSubmit={onSubmit} onToggle={onToggle} />
          </Elements>
        </Collapse>
      </Card>
    </Elements>
  );
};

export default PaymentSettings;
