import { AssessmentAT, UnitAT } from 'redux/actionTypes/cms';

import { Action } from 'types';
import {
  UnitListState,
  UnitDetailState,
  UnitUIState,
  IUnitListItem,
  UnitSummaryState,
} from 'types/cms';

const initialState: UnitListState = {};

export const unitListReducer = (
  state = initialState,
  action: Action
): UnitListState => {
  switch (action.type) {
    case UnitAT.CMS_CREATE_UNIT_REQUEST:
    case UnitAT.CMS_FETCH_UNIT_LIST_REQUEST:
      return state;
    case UnitAT.CMS_CREATE_UNIT_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.units,
      };
    case UnitAT.CMS_CREATE_UNIT_FAILURE:
    case UnitAT.CMS_FETCH_UNIT_LIST_FAILURE:
      return state;
    case UnitAT.CMS_FETCH_UNIT_SUCCESS:
    case UnitAT.CMS_PATCH_UNIT_SUCCESS: {
      // When we get or update a unit, we find that unit in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const unit = action.payload.entities.unit[idString];

      // If we don't have a list representation of the unit yet
      // then do nothing
      if (!state[idString] || Object.keys(state).length === 0) return state;

      const item = Object.keys(state[idString]).reduce((acc, k: string) => {
        const key = k as keyof IUnitListItem;
        const value = unit[key] || state[idString][key];

        // @ts-ignore
        acc[key] = value;

        return acc;
      }, {} as IUnitListItem);

      return {
        ...state,
        [idString]: item,
      };
    }
    default:
      return state;
  }
};

const unitInitialState: UnitDetailState = {};

export const unitReducer = (
  state = unitInitialState,
  action: Action
): UnitDetailState => {
  switch (action.type) {
    case UnitAT.CMS_FETCH_UNIT_REQUEST:
      return state;
    case UnitAT.CMS_FETCH_UNIT_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.unit,
      };
    case UnitAT.CMS_FETCH_UNIT_FAILURE:
      return state;
    case UnitAT.CMS_PATCH_UNIT_REQUEST:
      return state;
    case UnitAT.CMS_PATCH_UNIT_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.unit,
      };
    case UnitAT.CMS_PATCH_UNIT_FAILURE:
      return state;
    default:
      return state;
  }
};

const unitSummaryInitialState: UnitSummaryState = {};

export const unitSummaryReducer = (
  state = unitSummaryInitialState,
  action: Action
): UnitSummaryState => {
  switch (action.type) {
    // We don't worry about updating unit summary data upon
    // `CMS_PATCH_UNIT_SUCCESS` like we do with unit list
    // data, as unit summary data is always re-retrieved before
    // use
    case AssessmentAT.CMS_COHORT_ASSESSMENTS_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.unitSummary,
      };
    default:
      return state;
  }
};

export const unitUIReducer = (
  state: UnitUIState = {
    unit: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
    unitList: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): UnitUIState => {
  switch (action.type) {
    case UnitAT.CMS_CREATE_UNIT_REQUEST:
    case UnitAT.CMS_FETCH_UNIT_LIST_REQUEST:
      return {
        ...state,
        unitList: {
          ...state.unitList,
          loading: !action.error,
        },
      };
    case UnitAT.CMS_CREATE_UNIT_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_LIST_SUCCESS:
    case UnitAT.CMS_CREATE_UNIT_FAILURE:
    case UnitAT.CMS_FETCH_UNIT_LIST_FAILURE:
      return {
        ...state,
        unitList: {
          ...state.unitList,
          loading: false,
        },
      };
    case UnitAT.CMS_FETCH_UNIT_REQUEST:
    case UnitAT.CMS_PATCH_UNIT_REQUEST:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: !action.error,
        },
      };
    case UnitAT.CMS_FETCH_UNIT_SUCCESS:
    case UnitAT.CMS_PATCH_UNIT_SUCCESS:
    case UnitAT.CMS_FETCH_UNIT_FAILURE:
    case UnitAT.CMS_PATCH_UNIT_FAILURE:
      return {
        ...state,
        unit: {
          ...state.unit,
          loading: false,
        },
      };
    default:
      return state;
  }
};
