const RoleAT = {
  FETCH_ROLE_REQUEST: 'FETCH_ROLE_REQUEST',
  FETCH_ROLE_SUCCESS: 'FETCH_ROLE_SUCCESS',
  FETCH_ROLE_FAILURE: 'FETCH_ROLE_FAILURE',
} as const;

const OrganisationProfileAT = {
  FETCH_ORGANISATION_REQUEST: 'FETCH_ORGANISATION_REQUEST',
  FETCH_ORGANISATION_SUCCESS: 'FETCH_ORGANISATION_SUCCESS',
  FETCH_ORGANISATION_FAILURE: 'FETCH_ORGANISATION_FAILURE',

  UPDATE_ORGANISATION_REQUEST: 'UPDATE_ORGANISATION_REQUEST',
  UPDATE_ORGANISATION_SUCCESS: 'UPDATE_ORGANISATION_SUCCESS',
  UPDATE_ORGANISATION_FAILURE: 'UPDATE_ORGANISATION_FAILURE',

  CREATE_ORGANISATION_REQUEST: 'CREATE_ORGANISATION_REQUEST',
  CREATE_ORGANISATION_SUCCESS: 'CREATE_ORGANISATION_SUCCESS',
  CREATE_ORGANISATION_FAILURE: 'CREATE_ORGANISATION_FAILURE',
} as const;

const PermissionAT = {
  FETCH_PERMISSION_REQUEST: 'FETCH_PERMISSION_REQUEST',
  FETCH_PERMISSION_SUCCESS: 'FETCH_PERMISSION_SUCCESS',
  FETCH_PERMISSION_FAILURE: 'FETCH_PERMISSION_FAILURE',
} as const;

const TeamMembershipListAT = {
  FETCH_TEAM_MEMBER_LIST_REQUEST: 'FETCH_TEAM_MEMBER_LIST_REQUEST',
  FETCH_TEAM_MEMBER_LIST_SUCCESS: 'FETCH_TEAM_MEMBER_LIST_SUCCESS',
  FETCH_TEAM_MEMBER_LIST_FAILURE: 'FETCH_TEAM_MEMBER_LIST_FAILURE',

  CREATE_TEAM_MEMBER_REQUEST: 'CREATE_TEAM_MEMBER_REQUEST',
  CREATE_TEAM_MEMBER_SUCCESS: 'CREATE_TEAM_MEMBER_SUCCESS',
  CREATE_TEAM_MEMBER_FAILURE: 'CREATE_TEAM_MEMBER_FAILURE',
} as const;

const TeamMembershipAT = {
  FETCH_TEAM_MEMBER_REQUEST: 'FETCH_TEAM_MEMBER_REQUEST',
  FETCH_TEAM_MEMBER_SUCCESS: 'FETCH_TEAM_MEMBER_SUCCESS',
  FETCH_TEAM_MEMBER_FAILURE: 'FETCH_TEAM_MEMBER_FAILURE',

  PATCH_TEAM_MEMBER_REQUEST: 'PATCH_TEAM_MEMBER_REQUEST',
  PATCH_TEAM_MEMBER_SUCCESS: 'PATCH_TEAM_MEMBER_SUCCESS',
  PATCH_TEAM_MEMBER_FAILURE: 'PATCH_TEAM_MEMBER_FAILURE',

  DELETE_TEAM_MEMBER_REQUEST: 'DELETE_TEAM_MEMBER_REQUEST',
  DELETE_TEAM_MEMBER_SUCCESS: 'DELETE_TEAM_MEMBER_SUCCESS',
  DELETE_TEAM_MEMBER_FAILURE: 'DELETE_TEAM_MEMBER_FAILURE',

  SEND_INVITATION_REQUEST: 'SEND_INVITATION_REQUEST',
  SEND_INVITATION_SUCCESS: 'SEND_INVITATION_SUCCESS',
  SEND_INVITATION_FAILURE: 'SEND_INVITATION_FAILURE',

  RESEND_INVITATION_REQUEST: 'RESEND_INVITATION_REQUEST',
  RESEND_INVITATION_SUCCESS: 'RESEND_INVITATION_SUCCESS',
  RESEND_INVITATION_FAILURE: 'RESEND_INVITATION_FAILURE',

  ACCEPT_INVITATION_REQUEST: 'ACCEPT_INVITATION_REQUEST',
  ACCEPT_INVITATION_SUCCESS: 'ACCEPT_INVITATION_SUCCESS',
  ACCEPT_INVITATION_FAILURE: 'ACCEPT_INVITATION_FAILURE',

  FETCH_INVITATION_REQUEST: 'FETCH_INVITATION_REQUEST',
  FETCH_INVITATION_SUCCESS: 'FETCH_INVITATION_SUCCESS',
  FETCH_INVITATION_FAILURE: 'FETCH_INVITATION_FAILURE',

  FETCH_INVITATION_LIST_REQUEST: 'FETCH_INVITATION_LIST_REQUEST',
  FETCH_INVITATION_LIST_SUCCESS: 'FETCH_INVITATION_LIST_SUCCESS',
  FETCH_INVITATION_LIST_FAILURE: 'FETCH_INVITATION_LIST_FAILURE',
} as const;

const MyTeamsAT = {
  FETCH_MY_TEAMS_REQUEST: 'FETCH_MY_TEAMS_REQUEST',
  FETCH_MY_TEAMS_SUCCESS: 'FETCH_MY_TEAMS_SUCCESS',
  FETCH_MY_TEAMS_FAILURE: 'FETCH_MY_TEAMS_FAILURE',
} as const;

const CurrentTeamAT = {
  SET_CURRENT_TEAM: 'SET_CURRENT_TEAM',
} as const;

const UIOrganisationAT = {
  SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
} as const;

const OrganisationAT = {
  LIST_ORGANISATIONS_REQUEST: 'LIST_ORGANISATIONS_REQUEST',
  LIST_ORGANISATIONS_SUCCESS: 'LIST_ORGANISATIONS_SUCCESS',
  LIST_ORGANISATIONS_FAILURE: 'LIST_ORGANISATIONS_FAILURE',
} as const;

export default {
  ...PermissionAT,
  ...RoleAT,
  ...TeamMembershipAT,
  ...TeamMembershipListAT,
  ...MyTeamsAT,
  ...OrganisationProfileAT,
  ...CurrentTeamAT,
  ...UIOrganisationAT,
  ...OrganisationAT,
};
