const AuthAT = {
  CLEAR_AUTH_ERROR: 'CLEAR_AUTH_ERROR',

  DELETE_TOKEN_FAILURE: 'DELETE_TOKEN_FAILURE',
  DELETE_TOKEN_REQUEST: 'DELETE_TOKEN_REQUEST',
  DELETE_TOKEN_SUCCESS: 'DELETE_TOKEN_SUCCESS',

  FETCH_TOKEN_FAILURE: 'FETCH_TOKEN_FAILURE',
  FETCH_TOKEN_REQUEST: 'FETCH_TOKEN_REQUEST',
  FETCH_TOKEN_SUCCESS: 'FETCH_TOKEN_SUCCESS',

  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',

  SIGNUP_FAILURE: 'SIGNUP_FAILURE',
  SIGNUP_REQUEST: 'SIGNUP_REQUEST',
  SIGNUP_SUCCESS: 'SIGNUP_SUCCESS',

  SOCIAL_LOGIN_FAILURE: 'SOCIAL_LOGIN_FAILURE',
  SOCIAL_LOGIN_REQUEST: 'SOCIAL_LOGIN_REQUEST',
  SOCIAL_LOGIN_SUCCESS: 'SOCIAL_LOGIN_SUCCESS',

  SOCIAL_CONNECT_REQUEST: 'SOCIAL_CONNECT_REQUEST',
  SOCIAL_CONNECT_SUCCESS: 'SOCIAL_CONNECT_SUCCESS',
  SOCIAL_CONNECT_FAILURE: 'SOCIAL_CONNECT_FAILURE',

  IMPERSONATE_USER_REQUEST: 'IMPERSONATE_USER_REQUEST',
  IMPERSONATE_USER_SUCCESS: 'IMPERSONATE_USER_SUCCESS',
  IMPERSONATE_USER_FAILURE: 'IMPERSONATE_USER_FAILURE',

  STOP_IMPERSONATE_USER_REQUEST: 'STOP_IMPERSONATE_USER_REQUEST',
  STOP_IMPERSONATE_USER_SUCCESS: 'STOP_IMPERSONATE_USER_SUCCESS',
  STOP_IMPERSONATE_USER_FAILURE: 'STOP_IMPERSONATE_USER_FAILURE',

  CHANGE_PASSWORD_REQUEST: 'CHANGE_PASSWORD_REQUEST',
  CHANGE_PASSWORD_SUCCESS: 'CHANGE_PASSWORD_SUCCESS',
  CHANGE_PASSWORD_FAILURE: 'CHANGE_PASSWORD_FAILURE',

  RESET_PASSWORD_REQUEST: 'RESET_PASSWORD_REQUEST',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  RESET_PASSWORD_FAILURE: 'RESET_PASSWORD_FAILURE',

  VERIFY_PW_RESET_KEY_REQUEST: 'VERIFY_PW_RESET_KEY_REQUEST',
  VERIFY_PW_RESET_KEY_SUCCESS: 'VERIFY_PW_RESET_KEY_SUCCESS',
  VERIFY_PW_RESET_KEY_FAILURE: 'VERIFY_PW_RESET_KEY_FAILURE',

  FETCH_SOCIAL_PROVIDERS_REQUEST: 'FETCH_SOCIAL_PROVIDERS_REQUEST',
  FETCH_SOCIAL_PROVIDERS_SUCCESS: 'FETCH_SOCIAL_PROVIDERS_SUCCESS',
  FETCH_SOCIAL_PROVIDERS_FAILURE: 'FETCH_SOCIAL_PROVIDERS_FAILURE',

  FETCH_SOCIAL_CONNECTIONS_REQUEST: 'FETCH_SOCIAL_CONNECTIONS_REQUEST',
  FETCH_SOCIAL_CONNECTIONS_SUCCESS: 'FETCH_SOCIAL_CONNECTIONS_SUCCESS',
  FETCH_SOCIAL_CONNECTIONS_FAILURE: 'FETCH_SOCIAL_CONNECTIONS_FAILURE',

  REMOVE_SOCIAL_CONNECTION_REQUEST: 'REMOVE_SOCIAL_CONNECTION_REQUEST',
  REMOVE_SOCIAL_CONNECTION_SUCCESS: 'REMOVE_SOCIAL_CONNECTION_SUCCESS',
  REMOVE_SOCIAL_CONNECTION_FAILURE: 'REMOVE_SOCIAL_CONNECTION_FAILURE',
} as const;

export default AuthAT;
