import React, { useEffect, useState } from 'react';
import { History } from 'history';
import * as Sentry from '@sentry/react';
import { useLocation } from 'react-router';

import { FallbackError } from 'components/Error/FallbackError';
import { LoadingScreen } from 'components/Loading';
import InitPage from 'navigation/InitPage';
import {
  Router,
  Route as RouterRoute,
  Redirect,
  Switch,
} from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { useToast } from '@workshop/ui';

import {
  authActions,
  profileActions,
  discourseActions,
  uiAction,
} from 'redux/actions/common';
import { cohortActions } from 'redux/actions/learner';
import {
  useIsAuthenticated,
  useTeams,
  useCurrentTeam,
  useIsStaff,
} from 'redux/selectors';
import { GlobalState } from 'types';
import messageBus from 'utils/messageBus';
import { hooks } from 'utils';

import PrivateRoutes from './PrivateRoutes';
import navRoutes from './Routes';

interface RoutesRenderer {
  isAuthenticated: boolean;
  isUserAccessLoading: boolean;
}

// Create Custom Sentry Route component to parameterized transactions
// @ts-ignore
const Route = Sentry.withSentryRouting(RouterRoute);

const RoutesRenderer: React.FC<RoutesRenderer> = ({
  isAuthenticated,
  isUserAccessLoading,
  ...rest
}) => {
  const location = useLocation();
  const myTeams = useTeams();
  if (isAuthenticated) {
    // If logged in, wait until we know whether the user is a learner or
    // team member before deciding where to take them
    if (isUserAccessLoading) return <LoadingScreen />;
    const homepage = navRoutes.common.home.path();
    return (
      <Switch>
        {/* <Redirect exact from="/" to={homepage} /> */}
        {Object.keys(navRoutes.public).map((k, idx) => {
          const route = navRoutes.public[k];
          const { path, redirect } = route;

          return (
            <Redirect
              key={`route-${path()}_${idx}`}
              from={path()}
              to={redirect || homepage}
            />
          );
        })}
        {Object.keys(navRoutes.global).map((k, idx) => {
          const route = navRoutes.global[k];
          const { path, component } = route;

          return (
            <Route
              key={`route-${path()}_${idx}`}
              path={path()}
              component={component}
            />
          );
        })}
        <Route exact render={() => <PrivateRoutes />} {...rest} />
      </Switch>
    );
  }

  return (
    <Switch>
      {Object.keys({ ...navRoutes.public, ...navRoutes.global }).map(
        (k, idx) => {
          const route = navRoutes.public[k] || navRoutes.global[k];
          const { path, component } = route;

          return (
            <Route
              key={`route-${path()}_${idx}`}
              path={path()}
              component={component}
            />
          );
        }
      )}
      <Redirect
        to={`${navRoutes.public.login.path()}?redirect=${location.pathname}`}
      />
    </Switch>
  );
};

const AppRouter: React.FC<{ history: History<unknown> }> = ({ history }) => {
  const dispatch = useDispatch();
  const isAuthenticated = useIsAuthenticated();

  const [initAuthCompleted, setInitAuthCompleted] = useState(false);
  const [userDetailsRetrieved, setUserDetailsRetrieved] = useState(false);

  const userAccessDataFetched = hooks.useUserAccess();
  const baseDataFetched = hooks.useLoadBaseData();

  const currentTeam = useCurrentTeam();
  const isStaff = useIsStaff();

  // When the application is first loaded, attempt to refresh the JWT
  // token, if one is present
  useEffect(() => {
    const initAuth = async () => {
      await dispatch(authActions.refreshTokenRequest());

      setInitAuthCompleted(true);
    };

    initAuth();
  }, [setInitAuthCompleted, dispatch]);

  // Once the JWT token has been refreshed (or failed to refresh), attempt
  // to retrieve the user's details. This will only run if the refresh token
  // request was successful and the user is marked as authenticated
  useEffect(() => {
    if (!initAuthCompleted) return;

    if (!isAuthenticated) {
      setUserDetailsRetrieved(true);
      return;
    }

    const fetchUserProfile = async () => {
      await dispatch(profileActions.fetchUserProfile());
      setUserDetailsRetrieved(true);

      dispatch(discourseActions.getNotifications());
    };

    fetchUserProfile().then(() => {
      /**
       * This is necessary to help with discourse data
       * TODO: Check this is needed after hooking discourse
       * data up to notifications in backend
       */
      dispatch(cohortActions.list({ fetchNextPage: true }));
    });
  }, [initAuthCompleted, isAuthenticated, dispatch]);

  // Discourse Init
  const discourseUserId = useSelector(
    (state: GlobalState) => state.user.discourseUser.user?.id
  );
  const userApiKey = useSelector(
    (state: GlobalState) => state.user.userDetails.discourseUserApiKey
  );

  // If the user is authenticated and we have both a Discourse
  // API key and Discourse user ID for them, initialise the message
  // bus with the user's details
  useEffect(() => {
    if (userApiKey && discourseUserId) {
      messageBus.init(userApiKey, discourseUserId, dispatch);
    }
  }, [userApiKey, discourseUserId]);

  // Toast Init
  const toastUI = useSelector((state: GlobalState) => state.ui.toast);
  const toast = useToast();
  // If the application toast UI state changes, trigger the display of
  // a toast in the correct format (success/error)
  useEffect(() => {
    if (toastUI.error) {
      if (!toast.isActive('toast-error')) {
        toast({
          id: 'toast-error',
          title: 'An error occurred.',
          description: toastUI.errorMessage || undefined,
          status: 'error',
          duration: 2000,
          isClosable: true,
        });
      }

      // The following timeout allows us to prevent the taost
      // error to display multiple times, e.g in case we get multiple
      // failures when fetching data for a single page
      setTimeout(() => {
        dispatch(uiAction.clearToastMessage);
      }, 4000);
    } else if (toastUI.success) {
      if (!toast.isActive('toast-success')) {
        toast({
          id: 'toast-success',
          title: toastUI.successMessage || 'Success',
          status: 'success',
          duration: 2000,
          isClosable: true,
        });
      }
      dispatch(uiAction.clearToastMessage);
    }
  }, [
    toast,
    toastUI.error,
    toastUI.errorMessage,
    toastUI.success,
    toastUI.successMessage,
  ]);

  if (!initAuthCompleted || !userDetailsRetrieved || !baseDataFetched) {
    return null;
  }

  return (
    <Router history={history}>
      {/* @ts-ignore */}
      <Sentry.ErrorBoundary fallback={FallbackError} showDialog>
        <InitPage>
          <RoutesRenderer
            isAuthenticated={isAuthenticated}
            isUserAccessLoading={!userAccessDataFetched}
          />
        </InitPage>
      </Sentry.ErrorBoundary>
    </Router>
  );
};

export default AppRouter;
