import React from 'react';
import { animated } from 'react-spring';

import { Flex, FlexProps } from '@workshop/ui';

const Animated = animated(Flex);

type RefType =
  | ((instance: HTMLDivElement | null) => void)
  | React.RefObject<HTMLDivElement>
  | null
  | undefined;

const AnimatedFlex: React.FC<FlexProps & { ref?: RefType }> = ({
  style = {},
  children,
  ref,
  ...props
}) => (
  <Animated style={style} ref={ref} {...props}>
    {children}
  </Animated>
);

export default AnimatedFlex;
