import * as React from 'react';
import { Link } from 'react-router-dom';

import { Flex, Text, MdIcon } from '@workshop/ui';

interface Props {
  icon?: string;
  iconComponent?: React.ReactNode;
  disabled?: boolean;
  label?: string;
  labelComponent?: React.ReactNode;
  linkTo?: string;
  onClick?: () => void;
  selected?: boolean;
  minimised?: boolean;
  color?: string;
  hoverColor?: string;
}

const SidebarLink: React.FC<Props> = ({
  icon,
  iconComponent = null,
  disabled = false,
  label,
  labelComponent = null,
  linkTo = '#',
  onClick,
  selected = false,
  minimised = false,
  color = 'text.light',
  hoverColor = 'background.tint2',
}) => {
  const content = (
    <>
      {icon ? (
        <Flex padding={2}>
          <MdIcon
            name={icon}
            boxSize="icon"
            color={color}
            opacity={disabled ? 0.5 : 1}
          />
        </Flex>
      ) : null}
      {iconComponent}
      {!minimised ? (
        <Flex ml={4} flex={1} alignItems="center" justifyContent="flex-end">
          {label ? (
            <Text
              flex={1}
              color={color}
              opacity={disabled ? 0.5 : 1}
              fontWeight="semibold"
              fontSize="sm"
              pr={1}
              noOfLines={2}
            >
              {label}
            </Text>
          ) : null}
          {labelComponent}
          {disabled && (
            <Text color="text.muted" fontSize="2xs" ml={1}>
              Coming Soon
            </Text>
          )}
        </Flex>
      ) : null}
    </>
  );
  return disabled ? (
    <Flex padding={2} alignItems="center" cursor="not-allowed">
      {content}
    </Flex>
  ) : (
    <Flex
      flex={1}
      _hover={{ backgroundColor: hoverColor }}
      onClick={onClick}
      borderRightWidth={selected ? 4 : 0}
      borderRightColor="common.primary"
      backgroundColor={selected ? 'background.tint3' : 'transparent'}
    >
      <Link to={linkTo}>
        <Flex padding={2} alignItems="center">
          {content}
        </Flex>
      </Link>
    </Flex>
  );
};

export default SidebarLink;
