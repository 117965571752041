import * as React from 'react';
import {
  Flex,
  Text,
  Skeleton,
  LinkButton,
  AlertDialogIcon,
} from '@workshop/ui';

import {
  EditCard,
  DynamicList,
  LabelInput,
  useDynamicList,
  IDynamicTable,
} from 'components/Common';
import { IDownload } from 'types/cms';
import isURL from 'validator/lib/isURL';

interface DownloadsCardProps {
  teacherOnly?: boolean;
  helpText?: string;
  items: IDownload[];
  onSave: (formData: IDynamicTable) => Promise<void>;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
}

const noop = () => null;

const googleDocRegex =
  /^https:\/\/docs\.google\.com\/document\/d\/.*\/export\?format=pdf$/;

const sortItems = (a: IDownload, b: IDownload) => {
  if (a.id && b.id) {
    return a.id - b.id;
  }
  return 0;
};

const DownloadsCard: React.FC<DownloadsCardProps> = ({
  teacherOnly = false,
  items = [],
  helpText,
  onSave,
  onCancel = noop,
  isDisabled = false,
  isUpdating,
  isLoading = false,
}) => {
  const rules = {
    name: (value: string) => !Boolean(value === ''),
    link: (value: string) => Boolean(isURL(value)),
  };

  const { formData, setReset, isValid, isDirty, setDirty, ...dynamicProps } =
    useDynamicList(rules);

  const onSubmit = async () => {
    const newFormData = Object.values(formData).reduce(
      (acc, c, idx) => ({
        ...acc,
        [idx]: {
          ...c,
          ...(c.name && c.link ? { teacherOnly: teacherOnly.toString() } : {}),
        },
      }),
      {} as IDynamicTable
    );
    await onSave(newFormData);
    setReset(true);
  };

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        setReset(true);
      }}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
      saveDisabled={!isValid || !isDirty}
    >
      <Flex flex={1}>
        <Flex flexDirection="column" flex={1}>
          <DynamicList defaultValues={items.sort(sortItems)} {...dynamicProps}>
            {({
              inputId,
              inputIndex,
              value,
              itemIsFinal,
              hasInputError,
              handleChangeEvent,
              handleRemove,
            }) => {
              return (
                <Flex direction="column">
                  <Flex alignItems="center">
                    <Flex direction="column" flex={1} mr="defaultMargin">
                      <Skeleton isLoaded={!isLoading}>
                        <LabelInput
                          id={`name-${inputId}`}
                          name="name"
                          value={value?.name || ''}
                          label={inputIndex === 0 ? 'Name' : ''}
                          labelPosition="top"
                          error={hasInputError && hasInputError('name')}
                          errorMessage="This field is required"
                          placeholder="Name"
                          onChange={handleChangeEvent}
                          onPaste={handleChangeEvent}
                          isDisabled={isDisabled}
                        />
                      </Skeleton>
                    </Flex>
                    <Flex direction="column" flex={1} mr="defaultMargin">
                      <Skeleton isLoaded={!isLoading}>
                        <LabelInput
                          id={`link-${inputId}`}
                          name="link"
                          value={value?.link || ''}
                          label={inputIndex === 0 ? 'Link' : ''}
                          labelPosition="top"
                          error={hasInputError && hasInputError('link')}
                          errorMessage="Please enter a valid URL"
                          placeholder="https://"
                          onChange={handleChangeEvent}
                          onPaste={handleChangeEvent}
                          isDisabled={isDisabled}
                        />
                      </Skeleton>
                    </Flex>
                    {value?.link && googleDocRegex.test(value.link) && (
                      <Flex
                        direction="column"
                        justifyContent="center"
                        mr="defaultMargin"
                      >
                        <LinkButton
                          secondary
                          href={value.link.replace(/\/export\?format=pdf$/, '')}
                          mt={inputIndex === 0 ? 4 : 0}
                          mb={inputIndex === 0 ? 0 : 4}
                          target="_blank"
                          rel="noopener noreferrer"
                          size="sm"
                        >
                          Open Google Doc
                        </LinkButton>
                      </Flex>
                    )}
                    {value?.link && Boolean(isURL(value.link)) && (
                      <Flex
                        direction="column"
                        justifyContent="center"
                        mr="defaultMargin"
                      >
                        <LinkButton
                          variant="ghost"
                          mt={inputIndex === 0 ? 4 : 0}
                          mb={inputIndex === 0 ? 0 : 4}
                          icon="OpenInNew"
                          href={value.link}
                          target="_blank"
                          rel="noopener noreferrer"
                          size="sm"
                        />
                      </Flex>
                    )}
                    <Flex direction="column" justifyContent="center">
                      {!isLoading && !isDisabled && !itemIsFinal && (
                        <AlertDialogIcon
                          name="RemoveCircle"
                          alertHeader="Remove Download"
                          alertBody="Are you sure you would like to remove this download?"
                          submitBtnLabel="Remove"
                          submitBtnColor="red"
                          onSubmit={() => {
                            if (handleRemove) {
                              handleRemove();
                              setDirty(true);
                            }
                          }}
                          onCancel={() => {}}
                          color={isLoading ? 'neutral.300' : 'red.300'}
                          cursor={isLoading ? 'default' : 'pointer'}
                          mt={inputIndex === 0 ? 4 : 0}
                          mb={inputIndex === 0 ? 0 : 4}
                          boxSize="18px"
                          minW="18px"
                          minH="18px"
                        />
                      )}
                    </Flex>
                  </Flex>
                </Flex>
              );
            }}
          </DynamicList>
        </Flex>
      </Flex>
      {!isLoading && !isDisabled && helpText && (
        <Flex
          flex={1}
          flexDirection="column"
          marginBottom="defaultMargin"
          alignItems="center"
        >
          <Text fontSize="xs" color="text.muted" marginBottom="defaultMargin">
            {helpText}
          </Text>
        </Flex>
      )}
    </EditCard>
  );
};

export default DownloadsCard;
