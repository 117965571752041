import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import isEmail from 'validator/lib/isEmail';

import { GlobalState } from 'types';
import { SignupData } from 'types/common';

import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Input,
  Radio,
  RadioGroup,
  Text,
  Skeleton,
  VStack,
  Divider,
  Link,
} from '@workshop/ui';

import { authActions, uiAction } from 'redux/actions/common';
import { useAuthError } from 'redux/selectors/auth';

import FacebookButton, {
  FacebookResponse,
} from 'components/SocialAuth/FacebookButton';
import AppleButton, { AppleResponse } from 'components/SocialAuth/AppleButton';

interface SignupFormData extends Omit<SignupData, 'marketingConsent'> {
  marketingConsent?: number;
}

interface SignupFormProps {
  buttonLabel?: string;
  email?: string;
  isLoading?: boolean;
  onSubmit: (data: SignupData) => Promise<void>;
  hideSocialButtons?: boolean;
}

const SignupForm: React.FC<SignupFormProps> = ({
  buttonLabel = 'Sign Up',
  email,
  isLoading: isLoadingProp = false,
  onSubmit,
  hideSocialButtons = false,
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [facebookLoading, setFacebookLoading] = React.useState(false);
  const [appleLoading, setAppleLoading] = React.useState(false);

  const { register, handleSubmit, errors, setValue, triggerValidation } =
    useForm<SignupFormData>({
      defaultValues: { email },
    });

  const dispatch = useDispatch();
  const { errorMessage } = useAuthError();
  const isLoading = useSelector(({ auth }: GlobalState) => auth.loading);

  const onSubmitData = handleSubmit(
    async ({ email: formEmail, marketingConsent, name, password1 }) => {
      setIsSubmitting(true);
      await onSubmit({
        email: email || formEmail,
        marketingConsent: marketingConsent === 1,
        name,
        password1,
      });
      setIsSubmitting(false);
    }
  );

  useEffect(() => {
    register('marketingConsent', {
      required: { value: true, message: 'Please select one of these options' },
    });
  }, []);

  const handleFacebookLogin = async (result: FacebookResponse) => {
    if ('status' in result) {
      // TODO: Handle error - should be handled by onError
      return;
    }

    setFacebookLoading(true);
    const { accessToken } = result;
    await dispatch(authActions.socialLogin('facebook', { accessToken }));
    setFacebookLoading(false);
  };

  const handleAppleLogin = async (result: AppleResponse) => {
    if ('error' in result) {
      // TODO: Handle error - should be handled by onError
      return;
    }

    // TODO: TODO: Don't know how to handle
    if ('code' in result) return;

    setAppleLoading(true);
    const { code, id_token } = result.authorization;

    // When the user signs in via Apple for the first time their
    // user details are included in the response from Apple.
    //
    // In all subsequery requests, their details are omitted. In
    // this scenario the user will receive an error when trying
    // to login with Apple if we weren't successful in creating
    // their account the first time around
    //
    // TODO: Come up with a better way of handling the above?
    let userDetails: {
      name: { firstName: string | null; lastName: string | null };
      email: string | null;
    } = {
      name: { firstName: null, lastName: null },
      email: null,
    };

    if (result.user) {
      const { user } = result;
      userDetails = { name: user.name, email: user.email };
    }

    await dispatch(
      authActions.socialLogin('apple', {
        accessToken: code,
        idToken: id_token,
        ...userDetails,
      })
    );
    setAppleLoading(false);
  };

  const handleChange = () => {
    if (errorMessage) {
      dispatch(authActions.clearAuthError);
    }
  };

  return (
    <Flex flex={1} width="100%">
      <Flex flexDirection="column" flex={1}>
        <Skeleton isLoaded={!isLoading && !isLoadingProp}>
          <Flex flexDirection="column">
            {email ? (
              <Text textAlign="center" color="text.muted" mb={2}>
                {email}
              </Text>
            ) : (
              <FormControl isInvalid={Boolean(errors.email)}>
                <Input
                  ref={register({
                    required: true,
                    validate: (value) => isEmail(value),
                  })}
                  id="email"
                  name="email"
                  onChange={handleChange}
                  placeholder="Email"
                  type="email"
                />
                <FormErrorMessage>
                  Please enter a valid email address
                </FormErrorMessage>
              </FormControl>
            )}
          </Flex>
        </Skeleton>

        <Flex flexDirection="column" mb={2} mt={2}>
          <FormControl isInvalid={Boolean(errors.name)}>
            <Input
              ref={register({
                required: {
                  value: true,
                  message: 'Please enter your full name',
                },
              })}
              id="name"
              name="name"
              onChange={handleChange}
              placeholder="Full Name"
              type="name"
            />
            <FormErrorMessage>{errors.name?.message}</FormErrorMessage>
          </FormControl>
        </Flex>

        <Flex flexDirection="column" mb={2}>
          <FormControl isInvalid={Boolean(errors.password1)}>
            <Input
              ref={register({
                required: {
                  value: true,
                  message: 'Please enter your password',
                },
                minLength: {
                  value: 6,
                  message: 'Your password must be at least 6 characters',
                },
              })}
              id="password1"
              name="password1"
              onChange={handleChange}
              placeholder="Password"
              type="password"
            />
            <FormErrorMessage>{errors.password1?.message}</FormErrorMessage>
          </FormControl>
        </Flex>
        <FormControl isInvalid={Boolean(errors.marketingConsent)}>
          <RadioGroup
            id="marketingConsent"
            name="marketingConsent"
            mt={2}
            mb="defaultMargin"
            onChange={async (e) => {
              await setValue('marketingConsent', Number(e));
              // If there was previously an error with marketing consent,
              // trigger form validation now that a change has occurred.
              Boolean(errors.marketingConsent) && triggerValidation();
            }}
          >
            <Radio value="1" mb={2}>
              <Text fontSize="sm">
                Yes, I'd like to receive exclusive discounts and updates from
                Workshop. I can opt out at any time.
              </Text>
            </Radio>
            <Radio value="0">
              <Text fontSize="sm">
                No, I don't want to receive exclusive discounts and updates from
                Workshop.
              </Text>
            </Radio>
          </RadioGroup>
          <FormErrorMessage>
            {errors.marketingConsent?.message}
          </FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={Boolean(errorMessage)}>
          <FormErrorMessage>{errorMessage}</FormErrorMessage>
        </FormControl>
        <Button
          fontSize="md"
          isDisabled={isLoading || isSubmitting}
          isLoading={isLoading || isSubmitting}
          mt={2}
          onClick={onSubmitData}
          textTransform="initial"
          type="submit"
          variant="solid"
          colorScheme="blue"
        >
          {buttonLabel}
        </Button>
        <Divider marginY="defaultMargin" />
        {!hideSocialButtons && (
          <VStack justifyContent="center" pb={4}>
            <FacebookButton
              isLoading={facebookLoading}
              isDisabled={facebookLoading}
              callback={handleFacebookLogin}
              onError={() =>
                dispatch(
                  uiAction.errorToastMessage(
                    'Unable to log in. Please try again.'
                  )
                )
              }
            />
            <AppleButton
              isLoading={appleLoading}
              isDisabled={appleLoading}
              callback={handleAppleLogin}
              onError={() =>
                dispatch(
                  uiAction.errorToastMessage(
                    'Unable to log in. Please try again.'
                  )
                )
              }
            />
          </VStack>
        )}
        <Text textAlign="center" fontSize="sm" mt={2}>
          By signing up, you agree to our{' '}
          <Link
            color="text.info"
            href="https://www.workshop.ws/terms/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of Service
          </Link>{' '}
          and{' '}
          <Link
            color="text.info"
            href="https://www.workshop.ws/privacy-policy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy
          </Link>
          .
        </Text>
      </Flex>
    </Flex>
  );
};

export default SignupForm;
