import React from 'react';
import { useDispatch } from 'react-redux';

import { Flex, Heading, Text, LinkButton } from '@workshop/ui';
import navRoutes from 'navigation/Routes';

import { SignupData } from 'types/common';
import { authActions } from 'redux/actions/common';
import { SignupCard } from 'screens/common/Signup/SignupForm';
import { BrandLg } from 'components/Brand';

const SignupScreen: React.FC = () => {
  const dispatch = useDispatch();

  const onSubmit = async ({
    email,
    marketingConsent,
    name,
    password1,
  }: SignupData) => {
    await dispatch(
      authActions.signupRequest({
        email,
        marketingConsent,
        name,
        password1,
      })
    ).then((res) => {
      if (!res.error) {
        dispatch(
          authActions.tokenRequest({ username: email, password: password1 })
        );
      }
    });
  };

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      <BrandLg mb={10} />
      <SignupCard onSubmit={onSubmit}>
        <Heading
          as="h1"
          fontSize="2xl"
          fontWeight="bold"
          mb={6}
          textAlign="center"
        >
          Create Your Account
        </Heading>
      </SignupCard>
      <Flex
        mt={4}
        py={4}
        alignItems="center"
        maxWidth={450}
        width="100%"
        paddingX={{ base: 4, md: 0 }}
        flexDirection={{ base: 'column', sm: 'row' }}
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderColor="border.default"
      >
        <Text flex={1} mb={{ base: 2, sm: 0 }}>
          Already have an account?
        </Text>
        <LinkButton to={navRoutes.public.login.path()}>Log In</LinkButton>
      </Flex>
    </Flex>
  );
};

export default SignupScreen;
