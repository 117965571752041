import React from 'react';
import { Redirect } from 'react-router-dom';
import { useLocation, useHistory } from 'react-router';

import { Flex, Heading, Text, LinkButton } from '@workshop/ui';

import { getParamFromUrl } from 'utils';
import navRoutes from 'navigation/Routes';
import { useIsAuthenticated } from 'redux/selectors/auth';

import { LoginCard } from 'screens/common/Login/LoginForm';
import { BrandLg } from 'components/Brand';

const LoginContainer = () => {
  const location = useLocation();
  const history = useHistory();

  const redirect = getParamFromUrl(location, 'redirect');

  const isAuthenticated = useIsAuthenticated();
  if (isAuthenticated) {
    return <Redirect to={navRoutes.common.home.path()} />;
  }

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      <BrandLg mb={10} />
      <LoginCard onLoginSuccess={() => redirect && history.push(redirect)}>
        <Heading
          as="h1"
          fontSize="2xl"
          fontWeight="bold"
          mb={6}
          textAlign="center"
        >
          Welcome Back 👋
        </Heading>
      </LoginCard>
      <Flex
        mt={4}
        py={4}
        alignItems="center"
        maxWidth={450}
        width="100%"
        paddingX={{ base: 4, md: 0 }}
        flexDirection={{ base: 'column', sm: 'row' }}
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderColor="border.default"
      >
        <Text flex={1} mb={{ base: 2, sm: 0 }}>
          New to Workshop?
        </Text>
        <LinkButton to={navRoutes.public.signup.path()}>
          Create an Account
        </LinkButton>
      </Flex>
    </Flex>
  );
};

export default LoginContainer;
