import React from 'react';

import { Card } from '@workshop/ui';
import LoginForm from './LoginForm';

interface LoginCardProps {
  email?: string;
  isLoading?: boolean;
  onLoginSuccess?: () => void;
}

const LoginCard: React.FunctionComponent<LoginCardProps> = ({
  children,
  email,
  isLoading,
  onLoginSuccess,
}) => (
  <Card
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    maxWidth={450}
    width="100%"
    padding={{ base: 6, sm: 10 }}
  >
    {children}
    <LoginForm
      email={email}
      isLoading={isLoading}
      onLoginSuccess={onLoginSuccess}
    />
  </Card>
);

export default LoginCard;
