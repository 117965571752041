import React from 'react';
import ReactDatePicker from 'react-datepicker';
import { Message } from 'react-hook-form';
import moment from 'moment';

import { FormLabelProps, BoxProps } from '@workshop/ui';

import { Tooltip } from 'components/IconTooltip';

import LabelWrapper from './LabelWrapper';

import 'react-datepicker/dist/react-datepicker.css';

import './LabelDatePicker.css';

export interface SelectOption {
  [key: string]: string;
}

interface LabelDatePickerProps {
  id: string;
  // date?: string;
  date: Date | null;
  error?: boolean;
  errorMessage?: Message;
  helpText?: string;
  helpTextPosition?: 'underline' | 'inline';
  isLoading?: boolean;
  label?: string;
  labelPosition?: 'top' | 'inline';
  labelStyleProps?: FormLabelProps;
  loadingStyle?: BoxProps;
  onChange: (date: Date | null) => void;
  tooltip?: Tooltip;
  emptyValue?: string;
}

const LabelDatePicker = ({
  id,
  date,
  error = false,
  errorMessage,
  helpText,
  helpTextPosition = 'underline',
  isLoading = false,
  label,
  labelPosition = 'inline',
  labelStyleProps = {},
  loadingStyle,
  onChange,
  emptyValue = 'None',
}: LabelDatePickerProps) => {
  return (
    <LabelWrapper
      inputId={id}
      label={label}
      helpText={helpText}
      helpTextPosition={helpTextPosition}
      labelPosition={labelPosition}
      labelStyleProps={labelStyleProps}
      isInvalid={error}
      errorMessage={errorMessage}
      isLoading={isLoading}
      loadingStyle={loadingStyle}
    >
      <ReactDatePicker
        wrapperClassName="label-date-picker"
        value={date ? moment(date).format('D MMM YYYY') : emptyValue}
        selected={date}
        onChange={onChange}
      />
    </LabelWrapper>
  );
};

export default LabelDatePicker;
