import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import { connect, ConnectedProps, useDispatch } from 'react-redux';

import {
  Flex,
  Box,
  Text,
  Heading,
  Spinner,
  Alert,
  AlertIcon,
} from '@workshop/ui';

import { GlobalState } from 'types';
import { SignupData } from 'types/common';
import { hooks } from 'utils';
import navRoutes from 'navigation/Routes';

import { authActions, organisationActions } from 'redux/actions/common';
import { useIsAuthenticated } from 'redux/selectors/auth';

import { SignupCard } from 'screens/common/Signup/SignupForm';
import { LoginCard } from 'screens/common/Login/LoginForm';

import { BrandLg } from 'components/Brand';

// Routing Props
interface MatchParams {
  invitationKey: string;
}

// Props passed to our component from parents
interface OwnProps extends RouteComponentProps<MatchParams> {}

// Props passed to our component via redux
type PropsFromRedux = ConnectedProps<typeof connector>;

// Combined props we're passing to our component
interface Props extends OwnProps, PropsFromRedux {}

const AcceptInvitation: React.FC<Props> = ({
  invitation,
  history,
  match: {
    params: { invitationKey },
  },
  userId,
}) => {
  const dispatch = useDispatch();
  const [error, setError] = useState<string | null>(null);
  const isAuthenticated = useIsAuthenticated();

  const [invitationLoading, response] = hooks.useLoadingData(() =>
    organisationActions.retrieveMemberInvitation(invitationKey)
  );

  const redirectUrl = invitation?.redirectUrl
    ? `${invitation.redirectUrl}?t=${invitation.team.id}`
    : navRoutes.common.home.path();

  useEffect(() => {
    if (!isAuthenticated || invitationLoading) return;

    if (userId && userId !== invitation?.user) {
      // Invitation has been fetched and is for a different user
      // --> log out user
      dispatch(authActions.tokenDeleteRequest());
    }

    if (userId === invitation?.user) {
      // Invitation has been fetched and user is already logged in
      // --> redirect them
      history.push(navRoutes.common.home.path());
    }
  }, [isAuthenticated, invitationLoading, userId, invitation]);

  useEffect(() => {
    // If the initial retrieve invitation request failed, determine why and
    // show a relevant error message to the user
    if (response && response.error) {
      const { payload } = response;
      if (payload && 'status' in payload) {
        const { status } = payload;

        if (status === 410) {
          setError('This invitation has expired.');
        } else {
          setError('This invitation is invalid. Redirecting...');
          setTimeout(() => history.push(navRoutes.common.home.path()), 1500);
        }
      }
    } else {
      setError(null);
    }
  }, [response]);

  const onSignUp = async ({
    email,
    marketingConsent,
    name,
    password1,
  }: SignupData) => {
    await dispatch(
      authActions.signupRequest({
        email,
        marketingConsent,
        name,
        password1,
      })
    ).then(({ error }) => {
      if (error) return;

      dispatch(
        authActions.tokenRequest({ username: email, password: password1 })
      ).then(() => history.push(redirectUrl));
    });
  };

  if ((isAuthenticated && invitationLoading) || userId === invitation?.user) {
    return (
      <Flex
        position="absolute"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        width="100vw"
        height="100vh"
        backgroundColor="background.tint3"
      >
        <Spinner size="xl" />
      </Flex>
    );
  }

  const welcomeHeading = (
    <Box>
      <Heading
        as="h1"
        fontSize="2xl"
        fontWeight="bold"
        mb={6}
        textAlign="center"
      >
        {invitation?.team
          ? `Join ${invitation.team.name} on Workshop`
          : 'Join Your Team on Workshop'}
      </Heading>
      {invitation?.message ? (
        <>
          <Box
            backgroundColor="background.tint2"
            padding={4}
            mb={6}
            borderRadius="md"
          >
            <Text
              textAlign="center"
              // fontWeight="semibold"
              color="text.muted"
              mb={1}
            >
              Invite Message
            </Text>
            <Text fontStyle="italic" textAlign="center">
              {invitation.message}
            </Text>
          </Box>
        </>
      ) : null}
    </Box>
  );

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      <BrandLg mb={10} />
      {error && (
        <Alert status="error" mb={[2, 3]}>
          <AlertIcon />
          {error}
        </Alert>
      )}
      {invitation?.user || invitation?.accepted ? (
        <LoginCard
          isLoading={invitationLoading}
          email={invitation?.email}
          onLoginSuccess={async () => {
            // Accept the invitation then redirect
            await dispatch(
              organisationActions.acceptMemberInvitation(invitationKey)
            );
            history.push(redirectUrl);
          }}
        >
          {welcomeHeading}
        </LoginCard>
      ) : (
        <SignupCard
          isLoading={invitationLoading}
          email={invitation?.email}
          onSubmit={onSignUp}
          hideSocialButtons
        >
          {welcomeHeading}
        </SignupCard>
      )}
    </Flex>
  );
};

const mapStateToProps = (state: GlobalState, props: OwnProps) => {
  const { invitations } = state.organisation;
  const invitation =
    invitations &&
    Object.values(invitations).find(
      (i) => i.key === props.match.params.invitationKey
    );

  return {
    invitation,
    userId: state.user.userDetails.id,
  };
};

const connector = connect(mapStateToProps);

export default connector(AcceptInvitation);
