import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Box } from '@workshop/ui';

import { Organisation, PERMISSION_SLUGS } from 'types/common';

import { useHasPermission } from 'redux/selectors/organisation';
import { EditCard, LabelInput } from 'components/Common';

interface BillingDetailsCardProps {
  accountName?: string;
  accountNumber?: number;
  sortCode?: string;
  vatNumber?: number;
  isLoading?: boolean;

  onSubmit: (args: Partial<Organisation>) => Promise<void>;
}

const BillingDetailsCard: React.FC<BillingDetailsCardProps> = (props) => {
  const isEditingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_billing_details
  );
  const { isLoading = false, onSubmit } = props;

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<Partial<Organisation>>({
    defaultValues: {
      accountName: props.accountName,
      accountNumber: props.accountNumber,
      sortCode: props.sortCode,
      vatNumber: props.vatNumber?.toString(),
    },
  });

  const [submitting, setSubmitting] = useState<boolean>(false);

  useEffect(() => {
    reset({
      accountName: props.accountName,
      accountNumber: props.accountNumber,
      sortCode: props.sortCode,
      vatNumber: props.vatNumber?.toString(),
    });
  }, [props.accountName, props.accountNumber, props.sortCode, props.vatNumber]);

  const onSubmitData = handleSubmit(async (data) => {
    setSubmitting(true);
    await onSubmit(data);

    reset(data, { dirty: false });
    setSubmitting(false);
  });

  return (
    <EditCard
      onSave={onSubmitData}
      onCancel={() => {
        clearError();

        reset({
          accountName: props.accountName,
          accountNumber: props.accountNumber,
          sortCode: props.sortCode,
          vatNumber: props.vatNumber?.toString(),
        });
      }}
      isUpdating={submitting}
      isDisabled={isEditingDisabled}
      saveDisabled={Boolean(!dirty || !isEmpty(errors))}
    >
      <Box flex={1}>
        <LabelInput
          id="accountName"
          name="accountName"
          isDisabled={isEditingDisabled}
          label="Account name"
          error={Boolean(errors.accountName)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelInput
          id="accountNumber"
          inputType="number"
          isDisabled={isEditingDisabled}
          name="accountNumber"
          label="Account number"
          error={Boolean(errors.accountNumber)}
          errorMessage="Please enter a valid number."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelInput
          id="sortCode"
          name="sortCode"
          isDisabled={isEditingDisabled}
          label="Sort code"
          error={Boolean(errors.sortCode)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelInput
          id="vatNumber"
          name="vatNumber"
          isDisabled={isEditingDisabled}
          label="VAT number"
          error={Boolean(errors.vatNumber)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register({ required: false })}
        />
      </Box>
    </EditCard>
  );
};

export default BillingDetailsCard;
