import React from 'react';
import { Box, Flex, LinkButton, Heading, MdIcon } from '@workshop/ui';
import { HeaderTag } from 'components/AppHeader';

interface Props {
  buttonLabel?: string;
  buttonLink?: string;
  image: string;
  onClick?: () => void;
  tag?: string;
  tagBg?: string;
  tagColor?: string;
  title: string;
}

const ItemLarge: React.FC<Props> = ({
  title,
  tag,
  tagBg = 'background.tint1',
  tagColor = 'text.muted',
  onClick,
  buttonLabel,
  buttonLink,
  image,
}) => (
  <Flex padding="defaultPadding" alignItems="center">
    <Flex mr={4}>
      <Box
        overflow="hidden"
        borderRadius="md"
        backgroundColor="background.tint3"
      >
        {image ? (
          <Flex
            backgroundImage={`url(${image})`}
            backgroundSize="cover"
            backgroundPosition="center"
            height="image.md"
            width="image.md"
          />
        ) : (
          <Flex
            height="image.md"
            width="image.md"
            flex="1 0"
            alignItems="center"
            justifyContent="center"
          >
            <MdIcon name="CropOriginal" color="text.muted" boxSize={8} />
          </Flex>
        )}
      </Box>
    </Flex>
    <Flex flex={1} flexDirection="column" mr="defaultMargin">
      <Heading as="h3" fontWeight="semibold" fontSize="lg">
        {title}
      </Heading>
      {tag ? (
        <Flex mt={2}>
          <HeaderTag title={tag} bg={tagBg} color={tagColor} />
        </Flex>
      ) : null}
    </Flex>
    <Flex
      alignItems="center"
      justifyContent="flex-end"
      display={{ base: 'none', md: 'flex' }}
    >
      <Box>
        {buttonLabel && buttonLink && (
          <LinkButton size="sm" secondary to={buttonLink}>
            {buttonLabel}
          </LinkButton>
        )}
      </Box>
    </Flex>
  </Flex>
);

export default ItemLarge;
