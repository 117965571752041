import React from 'react';
import { Message } from 'react-hook-form';
import ResizeTextarea from 'react-textarea-autosize';

import { Box, Textarea, FormLabelProps, TextareaProps } from '@workshop/ui';
import { IconTooltip, Tooltip } from 'components/IconTooltip';

import LabelWrapper from './LabelWrapper';

interface LabelTextAreaProps extends TextareaProps {
  id: string;
  isDisabled?: boolean;
  defaultValue?: string;
  error?: boolean;
  errorMessage?: Message;
  helpText?: string;
  isLoading?: boolean;
  label: string;
  name: string;
  registerInputRef?: RegisterFunc<HTMLTextAreaElement>;
  unit?: string;
  labelPosition?: 'top' | 'inline';
  labelStyleProps?: FormLabelProps;
  tooltip?: Tooltip;
  maxLength?: number;
  autoResize?: boolean;
}

const LabelTextArea: React.FC<LabelTextAreaProps> = ({
  id,
  isDisabled = false,
  defaultValue = '',
  error = false,
  errorMessage,
  helpText,
  isLoading = false,
  label,
  name,
  registerInputRef,
  unit,
  labelPosition = 'inline',
  labelStyleProps = {},
  tooltip,
  maxLength = 1000,
  autoResize = false,
  ...rest
}) => {
  return (
    <LabelWrapper
      labelPosition={labelPosition}
      labelStyleProps={labelStyleProps}
      errorMessage={errorMessage}
      helpText={helpText}
      inputId={id}
      isInvalid={error}
      isLoading={isLoading}
      label={label}
      labelAlign="flex-start"
      unit={unit}
    >
      <Box position="relative">
        {/* @ts-ignore */}
        <Textarea
          id={id}
          ref={registerInputRef}
          isDisabled={isDisabled}
          name={name}
          maxLength={maxLength}
          defaultValue={defaultValue}
          {...(autoResize
            ? {
                as: ResizeTextarea,
                minH: 'unset',
                overflow: 'hidden',
                resize: 'none',
                minRows: 1,
              }
            : {})}
          {...rest}
        />
        {tooltip && (
          <Box position="absolute" bottom={2} right={2}>
            <IconTooltip tooltip={tooltip} />
          </Box>
        )}
      </Box>
    </LabelWrapper>
  );
};

export default LabelTextArea;
