import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import isEmail from 'validator/lib/isEmail';

import { Flex, Button, MdIcon } from '@workshop/ui';
import { EditCard, LabelInput, LabelSelect } from 'components/Common';

interface AccountBasicsForm {
  email: string;
  name: string;
  country: string;
}

interface AccountBasicsProps {
  email: string;
  name: string;
  country: string;
  unverifiedEmail: string | null;
  isLoading?: boolean;
  countryOptions: {
    [key: string]: string;
  };
  onSubmit: (args: AccountBasicsForm) => void;
  onCancelEmailVerification: () => void;
  onResendVerificationEmail: () => void;
}

const AccountBasics: React.FC<AccountBasicsProps> = (props) => {
  const {
    countryOptions,
    isLoading = false,
    name,
    email,
    unverifiedEmail,
    country,
    onSubmit,
    onCancelEmailVerification,
    onResendVerificationEmail,
  } = props;
  const [state, setState] = useState({
    isSubmitting: false,
    isResending: false,
    isCancelling: false,
  });

  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState: { dirty },
    reset,
  } = useForm<AccountBasicsForm>({
    defaultValues: {
      email: unverifiedEmail || email,
      name,
      country,
    },
  });

  const onSubmitData = handleSubmit(async (data) => {
    setState((prevState) => ({ ...prevState, isSubmitting: true }));
    await onSubmit(data);

    reset(data);
    setState((prevState) => ({ ...prevState, isSubmitting: false }));
  });

  const handleCancelEmailVerification = async () => {
    setState((prevState) => ({ ...prevState, isCancelling: true }));
    await onCancelEmailVerification();
    setState((prevState) => ({ ...prevState, isCancelling: false }));
    // On cancelling email change we revert the email value
    // back to the original verified email
    reset({ email });
  };

  const handleResendVerificationEmail = async () => {
    setState((prevState) => ({ ...prevState, isResending: true }));
    await onResendVerificationEmail();
    setState((prevState) => ({ ...prevState, isResending: false }));
  };

  // If the users current email and unverified email are equal, then it
  // must mean that they haven't verified their original email address
  // used during registration.
  const isEmailInitial = email === unverifiedEmail;

  return (
    <EditCard
      onSave={onSubmitData}
      onCancel={() => {
        clearError();

        reset({
          email,
          name,
          country,
        });
      }}
      isUpdating={state.isSubmitting}
      saveDisabled={Boolean(!dirty || !isEmpty(errors))}
    >
      <Flex flexDir="column">
        <LabelInput
          registerInputRef={register({
            required: true,
            validate: {
              isEmail: (value: string) => Boolean(isEmail(value)),
            },
          })}
          id="email"
          name="email"
          label="Email"
          error={Boolean(errors.email)}
          errorMessage="Please enter a valid email address."
          isLoading={isLoading}
          loadingStyle={{ width: '75%' }}
          isDisabled={!!unverifiedEmail}
          helpText={
            isEmailInitial
              ? `Your email address is still pending verification.`
              : unverifiedEmail
              ? `${unverifiedEmail} is still pending verification. Until it is verified,` +
                ` all emails will be sent to ${email}. Check your email (${unverifiedEmail})` +
                ' to confirm your new email address.'
              : undefined
          }
          rightIcon={
            unverifiedEmail ? (
              <MdIcon color="text.warning" name="Warning" />
            ) : undefined
          }
        />
        {unverifiedEmail && (
          <Flex
            justifyContent="flex-end"
            mb="defaultMargin"
            mt={{ base: 6, sm: 3 }}
          >
            <Button
              onClick={handleResendVerificationEmail}
              size="sm"
              isLoading={state.isResending}
            >
              Resend Verification Email
            </Button>
            {!isEmailInitial && (
              <Button
                onClick={handleCancelEmailVerification}
                size="sm"
                isLoading={state.isCancelling}
                ml={2}
              >
                Cancel this change
              </Button>
            )}
          </Flex>
        )}
        <LabelInput
          id="name"
          name="name"
          label="Name"
          error={Boolean(errors.name)}
          errorMessage="Please enter a valid name."
          isLoading={isLoading}
          registerInputRef={register({ required: true })}
        />
        <LabelSelect
          id="country"
          name="country"
          label="Country"
          error={Boolean(errors.country)}
          errorMessage="This information is required."
          isLoading={isLoading}
          registerInputRef={register}
          options={countryOptions}
          defaultValue={country ? countryOptions[country] : undefined}
        />
      </Flex>
    </EditCard>
  );
};

export default AccountBasics;
