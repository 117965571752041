import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { hooks } from 'utils';

const InitPage: React.FC<any> = ({ children }) => {
  // Set document title & scroll to top on route change

  const { pathname } = useLocation();
  const currentRoute = hooks.useCurrentRoute();

  useEffect(() => {
    document.title = currentRoute?.name
      ? `${currentRoute?.name} · Workshop`
      : 'Workshop';
    if (currentRoute?.scrollTopDisabled) return;

    window.scrollTo(0, 0);
  }, [pathname]);

  return children;
};

export default InitPage;
