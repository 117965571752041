import { api, baseUrl } from './env';

export const buildQueryParamsFromArray = (
  queryParams: string[],
  key: string
): string => queryParams.map((q) => `${key}=${q}`).join('&');

const auth = {
  impersonate: (userId: number) => `${baseUrl}/internal/impersonate/${userId}/`,
  stopImpersonating: `${baseUrl}/internal/impersonate/stop/`,
  // API for retrieving JWT token
  login: `${api}/token/`,
  // API for refreshing JWT token
  refreshToken: `${api}/token/refresh/`,
  // API for logging the user in
  accountLogin: `${api}/login/`,
  // API for logging out
  logout: `${api}/token/delete/`,
  facebookLogin: `${api}/socialLogin/facebook/`,
  signup: `${api}/register/`,
  // API for changing password
  changePassword: `${api}/user/password/change/`,
  // API for resetting password
  resetPassword: (key?: string, uidb36?: string) =>
    key && uidb36
      ? `${api}/user/password/reset/key/${uidb36}-${key}/`
      : `${api}/user/password/reset/`,
  // API for getting all the social providers Workshop supports
  socialProviders: `${api}/socialProviders/`,
  // API for getting all the social providers associated with the user
  socialConnections: `${api}/user/socialConnections/`,
  // API for removing a user's connection with a specific social provider
  removeSocialConnection: `${api}/user/socialConnection/remove/`,
  // API for deleting the user's account
  deleteAccount: `${api}/user/delete/`,
  // API for connecting a Social Account
  connectSocialAccount: (provider: string, params: string) =>
    `${api}/socialConnect/${provider}${params}`,
  // API for logging in with Social Providers
  socialLogin: (provider: string) => `${api}/socialLogin/jwt/${provider}/`,
  // API for connecting Social Providers
  socialConnect: (provider: string) =>
    `${api}/socialConnect/${provider}/?process=connect`,
};

const account = {
  userProfile: `${api}/userProfile/`,
  userLibrary: `${api}/userProfile/library/`,
  userWorkshopRelationships: `${api}/userProfile/workshopRelationships/`,
  mentorProfile: `${api}/mentorProfile/`,
  mentorApplication: (id?: number) =>
    id
      ? `${api}/mentorProfile/application/${id}/`
      : `${api}/mentorProfile/application/`,
  notificationPreferences: `${api}/userProfile/notificationPreferences/`,
  userCoupons: `${api}/userCoupons/`,
  redeemCoupon: `${api}/redeemCoupon/`,
  subscriptions: `${api}/userProfile/subscriptions/`,
  paymentSettings: `${api}/userProfile/paymentSettings/`,
  // API for resending the email verification
  resendVerificationEmail: `${baseUrl}/account/resend-verification/`,
  // API for cancelling an email change request
  cancelEmailChange: `${baseUrl}/account/email/cancel/`,
};

const common = {
  categories: () => `${api}/categories/`,
  zendeskRequest: `${api}/support/messages/`,
};

const organisation = {
  list: `${api}/organisations/`,
  create: `${api}/organisations/create`,
  roles: `${api}/organisations/roles`,
  permissions: `${api}/organisations/permissions`,
  teamMembers: (id?: number) =>
    `${api}/teams/{team}/members${id ? `/${id}` : ''}`,
  listCohorts: `${api}/organisations/{team}/cohorts`,
  updateCohort: (cohortId: number) =>
    `${api}/organisations/{team}/cohorts/${cohortId}`,
  licenses: `${api}/organisations/{team}/licenses`,
  updateLicense: (licenseId: number) =>
    `${api}/organisations/{team}/licenses/${licenseId}`,
  enrolments: `${api}/organisations/{team}/enrolments`,
  updateEnrolment: (enrolmentId: number) =>
    `${api}/organisations/{team}/enrolments/${enrolmentId}`,
  costRecords: `${api}/organisations/{team}/costRecords`,
  courseProgress: (courseProgressId: number) =>
    `${api}/organisations/{team}/courseProgress/${courseProgressId}`,
  cohortCourseProgress: (cohortId: number) =>
    `${api}/organisations/{team}/cohorts/${cohortId}/courseProgress`,
  userCourseProgress: (username: string) =>
    `${api}/organisations/{team}/cohortParticipant/${username}/courseProgress`,
  teamMemberInvitation: () => `${api}/organisations/{team}/invite`,
  resendInvitation: (id: number) =>
    `${api}/organisations/{team}/invite/${id}/resend/`,
  invitation: (invitationKey?: string) => `${api}/invitation/${invitationKey}`,
  myTeams: `${api}/teams`,
  organisationProfile: (id?: number) => `${api}/teams/${id}/organisation`,
};

const analytics = {
  getData: (queryParams: string) =>
    `${api}/analytics/{team}${queryParams.length ? `?${queryParams}` : ''}`,
};

const room = {
  create: `${api}/teams/{team}/rooms/`,
  get: (roomId: string) => `${api}/rooms/${roomId}/`,
  update: (roomPk: number) => `${api}/rooms/${roomPk}/`,
  devices: (roomPkOrId: number | string) =>
    `${api}/rooms/${roomPkOrId}/devices/`,
  updateDevice: (deviceId: string) => `${api}/devices/${deviceId}/`,
};

const cms = {
  settings: `${api}/mentor/settings/`,
  /* -----------------------  Mentor CMS Courses ----------------------- */
  // To use CallAPIWithTeam => insert team as a string {team}
  mentorCourses: `${api}/mentor/{team}/courses`,
  assessment: (id: number) => `${api}/assessments/${id}/`,
  cohort: `${api}/mentor/{team}/cohort`,
  cohortMentor: `${api}/mentor/{team}/cohortMentor`,
  cohortAssessments: (cohortId: number) =>
    `${api}/assessments/cohort/${cohortId}`,
  course: (id: number) => `${api}/mentor/{team}/courses/${id}`,
  courseUnits: (id: number) => `${api}/mentor/{team}/courses/${id}/units`,
  myCohorts: `${api}/courses/myCohorts/`,
  unit: (id: number) => `${api}/mentor/{team}/units/${id}`,
  prompt: `${api}/mentor/{team}/prompt`,
  sessions: (course: number, unit: number) =>
    `${api}/mentor/{team}/courses/${course}/units/${unit}/sessions`,
  stepMCQ: (
    course: number,
    unit: number,
    session: number,
    step: number,
    questionId?: number
  ) =>
    questionId
      ? `${api}/mentor/{team}/courses/${course}/units/${unit}/sessions/${session}/steps/${step}/mcq/${questionId}`
      : `${api}/mentor/{team}/courses/${course}/units/${unit}/sessions/${session}/steps/${step}/mcq`,
  questions: (questionIds: number[]) => {
    const ids = questionIds.map((a) => a.toString());
    const queryParams = buildQueryParamsFromArray(ids, 'ids[]');

    return `${api}/mentor/{team}/questions?${queryParams}`;
  },
  session: (id: number) => `${api}/mentor/{team}/sessions/${id}`,
  sessionSteps: (course: number, unit: number, session: number) =>
    `${api}/mentor/{team}/courses/${course}/units/${unit}/sessions/${session}/steps`,
  step: (id: number) => `${api}/mentor/{team}/sessionSteps/${id}`,
  videoClips: (course: number, unit: number, session: number, step: number) =>
    `${api}/mentor/{team}/courses/${course}/units/${unit}/sessions/${session}/steps/${step}/clips`,
  sessionVideoClips: (course: number, unit: number, session: number) =>
    `${api}/mentor/{team}/courses/${course}/units/${unit}/sessions/${session}/clips`,
  videoClip: (id: number) => `${api}/mentor/{team}/videoClips/${id}`,
  // API for uploading chunks of files
  chunkUpload: (uploadId?: string | null) => {
    return uploadId
      ? `${api}/mentor/upload/${uploadId}/`
      : `${api}/mentor/upload/`;
  },
  previewEmail: () => `${api}/organisations/{team}/sendTestEmail/`,
};

const learner = {
  cohort: (courseSlug: string) => `${api}/courses/${courseSlug}/myCohort/`,
  course: (slug?: string | number) =>
    slug ? `${api}/courses/${slug}/` : `${api}/courses/`,
  courseProgress: (slug?: string) =>
    slug ? `${api}/courseProgress/${slug}` : `${api}/courseProgress/`,
  courseSchedule: (slug: string) => `${api}/courses/${slug}/mySchedule/`,
  goalProgress: `${api}/weeklyGoal/progress/`,
  myCohorts: `${api}/courses/myCohorts/`,
  questions: (questionIds: number[]) => {
    const ids = questionIds.map((a) => a.toString());
    const queryParams = buildQueryParamsFromArray(ids, 'ids[]');

    return `${api}/questions?${queryParams}`;
  },
  updateUserModuleProgress: (courseSlug: string, moduleSlug: string) =>
    `${api}/courseProgress/${courseSlug}/module/${moduleSlug}/`,
  updateUserUnitProgress: (courseSlug: string, unitSlug: string) =>
    `${api}/courseProgress/${courseSlug}/unit/${unitSlug}/`,
  updateCourseModuleSchedule: (courseSlug: string, moduleId: number) =>
    `${api}/courses/${courseSlug}/mySchedule/${moduleId}/`,
  userCheckList: `${api}/userChecklist/`,
  userJournal: `${api}/userJournal/`,
  userJournalEntry: (id?: number) =>
    id
      ? `${api}/userJournal/journalEntry/${id}/`
      : `${api}/userJournal/journalEntry/`,
  userJournalEntries: `${api}/userJournal/list/`,
  submitAnswers: `${api}/answers`,
  addRating: `${api}/rating/add/`,
  workshop: (slug: string, detail?: boolean) =>
    detail ? `${api}/workshop/${slug}/detail/` : `${api}/workshops/${slug}`,
  workshops: `${api}/workshops/`,
  dashboard: `${api}/dashboard/`,
};

export default {
  auth,
  account,
  cms,
  common,
  learner,
  organisation,
  analytics,
  room,
};
