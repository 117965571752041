import { useEffect, useState } from 'react';
import isURL from 'validator/lib/isURL';

// TODO: Fix TS issues around this package
// @ts-ignore
import 'material-dynamic-colors';

import { MaterialColors } from 'types/common';

import { useTheme } from '@workshop/ui';

// Input can be image or primary color hex code
// See https://www.npmjs.com/package/material-dynamic-colors
const useColors = (input: string | null | undefined) => {
  const [colors, setColors] = useState<MaterialColors | null>(null);
  const theme = useTheme();

  const getMaterialColors = async (i: string) => {
    try {
      // @ts-ignore
      const materialColors = await materialDynamicColors(i);
      setColors(materialColors);
    } catch {
      // @ts-ignore
      const defaultMaterialColors = await materialDynamicColors(
        theme.colors.common.primary
      );
      setColors(defaultMaterialColors);
    }
  };

  useEffect(() => {
    if (input) {
      getMaterialColors(input);
    }
  }, [input]);

  return colors;
};

export default useColors;
