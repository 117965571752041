import React, { useState, useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  Spinner,
} from '@workshop/ui';

import { MCQFormData, MCQFormDefaultValues } from 'components/ListItem';
import { DynamicList, LabelSelect, LabelInput } from 'components/Common';

import { HandleChangeEvent } from 'components/Common/DynamicList';
import { Choice } from 'components/ListItem/types';

import { createNamedImports } from 'typescript';

const validateChoicesField = (choices: { isCorrect: boolean }[]) => {
  if (!choices || choices.length < 2) {
    return 'Please enter at least 2 answers';
  }
  if (!choices.find((c) => c.isCorrect)) {
    return 'Please select at least one correct answer';
  }
  return true;
};

interface MCQFormProps {
  isDisabled?: boolean;
  defaultValues?: MCQFormDefaultValues;
  onSubmit: (args: MCQFormData) => Promise<any>;
  resetAfterSubmit?: boolean;
}

const MultipleChoiceForm: React.FC<MCQFormProps> = ({
  isDisabled = false,
  defaultValues,
  onSubmit,
  resetAfterSubmit = false,
}) => {
  /** Temporary fix to bring back focus to
   *  the last input added by the dynamic list */
  const [focusedId, setFocusedId] = useState<string | null>(null);

  const {
    errors,
    formState: { dirty, isSubmitting },
    getValues,
    handleSubmit,
    register,
    reset,
    setValue,
    triggerValidation,
  } = useForm<MCQFormDefaultValues>({
    defaultValues: defaultValues || {},
  });

  const values: MCQFormDefaultValues = getValues();

  const registerChoices = useCallback(() => {
    register({ name: 'choices' }, { validate: validateChoicesField });
  }, []);

  useEffect(registerChoices, []);

  /** Temporary fix to bring back focus to
   *  the last input added by the dynamic list */
  useEffect(() => {
    if (focusedId === null) return;

    setTimeout(() => setFocusedId(null), 250);
  }, [focusedId]);

  const submitData = (data: MCQFormData) =>
    onSubmit({
      choiceOrder: data.choiceOrder,
      explanation: data.explanation,
      question: data.question,
      /** If a choice id includes item__ this item is new and needs to be created
       *  by the backend - therefore we don't send the id */

      choices: data.choices?.map((c) =>
        typeof c.id === 'string' && c.id.includes('item__')
          ? { text: c.text, isCorrect: c.isCorrect }
          : { ...c }
      ),
    })
      .then(() => {
        if (resetAfterSubmit) {
          // Resetting all values
          Object.keys(values).forEach((k) => setValue(k, undefined));
          return;
        }

        reset({ choices: data.choices }, { dirty: false });
      })
      .then(registerChoices);

  /**
   * Manually set the text values for the choices
   * after a change in the dynamic list
   */
  const handleChoiceChange =
    (
      defaultValue: Choice | undefined,
      id: string,
      handleChangeEvent: HandleChangeEvent
    ) =>
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      handleChangeEvent(e);

      const text = e.target.value;
      let choices = values.choices ? [...values.choices] : [];

      /** Check if we are removing an existing item or adding/updating */
      const removeItem = !Boolean(text?.length);

      /** Look for the relevant choice in the existing values */
      const foundChoice = choices.find((c) =>
        defaultValue?.id ? c.id === defaultValue.id : c.id === id
      );

      if (removeItem) {
        /** Remove the found item from the list of choices */
        choices = choices.filter((c) => c.id !== foundChoice?.id);
      } else {
        /** Either update the text for the existing item, or add a new one */
        choices = foundChoice
          ? choices.map((c) =>
              c.id === foundChoice.id ? { ...c, text } : { ...c }
            )
          : [...choices, { id, text, isCorrect: false }];
      }

      await setValue('choices', choices);

      triggerValidation();
      if (!removeItem) setFocusedId(id);
    };

  /**
   * Manually set the isCorrect value for the choices
   * after a change in the dynamic list
   */
  const handleToggleChoiceCorrect =
    (defaultValue: Choice | undefined, id: string) =>
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      let choices = values.choices ? [...values.choices] : [];

      /** Look for the relevant choice in the existing values */
      const foundChoice = choices.find((c) =>
        defaultValue?.id ? c.id === defaultValue.id : c.id === id
      );

      /** Toggle the isCorrect value for the relevant choice item */
      choices = foundChoice
        ? choices.map((c) =>
            c.id === foundChoice.id
              ? { ...c, isCorrect: e.target.checked }
              : { ...c }
          )
        : [...choices];

      await setValue('choices', choices);
      triggerValidation();
    };

  return (
    <Flex
      borderRadius="md"
      flex={1}
      marginBottom={2}
      overflow="hidden"
      padding={2}
    >
      <Flex flex={1} flexDirection="column" position="relative">
        <LabelInput
          isDisabled={isDisabled}
          id="question"
          backgroundColor="background.default"
          error={Boolean(errors.question)}
          errorMessage={errors.question && 'Please enter a question'}
          label="Question"
          name="question"
          onChange={() => triggerValidation()}
          registerInputRef={register({
            required: true,
          })}
        />
        <FormControl isInvalid={Boolean(errors.choices)}>
          <DynamicList defaultValues={values?.choices || []}>
            {({
              value,
              handleChangeEvent: handleChange,
              inputId: itemId,
              inputIndex,
              itemIsFinal,
            }) => {
              const id = value?.id || itemId;
              const name = `item__${inputIndex}`;

              return (
                <LabelInput
                  isDisabled={isDisabled}
                  backgroundColor="background.default"
                  value={value?.text || ''}
                  key={`mcq-${id}`}
                  id={name}
                  name={name}
                  label={`Answer ${inputIndex + 1}`}
                  onChange={handleChoiceChange(value, name, handleChange)}
                  registerInputRef={(inputEl: HTMLInputElement | null) => {
                    if (!inputEl || itemIsFinal) return;
                    register(inputEl);
                    if (focusedId === id) inputEl.focus();
                  }}
                >
                  <Checkbox
                    isDisabled={isDisabled || itemIsFinal}
                    defaultIsChecked={value?.isCorrect || false}
                    minW="200px"
                    ml={2}
                    name={`${name}__correct`}
                    onChange={handleToggleChoiceCorrect(value, name)}
                  >
                    Correct answer
                  </Checkbox>
                </LabelInput>
              );
            }}
          </DynamicList>
          <Flex mt={-4} mb={4}>
            <Box minW={24} flex={1} />
            <FormErrorMessage
              color="text.error"
              flex={4}
              fontFamily="body"
              fontSize="xs"
            >
              {errors.choices?.message}
            </FormErrorMessage>
          </Flex>
        </FormControl>
        <LabelSelect
          isDisabled={isDisabled}
          id="choiceOrder"
          helpText="
          The order in which choices are displayed to the user.
          Select “Random” to shuffle choices, select “Index” to display them in the order
          written above."
          helpTextPosition="inline"
          label="Choice Order"
          name="choiceOrder"
          options={{ random: 'Random', index: 'Index' }}
          registerInputRef={register}
        />

        <LabelInput
          isDisabled={isDisabled}
          id="explanation"
          backgroundColor="background.default"
          helpText="Explanation to be shown after the question has been answered."
          label="Explanation (Optional)"
          name="explanation"
          registerInputRef={register}
        />

        {!isDisabled && (
          <Flex flex={1} justifyContent="flex-end">
            <Button
              variant="solid"
              colorScheme="blue"
              isDisabled={
                isSubmitting ||
                !dirty ||
                Boolean(errors.choices || errors.question)
              }
              minW={20}
              onClick={handleSubmit((d) => submitData(d as MCQFormData))}
            >
              {isSubmitting ? <Spinner boxSize="sm" speed="0.75s" /> : 'Save'}
            </Button>
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};

export default MultipleChoiceForm;
