import React, { useState, useEffect, useRef } from 'react';
import ReactPlayer from 'react-player';
import QRCode from 'react-qr-code';
import { useDispatch } from 'react-redux';
import { saveAs } from 'file-saver';

import { videoClipActions } from 'redux/actions/cms';

import styled, {
  Box,
  Flex,
  MdIcon,
  Text,
  Spinner,
  Button,
  Image,
  Input,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@workshop/ui';

import { STATUS } from 'constants/background';
import { useUploadList } from 'redux/selectors/background';
import { hooks } from 'utils';

import { UploadStatus } from 'components/AppHeader/UploadStatusButton';

import { ClipProgress, VideoClipsPlayerProps, VideoPlayerProps } from './types';

const orientationStyleMapping = {
  portrait: {
    width: '100%',
    paddingTop: '177.7%',
  },
  landscape: {
    width: '100%',
    height: '0',
    paddingTop: '28.2%',
    paddingBottom: '28.2%',
  },
};

const containerStyle = {
  position: 'absolute',
  top: 0,
  bottom: 0,
  width: '100%',
  height: '100%',
} as const;

const StyledVideo = styled.div`
  width: 100%;
  height: 100%;
  video {
    object-fit: cover;
  }
`;

const timeout = (ms: number) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
};

const VideoPlayer: React.FC<VideoPlayerProps> = ({
  id,
  autoplay,
  autoPlayNext = false,
  clip,
  nextIdx,
  currentIdx,
  orientation,
  stepsTotal,
  isEditable = false,
  onEdit = () => null,
  qrBlob,
}) => {
  const fileInput = useRef<HTMLInputElement>();
  const { onClickNext, onClickPrev, showNextBtn, showPrevBtn, src, summary } =
    clip;
  const orientationStyles = orientationStyleMapping[orientation];

  const playerRef = useRef<ReactPlayer>(null);

  const dispatch = useDispatch();

  const [clipProgress, setClipProgress] = useState<ClipProgress>({
    // Amount of video loaded & played (%)
    loaded: 0,
    played: 0,
    // Amount of video loaded & played (seconds)
    loadedSeconds: 0,
    playedSeconds: 0,
  });

  const [clipDuration, setClipDuration] = useState(0);
  const [clipLoaded, setClipLoaded] = useState(false);

  const [isPlaying, setIsPlaying] = useState(autoplay);

  // Init state for handling the animating/transitioning of video clips
  // on clip change.
  const [screenshot, setScreenshot] = useState('');
  const [screenshotOpacity, setScreenshotOpacity] = useState('0%');
  const [clipTranslations, setClipTranslations] = useState({
    screenshotTranslateX: '0%',
    videoTranslateX: '0%',
  });
  const [isTransitioning, setIsTransitioning] = useState(false);

  const changeClip = async (direction: 'next' | 'previous') => {
    /**
     * To create a swiping animation from clip to clip, we take a screenshot
     * of the current state of the video and position the resulting image over the
     * video, we then run a string of CSS changes to move the video (which will contain
     * a new clip source) to the side of the screenshot, then animate the image and
     * video together to create the illusion of swiping from one clip to another
     * (animations are set via css "transitions" on the relevant elements)
     */
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    const video = playerRef.current?.getInternalPlayer();

    // Canvas screenshot doesn't currently work on Mac Safari, so skip animation on Safari
    // @ts-ignore
    const isSafari = window.safari !== undefined;
    if (isSafari) return;
    setIsTransitioning(true);
    if (context && video) {
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      // "Double request animation frame" to ensure smooth animation
      requestAnimationFrame(() => {
        requestAnimationFrame(async () => {
          // Wrapped in a 'try' as it occasionally fails
          try {
            context.drawImage(
              video as HTMLVideoElement,
              0,
              0,
              video.videoWidth,
              video.videoHeight
            );
            const dataURI = canvas.toDataURL();
            // Init transition by pausing the video and showing the screenshot overlay
            setScreenshot(dataURI);
            setIsPlaying(false);
            setScreenshotOpacity('100%');
            await timeout(100);
            // Start transition
            setClipTranslations({
              screenshotTranslateX: '0%',
              videoTranslateX: direction === 'next' ? '100%' : '-100%',
            });
            await timeout(500);
            requestAnimationFrame(() =>
              setClipTranslations({
                screenshotTranslateX: direction === 'next' ? '-100%' : '100%',
                videoTranslateX: '0%',
              })
            );
            await timeout(500);
            // Restart the video playing and reset transition state
            setIsPlaying(true);
            setScreenshotOpacity('0%');
            setScreenshot('');
            await timeout(100);
            setClipTranslations({
              screenshotTranslateX: '0%',
              videoTranslateX: '0%',
            });
          } catch {}
        });
      });
    }
    setIsTransitioning(false);
  };

  // If the `nextIdx` prop changes, animate to the next clip
  // TODO: Prevent this on "step" change - usePreviousValue
  useEffect(() => {
    if (nextIdx !== currentIdx) {
      changeClip(nextIdx > currentIdx ? 'next' : 'previous');
    }
  }, [nextIdx, currentIdx]);

  useEffect(() => {
    /**
     * Start playing the video if autoplay is set to true
     * after changing the video source
     */
    if (!src || !autoplay || isPlaying) return;

    setIsPlaying(true);
  }, [src]);

  hooks.useDeepEqualEffect(() => {
    /**
     * Runs after handleOnProgress
     * (every 100ms - see progressInterval prop on ReactPlayer)
     */

    /**
     * Move to the next clip and start playing it automatically:
     * - if autoPlayNext is enabled
     * - after the current clip has finished playing (i.e, clipProgress.played === 1)
     * - if there is another clip
     */
    if (!autoPlayNext || clipProgress.played !== 1) return;
    if (!stepsTotal || currentIdx + 1 >= stepsTotal) return;

    onClickNext && onClickNext();
    setIsPlaying(true);
  }, [clipProgress]);

  const progressPercent = clipProgress.played * 100;

  // Setup video player callbacks
  const handleOnProgress = (args: ClipProgress) => setClipProgress(args);

  const handleOnDuration = (duration: number) => {
    setClipLoaded(true);
    setClipDuration(duration);
  };

  // If there is an in progress or completed video upload related to this
  // video clip, then extract it from the state so that we're able to display
  // upload progress metadata.
  const uploadList = useUploadList();
  const clipUpload = Object.values(uploadList).find(
    ({ metadata }) =>
      metadata && 'id' in metadata && metadata.id === id.toString()
  );

  // If an upload exists for this clip and the status isn't `completed`
  // then we have an in progress upload
  const uploadInProgress = clipUpload && clipUpload.status !== STATUS.completed;

  return (
    <Box {...orientationStyles} position="relative">
      <Flex
        {...containerStyle}
        alignItems="center"
        backgroundColor="black"
        justifyContent="center"
        zIndex={0}
      >
        <StyledVideo>
          {screenshot ? (
            <Image
              src={screenshot}
              opacity={screenshotOpacity}
              position="absolute"
              width="100%"
              height="100%"
              objectFit="cover"
              transform={`translateX(${clipTranslations.screenshotTranslateX})`}
              transition="opacity 0.1s, transform 0.5s ease-in-out"
              backgroundColor="background.tint4"
              zIndex={1}
            />
          ) : null}
          {(!isEditable || src) && !clipLoaded && (
            <Flex
              alignItems="center"
              backgroundColor="neutralAlpha.200"
              height="100%"
              justifyContent="center"
              width="100%"
              zIndex={1}
            >
              <Spinner color="neutral.700" />
            </Flex>
          )}
          {uploadInProgress && clipUpload && (
            <Flex
              width="100%"
              height="100%"
              alignItems="center"
              justifyContent="center"
              flexDir="column"
              zIndex={1}
            >
              <Spinner color="neutral.700" />
              <Flex maxW="400px" mt={6}>
                <UploadStatus
                  id={id.toString()}
                  upload={clipUpload}
                  displayText
                  light
                />
              </Flex>
            </Flex>
          )}
          <Flex
            width="calc(100% + 2px)"
            height="100%"
            transform={`translateX(${clipTranslations.videoTranslateX})`}
            transition="transform 0.5s ease-in-out"
          >
            {isEditable && !src ? (
              <Flex
                width="100%"
                height="100%"
                backgroundColor="background.tint3"
                alignItems="center"
                justifyContent="center"
                flexDir="column"
              >
                {qrBlob ? (
                  <Flex alignItems="center" flexDir="column" m={4}>
                    <Text
                      textAlign="center"
                      fontSize="sm"
                      fontWeight="semibold"
                      maxWidth={150}
                      color="text.muted"
                      pb={4}
                    >
                      Scan in the app to film from your phone:
                    </Text>
                    <Box
                      backgroundColor="white"
                      borderRadius="md"
                      p={2}
                      boxShadow="lg"
                      mb={4}
                    >
                      {/* @ts-ignore */}
                      <QRCode value={qrBlob} size={128} />
                    </Box>
                    <Button
                      icon="Refresh"
                      secondary
                      onClick={() =>
                        dispatch(videoClipActions.retrieve(id as number))
                      }
                      size="sm"
                      backgroundColor="transparent"
                    >
                      Reload Clip
                    </Button>
                  </Flex>
                ) : (
                  <MdIcon name="Movie" color="icon.muted" boxSize={16} m={4} />
                )}
                <Button
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPlaying(false);
                    fileInput.current?.click();
                  }}
                  icon="FileUpload"
                  fontSize="sm"
                  isLoading={uploadInProgress}
                  isDisabled={uploadInProgress}
                >
                  Upload Video from File
                </Button>
              </Flex>
            ) : (
              // @ts-ignore
              <ReactPlayer
                ref={playerRef}
                width="100%"
                height="100%"
                onDuration={handleOnDuration}
                onProgress={handleOnProgress}
                playing={isPlaying}
                progressInterval={100}
                url={src}
                playsinline
              />
            )}
          </Flex>
        </StyledVideo>
      </Flex>
      {clipLoaded && (!isEditable || src) && (
        <Flex
          {...containerStyle}
          alignItems="flex-end"
          justifyContent="center"
          pb={7}
          role="group"
        >
          {/** CLIP CONTROLS (play/pause/skip) */}
          <Box
            {...containerStyle}
            height="100%"
            zIndex={2}
            backgroundColor="neutralAlpha.400"
            onClick={() => setIsPlaying(!isPlaying)}
            pointerEvents={uploadInProgress ? 'none' : 'auto'}
            opacity={!isPlaying || progressPercent === 100 ? 1 : 0}
            transition="all 200ms ease-in"
            _groupHover={{ base: {}, md: { opacity: 1 } }}
          >
            <Flex
              {...containerStyle}
              flexDirection="column"
              justifyContent="center"
            >
              {Boolean(progressPercent === 100 && !isTransitioning) && (
                <>
                  {Boolean(summary) && (
                    <Flex
                      px={4}
                      pb={6}
                      mx="auto"
                      display={{ base: 'flex', lg: 'none' }}
                    >
                      <Text
                        color="text.light"
                        fontWeight="semibold"
                        textAlign="center"
                      >
                        {summary}
                      </Text>
                    </Flex>
                  )}
                  <Flex
                    mx="auto"
                    display={{ base: 'flex', lg: 'none' }}
                    mb={20}
                  >
                    {Boolean(showPrevBtn && onClickPrev) && (
                      <Button secondary mx={2} mt={2} onClick={onClickPrev}>
                        Previous
                      </Button>
                    )}
                    {Boolean(showNextBtn && onClickNext) && (
                      <Button
                        mx={2}
                        mt={2}
                        minWidth="100px"
                        onClick={onClickNext}
                      >
                        Next
                      </Button>
                    )}
                  </Flex>
                </>
              )}
              {Boolean(progressPercent !== 100) && (
                <Flex alignItems="center" paddingX={4}>
                  <Box
                    backgroundColor="neutralLightAlpha.300"
                    _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                    borderRadius="50%"
                    cursor="pointer"
                    padding={1}
                    onClick={(e) => {
                      e.stopPropagation();
                      // Move 10 second backward
                      let prevTenSeconds =
                        (playerRef.current?.getCurrentTime() || 0) - 10;

                      playerRef.current?.seekTo(prevTenSeconds);
                    }}
                  >
                    <MdIcon name="Replay10" color="white" boxSize={8} />
                  </Box>
                  <Flex flex={1} justify="center">
                    <Box
                      backgroundColor="neutralLightAlpha.300"
                      _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                      borderRadius="50%"
                      cursor="pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsPlaying(!isPlaying);
                      }}
                      padding={1}
                    >
                      <MdIcon
                        color="white"
                        name={isPlaying ? 'Pause' : 'PlayArrow'}
                        boxSize={16}
                      />
                    </Box>
                  </Flex>
                  <Box
                    backgroundColor="neutralLightAlpha.300"
                    _hover={{ backgroundColor: 'neutralLightAlpha.400' }}
                    borderRadius="50%"
                    cursor="pointer"
                    padding={1}
                    onClick={(e) => {
                      e.stopPropagation();
                      // Move 10 second forward
                      let nextTenSeconds =
                        (playerRef.current?.getCurrentTime() || 0) + 10;
                      if (nextTenSeconds < clipDuration) {
                        playerRef.current?.seekTo(nextTenSeconds);
                        return;
                      }
                      playerRef.current?.seekTo(clipDuration);
                      setIsPlaying(false);
                    }}
                  >
                    <MdIcon name="Forward10" color="white" boxSize={8} />
                  </Box>
                </Flex>
              )}
            </Flex>
            <Flex
              bottom={0}
              alignItems="center"
              flexDir="column"
              mb={4}
              pb={12}
              position="absolute"
              width="100%"
            >
              <Flex
                alignItems="center"
                backgroundColor="neutralLightAlpha.300"
                _hover={{ backgroundColor: 'neutralLightAlpha.200' }}
                borderRadius="50px"
                cursor="pointer"
                justifyContent="center"
                mb={4}
                px={4}
                py={2}
                onClick={(e) => {
                  e.stopPropagation();
                  playerRef.current?.seekTo(0);
                  setIsPlaying(true);
                }}
              >
                <MdIcon name="Replay" mr={1} color="white" boxSize={5} />
                <Text color="white" fontSize="sm" fontWeight="bold">
                  Restart
                </Text>
              </Flex>
              {isEditable && (
                <Button
                  secondary
                  mt={4}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsPlaying(false);
                    fileInput.current?.click();
                  }}
                  icon="Cached"
                  fontSize="sm"
                  isLoading={uploadInProgress}
                  isDisabled={uploadInProgress}
                >
                  Replace Video
                </Button>
              )}
            </Flex>
            {isEditable && (
              <Flex
                position="absolute"
                top={0}
                right={0}
                cursor="pointer"
                px={4}
                py={4}
                onClick={(e) => {
                  e.stopPropagation();
                  setIsPlaying(false);
                  // Download video
                  const filename = src.substring(src.lastIndexOf('/') + 1);
                  saveAs(src, filename);
                }}
              >
                <MdIcon name="Download" color="white" boxSize={5} />
              </Flex>
            )}
          </Box>

          <Flex width="100%" alignItems="center" zIndex={3}>
            <Flex width="20%" justifyContent="center" h={7}>
              {Boolean(showPrevBtn && onClickPrev) && (
                <Flex
                  display={{ base: 'flex', lg: 'none' }}
                  backgroundColor="neutralLightAlpha.300"
                  _hover={{ backgroundColor: 'neutralLightAlpha.200' }}
                  borderRadius="full"
                  cursor="pointer"
                  p={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickPrev && onClickPrev();
                  }}
                >
                  <MdIcon name="ArrowBack" color="white" boxSize={5} />
                </Flex>
              )}
            </Flex>
            {/** CLIP(s) PROGRESS INDICATORS */}
            <Flex width="60%" justifyContent="center" alignItems="center">
              {Array.from(Array(stepsTotal)).map((c, idx) =>
                idx === currentIdx ? (
                  <Slider
                    key={`slider-${idx}`}
                    aria-label="Video-progress"
                    marginX={1}
                    flex={1}
                    focusThumbOnChange={false}
                    value={progressPercent}
                    onChange={(value) => {
                      playerRef.current?.seekTo(value / 100, 'fraction');
                    }}
                  >
                    <SliderTrack
                      height={2}
                      backgroundColor="neutralLightAlpha.400"
                    >
                      <SliderFilledTrack
                        backgroundColor="common.progress"
                        transition="width 100ms linear"
                      />
                    </SliderTrack>
                    <SliderThumb
                      opacity={0}
                      transition="opacity 200ms ease-in, left 100ms linear"
                      _groupHover={{ opacity: progressPercent === 100 ? 0 : 1 }}
                    />
                  </Slider>
                ) : (
                  <Box
                    key={`indicator-${idx}`}
                    backgroundColor={
                      idx < currentIdx
                        ? 'common.progress'
                        : 'neutralLightAlpha.500'
                    }
                    borderRadius="4px"
                    height={2}
                    marginX={1}
                    width={2}
                  />
                )
              )}
            </Flex>
            <Flex width="20%" justifyContent="center" h={7}>
              {Boolean(showNextBtn && onClickNext) && (
                <Flex
                  display={{ base: 'flex', lg: 'none' }}
                  backgroundColor="neutralLightAlpha.300"
                  _hover={{ backgroundColor: 'neutralLightAlpha.200' }}
                  borderRadius="full"
                  cursor="pointer"
                  p={1}
                  onClick={(e) => {
                    e.stopPropagation();
                    onClickNext && onClickNext();
                  }}
                >
                  <MdIcon name="ArrowForward" color="white" boxSize={5} />
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      )}
      {isEditable && (
        <Input
          ref={(e: HTMLInputElement | null) => {
            if (e) {
              fileInput.current = e;
            }
          }}
          type="file"
          accept="video/mp4,video/x-m4v,video/*"
          style={{ display: 'none' }}
          onChange={(e) => onEdit(e, id.toString())}
          onClick={(e) => e.stopPropagation()}
        />
      )}
    </Box>
  );
};

const VideoClipsPlayer: React.FC<VideoClipsPlayerProps> = ({
  autoplay = true,
  autoPlayNext = false,
  currentStepIdx = 0,
  currentSessionStepId = null,
  loading = false,
  orientation = 'portrait',
  clips,
  isEditable = false,
  onEdit = () => null,
  qrBlob,
}) => {
  let startingStepIdx = currentStepIdx;
  if (startingStepIdx + 1 > clips.length) startingStepIdx = clips.length - 1;
  if (startingStepIdx < 0) startingStepIdx = 0;

  const [currentIdx, setCurrentIdx] = useState(startingStepIdx);
  const [savedSessionStepId, setSavedSessionStepId] =
    useState(currentSessionStepId);
  const [nextIdx, setNextIdx] = useState(startingStepIdx);

  // Update saved session step index when moving between session steps and if
  // our `currentStepIdx` prop changes, then navigate to that step (clip)
  useEffect(() => {
    // If the session step has changed, but other indicators show that this was the page's first render,
    // save the session step to the state without triggering a new clip animation
    if (
      ((currentStepIdx === 0 && currentIdx === 0) ||
        savedSessionStepId === null) &&
      currentSessionStepId !== savedSessionStepId
    ) {
      setSavedSessionStepId(currentSessionStepId);
      return;
    }

    // Don't do anything if the clip isn't set or hasn't changed
    if (currentStepIdx === undefined || currentStepIdx === currentIdx) {
      return;
    }

    // If the session step changes, change the current clip without a swiping animation, otherwise trigger
    // an animation when changing clips
    if (currentSessionStepId !== savedSessionStepId) {
      setNextIdx(0);
      setCurrentIdx(0);
      setSavedSessionStepId(currentSessionStepId);
    } else {
      // Set next index to trigger an animation in VideoPlayer, then set current index to switch clip
      setNextIdx(currentStepIdx);
      // Timeout to make sure the change of video clip source happens in the background while the sliding
      // animation between videos is happening over the top
      setTimeout(() => setCurrentIdx(currentStepIdx), 500);
    }
  }, [currentStepIdx, currentSessionStepId]);

  const orientationStyles = orientationStyleMapping[orientation];
  const currentStep = clips.length && clips[currentIdx];

  // Render a placeholder if no currentStep to render
  if (loading || !currentStep) {
    return (
      <Box {...orientationStyles} position="relative">
        <Flex
          {...containerStyle}
          backgroundColor="neutral.300"
          justifyContent="center"
          alignItems="center"
        >
          <Spinner color="neutral.700" />
        </Flex>
      </Box>
    );
  }

  if (currentStep.type === 'image') {
    return (
      <Box {...orientationStyles} position="relative">
        <Box
          {...containerStyle}
          backgroundImage={
            currentStep.data.src && `url(${currentStep.data.src})`
          }
          backgroundPosition="center"
          backgroundSize="cover"
        />
      </Box>
    );
  }

  return (
    <VideoPlayer
      {...currentStep.data}
      id={currentStep.id}
      autoplay={autoplay}
      autoPlayNext={autoPlayNext}
      currentIdx={currentIdx}
      nextIdx={nextIdx}
      orientation={orientation}
      stepsTotal={clips.length}
      isEditable={isEditable}
      onEdit={onEdit}
      qrBlob={qrBlob}
    />
  );
};

export default VideoClipsPlayer;
