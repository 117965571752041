// Learner schemas
import { schema } from 'normalizr';
import { UserDetails } from 'types/common';

export const CreatorInfoSchema = new schema.Entity('creator');

export const ModuleSchema = new schema.Entity(
  'modules',
  {},
  {
    idAttribute: 'slug',
    processStrategy: (value, parent) => ({ ...value, unit: parent.slug }),
  }
);
export const UnitSchema = new schema.Entity(
  'units',
  { modules: [ModuleSchema] },
  {
    idAttribute: 'slug',
    processStrategy: (value, parent, key) => ({
      ...value,
      course: parent.slug,
    }),
  }
);
export const CourseSchema = new schema.Entity(
  'course',
  {
    creatorInformation: CreatorInfoSchema,
    units: [UnitSchema],
  },
  { idAttribute: 'slug' }
);
export const LibraryCourseSchema = new schema.Entity(
  'courses',
  {},
  {
    idAttribute: 'slug',
  }
);
export const PromotionSchema = new schema.Entity(
  'promotions',
  {},
  { idAttribute: 'ref' }
);
export const WorkshopSchema = new schema.Entity(
  'workshops',
  { activePromotion: PromotionSchema },
  { idAttribute: (workshop) => workshop.slug.toLowerCase() }
);
export const LibraryEntity = new schema.Entity(
  'libraryEntry',
  {
    workshop: WorkshopSchema,
  },
  {
    idAttribute: (item) => item.workshop.slug,
  }
);
export const UserLibrarySchema = new schema.Object({
  purchased: [LibraryEntity],
  removed: [LibraryEntity],
  wishlist: [LibraryEntity],
  // TODO: Fix or remove
  // sessions: [LibraryEntity],
  courses: [LibraryCourseSchema],
});
export const UserCheckListStateSchema = new schema.Entity(
  'userCheckListState',
  {},
  { idAttribute: 'id' }
);
export const UserLessonRelationshipSchema = new schema.Entity(
  'userLessonRelationships',
  { checkListState: UserCheckListStateSchema },
  { idAttribute: (e) => e.lesson }
);
export const UserWorkshopRelationshipSchema = new schema.Entity(
  'userWorkshopRelationships',
  {
    workshop: WorkshopSchema,
    lessonState: [UserLessonRelationshipSchema],
  },
  { idAttribute: (e) => e.workshop.slug }
);
export const ModuleProgressSchema = new schema.Entity(
  'moduleProgress',
  { checkListState: UserCheckListStateSchema },
  { idAttribute: 'module' }
);
export const UnitProgressSchema = new schema.Entity(
  'unitProgress',
  {
    moduleProgress: [ModuleProgressSchema],
  },
  { idAttribute: 'unit' }
);
export const CourseProgressSchema = new schema.Entity(
  'courseProgress',
  {
    unitProgress: [UnitProgressSchema],
  },
  { idAttribute: 'course' }
);
export const LessonSchema = new schema.Entity(
  'lessons',
  {},
  { idAttribute: 'id' }
);
export const LessonEntrySchema = new schema.Entity('lessonEntry', {
  destinationContentObject: LessonSchema,
});
export const SessionSchema = new schema.Entity(
  'sessions',
  { creatorInformation: CreatorInfoSchema, sessionContent: LessonSchema },
  { idAttribute: (session) => session.id }
);
export const SessionEntrySchema = new schema.Entity('sessionEntry', {
  destinationContentObject: SessionSchema,
});
export const JournalEntrySchema = new schema.Array(
  {
    usersessionrelationships: SessionEntrySchema,
    userlessonrelationships: LessonEntrySchema,
  },
  (value) => `${value.contentType}s`
);
export const JournalSchema = new schema.Entity('userJournal', {
  entries: JournalEntrySchema,
});
export const DeviceInfoSchema = new schema.Entity('deviceInfo', {});
export const UserProfileSchema = new schema.Entity(
  'userProfile',
  {
    deviceInfo: DeviceInfoSchema,
  },
  { idAttribute: (entity, parent: UserDetails) => `${parent.id}` }
);
export const UserDetailsSchema = new schema.Entity('userDetails', {
  profile: UserProfileSchema,
});
export const ModuleScheduleSchema = new schema.Entity(
  'moduleSchedules',
  {},
  {
    idAttribute: 'module',
    processStrategy: (value, parent, key) => ({
      ...value,
      ...(parent.unit ? { unit: parent.unit } : {}),
    }),
  }
);
export const UnitScheduleSchema = new schema.Entity(
  'unitSchedules',
  {
    moduleSchedules: [ModuleScheduleSchema],
  },
  {
    idAttribute: 'unit',
    processStrategy: (value, parent, key) => ({
      ...value,
      course: parent.course,
    }),
  }
);
export const UserScheduleSchema = new schema.Entity(
  'courseSchedules',
  {
    unitSchedules: [UnitScheduleSchema],
  },
  { idAttribute: 'course' }
);

export const MCQSchema = new schema.Entity('questions', {});

export const CohortSchema = new schema.Entity(
  'cohorts',
  {},
  { idAttribute: (cohort) => `${cohort.course}` }
);

export const DiscourseCategorySchema = new schema.Entity(
  'discourseCategories',
  {},
  {
    idAttribute: (c) => c.topicList.topics[0].categoryId,
  }
);

/* Dashboard */
export const DashboardSchema = {
  courses: [CourseSchema],
  schedule: [UserScheduleSchema],
  progress: [CourseProgressSchema],
  cohorts: [CohortSchema],
  // journal: [JournalEntrySchema],
  // library: [UserLibrarySchema],
  // assessment: [AssessmentsEntity],
};
