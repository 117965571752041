import React from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import {
  Box,
  Button,
  Card,
  Divider,
  Flex,
  LinkButton,
  Skeleton,
  Text,
} from '@workshop/ui';

import { PERMISSION_SLUGS } from 'types/common';

import { useHasPermission } from 'redux/selectors/organisation';
import { organisationActions } from 'redux/actions/common';
import navRoutes from 'navigation/Routes';

interface Members {
  id: number;
  invitationSentDate: string;
  name: string;
  email: string;
  status: 'active' | 'invited';
  roles: string[];
}

interface TeamMembersCardProps {
  isLoading?: boolean;
  members: Members[];
}

const TeamMembersCard: React.FC<TeamMembersCardProps> = ({
  isLoading = false,
  members,
}) => {
  const dispatch = useDispatch();
  const isEditingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_members
  );

  return (
    <Card padding={0}>
      <Flex flexDirection="column" flex={1}>
        <Flex flexDirection="column">
          {members.map((member, idx) => {
            const { id, invitationSentDate, name, email, roles, status } =
              member;

            const sentDate = status === 'invited' && moment(invitationSentDate);
            const invitationPending24Hrs =
              sentDate && moment().diff(sentDate, 'day') > 0;

            return (
              <Flex flex={1} flexDirection="column">
                <Flex
                  flex={1}
                  alignItems="center"
                  px={{ base: 3, md: 4 }}
                  pt={idx === 0 ? 2 : 0}
                  minHeight={14}
                >
                  <Flex
                    flex={1}
                    alignItems={{ base: 'flex-start', xl: 'center' }}
                    flexDirection={{ base: 'column', xl: 'row' }}
                  >
                    <Text
                      whiteSpace="nowrap"
                      mr={2}
                      fontWeight="semibold"
                      width={{ base: '125px', sm: '170px', md: '200px' }}
                      textOverflow="ellipsis"
                      overflow="hidden"
                    >
                      {name}
                    </Text>
                    <Text
                      mr={3}
                      width={{ base: '125px', sm: '170px', md: '200px' }}
                      overflow="hidden"
                      // https://github.com/chakra-ui/chakra-ui/issues/662
                      style={{ textOverflow: 'ellipsis' }}
                      color="text.muted"
                      textOverflow="ellipsis"
                      noOfLines={1}
                      fontSize="sm"
                    >
                      {email}
                    </Text>
                  </Flex>
                  <Flex flex={1} display={{ base: 'none', md: 'flex' }}>
                    <Text color="text.muted" mr={3} fontSize="sm">
                      {roles.join(', ')}
                    </Text>
                  </Flex>
                  <Flex
                    w={{ base: '64px', sm: '80px' }}
                    justifyContent="flex-start"
                  >
                    <Text
                      fontSize="sm"
                      textTransform="capitalize"
                      color={
                        status === 'active' ? 'text.success' : 'text.warning'
                      }
                      backgroundColor={
                        status === 'active'
                          ? 'background.success'
                          : 'background.warning'
                      }
                      borderRadius="sm"
                      paddingY={1}
                      paddingX={2}
                    >
                      {status}
                    </Text>
                  </Flex>
                  {status === 'active' &&
                  !isEditingDisabled &&
                  !roles.includes('Owner') ? (
                    <LinkButton
                      to={navRoutes.cms.teamMember.path(id)}
                      ml={2}
                      minW="80px"
                      secondary
                      size="sm"
                    >
                      Edit
                    </LinkButton>
                  ) : status === 'invited' &&
                    !isEditingDisabled &&
                    !roles.includes('Owner') &&
                    invitationPending24Hrs ? (
                    <Button
                      onClick={() =>
                        dispatch(organisationActions.resendMemberInvitation(id))
                      }
                      ml={2}
                      minW="80px"
                      secondary
                      size="sm"
                    >
                      Resend
                    </Button>
                  ) : roles.includes('Owner') ? (
                    <Text
                      ml={2}
                      minW="80px"
                      color="text.muted"
                      textAlign="center"
                      fontSize="sm"
                    >
                      Owner
                    </Text>
                  ) : (
                    <Box ml={2} minW="80px" />
                  )}
                </Flex>
                {!isEditingDisabled && idx < members.length && <Divider />}
              </Flex>
            );
          })}
        </Flex>
        {!isEditingDisabled && (
          <Flex padding={2}>
            <Skeleton isLoaded={!isLoading} loadingStyle={{ width: '100%' }}>
              <LinkButton
                pl={2}
                pr={2}
                to={navRoutes.cms.inviteMember.path()}
                variant="ghost"
                boxShadow="none"
                display="flex"
                alignItems="center"
                icon="AddCircleOutline"
                colorScheme="blue"
              >
                New Member
              </LinkButton>
            </Skeleton>
          </Flex>
        )}
      </Flex>
    </Card>
  );
};

export default TeamMembersCard;
