// DEPRECATED
import * as React from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import styled, { Flex, Text, MdIcon } from '@workshop/ui';

import navRoutes from 'navigation/Routes';
import { authActions } from 'redux/actions/common';

export const NavbarButton = styled(Text)`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface Props {
  navbarButtons: Array<{
    label: React.ReactNode;
    icon?: string | null | undefined;
  }>;
}

const AppHeaderButtons: React.FC<Props> = (props) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { navbarButtons } = props;

  return (
    <Flex>
      {navbarButtons.map((btn, key) => (
        <NavbarButton
          key={key}
          marginRight={4}
          fontWeight="bold"
          color="text.dark"
        >
          {btn.icon && <MdIcon name={btn.icon} marginRight={2} />}
          {btn.label}
        </NavbarButton>
      ))}
      <NavbarButton
        onClick={async () => {
          await dispatch(authActions.tokenDeleteRequest());
          history.push(navRoutes.public.login.path());
        }}
        marginRight={4}
        fontWeight="bold"
        color="text.dark"
      >
        Sign Out
      </NavbarButton>
    </Flex>
  );
};

export default AppHeaderButtons;
