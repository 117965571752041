import React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';

import { InPageNav } from 'components/Common';

interface CourseMenuProps extends RouteComponentProps {
  courseSlug: string;
}

const CourseMenu: React.FC<CourseMenuProps> = ({ location, courseSlug }) => {
  let initialTab = 'overview';
  const currentPath = location.pathname.replace(/\/$/, '');
  if (currentPath === `/courses/${courseSlug}/browse`) {
    initialTab = 'browse';
  } else if (currentPath === `/courses/${courseSlug}/activity`) {
    initialTab = 'activity';
  } else if (currentPath === `/courses/${courseSlug}/certificate`) {
    initialTab = 'certificate';
  }
  return (
    <InPageNav
      initialTab={initialTab}
      tabs={[
        {
          slug: 'overview',
          icon: 'School',
          label: 'Overview',
          linkTo: `/courses/${courseSlug}`,
        },
        {
          slug: 'browse',
          icon: 'Search',
          label: 'Browse Course',
          linkTo: `/courses/${courseSlug}/browse`,
        },
        {
          slug: 'activity',
          icon: 'People',
          label: 'My Class',
          linkTo: `/courses/${courseSlug}/activity`,
        },
        {
          slug: 'certificate',
          icon: 'AssignmentTurnedIn',
          label: 'Certificate',
          linkTo: `/courses/${courseSlug}/certificate`,
        },
      ]}
    />
  );
};

export default withRouter(CourseMenu);
