import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import isNumeric from 'validator/lib/isNumeric';

import { hooks } from 'utils';

import { EditCard, LabelInput, LabelSelect } from 'components/Common';

import { FurtherDetailsFormData, IClassType } from 'types/cms';

interface FurtherDetailsProps extends FurtherDetailsFormData {
  categories: { [key: string]: string };
  classTypes: { [key in IClassType]: string };
  onSave: (formData: FurtherDetailsFormData) => void;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
  error?: boolean;
  category: number;
  classType: IClassType;
}

const noop = () => null;
const FurtherDetailsCard = ({
  categories,
  classTypes,
  onSave,
  onCancel = noop,
  courseDuration,
  accessDuration,
  price,
  category,
  classType,
  isDisabled = false,
  isUpdating = false,
  isLoading = false,
}: FurtherDetailsProps) => {
  const { register, handleSubmit, errors, clearError, formState, reset } =
    useForm<FurtherDetailsFormData>();

  const onSubmit = handleSubmit((formData) => {
    onSave(formData);
  });

  useEffect(() => {
    // Reset the state as default after state change (successful update)
    reset({ courseDuration, accessDuration, price, category, classType });
  }, [reset, courseDuration, accessDuration, price, category, classType]);

  hooks.useDeepEqualEffect(() => {
    // `categories` might not exist when first loaded, and since our selected
    // category is derived from `categories` we must therefore reset the form
    // when `catgories` changes or is loaded
    reset({ category });
  }, [categories]);

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        // TODO: Reset Select
        reset({ courseDuration, accessDuration, price, category, classType });
      }}
      saveDisabled={Boolean(
        !formState.dirty ||
          errors.price ||
          errors.courseDuration ||
          errors.classType
      )}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
    >
      <LabelSelect
        isDisabled={isDisabled}
        id="category"
        name="category"
        label="Category"
        error={!!errors.category}
        registerInputRef={register({
          required: true,
        })}
        defaultValue={categories[category]}
        options={categories}
        isLoading={isLoading}
        tooltip="course_category"
      />
      <LabelSelect
        isDisabled={isDisabled}
        id="classType"
        name="classType"
        label="Class Type"
        error={!!errors.classType}
        registerInputRef={register({
          required: true,
        })}
        defaultValue={classType}
        options={classTypes}
        isLoading={isLoading}
        tooltip="course_class_type"
      />
      <LabelInput
        isDisabled={isDisabled}
        id="courseDuration"
        name="courseDuration"
        label="Course Duration"
        error={!!errors.courseDuration}
        errorMessage="Please enter a valid number"
        registerInputRef={register({
          validate: (value) => isNumeric(value) && value < 50,
        })}
        defaultValue={courseDuration}
        unit="weeks"
        isLoading={isLoading}
        tooltip="course_duration"
      />
      <LabelInput
        isDisabled={isDisabled}
        id="accessDuration"
        name="accessDuration"
        label="Access Duration"
        error={!!errors.accessDuration}
        errorMessage="Please enter a valid number less than 52"
        registerInputRef={register({
          validate: (value) => isNumeric(value) && value <= 52,
        })}
        defaultValue={accessDuration}
        unit="weeks"
        isLoading={isLoading}
        tooltip="course_access_duration"
      />
      {/* Price is read-only */}
      <LabelInput
        id="price"
        name="price"
        label="Price (£)"
        defaultValue={price}
        helpText="Price of the course"
        unit="GBP"
        isDisabled
        isLoading={isLoading}
      />
    </EditCard>
  );
};

export default FurtherDetailsCard;
