import React, { useEffect, useState } from 'react';
import { convertToRaw, ContentState } from 'draft-js';
import { Editor, RawDraftContentState, EditorProps } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import sanitizeHtml from 'sanitize-html';

import { IconTooltip, Tooltip } from 'components/IconTooltip';

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './RichEditor.css';

import { Box } from '@workshop/ui';

const allowedTags = [
  'a',
  'br',
  'em',
  'h1',
  'h2',
  'h3',
  'h4',
  'i',
  'li',
  'ol',
  'p',
  'span',
  'strong',
  'ul',
];

const sanitizeMarkup = (markup: string) =>
  sanitizeHtml(markup, { allowedTags }).replace(/\n/g, '');

const getContentState = (markup: string) => {
  const sanitizedMarkup = sanitizeMarkup(markup);
  const rawContent = htmlToDraft(sanitizedMarkup);

  return (
    rawContent &&
    convertToRaw(ContentState.createFromBlockArray(rawContent.contentBlocks))
  );
};

interface RichTextEditorProps extends Omit<EditorProps, 'onChange'> {
  name: string;
  isDisabled?: boolean;
  defaultValue?: string;
  onChange: (name: string, markup: string, defaultValue?: string) => void;
  reset?: boolean;
  tooltip?: Tooltip;
}

export const RichTextEditor: React.FC<RichTextEditorProps> = ({
  name,
  isDisabled = false,
  defaultValue = '',
  onChange,
  reset,
  placeholder,
  tooltip,
  ...rest
}) => {
  const [resettingContent, setResettingContent] = useState(false);
  const [contentState, setContentState] = useState(() =>
    getContentState(defaultValue || '')
  );

  const resetContentState = async (defaultValue: string) => {
    /** Since we use the Editor in an un-controlled way,
     *  we reset the value by briefly rendering null,
     *  then re-render the Editor with its default value */

    setResettingContent(true);
    await setContentState(getContentState(defaultValue || ''));
    setResettingContent(false);
  };

  useEffect(() => {
    if (reset) resetContentState(defaultValue);
  }, [reset, defaultValue]);

  const onContentStateChange = (rawContentState: RawDraftContentState) => {
    const markup = draftToHtml(rawContentState);
    const sanitizedMarkup = sanitizeMarkup(markup);
    onChange(name, sanitizedMarkup, defaultValue);
  };

  /** See comment in resetContentState */
  if (resettingContent) return null;

  return (
    <Box
      border="1px"
      borderColor="border.muted"
      borderRadius="md"
      height="100%"
      position="relative"
    >
      <Editor
        readOnly={isDisabled}
        toolbarHidden={isDisabled}
        handlePastedText={() => {
          /**
           * This handler in theory shouldn't be necessary but it helps
           * fix an issue with copying/pasting lists from Google Docs
           */
          return false;
        }}
        defaultContentState={contentState}
        onContentStateChange={onContentStateChange}
        editorStyle={{ paddingLeft: 16, paddingRight: 16 }}
        placeholder={placeholder}
        {...rest}
      />
      {tooltip && (
        <Box position="absolute" bottom={3} right={3}>
          <IconTooltip tooltip={tooltip} />
        </Box>
      )}
    </Box>
  );
};
