import { CategorySchema } from 'redux/schemas';

import { callAPI } from 'utils';
import API from 'constants/api';

import { CategoryAT } from 'redux/actionTypes/common';

import { FetchCategoriesAction } from 'types/common';

export const list = () => {
  return callAPI<FetchCategoriesAction>({
    method: 'GET',
    endpoint: API.common.categories(),
    types: [
      CategoryAT.CMS_FETCH_CATEGORIES_REQUEST,
      CategoryAT.CMS_FETCH_CATEGORIES_SUCCESS,
      CategoryAT.CMS_FETCH_CATEGORIES_FAILURE,
    ],
    schema: [CategorySchema],
    path: 'results',
  });
};
