import React from 'react';
import { useSelector } from 'react-redux';
import get from 'lodash/get';
import {
  RouteComponentProps,
  withRouter,
  match,
  matchPath,
  Link,
} from 'react-router-dom';

import { GlobalState } from 'types';
import styled, { Breadcrumb, BreadcrumbItem, Text, MdIcon } from '@workshop/ui';

import { useIsAuthenticated, useIsMentor } from 'redux/selectors';
import navRoutes, { Route } from 'navigation/Routes';

/**
 * Takes a pathname (from router location) and splits it into different paths
 *
 * e.g: findPaths('/course/5/') => ['/course', '/course/5']
 */
export const findPaths = (pathname: string): string[] => {
  const paths: string[] = [];

  const cleanPathname = pathname.replace(/\/$/, '');
  if (cleanPathname === '/') return paths;

  return cleanPathname
    .split('/')
    .filter((a) => a)
    .reduce(
      (prev: string[], curr: string, idx) =>
        idx === 0 ? [`/${curr}`] : [...prev, `${prev[idx - 1]}/${curr}`],
      []
    );
};

interface Crumb {
  route: Route;
  matchedPath: match<{}>;
  stateNamePath: string;
  stateBannerPath: string;
  stateLogoPath: string;
  stateOrgNamePath: string;
}

/**
 * Maps over the given list of paths (see findPaths)
 * and turns them into an object with 2 keys:
 * - route: the relevant route from navigations/Routes
 * - matchedPath: the matched path from react-router-dom (found using matchPath)
 *
 * TODO: Update to use `useCurrentRoute` hook
 */
export const findCrumbs = (
  routes: { [key: string]: Route },
  paths: string[]
): Crumb[] => {
  let crumbs: Crumb[] = [];

  paths.forEach((p) => {
    const found = Object.keys(routes).find((k) => {
      const route = routes[k];
      const path = route.path();
      return matchPath(p, { path, exact: true, strict: false });
    });

    if (!found) return;

    const route = routes[found];
    const matchedPath = matchPath(p, {
      path: route.path(),
      exact: true,
      strict: false,
    });

    if (!matchedPath) return;

    const matchParams: { [key: string]: string } = matchedPath.params;
    let stateNamePath = route.stateNamePath || '';
    let stateBannerPath = route.stateBannerPath || '';
    let stateLogoPath = route.stateLogoPath || '';
    let stateOrgNamePath = route.stateOrgNamePath || '';

    /**
     *
     * Transform the state paths from the given route
     * (using the relevant match params) into a path for useSelector:
     * e.g: course.course.courseId with matchParams = { courseId: 5 }
     * ==> course.course.5
     */
    Object.keys(matchParams).forEach((k) => {
      stateNamePath = stateNamePath?.replace(k, matchParams[k]);
      stateBannerPath = stateBannerPath?.replace(k, matchParams[k]);
      stateLogoPath = stateLogoPath?.replace(k, matchParams[k]);
      stateOrgNamePath = stateOrgNamePath?.replace(k, matchParams[k]);
    });

    crumbs.push({
      route,
      matchedPath,
      stateNamePath,
      stateBannerPath,
      stateLogoPath,
      stateOrgNamePath,
    });
  });

  return crumbs;
};

export const getCrumbs = (
  pathname: string,
  isAuthenticated: boolean,
  isMentor: boolean
): Crumb[] => {
  const paths = findPaths(pathname);

  let allRoutes = {
    ...navRoutes.common,
    ...navRoutes.public,
  };

  if (isAuthenticated) {
    allRoutes = { ...allRoutes, ...navRoutes.learner };
  }

  if (isAuthenticated && isMentor) {
    allRoutes = { ...allRoutes, ...navRoutes.cms };
  }

  return findCrumbs(allRoutes, paths);
};

const CrumbText = styled(Text)`
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface BreadcrumbItemProps {
  name: string;
  path: string;
  url: string;
}

const BreadcrumbLinkItem: React.FC<BreadcrumbItemProps> = ({
  name,
  path,
  url,
}) => {
  const breadcrumbTitle = useSelector((state: GlobalState) =>
    path ? get(state, path, name) : name
  );

  return (
    <Link to={url}>
      <CrumbText color="common.primary" fontWeight="semibold" fontSize="sm">
        {breadcrumbTitle}
      </CrumbText>
    </Link>
  );
};

interface BreadcrumbsProps extends RouteComponentProps {}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ location }) => {
  const isAuthenticated = useIsAuthenticated();
  const isMentor = useIsMentor();

  const crumbs = getCrumbs(location.pathname, isAuthenticated, isMentor);

  if (crumbs.length < 2) {
    return null;
  }

  return (
    <Breadcrumb
      separator={<MdIcon name="KeyboardArrowRight" color="common.primary" />}
      height="auto"
      display="flex"
      alignItems="center"
    >
      {crumbs.map((c: Crumb) => {
        return (
          <BreadcrumbItem key={c.matchedPath.path}>
            <BreadcrumbLinkItem
              name={c.route.name || ''}
              path={c.stateNamePath}
              url={c.matchedPath.url}
            />
          </BreadcrumbItem>
        );
      })}
    </Breadcrumb>
  );
};

export default withRouter(Breadcrumbs);
