import React, { FC } from 'react';
import {
  Button as BaseButton,
  ButtonProps as BaseButtonProps,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';
import { MdIcon, Flex, Text } from '@workshop/ui';

export type ButtonProps = BaseButtonProps & {
  icon?: string;
  iconColor?: string;
  secondary?: boolean;
};

export type ButtonRef =
  | string
  | ((instance: HTMLButtonElement | null) => void)
  | React.RefObject<HTMLButtonElement>
  | null
  | undefined;

export type ButtonPropsWithRef = ButtonProps & {
  ref?: ButtonRef;
};

const Button: FC<ButtonPropsWithRef> = ({
  children,
  icon,
  iconColor,
  secondary = false,
  ...rest
}) => (
  <BaseButton
    cursor="pointer"
    fontWeight="semibold"
    {...(secondary
      ? {
          colorScheme: 'common.secondaryPalette',
          borderRadius: 'lg',
          color: 'text.dark',
        }
      : {
          colorScheme: 'common.primaryPalette',
          borderRadius: 'md',
          boxShadow:
            rest.variant === 'ghost' || rest.variant === 'outline'
              ? 'none'
              : 'lg',
        })}
    {...rest}
  >
    <Flex justifyContent="center" alignItems="center">
      {icon && (
        <MdIcon
          mr={children ? 1.5 : 0}
          name={icon}
          color={iconColor ? iconColor : secondary ? 'text.dark' : ''}
          minW={3}
        />
      )}
      {children}
    </Flex>
  </BaseButton>
);

export default Button;

interface AlertDialogButtonProps extends BaseButtonProps {
  onCancel: () => void;
  onSubmit: () => void;
  alertHeader: string;
  alertBody: string;
  allowSubmit?: boolean;
  submitBtnLabel: string;
  submitBtnColor: string;
}

export const AlertDialogButton: FC<AlertDialogButtonProps> = ({
  onCancel,
  onSubmit,
  alertHeader,
  alertBody,
  allowSubmit = true,
  submitBtnLabel,
  submitBtnColor,
  children,
  ...rest
}) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const onClose = () => setIsOpen(false);
  const cancelRef = React.useRef<HTMLButtonElement>(null);

  const handleCancel = () => {
    onCancel();
    onClose();
  };

  const handleSubmit = () => {
    onSubmit();
    onClose();
  };

  return (
    <>
      <Button secondary {...rest} onClick={() => setIsOpen(true)}>
        {children}
      </Button>

      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
      >
        <AlertDialogOverlay />
        <AlertDialogContent borderRadius="md">
          <AlertDialogHeader
            fontSize="lg"
            fontWeight="bold"
            paddingX={6}
            pt={6}
            pb={2}
          >
            {alertHeader}
          </AlertDialogHeader>

          <AlertDialogBody paddingX={6} pt={0} pb={6}>
            {alertBody}
          </AlertDialogBody>

          <AlertDialogFooter paddingX={6} pt={0} pb={6}>
            <Button secondary ref={cancelRef} onClick={handleCancel}>
              Cancel
            </Button>
            {allowSubmit && (
              <Button
                colorScheme={submitBtnColor}
                onClick={handleSubmit}
                ml="defaultMargin"
              >
                {submitBtnLabel}
              </Button>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

interface SwitchButtonProps extends BaseButtonProps {
  active: boolean;
}

export const SwitchButton: FC<SwitchButtonProps> = ({
  active,
  children,
  ...rest
}) => (
  <Button
    secondary
    {...(active
      ? {
          backgroundColor: 'text.dark',
          color: 'text.light',
          borderWidth: 1,
          borderColor: 'text.dark',
          _hover: { backgroundColor: 'text.dark' },
        }
      : {
          backgroundColor: 'text.light',
          color: 'text.muted',
          borderWidth: 1,
          borderColor: 'text.muted',
        })}
    {...rest}
  >
    {children}
  </Button>
);
