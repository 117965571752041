import React from 'react';
import { useDispatch } from 'react-redux';
import { animated, useTransition } from 'react-spring';
import {
  Alert,
  AlertTitle,
  AlertDescription,
  AlertIcon,
  Card,
  CloseButton,
  Flex,
  Box,
  BoxProps,
  Button,
  Collapse,
} from '@workshop/ui';

import { profileActions } from 'redux/actions/common';
import { useDismissedInformationCards } from 'redux/selectors/user';

export interface Information {
  title?: string;
  description: string;
  status?: 'info' | 'warning' | 'success' | 'error';
  overlay?: boolean;
  variants?: { [key: string]: string };
}

// TODO :
// These announcements should live in a backend model and
// should be retreievedvia the API
const INFORMATIONS: { [key: string]: Information } = {
  home_screen: {
    // title: 'Info Box Title',
    description:
      'Easily find your way around the platform and return to this page at any time by looking out for the Home button.',
    // variants: {
    //   owner: 'Some different text',
    // },
  },
  account_screen: {
    description:
      'This is where you can customise your channel details to update what is visible to your team members and students.',
  },
  team_screen: {
    description:
      "This is where you can invite new members to your channel's team and edit team members' roles and permissions.",
  },
  agreements_screen: {
    description:
      'This is where you can create and manage agreements between your channel and another. Agreements can be made to distribute a number of course enrolments to another channel in bulk.',
  },
  payments_screen: {
    description:
      'This is where you can update your bank details for receiving payment when you sell courses.',
  },
  course_preview_section: {
    title: 'Course Previews',
    description:
      'This is where you can preview courses you have access to from an active Agreement between your channel and another. This will allow you to view the course from the perspective of a student.',
    overlay: true,
  },
  browse_course_scheduled_cohort: {
    description:
      'Together, you and your classmates gain access to your course week by week. Once each week unlocks, you can revisit any previous sessions you may have already completed at any time during your course access period.',
    overlay: true,
  },
  browse_course_open_cohort: {
    description:
      'Together, you and your classmates have access to all course content right from the start. Follow along with the content in order, or jump around in whatever way suits you. Once you have completed a session, you can revisit it at any time from here throughout your access period.',
    overlay: true,
  },
  classes_active_license: {
    description:
      'Having an active bulk enrolment agreement allows you to enrol students on courses from another channel that you have been granted access to. To do this, create a new class to get started.',
    overlay: true,
  },
  extended_session_step: {
    title: 'Extended Sessions',
    description:
      'For extended sessions there only needs to be a single step, with a single clip',
  },
  unassigned_clips: {
    title: 'Unassigned Clips',
    description:
      'Video clips uploaded using the multiple-clip upload functionality will appear here. Assign each clip to a step and press save to save all of your changes at once.',
  },
  copywriter_overview: {
    title: 'Writing Clip Summaries',
    description:
      'As a copywriter, you can watch videos and write clip summaries in the sections below.',
  },
};

export const DYNAMIC_INFORMATION_IDS = {
  substitute_mentor: 'substitute_mentor__web_app',
};

interface Props extends BoxProps {
  id: string;
  variant?: string;
  information?: Information | null;
  onDismiss?: () => void;
  showInfo?: boolean;
  isDismissable?: boolean;
}

const InformationCard: React.FC<Props> = ({
  id,
  variant,
  information: informationProp,
  onDismiss,
  showInfo,
  isDismissable = true,
  ...props
}) => {
  const dispatch = useDispatch();

  const dismissedInformationCards = useDismissedInformationCards();

  const shouldShow = !dismissedInformationCards.includes(id) || showInfo;

  const onClick = () => {
    onDismiss && onDismiss();
    dispatch(
      profileActions.updateUserProfile(
        {
          dismissedInformationCards: [...dismissedInformationCards, id],
        },
        { toast: { success: false, error: false } }
      )
    );
  };

  const information = informationProp || INFORMATIONS[id] || null;

  // If no information object has been provided or found using the ID, return nothing
  if (!information) return null;

  const { description, variants } = information;

  // If a `variant` prop has been provided and the information object
  // contains `variants`, then see if we can use the `variant` prop to
  // extract a specific message, falling back to the default `description`
  // if not.
  const message =
    variant && variants ? variants[variant] || description : description;

  return (
    <Box>
      {information ? (
        <Collapse in={shouldShow}>
          <Alert
            status={information.status || 'info'}
            borderRadius="md"
            {...props}
          >
            {information.overlay && (
              <Box
                position="absolute"
                top={0}
                right={0}
                bottom={0}
                left={0}
                backgroundColor="background.defaultXTransparent"
              />
            )}
            <AlertIcon position="relative" />
            <Flex
              position="relative"
              flex={1}
              alignItems={{ base: 'none', sm: 'center' }}
              flexDirection={{ base: 'column', sm: 'row' }}
            >
              <Box flex={1}>
                {information.title ? (
                  <AlertTitle fontWeight="bold">{information.title}</AlertTitle>
                ) : null}
                <AlertDescription display="block">{message}</AlertDescription>
              </Box>
              {isDismissable && (
                <Flex flex={{ base: 1, sm: 'none' }} justifyContent="flex-end">
                  <Button
                    variant="outline"
                    size="xs"
                    icon="ThumbUp"
                    ml={{ base: 0, sm: 4 }}
                    mt={{ base: 2, sm: 0 }}
                    onClick={onClick}
                  >
                    Got it
                  </Button>
                </Flex>
              )}
            </Flex>
          </Alert>
        </Collapse>
      ) : null}
    </Box>
  );
};

export default InformationCard;
