import { CourseAT } from 'redux/actionTypes/cms';
import { Action } from 'types';
import {
  CourseDetailState,
  CourseListState,
  CourseUIState,
  ICourseListItem,
} from 'types/cms';

const initialState: CourseListState = {};

export const courseListReducer = (
  state = initialState,
  action: Action
): CourseListState => {
  switch (action.type) {
    case CourseAT.CMS_CREATE_COURSE_REQUEST:
    case CourseAT.CMS_FETCH_COURSE_LIST_REQUEST:
      return state;
    case CourseAT.CMS_CREATE_COURSE_SUCCESS:
    case CourseAT.CMS_FETCH_COURSE_LIST_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.courses,
      };
    case CourseAT.CMS_CREATE_COURSE_FAILURE:
    case CourseAT.CMS_FETCH_COURSE_LIST_FAILURE:
      return state;

    case CourseAT.CMS_FETCH_COURSE_SUCCESS:
    case CourseAT.CMS_PATCH_COURSE_SUCCESS: {
      // When we get or update a course, we find that course in the
      // list and overwrite properties in the list representation from
      // the detailed object received.
      const idString = action.payload.result.toString();
      const course = action.payload.entities.course[idString];

      // If we don't have a list representation of the course yet
      // then do nothing
      if (!state[idString] || Object.keys(state).length === 0) return state;
      // @ts-ignore
      return {
        ...state,
        [idString]: Object.keys(state[idString]).reduce(
          // @ts-ignore
          (intersection: ICourseListItem, key: keyof ICourseListItem) => {
            // @ts-ignore
            intersection[key] = course[key] || state[idString][key];
            return intersection;
          },
          {}
        ),
      };
    }
    default:
      return state;
  }
};

const courseInitialState: CourseDetailState = {};

export const courseReducer = (
  state = courseInitialState,
  action: Action
): CourseDetailState => {
  switch (action.type) {
    case CourseAT.CMS_FETCH_COURSE_REQUEST:
    case CourseAT.CMS_PATCH_COURSE_REQUEST:
      return state;
    case CourseAT.CMS_FETCH_COURSE_SUCCESS:
    case CourseAT.CMS_PATCH_COURSE_SUCCESS:
      return {
        ...state,
        ...action.payload.entities.course,
      };
    case CourseAT.CMS_FETCH_COURSE_FAILURE:
    case CourseAT.CMS_PATCH_COURSE_FAILURE:
      return state;
    default:
      return state;
  }
};

export const courseUIReducer = (
  state: CourseUIState = {
    course: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
    courseList: {
      loading: false,
      error: false,
      errorPayload: null,
      errorMessage: null,
      success: false,
    },
  },
  action: Action
): CourseUIState => {
  switch (action.type) {
    case CourseAT.CMS_CREATE_COURSE_REQUEST:
    case CourseAT.CMS_FETCH_COURSE_LIST_REQUEST:
      return {
        ...state,
        courseList: {
          ...state.courseList,
          loading: !action.error,
        },
      };
    case CourseAT.CMS_CREATE_COURSE_SUCCESS:
    case CourseAT.CMS_FETCH_COURSE_LIST_SUCCESS:
    case CourseAT.CMS_CREATE_COURSE_FAILURE:
    case CourseAT.CMS_FETCH_COURSE_LIST_FAILURE:
      return {
        ...state,
        courseList: {
          ...state.courseList,
          loading: false,
        },
      };
    case CourseAT.CMS_FETCH_COURSE_REQUEST:
    case CourseAT.CMS_PATCH_COURSE_REQUEST:
      return {
        ...state,
        course: {
          ...state.course,
          loading: !action.error,
        },
      };
    case CourseAT.CMS_FETCH_COURSE_SUCCESS:
    case CourseAT.CMS_PATCH_COURSE_SUCCESS:
    case CourseAT.CMS_FETCH_COURSE_FAILURE:
    case CourseAT.CMS_PATCH_COURSE_FAILURE:
      return {
        ...state,
        course: {
          ...state.course,
          loading: false,
        },
      };
    default:
      return state;
  }
};
