import * as React from 'react';
import { Flex, FlexProps, useTheme } from '@workshop/ui';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

interface WorkshopIconProps {
  color: string;
  size?: 'sm' | 'lg';
}

const WorkshopIcon = (
  props: WorkshopIconProps = {
    color: '#000',
    size: 'sm',
  }
) => {
  const { color, size } = props;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="261 261 424 184"
        width={size === 'sm' ? '33' : '44'}
        height={size === 'sm' ? '33' : '44'}
      >
        <path
          style={{
            fillOpacity: 0,
            stroke: color,
            strokeWidth: 20,
          }}
          d="M272.5 152.5h400v400h-400V152.5z"
        />
        <path
          style={{
            fillOpacity: 0,
            stroke: color,
            strokeWidth: 20,
          }}
          d="M362.5 266.5h22l22 150 66-139.7 66 139.7 22-150h22"
        />
      </svg>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 311 72"
        height={size === 'sm' ? '20' : '27'}
        style={{ marginLeft: size === 'sm' ? '10px' : '14px' }}
      >
        <path
          style={{ fill: color }}
          d="M.37,5.24H10L20,41,31.26,5.24h6.41L49.33,40.09,59,5.24h9.58L53.8,55.64H45.66L34.43,22,23.27,55.64H14.56Z"
        />
        <path
          style={{ fill: color }}
          d="M74,53.84a17,17,0,0,1-6.41-6.77,19.78,19.78,0,0,1-2.23-9.36,20.2,20.2,0,0,1,2.23-9.43A16.89,16.89,0,0,1,74,21.44a18.71,18.71,0,0,1,9.86-2.52,18.71,18.71,0,0,1,9.86,2.52,16.89,16.89,0,0,1,6.41,6.84,20.2,20.2,0,0,1,2.23,9.43,19.78,19.78,0,0,1-2.23,9.36,17,17,0,0,1-6.41,6.77,18.71,18.71,0,0,1-9.86,2.52A18.71,18.71,0,0,1,74,53.84ZM90.7,45.92a12.76,12.76,0,0,0,2.48-8.21,13,13,0,0,0-2.48-8.32,9.13,9.13,0,0,0-13.76,0,13,13,0,0,0-2.48,8.32,12.76,12.76,0,0,0,2.48,8.21,9.2,9.2,0,0,0,13.76,0Z"
        />
        <path
          style={{ fill: color }}
          d="M105.64,19.64H114l.21,3.81a15.26,15.26,0,0,1,4.07-3.16,10.71,10.71,0,0,1,5.22-1.37,8.63,8.63,0,0,1,1.59.14l-.65,8.79a12.22,12.22,0,0,0-3.1-.36,8.33,8.33,0,0,0-4.1,1,6.43,6.43,0,0,0-2.67,2.56V55.64h-8.92Z"
        />
        <path
          style={{ fill: color }}
          d="M126.44,1.64h8.93V34.4l11.16-14.76h10.08l-13.1,17.28L156.9,55.64H146.82l-11.45-16v16h-8.93Z"
        />
        <path
          style={{ fill: color }}
          d="M162.48,55.71a30.32,30.32,0,0,1-5.87-2l.79-7.63a30.73,30.73,0,0,0,5.65,2.7,16.73,16.73,0,0,0,5.44.9q5.47,0,5.47-3.6a3.48,3.48,0,0,0-1.44-3,25.43,25.43,0,0,0-5.18-2.48,21.89,21.89,0,0,1-7.85-4.43A9,9,0,0,1,157,29.43a9.33,9.33,0,0,1,3.6-7.6q3.6-2.91,9.72-2.91a22.46,22.46,0,0,1,11.16,2.81l-.79,7.63a29.07,29.07,0,0,0-5.15-2.88,13.08,13.08,0,0,0-5.08-1,5.84,5.84,0,0,0-3.56.94,3.11,3.11,0,0,0-1.26,2.66,3.23,3.23,0,0,0,.76,2.23,6.66,6.66,0,0,0,2.12,1.55c.91.46,2.42,1.09,4.54,1.91a20.78,20.78,0,0,1,7.56,4.43,9,9,0,0,1,2.3,6.44,9.38,9.38,0,0,1-3.67,7.92q-3.67,2.81-10.44,2.81A28.43,28.43,0,0,1,162.48,55.71Z"
        />
        <path
          style={{ fill: color }}
          d="M214.46,23.49q3.34,4.43,3.35,12.13v20h-8.93V36.27c0-3.41-.55-5.9-1.65-7.49A6,6,0,0,0,202,26.41a8.9,8.9,0,0,0-7,3.09V55.64h-8.93v-54H195v20.3a20.33,20.33,0,0,1,4.39-2.12,15,15,0,0,1,4.89-.76Q211.11,19.06,214.46,23.49Z"
        />
        <path
          style={{ fill: color }}
          d="M229.4,53.84A17.1,17.1,0,0,1,223,47.07a19.78,19.78,0,0,1-2.23-9.36A20.2,20.2,0,0,1,223,28.28a17,17,0,0,1,6.41-6.84,20.57,20.57,0,0,1,19.73,0,17,17,0,0,1,6.41,6.84,20.32,20.32,0,0,1,2.23,9.43,19.89,19.89,0,0,1-2.23,9.36,17.1,17.1,0,0,1-6.41,6.77,20.57,20.57,0,0,1-19.73,0Zm16.74-7.92a12.7,12.7,0,0,0,2.48-8.21,13,13,0,0,0-2.48-8.32,9.12,9.12,0,0,0-13.75,0,13,13,0,0,0-2.48,8.32,12.7,12.7,0,0,0,2.48,8.21,9.19,9.19,0,0,0,13.75,0Z"
        />
        <path
          style={{ fill: color }}
          d="M288.66,21.58a15.77,15.77,0,0,1,5.54,6.77,22.91,22.91,0,0,1,1.87,9.36,22.91,22.91,0,0,1-1.87,9.36,15.77,15.77,0,0,1-5.54,6.77,15.16,15.16,0,0,1-8.79,2.52,19.16,19.16,0,0,1-5.51-.69A14.36,14.36,0,0,1,270,53.48V73.06h-8.93V19.64h7.2l.94,3.31A16.81,16.81,0,0,1,274,20a16.3,16.3,0,0,1,5.83-1A15.16,15.16,0,0,1,288.66,21.58Zm-4,24.34a13.79,13.79,0,0,0,2.23-8.21,13.81,13.81,0,0,0-2.23-8.21,7.23,7.23,0,0,0-6.2-3.09,7.77,7.77,0,0,0-6.69,3q-2.24,3-2.23,8.28T271.81,46a7.77,7.77,0,0,0,6.69,3A7.23,7.23,0,0,0,284.7,45.92Z"
        />
        <path
          style={{ fill: color }}
          d="M298.63,25.46a7.14,7.14,0,0,1-2.78-2.88,9.09,9.09,0,0,1-1-4.32,9,9,0,0,1,1-4.31,7.05,7.05,0,0,1,2.78-2.87,8.41,8.41,0,0,1,8,0A7.09,7.09,0,0,1,309.43,14a9,9,0,0,1,1,4.31,9.09,9.09,0,0,1-1,4.32,7.18,7.18,0,0,1-2.77,2.88,8.34,8.34,0,0,1-8,0Zm7.58-1a5.62,5.62,0,0,0,2.19-2.53,8.78,8.78,0,0,0,.73-3.67,8.68,8.68,0,0,0-.73-3.65,5.7,5.7,0,0,0-2.19-2.52,7.28,7.28,0,0,0-7.12,0,5.79,5.79,0,0,0-2.2,2.52,8.68,8.68,0,0,0-.74,3.65,8.78,8.78,0,0,0,.74,3.67,5.7,5.7,0,0,0,2.2,2.53,7.28,7.28,0,0,0,7.12,0Zm-6.46-10.35h2.95a3.1,3.1,0,0,1,2,.6,2.27,2.27,0,0,1,.71,1.83,2.42,2.42,0,0,1-1.35,2.25l1.78,3.35h-1.65l-1.48-2.93H301.3v2.93h-1.55Zm2.8,3.7a1.15,1.15,0,0,0,1.28-1.25,1.22,1.22,0,0,0-.33-.9,1.3,1.3,0,0,0-.95-.32H301.3v2.47Z"
        />
      </svg>
    </>
  );
};

const WorkshopMiniIcon = (
  props: WorkshopIconProps = {
    color: '#000',
  }
) => {
  const { color } = props;
  return (
    <>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="261 261 424 184"
        width="33"
        height="33"
      >
        <path
          style={{ fillOpacity: 0, stroke: color, strokeWidth: 20 }}
          d="M272.5 152.5h400v400h-400V152.5z"
        />
        <path
          style={{ fillOpacity: 0, stroke: color, strokeWidth: 20 }}
          d="M362.5 266.5h22l22 150 66-139.7 66 139.7 22-150h22"
        />
      </svg>
    </>
  );
};

interface BrandProps {
  navbarTransparent: boolean;
  theme?: {
    textFeature: string;
    text: string;
  };
}

const Brand: React.FC<BrandProps> = (props) => {
  const { navbarTransparent } = props;
  const theme = useTheme();
  const textFeature = '#FFF';
  const text = theme.colors.text.default;
  const windowDimensions = useWindowDimensions();
  const isSmallMobile =
    windowDimensions.width < parseInt(theme.breakpoints.sm, 10);
  return (
    <Flex alignItems="center" justifyContent="flex-start">
      {isSmallMobile ? (
        <WorkshopMiniIcon color={navbarTransparent ? textFeature : text} />
      ) : (
        <WorkshopIcon
          size="sm"
          color={navbarTransparent ? textFeature : text}
        />
      )}
    </Flex>
  );
};

interface BrandLgProps extends FlexProps {}

export const BrandLg: React.FC<BrandLgProps> = (props) => {
  const theme = useTheme();
  const color = theme.colors.text.default;
  return (
    <Flex alignItems="center" justifyContent="flex-start" {...props}>
      <WorkshopIcon color={color} />
    </Flex>
  );
};

export default Brand;
