// TODO: Delete?
import React, { useState } from 'react';
import styled, { Box, Flex, Text, Textarea } from '@workshop/ui';
import { useForm } from 'react-hook-form';

import {
  StepItem,
  ItemButtons,
  AddItem,
  StepDataType,
} from 'components/ListItem';
import { Draggable } from 'components/Draggable';

interface Props {
  stepItemData: Array<StepDataType>;
  supportingNotesHelpText?: string;
  onSave?: Function;
}

const FlexForm = styled.form`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const AddStepItem = (data: Array<StepDataType>) => [
  ...data,
  {
    id: `step-item-${data.length + 1}`,
    index: data.length,
    name: `stepItem${data.length + 1}`,
    placeholder: 'Add your description here',
  },
];

const ExpandedStep: React.FC<Props> = ({
  stepItemData,
  supportingNotesHelpText = '(Optional) These notes appear below the videos in this step and can be used to provide further explanations and links.',
  onSave = () => {},
}) => {
  const { register, handleSubmit, errors } = useForm();
  const [clipData, setStepData] = useState(stepItemData);
  const onSubmit = handleSubmit((vals) => onSave(vals));

  // TOOD:
  // - Combine StepItem and ExpandedStep into a single component

  return (
    <Flex flexDirection="column" flex={1}>
      <FlexForm onSubmit={onSubmit}>
        <Box borderRadius="md" border="1px" borderColor="border.muted">
          <Draggable data={clipData} onDragEnd={() => {}} dragEnabled={true}>
            {(props: StepDataType) => (
              <StepItem
                {...props}
                index={props.index + 1}
                registerInputRef={register({
                  required: {
                    value: true,
                    message: 'This is required',
                  },
                })}
                registerInputRefFile={register}
                error={props.name && errors[props.name]?.message}
                onUpload={() => {}}
              />
            )}
          </Draggable>
        </Box>
        <AddItem
          onSave={() => {}}
          onClick={() => setStepData(AddStepItem(clipData))}
          label="Add Clip"
        />
        <Flex flexDirection="column" padding="defaultPadding">
          <Text color="gray" mb="defaultMargin">
            Supporting Notes:
          </Text>
          <Textarea
            name="supportingNotes"
            ref={register({
              required: false,
            })}
            placeholder="Some supporting notes..."
            borderColor="border.muted"
            backgroundColor="background.default"
            maxLength={1000}
          />

          <Text fontSize="xs" marginTop={1}>
            {supportingNotesHelpText}
          </Text>
          <ItemButtons
            onSave={onSubmit}
            onCancel={() => {}}
            cancelLabel="Reset"
          />
        </Flex>
      </FlexForm>
    </Flex>
  );
};

export default ExpandedStep;
