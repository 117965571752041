import React, { useEffect, useCallback } from 'react';
import { useForm } from 'react-hook-form';

import { UNIT_TYPE } from 'constants/courses';

import { EditCard, LabelSelect, LabelInputSelect } from 'components/Common';
import { FurtherDetailsUnitFormData, IStartUnit, IUnitType } from 'types/cms';
import isNumeric from 'validator/lib/isNumeric';

interface FurtherDetailsUnitProps extends FurtherDetailsUnitFormData {
  unitTypesOptions: { [key in IUnitType]: string };
  startUnitOptions: { [key in IStartUnit]: string };
  setUnitType: React.Dispatch<React.SetStateAction<IUnitType>>;
  onSave: (formData: FurtherDetailsUnitFormData) => void;
  onCancel?: () => void;
  isDisabled?: boolean;
  isUpdating?: boolean;
  isLoading?: boolean;
}

const noop = () => null;

const FurtherDetailsUnitCard = ({
  unitTypesOptions,
  unitType,
  startUnitOptions,
  startUnit,
  startValue,
  setUnitType,
  onSave,
  onCancel = noop,
  isDisabled = false,
  isUpdating,
  isLoading = false,
}: FurtherDetailsUnitProps) => {
  const {
    register,
    handleSubmit,
    errors,
    clearError,
    formState,
    reset,
    watch,
  } = useForm<FurtherDetailsUnitFormData>();

  const onSubmit = handleSubmit((formData) => onSave(formData));
  const watchUnitType = watch('unitType');

  const watchUnitTypeCallback = useCallback(() => {
    setUnitType(watchUnitType);
  }, [setUnitType, watchUnitType]);

  useEffect(watchUnitTypeCallback, [watchUnitTypeCallback]);

  const resetUnitType = useCallback(() => {
    reset({ unitType, startValue, startUnit });
  }, [unitType, startValue, startUnit, reset]);

  useEffect(resetUnitType, [resetUnitType]);

  const {
    intro,
    outro,
    ...rest
  }: {
    [key: string]: string;
  } = unitTypesOptions;

  const filteredUnitTypesOptions =
    unitType === 'intro' || unitType === 'outro' ? unitTypesOptions : rest;

  return (
    <EditCard
      onSave={onSubmit}
      onCancel={() => {
        onCancel();
        clearError();
        reset({ unitType, startValue, startUnit });
      }}
      isDisabled={isDisabled}
      isUpdating={isUpdating}
      saveDisabled={Boolean(
        !formState.dirty ||
          errors.unitType ||
          errors.startUnit ||
          errors.startValue
      )}
    >
      <LabelSelect
        id="unitType"
        name="unitType"
        label="Unit Type"
        error={!!errors.unitType}
        errorMessage={errors.unitType?.message}
        registerInputRef={register({
          required: 'This field is required',
        })}
        options={filteredUnitTypesOptions}
        defaultValue={unitType}
        isReadOnly={
          isDisabled ||
          unitType === UNIT_TYPE.intro ||
          unitType === UNIT_TYPE.outro
        }
        isDisabled={
          isDisabled ||
          unitType === UNIT_TYPE.intro ||
          unitType === UNIT_TYPE.outro
        }
        isLoading={isLoading}
        tooltip="unit_type"
      />
      <LabelInputSelect
        id="unitStarts"
        name={['startValue', 'startUnit']}
        label="Unit Starts"
        error={!!errors.startUnit}
        errorMessage="Please enter a valid unit value"
        registerInputRef={register({
          validate: (value) => isNumeric(value) && value < 50,
        })}
        registerSelectRef={register({
          required: true,
        })}
        options={startUnitOptions}
        unit="after course date"
        defaultValue={startValue}
        isDisabled={isDisabled}
        isLoading={isLoading}
      />
    </EditCard>
  );
};

export default FurtherDetailsUnitCard;
