import React from 'react';
import moment from 'moment';

import styled, {
  Card,
  Badge,
  Text,
  Link,
  Flex,
  Skeleton,
  Table,
  Thead,
  Tr,
  Th,
  Tbody,
  Td as baseTd,
  VStack,
} from '@workshop/ui';

import { SubscriptionData } from 'types/common';

const Td = styled(baseTd)`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`;

interface SubscriptionManagementProps {
  activeSubscriptions: SubscriptionData[];
  isLoading?: boolean;
}

const SubscriptionManagement: React.FC<SubscriptionManagementProps> = ({
  activeSubscriptions,
  isLoading = false,
}) => {
  return (
    <Card padding="defaultPadding">
      <Skeleton
        isLoaded={!isLoading}
        loadingStyle={{ width: '75%', height: 6 }}
      >
        {activeSubscriptions.length ? (
          <Table variant="simple">
            <Thead>
              <Tr>
                <Th>Subscription</Th>
                <Th>Status</Th>
                <Th>Next Payment</Th>
              </Tr>
            </Thead>
            {activeSubscriptions.map((subscription) => {
              const {
                plan,
                status,
                cancelAtPeriodEnd,
                cancelAt,
                currentPeriodEnd,
              } = subscription;
              return (
                <Tbody>
                  <Tr>
                    <Td>
                      <Flex flexDir="column" flex={1}>
                        <Text fontWeight="bold">{plan.product.name}</Text>
                        <Text fontSize="sm">{plan.nickname}</Text>
                      </Flex>
                    </Td>
                    <Td>
                      <VStack alignItems="flex-start" spacing={1}>
                        {status ? (
                          <Badge
                            variant="subtle"
                            colorScheme={
                              status === 'active' ? 'green' : 'blackAlpha'
                            }
                          >
                            {status}
                          </Badge>
                        ) : (
                          <Flex flex={1} />
                        )}
                        {cancelAtPeriodEnd && cancelAt && (
                          <Badge variant="subtle" colorScheme="red">
                            {`Cancels ${moment
                              .unix(cancelAt)
                              .format('Do MMM')}`}
                          </Badge>
                        )}
                      </VStack>
                    </Td>
                    <Td>
                      <Flex>
                        {moment.unix(currentPeriodEnd).format('MMM Do YYYY')}
                      </Flex>
                    </Td>
                  </Tr>
                </Tbody>
              );
            })}
          </Table>
        ) : (
          <Flex flexDir="column">
            <Text fontWeight="semibold">
              You currently have no active subcriptions
            </Text>
            <Text fontSize="sm" color="text.muted">
              If you think this is incorrect, please{' '}
              <Link
                color="common.primary"
                href="https://help.workshop.ws/hc/en-us"
              >
                get in touch
              </Link>
              .
            </Text>
          </Flex>
        )}
      </Skeleton>
    </Card>
  );
};

export default SubscriptionManagement;
