import React, { useState } from 'react';
import { useForm, FormContext } from 'react-hook-form';
import { Box, Flex, Text, MdIcon } from '@workshop/ui';
import isEmpty from 'lodash/isEmpty';

import { ItemButtons, PromptFormData } from 'components/ListItem';
import { LabelSelect, LabelInput } from 'components/Common';

/**
 * This form handles our user defined prompts on the item list
 */

interface Props {
  onSubmit: (values: PromptFormData) => Promise<void>;
  onCancel?: () => void;
  isLoading: boolean;
  helpText?: string;
  handleDeletePrompt: () => void;
  defaultFormValues?: PromptFormData;
}

const PromptForm: React.FC<Props> = ({
  onSubmit: onSubmitProp,
  onCancel,
  isLoading,
  helpText,
  handleDeletePrompt,
  defaultFormValues: defaultValues = {},
}) => {
  const [isSubmitting, setIsSubmitting] = useState(false);

  const formMethods = useForm<PromptFormData>({
    defaultValues,
  });

  const {
    handleSubmit,
    register,
    errors,
    formState: { dirty },
    reset,
  } = formMethods;

  const onSubmit = handleSubmit(async (data) => {
    setIsSubmitting(true);
    await onSubmitProp(data);
    setIsSubmitting(false);
    reset(data);
  });

  return (
    <FormContext {...formMethods}>
      <Box padding={2}>
        <Flex
          backgroundColor="background.tint1"
          borderRadius="md"
          overflow="hidden"
          padding="defaultPadding"
          flex={1}
          marginY={2}
        >
          <Flex flex={1} flexDirection="column" position="relative">
            <Flex
              alignItems="center"
              justifyContent="center"
              position="absolute"
              right={0}
              height="icon"
              width="icon"
              cursor="pointer"
              borderRadius="md"
              backgroundColor="common.notification"
              color="background.tint1"
              onClick={handleDeletePrompt}
            >
              <MdIcon name="Remove" />
            </Flex>
            <Text fontWeight="bold" color="text.muted" mb="defaultMargin">
              Prompt
            </Text>
            <LabelSelect
              id="responseType"
              name="responseType"
              label="Response Type"
              registerInputRef={register}
              options={{ none: 'None', text: 'Text', image: 'Image' }}
              helpText={
                'Select “None” to require no action from the learner, “Text” to ask them to submit a written response or "Image” if you’d like them to upload a photo '
              }
              helpTextPosition="inline"
              errorMessage={
                errors.responseType && 'A prompt must have a response type'
              }
              error={Boolean(errors.responseType)}
            />
            <LabelInput
              id="label"
              name="label"
              label="Label"
              registerInputRef={register({
                required: true,
              })}
              errorMessage={errors.label && 'A prompt must have a label'}
              helpText="For example, “Key Question” or “Discussion Point”"
              backgroundColor="background.default"
              error={Boolean(errors.label)}
            />
            <LabelInput
              id="title"
              name="title"
              label="Question"
              registerInputRef={register({
                required: true,
              })}
              errorMessage={errors.title && 'A prompt must have a question'}
              helpText="You can give the learner some advice on what to think about or how to answer the question here."
              backgroundColor="background.default"
              error={Boolean(errors.title)}
            />
            <LabelInput
              id="tip"
              name="tip"
              label="Tip"
              registerInputRef={register({ required: true })}
              backgroundColor="background.default"
              errorMessage={errors.tip && 'A prompt must have a tip'}
              error={Boolean(errors.tip)}
            />
          </Flex>
        </Flex>
        <Flex padding={2}>
          <Flex flex={2}>
            {helpText && (
              <Text fontSize="xs" color="text.muted">
                {helpText}
              </Text>
            )}
          </Flex>
          <ItemButtons
            onCancel={onCancel}
            saveType="submit"
            isLoading={isLoading}
            isDisabled={isLoading || isSubmitting || !dirty || !isEmpty(errors)}
            onSave={onSubmit}
          />
        </Flex>
      </Box>
    </FormContext>
  );
};

export default PromptForm;
