import { decamelizeKeys } from 'humps';
import {
  CMSSessionDetailSchema,
  CMSSessionListSchema,
} from 'redux/schemas/cms';

import { callAPIWithTeam } from 'utils';
import API from 'constants/api';

import { SessionAT } from 'redux/actionTypes/cms';

import {
  FetchSessionAction,
  PatchSessionAction,
  SessionCreateAction,
  SessionListAction,
  ISessionListItem,
  ISession,
} from 'types/cms';

export const list = (courseId: number, unitId: number) => {
  return callAPIWithTeam<SessionListAction>({
    types: [
      SessionAT.CMS_FETCH_SESSION_LIST_REQUEST,
      SessionAT.CMS_FETCH_SESSION_LIST_SUCCESS,
      SessionAT.CMS_FETCH_SESSION_LIST_FAILURE,
    ],
    endpoint: API.cms.sessions(courseId, unitId),
    method: 'GET',
    schema: [CMSSessionListSchema],
    path: 'results',
    meta: {
      toast: {
        error: 'Could not retrieve sessions',
      },
    },
  });
};

export const create = (
  courseId: number,
  unitId: number,
  data: Partial<ISessionListItem>
) => {
  return callAPIWithTeam<SessionCreateAction>({
    types: [
      SessionAT.CMS_CREATE_SESSION_REQUEST,
      SessionAT.CMS_CREATE_SESSION_SUCCESS,
      SessionAT.CMS_CREATE_SESSION_FAILURE,
    ],
    endpoint: API.cms.sessions(courseId, unitId),
    method: 'POST',
    body: decamelizeKeys(data),
    schema: CMSSessionListSchema,
    meta: {
      toast: {
        success: 'Successfully created session.',
        error: 'Could not create session',
      },
    },
  });
};

export const retrieve = (id: number) => {
  return callAPIWithTeam<FetchSessionAction>({
    types: [
      SessionAT.CMS_FETCH_SESSION_REQUEST,
      SessionAT.CMS_FETCH_SESSION_SUCCESS,
      SessionAT.CMS_FETCH_SESSION_FAILURE,
    ],
    endpoint: API.cms.session(id),
    method: 'GET',
    schema: CMSSessionDetailSchema,
    meta: {
      toast: {
        error: 'Could not retrieve this session',
      },
    },
  });
};

export const update = (id: number, data: Partial<ISession> | FormData) => {
  return callAPIWithTeam<PatchSessionAction>({
    types: [
      SessionAT.CMS_PATCH_SESSION_REQUEST,
      SessionAT.CMS_PATCH_SESSION_SUCCESS,
      SessionAT.CMS_PATCH_SESSION_FAILURE,
    ],
    endpoint: API.cms.session(id),
    method: 'PATCH',
    body: data instanceof FormData ? data : decamelizeKeys(data),
    schema: CMSSessionDetailSchema,
    meta: {
      toast: {
        error: { source: 'api' },
      },
    },
  });
};
