import React, { useEffect, useState } from 'react';
import moment from 'moment';
import groupBy from 'lodash/groupBy';
import { useDispatch } from 'react-redux';

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Card,
  Flex,
  Text,
  Skeleton,
  useDisclosure,
  Spinner,
} from '@workshop/ui';

import { uiAction } from 'redux/actions/common';

import { HeaderTag } from 'components/AppHeader';
import { UserAvatar } from 'components/UserAvatar';

import { Dispatch } from 'types';
import {
  JournalEntry,
  Assessment,
  ISessionSummary,
  IUnitSummary,
} from 'types/cms';

import { JournalEntryModal } from '.';

const getBackgroundColor = (status: 'active' | 'upcoming' | 'expired') => {
  switch (status) {
    case 'active':
      return 'background.success';

    case 'upcoming':
      return 'background.warning';
  }

  return 'background.error';
};

const getColor = (status: 'active' | 'upcoming' | 'expired') => {
  switch (status) {
    case 'active':
      return 'text.success';

    case 'upcoming':
      return 'text.warning';
  }

  return 'text.error';
};

export interface JournalUnitSummary extends Omit<IUnitSummary, 'modules'> {
  modules: ISessionSummary[];
}

export interface UserAssessments extends Assessment {
  journalEntries: JournalEntry[];
}

export interface CohortMember {
  id: number;
  assessments?: UserAssessments[];
  assessmentCount?: number;
  journalEntries?: JournalEntry[];
  avatar?: string;
  hasAssessment?: boolean;
  isLoading?: boolean;
  name: string;
  onOpenAssessment?: () => void;
  postsTotal?: number;
  username: string;
  privateMessageTopicId?: number;
}

interface MemberCardProps extends CohortMember {
  currentUserName: string;
  onClickAssessment?: (userName: string, cohortId: number) => void;
  onClickUserActivity?: (
    cohortId: number,
    username: string,
    subcategory: number
  ) => void;
  cohortId: number;
  dispatch: Dispatch;
  discourseCategoryId: number | null;
}

const MemberCard: React.FC<MemberCardProps> = ({
  assessmentCount,
  avatar,
  cohortId,
  currentUserName,
  id: memberId,
  name: memberName,
  username: memberUsername,
  isLoading: memberLoading,
  privateMessageTopicId,
  name,
  onClickAssessment,
  onClickUserActivity,
  username,
  dispatch,
  discourseCategoryId,
}) => {
  return (
    <Box
      key={`member-${username}-${memberId}`}
      marginY={1}
      paddingX={1}
      w={['100%', '100%', '100%', '100%', '100%', '50%']}
    >
      <Skeleton isLoaded={!memberLoading}>
        <Card
          flexDirection={{ base: 'column', md: 'row' }}
          alignItems={{ base: 'flex-start', md: 'center' }}
          boxShadow="md"
          borderRadius={{ base: 'sm', md: 'xl' }}
        >
          <Flex alignItems="center" flex={1} mb={{ base: 2, md: 0 }}>
            <UserAvatar
              avatarPicture={avatar}
              name={name}
              size="2xs"
              userId={memberId}
            />
            <Flex
              flex={1}
              ml={2}
              minW={0}
              cursor="pointer"
              onClick={() =>
                onClickUserActivity &&
                discourseCategoryId &&
                onClickUserActivity(
                  cohortId,
                  memberUsername,
                  discourseCategoryId
                )
              }
            >
              <Text
                textOverflow="ellipsis"
                whiteSpace="nowrap"
                overflow="hidden"
              >
                {name}
              </Text>
            </Flex>
          </Flex>
          <Flex
            width={{ base: '100%', sm: 'auto' }}
            flexDirection={{ base: 'column', sm: 'row' }}
            alignItems={{ base: 'flex-start', sm: 'normal', md: 'center' }}
          >
            <Button
              width={{ base: '100%', sm: 'auto' }}
              secondary
              icon={privateMessageTopicId ? 'Chat' : 'AddCircle'}
              size="sm"
              fontSize="xs"
              onClick={() => {
                dispatch(
                  uiAction.toggleUserMessaging({
                    userId: memberId,
                    title: memberName,
                    username: memberUsername,
                    isExpanded: true,
                  })
                );
              }}
            >
              {privateMessageTopicId ? 'Messages' : 'New Message'}
            </Button>
            {/* TODO: Ensure that user summaries are reliable before linking this way */}
            {/* {postsTotal
        ? (
            <Button
              secondary
              icon="Collections"
              size="sm"
              fontSize="xs"
              ml={1}>
              {`${postsTotal} post${postsTotal > 1 ? 's' : ''}`}
            </Button>)
          : null}
          */}
            <Button
              width={{ base: '100%', sm: 'auto' }}
              secondary
              icon="Collections"
              size="sm"
              fontSize="xs"
              ml={{ base: 0, sm: 2 }}
              mt={{ base: 2, sm: 0 }}
              onClick={() =>
                onClickUserActivity &&
                discourseCategoryId &&
                onClickUserActivity(
                  cohortId,
                  memberUsername,
                  discourseCategoryId
                )
              }
            >
              Posts
            </Button>
            {assessmentCount && assessmentCount > 0 ? (
              <Button
                width={{ base: '100%', sm: 'auto' }}
                secondary
                icon="ContentPaste"
                size="sm"
                fontSize="xs"
                ml={{ base: 0, sm: 2 }}
                mt={{ base: 2, sm: 0 }}
                onClick={() =>
                  onClickAssessment && onClickAssessment(username, cohortId)
                }
              >
                {`${assessmentCount} Assessment${
                  assessmentCount > 1 ? 's' : ''
                }`}
              </Button>
            ) : null}
          </Flex>
        </Card>
      </Skeleton>
    </Box>
  );
};

interface Cohort {
  id: number;
  members: CohortMember[];
  journalUnits: JournalUnitSummary[];
  totalPosts?: number;
  onExpandCohort: () => Promise<void>;
  startDate: string;
  status: 'active' | 'upcoming' | 'expired';
  statusDescription?: string;
  discourseCategoryId: number | null;
  discourseGroupChatTopicId?: number;
  label: string;
  isLoading?: boolean;
  assessmentsLoading?: boolean;
}
interface CohortItemProps extends Cohort {
  currentUserName: string;
  groupChatTitle?: string;
  onClickAssessment?: (userName: string, cohortId: number) => void;
  onClickAllActivity?: (cohortId: number, subcategory: number) => void;
  onClickUserActivity?: (
    cohortId: number,
    username: string,
    subcategory: number
  ) => void;
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
  dispatch: Dispatch;
}

const CohortItem: React.FC<CohortItemProps> = (
  {
    members,
    journalUnits,
    totalPosts,
    status,
    startDate,
    statusDescription,
    id: cohortId,
    discourseCategoryId,
    discourseGroupChatTopicId,
    label,
    currentUserName,
    groupChatTitle,
    onClickAssessment,
    onClickAllActivity,
    onClickUserActivity,
    isOpen,
    onOpen,
    onClose,
    dispatch,
    isLoading,
    assessmentsLoading,
  },
  idx
) => {
  return (
    <AccordionItem
      key={`accordion-item-${cohortId}`}
      borderTop={idx === 0 ? 0 : 'default'}
    >
      {({ isExpanded }) => (
        <>
          <AccordionButton display="flex" p={3}>
            <Flex flex={1}>
              <HeaderTag
                title={status}
                bg={getBackgroundColor(status)}
                color={getColor(status)}
                mr={3}
                minW="75px"
                justifyContent="center"
              />
              <Text textAlign="left">
                {`${moment(startDate).format('Do MMMM YYYY')}${
                  label ? ` - ${label}` : ''
                }`}
              </Text>
              {status === 'active' && statusDescription && (
                <Text color="text.muted" flex={1} ml={4} textAlign="left">
                  {statusDescription}
                </Text>
              )}
            </Flex>
            {totalPosts ? (
              <Text color="red.400" mr="2">{`${totalPosts} new posts`}</Text>
            ) : null}
            <Text
              color="text.muted"
              pr={1}
              display={{ base: 'none', sm: 'inline' }}
            >
              {isExpanded ? 'Collapse' : 'Expand'}
            </Text>
            <AccordionIcon color="text.muted" />
          </AccordionButton>
          <AccordionPanel padding={2} backgroundColor="background.tint1">
            {assessmentsLoading ? (
              <Flex flex={1} justifyContent="center" alignItems="center" my={2}>
                <Spinner color="text.muted" size="sm" mr={2} />
                <Text fontSize="sm" color="text.muted">
                  Student assessments loading...
                </Text>
              </Flex>
            ) : null}
            <Flex flexWrap="wrap">
              {isExpanded
                ? members.map((m) => (
                    <MemberCard
                      {...m}
                      key={`member-card-${cohortId}-${m.id}`}
                      onClickAssessment={onClickAssessment}
                      onClickUserActivity={onClickUserActivity}
                      currentUserName={currentUserName}
                      cohortId={cohortId}
                      dispatch={dispatch}
                      discourseCategoryId={discourseCategoryId}
                    />
                  ))
                : null}
            </Flex>
            <Flex
              flexDirection={{ base: 'column', md: 'row' }}
              justifyContent="flex-end"
              p={2}
              pt={3}
            >
              {discourseGroupChatTopicId ? (
                <Button
                  isLoading={isLoading}
                  icon="Chat"
                  size="sm"
                  onClick={() =>
                    discourseGroupChatTopicId &&
                    dispatch(
                      uiAction.toggleUserMessaging({
                        discourseGroupTopicId: discourseGroupChatTopicId,
                        title: groupChatTitle || '',
                        isExpanded: true,
                      })
                    )
                  }
                >
                  Open Class Discussion
                </Button>
              ) : null}
              {discourseCategoryId ? (
                <Button
                  isLoading={isLoading}
                  icon="Collections"
                  size="sm"
                  ml={{ base: 0, md: 2 }}
                  mt={{ base: 2, md: 0 }}
                  onClick={() =>
                    onClickAllActivity &&
                    onClickAllActivity(cohortId, discourseCategoryId)
                  }
                >
                  View All Post Activity
                </Button>
              ) : null}
              <Button
                isLoading={isLoading || assessmentsLoading}
                icon="TableChart"
                size="sm"
                ml={{ base: 0, md: 2 }}
                mt={{ base: 2, md: 0 }}
                onClick={onOpen}
              >
                See Table of Uploads
              </Button>
            </Flex>
          </AccordionPanel>
          {isExpanded ? (
            <JournalEntryModal
              isOpen={isOpen}
              onClose={onClose}
              members={members}
              journalUnits={journalUnits}
            />
          ) : null}
        </>
      )}
    </AccordionItem>
  );
};

interface CohortsCardProps {
  cohorts: Cohort[];
  currentUserName: string;
  courseTitle?: string;
  onClickAssessment?: (userName: string, cohortId: number) => void;
  onClickAllActivity?: (cohortId: number, subcategory: number) => void;
  onClickUserActivity?: (
    cohortId: number,
    username: string,
    subcategory: number
  ) => void;
  isLoading?: boolean;
}
const CohortsCard: React.FC<CohortsCardProps> = ({
  courseTitle = undefined,
  cohorts,
  currentUserName,
  isLoading,
  onClickAssessment,
  onClickAllActivity,
  onClickUserActivity,
}) => {
  const [expandedItemIndexes, setExpandedItemIndexes] = useState<number[]>([]);
  const [showExpiredCohorts, setShowExpiredCohorts] = useState<boolean>(false);

  useEffect(() => {}, [expandedItemIndexes]);

  const dispatch = useDispatch();

  // Journal Entries Modal
  const { isOpen, onOpen, onClose } = useDisclosure();

  const cohortsByStatus = groupBy(cohorts, 'status');

  return (
    <Card padding={0}>
      {isLoading ? (
        <Skeleton isLoaded={false} w="100%" margin={2}>
          <Box h="40px" w="100%" />
        </Skeleton>
      ) : (
        <Accordion
          // allowMultiple - this breaks things at the moment
          allowToggle
          flex="1"
          onChange={(expandedIdx) => {
            // Called whenever an item is expanded or collapsed
            const expandedIndexes =
              typeof expandedIdx === 'number' ? [expandedIdx] : expandedIdx;

            const allCohorts = [];
            if (cohortsByStatus.active) {
              allCohorts.push(...cohortsByStatus.active);
            }
            if (cohortsByStatus.upcoming) {
              allCohorts.push(...cohortsByStatus.upcoming);
            }
            if (cohortsByStatus.expired) {
              allCohorts.push(...cohortsByStatus.expired);
            }

            const expandedCohort = allCohorts.find(
              (c, idx) =>
                !expandedItemIndexes.includes(idx) &&
                expandedIndexes.includes(idx)
            );

            expandedCohort && expandedCohort.onExpandCohort();
            setExpandedItemIndexes(expandedIndexes);
          }}
        >
          {cohortsByStatus.active?.map((c, idx) =>
            CohortItem(
              {
                ...c,
                currentUserName,
                groupChatTitle: courseTitle,
                onClickAssessment,
                onClickAllActivity,
                onClickUserActivity,
                isOpen,
                onOpen,
                onClose,
                dispatch,
              },
              idx
            )
          )}
          {cohortsByStatus.upcoming?.map((c) =>
            CohortItem({
              ...c,
              currentUserName,
              groupChatTitle: courseTitle,
              onClickAssessment,
              onClickAllActivity,
              onClickUserActivity,
              isOpen,
              onOpen,
              onClose,
              dispatch,
            })
          )}
          {cohortsByStatus.expired?.length > 0 ? (
            <>
              <Flex borderTop="default" p={3}>
                <Button
                  secondary
                  size="xs"
                  onClick={() => setShowExpiredCohorts(!showExpiredCohorts)}
                  icon="History"
                >
                  {`${
                    showExpiredCohorts
                      ? 'Hide'
                      : `Show ${cohortsByStatus.expired.length}`
                  } Expired Class${
                    cohortsByStatus.expired.length === 1 ? '' : 'es'
                  }`}
                </Button>
              </Flex>
              {showExpiredCohorts
                ? cohortsByStatus.expired.map((c) =>
                    CohortItem({
                      ...c,
                      currentUserName,
                      groupChatTitle: courseTitle,
                      onClickAssessment,
                      onClickAllActivity,
                      onClickUserActivity,
                      isOpen,
                      onOpen,
                      onClose,
                      dispatch,
                    })
                  )
                : null}
            </>
          ) : null}
        </Accordion>
      )}
    </Card>
  );
};

export default CohortsCard;
