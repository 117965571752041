import React from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import {
  Card,
  Flex,
  Heading,
  Text,
  Link as ExternalLink,
  LinkButton,
} from '@workshop/ui';

import { BrandLg } from 'components/Brand';

import navRoutes from 'navigation/Routes';
import { useIsAuthenticated } from 'redux/selectors/auth';

import { PasswordResetForm } from './PasswordResetForm';

interface MatchParams {
  keyId?: string;
  uidb36?: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const PasswordReset: React.FC<Props> = ({ history, match }) => {
  const isAuthenticated = useIsAuthenticated();

  if (isAuthenticated) {
    return <Redirect to={navRoutes.common.home.path()} />;
  }

  const { keyId, uidb36 } = match.params;

  const onVerifiedKey = uidb36
    ? () => {
        history.push(navRoutes.public.resetPassword.path(uidb36));
      }
    : undefined;

  return (
    <Flex
      position="absolute"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      width="100vw"
      minHeight="100vh"
      py={12}
      backgroundColor="background.tint3"
    >
      <BrandLg mb={10} />
      <Card
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        maxWidth={450}
        width="100%"
        padding={{ base: 6, sm: 10 }}
      >
        <Heading
          as="h1"
          fontSize="2xl"
          fontWeight="bold"
          mb={2}
          textAlign="center"
        >
          Reset Your Password
        </Heading>
        <PasswordResetForm
          keyId={keyId}
          uidb36={uidb36}
          onVerifiedKey={onVerifiedKey}
        />
        <Text mt="defaultMargin" fontSize="sm" textAlign="center">
          Please{' '}
          <ExternalLink href="https://help.workshop.ws/" color="common.primary">
            contact us
          </ExternalLink>{' '}
          if you have any trouble resetting your password.
        </Text>
      </Card>
      <Flex
        mt={4}
        py={4}
        alignItems="center"
        maxWidth={450}
        width="100%"
        paddingX={{ base: 4, md: 0 }}
        flexDirection={{ base: 'column', sm: 'row' }}
        borderTopWidth="1px"
        borderBottomWidth="1px"
        borderColor="border.default"
      >
        <Text flex={1} mb={{ base: 2, sm: 0 }}>
          Remembered your password?
        </Text>
        <LinkButton to={navRoutes.public.login.path()}>Log In</LinkButton>
      </Flex>
    </Flex>
  );
};

export default PasswordReset;
