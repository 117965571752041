import { useEffect, useState, useRef, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';
import { usePreviousValue } from 'beautiful-react-hooks';

import { ThunkAction } from 'types';

import navRoutes, { Route } from 'navigation/Routes';

import useDeepEqualEffect from './useDeepEqualEffect';
import useChunkUpload from './useChunkUpload';
import useUserAccess from './useAccess';
import useLoadBaseData from './useLoadBaseData';
import useDateTimeSelect from './useDateTimeSelect';
import useColors from './useColors';

/**
 * Used to reflect the loading state of a component while fetching data.
 * Receives a single action, calls it and waits for the response
 */
const useLoadingData = <T>(
  action: () => ThunkAction<T>
): [boolean, T | undefined] => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  let result = useRef<T>();

  useEffect(() => {
    const loadData = async () => {
      result.current = await dispatch(action());
      setIsLoading(false);
    };

    loadData();
  }, []);

  return [isLoading, result.current];
};

/**
 * Used to reflect the loading state of a component while fetching data.
 * Receives an object where each key references a list of actions.
 *
 * Each list of action is called individually and resolved in the same promise.
 * Once all actions in a given list is completed, the relevant state is updated.
 *
 * Returns an object with keys matching that of the given actionData argument.
 * The state value is true if still loading, false once all actions are completed.
 */
const useLoadingDataState = (
  actionData: {
    [key: string]: {
      actions: (() => ThunkAction)[];
      // TODO: Will refactor this once we've figured out a way to
      // tackle chaining of action (which needs better typing)
      startLoading?: boolean;
      onError?: () => void;
    };
  },
  dependencies: React.DependencyList = []
): { [key: string]: boolean } => {
  const dispatch = useDispatch();

  const [loadingState, setLoadingState] = useState<{ [key: string]: boolean }>(
    Object.keys(actionData).reduce((acc, k) => {
      return Object.assign({ ...acc }, { [k]: true });
    }, {})
  );

  useEffect(() => {
    const actionsPromises = Object.keys(actionData).map((k) => {
      const { actions, startLoading = true, onError } = actionData[k];
      if (!startLoading) return;

      return async () => {
        await Promise.all(
          actions.map((a) =>
            dispatch(a()).then((res) => {
              //@ts-ignore
              if (res && res.error && onError) {
                onError();
              }
              return res;
            })
          )
        );
        setLoadingState((prevState) => ({ ...prevState, [k]: false }));
      };
    });

    Promise.all(actionsPromises.map((promise) => promise && promise()));
    // eslint-disable-next-line
  }, [dispatch, ...dependencies]);

  return loadingState;
};

const useEffectDebugger = (
  effectHook: React.EffectCallback,
  dependencies: React.DependencyList,
  dependencyNames = []
) => {
  const previousDeps: React.DependencyList = usePreviousValue(dependencies);

  const changedDeps = dependencies.reduce((accum, dependency, index) => {
    if (dependency !== previousDeps[index]) {
      const keyName = dependencyNames[index] || index;
      return {
        ...accum,
        [keyName]: {
          before: previousDeps[index],
          after: dependency,
        },
      };
    }

    return accum;
  }, {});

  if (Object.keys(changedDeps).length) {
    console.log('[use-effect-debugger] ', changedDeps);
  }

  useDeepEqualEffect(effectHook, dependencies);
};

/**
 * Hook for extracting the current Route from out overall application route
 * definition, based on the current URL.
 */
const useCurrentRoute = () => {
  const location = useLocation();

  const currentRoute = useMemo(() => {
    // Build an array of Route's from the `navRoutes`. Since `navRoutes` has
    // 2 levels of nesting (i.e. routes namespaced by function - e.g. admin)
    // we extract 2 levels deep and flatten to a single array
    const routes: Route[] = Object.values(navRoutes).flatMap((route) =>
      Object.values(route)
    );

    // See if the current path matches any route
    return routes.find((route) =>
      matchPath(location.pathname, {
        path: route.path(),
        exact: true,
        strict: false,
      })
    );
  }, [location.pathname]);

  return currentRoute;
};

export default {
  useChunkUpload,
  useDeepEqualEffect,
  useDispatch,
  useLoadingData,
  useLoadingDataState,
  useUserAccess,
  useLoadBaseData,
  useEffectDebugger,
  useCurrentRoute,
  useDateTimeSelect,
  useColors,
};
