import React, { useState, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import isURL from 'validator/lib/isURL';

import {
  Box,
  Flex,
  Text,
  Input,
  FormControl,
  FormLabel,
  FormErrorMessage,
} from '@workshop/ui';

import { ImageUpload, LabelTextArea } from 'components/Common';

import { EmailConfigFormData } from './EmailConfig';

interface Props {
  image: string | null | undefined;
  options: {
    [key: string]: string;
  };
  isDisabled?: boolean;
}

interface PreviewState {
  [key: string]: string | null;
}

// The image upload requires a static height and width dimension
const aspectRatio = 0.5625; // height / width;
const uploadWidth = 355;
const uploadHeight = uploadWidth * aspectRatio;

const WelcomeEmailConfig: React.FC<Props> = ({
  image,
  options,
  isDisabled = true,
}) => {
  const { register, setValue, errors } = useFormContext<EmailConfigFormData>();

  // TODO: Toggle downloads on/off
  // const [enabledDownloads, setEnabledDownlaods] = useState<string[]>();

  const [imagePreview, setImagePreview] = useState<string | null>(null);

  useEffect(() => {
    // Avoiding memory leaks by deleting the image preview on unmount
    return () => {
      imagePreview && URL.revokeObjectURL(imagePreview);
    };
  }, [imagePreview]);

  const onImageDrop = (name: string, acceptedFiles: File[]) => {
    setValue(name, acceptedFiles[0]);
    setImagePreview(URL.createObjectURL(acceptedFiles[0]));
  };

  const { welcomeEmail: welcomeEmailErrors } = errors;

  return (
    <>
      <Flex justifyContent="flex-stretch" paddingY="defaultPadding">
        <ImageUpload
          id="welcomeEmail[image]"
          name="welcomeEmail[image]"
          label="Header Image"
          width={uploadWidth}
          height={uploadHeight}
          backgroundColor="neutral.300"
          textColor="background.default"
          onDrop={onImageDrop}
          image={imagePreview || image || ''}
          isDisabled={isDisabled}
        />
        <Box flex={1} marginLeft="defaultMargin">
          <LabelTextArea
            registerInputRef={register}
            id="welcomeEmail[text]"
            name="welcomeEmail[text]"
            label="Welcome Email Text (Optional)"
            labelPosition="top"
            error={Boolean(welcomeEmailErrors?.text)}
            isDisabled={isDisabled}
          />
        </Box>
      </Flex>
      <Flex flexDirection="column">
        <FormLabel color="text.muted" fontSize="md">
          Downloads (Optional):
        </FormLabel>
        <Flex flexDir="column" flex={1}>
          {Object.keys(options).map((optionKey) => {
            const isInvalid = Boolean(
              // @ts-ignore
              welcomeEmailErrors?.downloads?.[optionKey]
            );
            return (
              <Flex key={optionKey} mb="defaultMargin">
                <Flex flex={1} mr="defaultMargin" alignItems="center">
                  <Text>{options[optionKey]}</Text>
                </Flex>
                <Flex flex={2}>
                  <Flex flex={1} flexDirection="column">
                    <FormControl isInvalid={isInvalid}>
                      <Input
                        ref={register({
                          validate: {
                            notUrl: (value: string) =>
                              value.length ? Boolean(isURL(value)) : true,
                          },
                        })}
                        name={`welcomeEmail[downloads]${optionKey}`}
                        placeholder="https://"
                        isDisabled={isDisabled}
                      />
                      <FormErrorMessage>
                        Please enter a valid URL
                      </FormErrorMessage>
                    </FormControl>
                  </Flex>
                  {/* <Checkbox ml="defaultMargin" /> */}
                </Flex>
              </Flex>
            );
          })}
        </Flex>
      </Flex>
    </>
  );
};

export default WelcomeEmailConfig;
