import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps, Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';

import { GlobalState } from 'types';
import { ICourseListItem } from 'types/cms';
import { CourseDetails } from 'types/learner';

import navRoutes from 'navigation/Routes';

import { courseActions as learnerCourseActions } from 'redux/actions/learner';
import { courseActions, enrolmentActions } from 'redux/actions/cms';
import { getLicensedCourses } from 'redux/selectors/course';

import { ICoursePublishStatus } from 'constants/courses';
import { CREATE_COURSE_WHITELIST } from 'constants/organisation';

import {
  Text,
  Card,
  Flex,
  Box,
  Button,
  useDisclosure,
  chakra,
} from '@workshop/ui';

import { CatalogueSkeleton } from 'screens/cms/CourseCatalogue/src/CatalogueSkeleton';
import { ScreenWrapper } from 'screens/common/ScreenWrapper';

import { SectionTitle, InformationCard } from 'components/Common';
import { ItemLarge } from 'components/ListItem';

import CreateCourseModal from './CreateCourseModal';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux, RouteComponentProps {}

const getBackgroundColor = (status: ICoursePublishStatus) => {
  switch (status) {
    case 'published':
      return 'background.success';

    case 'in_review':
      return 'background.warning';
  }

  return 'background.tint1';
};

const getColor = (status: ICoursePublishStatus) => {
  switch (status) {
    case 'published':
      return 'text.success';

    case 'in_review':
      return 'text.warning';
  }

  return 'text.muted';
};

const CourseCatalogue: React.FC<Props> = ({
  courses,
  licensedCourses,
  licenses,
  currentTeam,
  ui,
}) => {
  const dispatch = useDispatch();
  const [isUpdating, setIsUpdating] = useState(false);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    const loadData = async () => {
      await dispatch(courseActions.list());
    };
    loadData();
    dispatch(enrolmentActions.listLicenses());
  }, [dispatch]);

  useEffect(() => {
    if (licenses.length > 0) {
      let coursesToFetch: string[] = [];
      licenses.forEach((l) => {
        const slugs = l.courses.map((c) => c.slug);
        coursesToFetch = [...new Set([...coursesToFetch, ...slugs])];
      });
      coursesToFetch.forEach((courseSlug) => {
        dispatch(learnerCourseActions.retrieve(courseSlug));
      });
    }
  }, [licenses.length]);

  const handleSave = async (data: Partial<ICourseListItem>) => {
    setIsUpdating(true);
    await dispatch(courseActions.create(data));
    setIsUpdating(false);
    onClose();
  };

  const isLoading = ui.courseList.loading && isEmpty(courses);

  const licensedCoursesByOrg = licensedCourses
    ? Object.values(licensedCourses).reduce(
        (
          obj: {
            [orgId: string]: {
              id: number;
              name: string;
              courses: CourseDetails[];
              contactEmail: string | null;
            };
          },
          value
        ) => {
          const key = value.organisation.id.toString();
          if (obj[key] == null) {
            obj[key] = {
              id: value.organisation.id,
              name: value.organisation.name,
              courses: [],
              contactEmail: value.organisation.contactEmail,
            };
          }

          obj[key].courses.push(value);
          return obj;
        },
        {}
      )
    : null;

  const myCourses = Object.values(courses).filter(
    (c) => c.organisation === currentTeam
  );
  return (
    <ScreenWrapper>
      {licensedCoursesByOrg && (
        <>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="course_preview_section" mb={6} />
          </Box>
          {Object.values(licensedCoursesByOrg).map((org) => {
            return (
              <Box key={`library-org-${org.id}`} mb={4}>
                <SectionTitle title={`Courses from ${org.name}`} />
                {org.contactEmail && (
                  <Text
                    marginX={{ base: 'defaultMargin', md: 0 }}
                    color="text.muted"
                    fontSize="sm"
                    mb={4}
                  >
                    {`If you have any questions for ${org.name}, please contact `}
                    <chakra.a
                      href={`mailto:${org.contactEmail}`}
                      color="text.primary"
                      fontWeight="semibold"
                    >
                      {org.contactEmail}
                    </chakra.a>
                    {'.'}
                  </Text>
                )}
                <Flex
                  flexDirection="column"
                  position="relative"
                  mb="defaultMargin"
                >
                  {Object.values(org.courses).map((course) => {
                    return (
                      <Link
                        key={`licensed-${course.slug}`}
                        to={navRoutes.cms.previewCourse.path(course.id)}
                        style={{ display: 'block' }}
                      >
                        <Card
                          direction="column"
                          mb="defaultMargin"
                          padding={0}
                          cursor="pointer"
                          _hover={{ bg: 'background.tint1' }}
                        >
                          <ItemLarge
                            title={course.title}
                            image={course.imageLandscapeThumbnail}
                            buttonLabel="Preview Course"
                            buttonLink={navRoutes.cms.previewCourse.path(
                              course.id
                            )}
                            tag="Agreement Active"
                            tagBg="background.info"
                            tagColor="text.info"
                          />
                        </Card>
                      </Link>
                    );
                  })}
                </Flex>
              </Box>
            );
          })}
        </>
      )}

      <SectionTitle title="My Courses" />
      {isLoading ? (
        <CatalogueSkeleton />
      ) : (
        <Flex direction="column">
          {myCourses.length > 0 ? (
            <Box>
              {myCourses.map((course) => {
                return (
                  <Link
                    to={navRoutes.cms.editCourse.path(course.id.toString())}
                    style={{ display: 'block' }}
                  >
                    <Card
                      key={course.id}
                      direction="column"
                      mb="defaultMargin"
                      padding={0}
                      cursor="pointer"
                      _hover={{ bg: 'background.tint1' }}
                    >
                      <ItemLarge
                        key={course.id}
                        title={course.title}
                        image={course.imageLandscapeThumbnail}
                        buttonLabel="Edit Course"
                        buttonLink={navRoutes.cms.editCourse.path(
                          course.id.toString()
                        )}
                        tag={`${course.status}${
                          course.isLockedForEditing ? ' - Locked' : ''
                        }`}
                        tagBg={getBackgroundColor(course.status)}
                        tagColor={getColor(course.status)}
                      />
                    </Card>
                  </Link>
                );
              })}{' '}
              {/* TODO: Remove this when launching platform to public (currently only allowing Leiths & Workshop to create courses) */}
              {currentTeam && CREATE_COURSE_WHITELIST.includes(currentTeam) ? (
                <Button
                  mt={6}
                  mx={{ base: 'defaultMargin', md: 0 }}
                  onClick={onOpen}
                  icon="LibraryAdd"
                >
                  New Course
                </Button>
              ) : (
                <Button
                  mt={6}
                  mx={{ base: 'defaultMargin', md: 0 }}
                  icon="LibraryAdd"
                  isDisabled
                >
                  Create Course (Coming Soon)
                </Button>
              )}
            </Box>
          ) : (
            <Box marginX={{ base: 'defaultMargin', md: 0 }}>
              <Text color="text.muted" pb={8}>
                You don't currently have any courses.
              </Text>
              {currentTeam && CREATE_COURSE_WHITELIST.includes(currentTeam) ? (
                <Button icon="LibraryAdd" onClick={onOpen}>
                  Create Course
                </Button>
              ) : (
                <Button icon="LibraryAdd" isDisabled>
                  Create Course (Coming Soon)
                </Button>
              )}
            </Box>
          )}
        </Flex>
      )}
      <CreateCourseModal
        onSave={handleSave}
        isOpen={isOpen}
        onClose={onClose}
        isUpdating={isUpdating}
        title="New Course"
      />
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => {
  const {
    organisation: { currentTeam },
    learner: {
      courses: {
        courses: { detail: courseState },
      },
    },
    cms: {
      enrolment: { license: licenseState },
    },
  } = state;

  const licensedCourses = getLicensedCourses(
    courseState,
    licenseState,
    currentTeam
  );
  const licenses = Object.values(licenseState);
  return {
    courses: state.cms.course.courseList,
    licensedCourses,
    licenses,
    currentTeam,
    ui: state.ui.course,
  };
};

const connector = connect(mapStateToProps);

export default connector(CourseCatalogue);
