import { IDynamicTable } from 'components/Common/DynamicList';
import { ISessionType, SessionListState } from 'types/cms/session';
import { IDownload, IUnitType } from 'types/cms/unit';
import { UNIT_TYPE } from 'constants/courses';

export interface DraggableSession {
  id: string;
  index: number;
  label: string;
  moduleType: string;
  title: string;
}

type FormattedData = {
  [key in ISessionType]: DraggableSession[];
};

export const formatSessionList = (
  courseId: string,
  sessions: SessionListState,
  hasIntroSession: boolean
): FormattedData => {
  return Object.keys(sessions).reduce<FormattedData>(
    (acc, sessionId) => {
      const session = sessions[sessionId];
      acc[session.moduleType] = [
        ...acc[session.moduleType],
        {
          id: sessionId,
          title: session?.title,
          label:
            session?.moduleType === 'intro'
              ? 'Introduction'
              : session?.moduleType === 'outro'
              ? 'Summary'
              : // If unit has an intro stsessionep, the `Session x` ;
                // index needs offsetting by 1 to account for the
                // intro having the index of 1.
                `Session ${
                  hasIntroSession ? session?.index - 1 : session?.index
                }`,
          index: session?.index,
          moduleType: session?.moduleType,
        },
      ];

      return acc;
    },
    {
      normal: [],
      intro: [],
      outro: [],
    }
  );
};

/**
 * Map the data from the downloads dynamic list (table) into a valid
 * format for submitting over the API
 */
export const getFormattedDownloadData = (data: IDynamicTable): IDownload[] => {
  return Object.values(data)
    .filter((d) => d.name && d.link)
    .map((d) => {
      return {
        name: d.name || '',
        link: d.link || '',
        teacherOnly: d.teacherOnly === 'true',
      };
    });
};

/**
 * Build unit type specific string values
 */
export const buildUnitStrings = (
  unitType: IUnitType
): { sectionTitle: string; videoLabel: string } => {
  switch (unitType) {
    case UNIT_TYPE.intro:
      return { sectionTitle: 'Course Introduction', videoLabel: 'Video' };
    case UNIT_TYPE.outro:
      return { sectionTitle: 'Course Outro', videoLabel: 'Video' };
    default:
      return {
        sectionTitle: 'Unit Overview',
        videoLabel: 'Unit Intro Video',
      };
  }
};
