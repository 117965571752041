import React, { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import uniqBy from 'lodash/uniqBy';

import { Box, Flex, Text, Button, useTheme } from '@workshop/ui';

import { GlobalState } from 'types';
import { Organisation, RoleState, PERMISSION_SLUGS } from 'types/common';
import { License } from 'types/cms';

import { hooks } from 'utils';
import { useWindowDimensions } from 'utils/hooks/useDimensions';

import { organisationActions } from 'redux/actions/common';
import { enrolmentActions } from 'redux/actions/cms';
import { useHasPermission } from 'redux/selectors';

import { SectionTitle, InPageNav, InformationCard } from 'components/Common';
import {
  AddressDetailsCard,
  BillingDetailsCard,
  OrganisationDetailsCard,
  TeamMembersCard,
  CreateLicenseModal,
  EditLicenseModal,
  LicensesTable,
} from '.';

import { ScreenWrapper } from 'screens/common/ScreenWrapper';

type PropsFromRedux = ConnectedProps<typeof connector>;

interface MyOrganisationProps extends PropsFromRedux, RouteComponentProps {}

const getMemberRoles = (roles: number[] | undefined, roleState: RoleState) =>
  roles?.map((id) => roleState[id]?.name || '').filter((a) => a) || [];

const MyOrganisation: React.FC<MyOrganisationProps> = ({
  currentTeam,
  invitations,
  organisationProfile,
  permissions,
  roles,
  teamMembers,
  licenses,
  costRecords,
}) => {
  const [currentView, setCurrentView] = useState('account');
  const [showCreateLicense, setShowCreateLicense] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState<License | null>(null);

  const dispatch = useDispatch();
  // Certain API calls and UI elements should only be available if the user
  // has edit member permissions
  const isViewMembersDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_view_members
  );

  const isEditBillingDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_billing_details
  );

  const isViewAgreementsDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_view_agreements
  );
  const isEditAgreementsDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_edit_agreements
  );

  const isViewReportsDisabled = !useHasPermission(
    PERMISSION_SLUGS.can_view_reports
  );

  const {
    teamMembership: teamsLoading,
    organisationProfile: organisationLoading,
    invitations: invitationsLoading,
    licenses: licensesLoading,
    costRecords: costRecordsLoading,
  } = hooks.useLoadingDataState(
    {
      teamMembership: {
        actions:
          currentTeam && !isViewMembersDisabled
            ? [() => organisationActions.listTeamMembers()]
            : [],
      },
      organisationProfile: {
        actions: currentTeam
          ? [() => organisationActions.fetchOrganisationProfile(currentTeam)]
          : [],
      },
      invitations: {
        actions: !isViewMembersDisabled
          ? [() => organisationActions.listMemberInvitation()]
          : [],
      },
      licenses: {
        actions:
          currentTeam && !isViewAgreementsDisabled
            ? [() => enrolmentActions.listLicenses()]
            : [],
      },
      costRecords: {
        actions:
          currentTeam && !isViewReportsDisabled
            ? [() => enrolmentActions.listCostRecords()]
            : [],
      },
    },
    [currentTeam]
  );

  const theme = useTheme();
  const windowDimensions = useWindowDimensions();
  const isMobile = windowDimensions.width < parseInt(theme.breakpoints.md, 10);

  const isLoading = teamsLoading || organisationLoading || invitationsLoading;

  const {
    accountName = null,
    accountNumber = null,
    address = null,
    city = null,
    contactEmail = null,
    contactNumber = null,
    introduction = null,
    logo = null,
    logoDark = null,
    name = null,
    postCode = null,
    sortCode = null,
    vatNumber = null,
    websiteUrl = null,
  } = organisationProfile || {};

  const members = teamMembers.map((m) => {
    const memberPerm: string[] = [];
    m.permissions?.forEach(
      (id) => permissions[id] && memberPerm.push(permissions[id].name)
    );

    return {
      id: m.id,
      name: m.user.name,
      email: m.user.email,
      roles: getMemberRoles(m.roles, roles),
      status: 'active' as const,
      invitationSentDate: '',
    };
  });

  const invitedMembers = Object.values(invitations).map(
    ({ id, sent, name, email, accepted, roles: invitedRoles }) => ({
      id,
      invitationSentDate: sent,
      name,
      email,
      roles: getMemberRoles(invitedRoles, roles),
      status: accepted ? ('active' as const) : ('invited' as const),
    })
  );

  const updateOrganisationData = async (data: Partial<Organisation>) => {
    if (!currentTeam) return;
    await dispatch(
      organisationActions.updateOrganisationProfile(currentTeam, { ...data })
    );
  };

  const createdLicenses = Object.values(licenses).filter(
    (l) => l.licensor.id === currentTeam
  );

  const receivedLicenses = Object.values(licenses).filter(
    (l) => l.licensee.id === currentTeam
  );

  const tabs = [
    {
      slug: 'account',
      label: 'Account',
      icon: 'Work',
    },
  ];

  if (!isViewMembersDisabled) {
    tabs.push({
      slug: 'team',
      label: 'Team',
      icon: 'Group',
    });
  }

  if (!isViewAgreementsDisabled) {
    tabs.push({
      slug: 'agreements',
      label: 'Agreements',
      icon: 'Description',
    });
  }

  if (!isEditBillingDisabled) {
    tabs.push({
      slug: 'payments',
      label: 'Payments',
      icon: 'Payments',
    });
  }

  return (
    <ScreenWrapper>
      <InPageNav
        tabs={tabs}
        initialTab="account"
        onSwitchTab={(activeTab) => setCurrentView(activeTab)}
        navByParams
      />
      {currentView === 'account' ? (
        <Flex direction="column" flex={1}>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="account_screen" mb={6} />
          </Box>
          <Box mb="defaultMargin" flex={1}>
            <SectionTitle title="Account Details" />
            <OrganisationDetailsCard
              isLoading={isLoading}
              onSubmit={updateOrganisationData}
              introduction={introduction || ''}
              contactEmail={contactEmail || ''}
              contactNumber={contactNumber || ''}
              logo={logo || ''}
              logoDark={logoDark || ''}
              name={name || ''}
              websiteUrl={websiteUrl || ''}
            />
          </Box>
          <Box my="defaultMargin" flex={1}>
            <SectionTitle title="Address" />
            <AddressDetailsCard
              address={address || ''}
              city={city || ''}
              postCode={postCode || ''}
              onSubmit={updateOrganisationData}
            />
          </Box>
        </Flex>
      ) : currentView === 'team' && !isViewMembersDisabled ? (
        <Flex direction="column" flex={1}>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="team_screen" mb={6} />
          </Box>

          <Box>
            <SectionTitle title="Team Members" />
            <TeamMembersCard
              isLoading={isLoading && isEmpty(members)}
              members={uniqBy([...members, ...invitedMembers], 'email')}
            />
          </Box>
        </Flex>
      ) : currentView === 'agreements' && !isViewAgreementsDisabled ? (
        <>
          <Flex direction="column" flex={1}>
            <Box mx={{ base: 'defaultMargin', md: 0 }}>
              <InformationCard id="agreements_screen" mb={6} />
            </Box>
            <Flex
              mb={4}
              flexDirection={{ base: 'column', sm: 'row' }}
              alignItems={{ base: 'flex-start', sm: 'normal' }}
            >
              <SectionTitle
                flex={1}
                mb={{ base: 3, sm: 0 }}
                title="Bulk Enrolment Agreements"
              />
              {!isEditAgreementsDisabled && (
                <Button
                  size="sm"
                  icon="NoteAdd"
                  mx={{ base: 'defaultMargin', md: 0 }}
                  onClick={() => setShowCreateLicense(true)}
                >
                  New Agreement
                </Button>
              )}
            </Flex>

            <Text
              fontSize="sm"
              fontWeight="semibold"
              marginX={{ base: 'defaultMargin', md: 0 }}
            >
              {`Created By ${name}`}
            </Text>
            <LicensesTable
              licenses={createdLicenses}
              isMobile={isMobile}
              type="created"
              onSelectLicense={(l) => setSelectedLicense(l)}
            />
            <Text
              fontSize="sm"
              fontWeight="semibold"
              marginX={{ base: 'defaultMargin', md: 0 }}
            >
              {`Created For ${name}`}
            </Text>
            <LicensesTable
              licenses={receivedLicenses}
              isMobile={isMobile}
              type="received"
              onSelectLicense={(l) => setSelectedLicense(l)}
            />
          </Flex>
          {showCreateLicense && (
            <CreateLicenseModal
              title="New Bulk Enrolment Agreement"
              isOpen
              onClose={() => setShowCreateLicense(false)}
              onCancel={() => setShowCreateLicense(false)}
              onSave={() => null}
              modalSize="lg"
            />
          )}
          {!!selectedLicense && (
            <EditLicenseModal
              title="Agreement Details"
              isOpen
              onClose={() => setSelectedLicense(null)}
              onCancel={() => setSelectedLicense(null)}
              onSave={() => null}
              license={selectedLicense}
              modalSize="lg"
              isLoading={costRecordsLoading}
              costRecord={Object.values(costRecords).find(
                (record) => record.contentLicense === selectedLicense.id
              )}
              showLicensorInfo={selectedLicense.licensor.id === currentTeam}
              showLicenseeInfo={selectedLicense.licensee.id === currentTeam}
            />
          )}
        </>
      ) : currentView === 'payments' && !isEditBillingDisabled ? (
        <Flex direction="column" flex={1}>
          <Box mx={{ base: 'defaultMargin', md: 0 }}>
            <InformationCard id="payments_screen" mb={6} />
          </Box>
          <Flex alignItems="center">
            <SectionTitle title="Bank Details" />
            <Text ml={1} mb={3} fontSize="sm" color="text.muted">
              (to receive payment)
            </Text>
          </Flex>
          <BillingDetailsCard
            accountName={accountName || ''}
            accountNumber={accountNumber || undefined}
            sortCode={sortCode || ''}
            vatNumber={vatNumber ? parseInt(vatNumber) : undefined}
            onSubmit={updateOrganisationData}
          />
        </Flex>
      ) : null}
    </ScreenWrapper>
  );
};

const mapStateToProps = (state: GlobalState) => ({
  currentTeam: state.organisation.currentTeam as number,
  invitations: state.organisation.invitations,
  organisationProfile: state.organisation.currentTeam
    ? state.organisation.teamProfiles[state.organisation.currentTeam]
    : null,
  teamMembers: Object.values(state.organisation.currentTeamMemberList),
  permissions: state.organisation.permissions,
  roles: state.organisation.roles,
  licenses: state.cms.enrolment.license,
  costRecords: state.cms.enrolment.costRecord,
});

const connector = connect(mapStateToProps);

export default connector(MyOrganisation);
