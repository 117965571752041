import React, { useState, useRef, useEffect } from 'react';
import { useSpring } from 'react-spring';
import { useForm } from 'react-hook-form';

import { Flex, Text, Input, MdIcon, Spinner } from '@workshop/ui';

import { AnimatedButton } from 'components/Common';

export interface IAddItem {
  inputText: string;
}

interface AddItemProps {
  onSave: (formData: IAddItem) => void;
  label: string;
  onClick?: (...args: any) => void;
  helpText?: string;
  isUpdating?: boolean;
  flexDir?: 'row' | 'column';
}

const AddItem: React.FC<AddItemProps> = ({
  onSave,
  label,
  onClick,
  helpText,
  isUpdating = false,
  flexDir = 'row',
}) => {
  const { register, handleSubmit, errors, clearError, formState, reset } =
    useForm<IAddItem>();
  const textInputEl = useRef<HTMLInputElement>();
  const [isSaving, setIsSaving] = useState(false);
  const [isInputVisible, setIsInputVisible] = useState(false);

  useEffect(() => {
    // When the text input is shown, autofocus it
    if (isInputVisible) {
      textInputEl.current?.focus();
    }
  }, [isInputVisible]);

  useEffect(() => {
    if (!onClick) {
      // If `isSaving` is true and `isUpdating` transitions
      // from true -> false, then we need to hide the input
      // as an update has occurred.
      if (isSaving && !isUpdating) {
        setIsInputVisible(false);
        setIsSaving(false);
      }
    }
  }, [isSaving, isUpdating, onClick]);

  const buttonsDisabled = Boolean(!formState.dirty || errors.inputText);

  const btnStyle = useSpring({
    opacity: buttonsDisabled || isUpdating ? 0.5 : 1,
  });

  const onAddItem = () => {
    onClick ? onClick() : setIsInputVisible(true);
  };

  const onSubmit = handleSubmit((formData) => {
    setIsSaving(true);
    onSave(formData);
  });

  const onCancel = () => {
    clearError();
    reset({ inputText: '' });
    setIsInputVisible(false);
  };

  return (
    <Flex
      flex={1}
      padding="defaultPadding"
      cursor="pointer"
      flexDirection="column"
      onClick={isInputVisible || isUpdating ? () => null : onAddItem}
      _hover={isInputVisible || isUpdating ? {} : { opacity: 0.8 }}
    >
      <Flex flex={1} alignItems="center">
        {isInputVisible ? (
          <Flex flex={1} flexDir={flexDir}>
            <Flex
              flex={2}
              alignItems="center"
              mb={flexDir === 'column' ? 2 : 0}
            >
              <MdIcon
                name="AddCircle"
                color="common.primary"
                mr={2}
                fontSize="lg"
              />
              <Input
                id="inputText"
                name="inputText"
                ref={(e: HTMLInputElement | null) => {
                  if (e) {
                    register(e);
                    textInputEl.current = e;
                  }
                }}
                color="text.default"
                placeholder={label}
              />
            </Flex>
            <Flex flex={1} justifyContent="flex-end" alignItems="center">
              <AnimatedButton
                secondary
                onClick={onCancel}
                isDisabled={isUpdating}
              >
                Cancel
              </AnimatedButton>
              <AnimatedButton
                isLoading={isUpdating}
                isDisabled={buttonsDisabled || isUpdating}
                style={btnStyle}
                onClick={onSubmit}
              >
                Save
              </AnimatedButton>
            </Flex>
          </Flex>
        ) : isUpdating ? (
          <Spinner color="common.primary" />
        ) : (
          <>
            <MdIcon
              name="AddCircle"
              color="common.primary"
              mr={2}
              fontSize="lg"
            />
            <Text
              color={isInputVisible ? 'neutral.300' : 'common.primary'}
              fontWeight="semibold"
              fontSize="md"
            >
              {label}
            </Text>
          </>
        )}
      </Flex>
      {helpText && (
        <Flex>
          <Text fontSize="xs" color="text.muted">
            {helpText}
          </Text>
        </Flex>
      )}
    </Flex>
  );
};

export default AddItem;
