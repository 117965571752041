import React, { useState } from 'react';
import { FormContext, useForm } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';

import { Flex, Card, Button, useDisclosure } from '@workshop/ui';

import { CourseEmailPreviewAction } from 'types/cms';
import { hooks } from 'utils';

import { LabelCheckbox } from 'components/Common';
import { AnimatedButton } from 'components/Common';

import EmailConfigModal, { ModalFormData } from './EmailConfigModal';
import WelcomeEmailConfig from './WelcomeEmailConfig';

export type EmailConfigTypes =
  | 'welcomeEmail'
  | 'automatedEmail'
  | 'onboardingEmail'
  | null;

export interface EmailConfigFormData {
  welcomeEmailEnabled: boolean;
  onboardingEmailEnabled: boolean;
  automatedEmailEnabled: boolean;
  welcomeEmail: {
    image: File | string | null | undefined;
    text: string | null;
    downloads: {
      welcomePack?: string;
      healthSafety?: string;
      equipmentDownload?: string;
    } | null;
  };
}

interface EmailConfigItemProps {
  register: RegisterFunc<HTMLInputElement>;
  name: string;
  label: string;
  helpText?: string;
  onChange?: (arg0: React.ChangeEvent<HTMLInputElement>) => void;
  isChecked: boolean;
  isDisabled: boolean;
  onPreview: () => void;
}

interface EmailConfigProps {
  welcomeEmail: {
    image: string | null | undefined;
    text: string | null;
    downloads: { [key: string]: string } | null;
    enabled: boolean;
  };
  automatedEmailEnabled?: boolean;
  onboardingEmailEnabled?: boolean;
  downloadOptions: {
    [key: string]: string;
  };
  unitOptions?: {
    [key: string]: string;
  };
  handleSendEmailSubmit: (
    arg0: ModalFormData
  ) => Promise<CourseEmailPreviewAction | void>;
  handleSubmit: (arg0: EmailConfigFormData) => void;
  isLoading?: boolean;
  isDisabled?: boolean;
}

const EmailConfigItem: React.FC<EmailConfigItemProps> = ({
  register,
  name,
  label,
  helpText,
  onChange,
  isChecked,
  isDisabled,
  onPreview,
  children,
}) => (
  <Flex
    flexDirection={children ? 'column' : 'row'}
    padding="defaultPadding"
    alignItems={children ? 'stretch' : 'center'}
    borderBottom="1px"
    borderColor="border.muted"
  >
    <LabelCheckbox
      registerInputRef={register}
      name={name}
      label={label}
      helpText={helpText}
      onChange={onChange}
      isChecked={isChecked}
      isDisabled={isDisabled}
    />
    {children}
    <Flex justifyContent="flex-end">
      <Button onClick={onPreview} isDisabled={!isChecked || isDisabled}>
        Preview
      </Button>
    </Flex>
  </Flex>
);

const EmailConfig: React.FC<EmailConfigProps> = ({
  downloadOptions,
  handleSubmit,
  handleSendEmailSubmit,
  unitOptions = {},
  welcomeEmail,
  automatedEmailEnabled,
  onboardingEmailEnabled,
  isDisabled = true,
  isLoading = false,
}) => {
  // Init form with default values
  const formMethods = useForm<EmailConfigFormData>({
    defaultValues: {
      welcomeEmailEnabled: !!welcomeEmail.enabled,
      onboardingEmailEnabled: !!onboardingEmailEnabled,
      automatedEmailEnabled: !!automatedEmailEnabled,
      welcomeEmail: {
        text: welcomeEmail.text || '',
        downloads: welcomeEmail.downloads,
        // Don't add image to form state as form state should
        // only contain *new* image data for upload
        // image: welcomeEmail.image,
      },
    },
  });

  const {
    register,
    watch,
    errors,
    reset,
    setValue,
    formState: { dirty },
    handleSubmit: baseHandleSubmit,
  } = formMethods;

  // Watch checkbox fields to control display of UI
  const isChecked = watch([
    'welcomeEmailEnabled',
    'onboardingEmailEnabled',
    'automatedEmailEnabled',
  ]);

  // State for controlling the display and type of email preview modal
  // to display
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [emailType, setEmailType] = useState<EmailConfigTypes>(null);

  // React to any changes in the email data which occurs from our props
  hooks.useDeepEqualEffect(() => {
    reset({
      welcomeEmailEnabled: welcomeEmail.enabled,
      onboardingEmailEnabled: onboardingEmailEnabled,
      automatedEmailEnabled: automatedEmailEnabled,
      welcomeEmail: {
        text: welcomeEmail.text || '',
        downloads: welcomeEmail.downloads,
        // Don't add image to form state as form state should
        // only contain *new* image data for upload
        // image: welcomeEmail.image,
      },
    });
  }, [reset, welcomeEmail, onboardingEmailEnabled, automatedEmailEnabled]);

  // Dynamically toggle checkbox values of the form when they are checked/unchecked
  const setIsChecked = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(e.target.name, e.target.checked);
  };

  const handleModalSubmit = (formData: ModalFormData) => {
    return handleSendEmailSubmit(formData);
  };

  const onSubmit = baseHandleSubmit((formData) => {
    handleSubmit(formData);
  });

  return (
    <>
      <Card padding={0} flex={1}>
        <FormContext {...formMethods}>
          <Flex as="form" onSubmit={onSubmit} flexDir="column" flex={1}>
            <EmailConfigItem
              register={register}
              name="welcomeEmailEnabled"
              label="Enable Welcome Email"
              helpText="Following the purchase of a course, students can receive a welcome email will include all of the information needed to get started (recommended)."
              onChange={setIsChecked}
              isChecked={isChecked.welcomeEmailEnabled}
              isDisabled={isDisabled}
              onPreview={() => {
                onOpen();
                setEmailType('welcomeEmail');
              }}
            >
              <WelcomeEmailConfig
                image={welcomeEmail.image}
                options={downloadOptions}
                isDisabled={!isChecked.welcomeEmailEnabled || isDisabled}
              />
            </EmailConfigItem>
            <EmailConfigItem
              register={register}
              name="onboardingEmailEnabled"
              label="Enable Onboarding Email"
              helpText="2 weeks before a cohort start date, students can access their course. This email notifies students of this and contains steps explaining how to access the course."
              onChange={setIsChecked}
              isChecked={isChecked.onboardingEmailEnabled}
              isDisabled={isDisabled}
              onPreview={() => {
                onOpen();
                setEmailType('onboardingEmail');
              }}
            />
            <EmailConfigItem
              register={register}
              name="automatedEmailEnabled"
              label="Enable Automated Unit Emails"
              helpText="At the end of each unit, students receive an email containing links to all the downloadable resources for the completed unit, as well as a list of everything they'll need for the upcoming unit."
              onChange={setIsChecked}
              isChecked={isChecked.automatedEmailEnabled}
              isDisabled={isDisabled}
              onPreview={() => {
                onOpen();
                setEmailType('automatedEmail');
              }}
            />
            {!isDisabled && (
              <Flex padding="defaultPadding" justifyContent="flex-end">
                <AnimatedButton
                  variant="solid"
                  colorScheme="blue"
                  type="submit"
                  onClick={() => {}} // noop as this is a submit button
                  isLoading={isLoading && !isOpen}
                  isDisabled={!isEmpty(errors) || !dirty}
                >
                  Save
                </AnimatedButton>
              </Flex>
            )}
          </Flex>
        </FormContext>
      </Card>
      <EmailConfigModal
        isOpen={isOpen}
        onClose={onClose}
        emailType={emailType}
        handleSubmit={handleModalSubmit}
        options={unitOptions}
      />
    </>
  );
};

export default EmailConfig;
