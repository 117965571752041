import SparkMD5 from 'spark-md5';
import { useDispatch } from 'react-redux';

import { getCookie } from 'utils';
import API from 'constants/api';
import { STATUS } from 'constants/background';

import { backgroundActions } from 'redux/actions/common';

import { ChunkUploadStatus } from 'types/common';

interface Progress {
  uploaded: number;
  total: number;
  status: ChunkUploadStatus;
}

interface ChunkResponse {
  id: string;
  url: string;
  offset: number;
  expires: string;
}

type OnStart = () => void;

type OnCreated = (id: string, progress: Progress) => void;

type OnProgress = (id: string, progress: Progress) => void;

type OnComplete = (id: string, data: { md5: string }) => void;

type OnCancel = (id: string) => void;

/**
 * Helper utility for safe slicing files across multiple browsers
 * depending on support
 */
const slice = (file: File, start: number, end: number) => {
  const noop = () => {};
  // @ts-ignore
  let slice = file.mozSlice || file.webkitSlice || file.slice || noop();
  return slice.bind(file)(start, end);
};

class ChunkUpload {
  /**
   * The size of our chunks
   * 1 = 1B
   * 100000 = 100KB
   * 1000000 = 1MB
   * 10000000 = 10MB
   */
  chunkSize: number;

  /**
   * The md5 of the current upload
   */
  md5: string;

  /**
   * The ID of the in progress upload, used to send chunks
   * the the correct endpoint of the API
   */
  uploadId: string | null;

  progress: Progress = {
    uploaded: 0,
    total: 0,
    status: STATUS.created,
  };

  onStart: OnStart;

  onCreated: OnCreated;

  onProgress: OnProgress;

  onComplete: OnComplete;

  onCancel: OnCancel;

  isDisabled: boolean;

  constructor({
    chunkSize,
    onStart = () => {},
    onCreated = () => {},
    onProgress = () => {},
    onComplete = () => {},
    onCancel = () => {},
    isDisabled,
  }: {
    chunkSize?: number;
    onStart: OnStart;
    onCreated: OnCreated;
    onProgress: OnProgress;
    onComplete: OnComplete;
    onCancel: OnCancel;
    isDisabled: boolean;
  }) {
    this.chunkSize = chunkSize || 10000000; // 10MB
    this.md5 = '';
    this.uploadId = null;

    this.onStart = isDisabled ? () => {} : onStart;
    this.onCreated = isDisabled ? () => {} : onCreated;
    this.onProgress = isDisabled ? () => {} : onProgress;
    this.onComplete = isDisabled ? () => {} : onComplete;
    this.onCancel = isDisabled ? () => {} : onCancel;

    this.isDisabled = isDisabled;
  }

  _onProgress = (progress: Partial<Progress>) => {
    this.progress = {
      ...this.progress,
      ...progress,
    };

    if (this.uploadId) {
      this.onProgress(this.uploadId, this.progress);
    }
  };

  calculateMD5 = (file: File) => {
    // Our file turned into a File/Blob slice
    const blobSlice = File.prototype.slice;

    // Calculate the number of chunks
    const chunks = Math.ceil(file.size / this.chunkSize);
    // Initialise a current chunk pointer
    let currentChunk = 0;

    // Initiate our MD5 hash
    const spark = new SparkMD5.ArrayBuffer();

    // Create a FileReader to process the file
    const fileReader = new FileReader();

    // Calculate our md5 hash for the chunked file being uploaded
    fileReader.onload = (e) => {
      if (e.target?.result) {
        // @ts-ignore
        spark.append(e.target.result); // Append chunk
        currentChunk++;
        if (currentChunk < chunks) {
          read_next_chunk();
        } else {
          this.md5 = spark.end();
        }
      }
    };

    fileReader.onerror = function () {
      console.warn('There was an error');
    };

    const read_next_chunk = () => {
      let start = currentChunk * this.chunkSize;
      let end = Math.min(start + this.chunkSize, file.size);
      fileReader.readAsArrayBuffer(blobSlice.call(file, start, end));
    };

    read_next_chunk();
  };

  /**
   * This method allows the adding files using the chunked upload API.
   * The function expects to receive an input element change event
   * and must have a files property
   */
  startUpload = async <T = any>(file: File): Promise<T | void> => {
    if (!this.isDisabled) {
      // Initialise upload with a temporary item to trigger UI change
      this.onStart();
      // Calculate the MD5 of the file for verification of a successful
      // upload upon completion.
      this.calculateMD5(file);
      // Start the upload
      return this.upload(file);
    }

    console.warn(
      'Start Upload failure, User does not have the required permissions.'
    );

    return Promise.resolve();
  };

  /**
   * Send individual PUT requests containing each chunk of the file
   */
  upload = async (file: File): Promise<any> => {
    let size = file.size;
    const filename = file.name;

    let start = 0;

    // Add progress listeners for this chunk upload:
    this._onProgress({
      uploaded: 0,
      total: size,
      status: STATUS.inProgress,
    });

    // Loop through all the chunks and upload to backend
    const _upload = async (): Promise<void> => {
      let end = start + this.chunkSize;

      if (size - end < 0) {
        end = size;
      }

      // Get the latest chunk
      let chunk = slice(file, start, end);

      // If the upload has been cancelled, don't continue
      if (
        this.progress.status === STATUS.cancelled ||
        this.progress.status === STATUS.error
      )
        return;

      // Expose the chunk bytes position range
      const contentRange = `bytes ${start}-${end - 1}/${size}`;

      // Submit the chunk as form data to the API
      const data = new FormData();
      data.append('file', chunk);
      data.append('filename', filename);

      try {
        const response = await fetch(API.cms.chunkUpload(this.uploadId), {
          method: 'PUT',
          credentials: 'include',
          headers: {
            'X-CSRFToken': getCookie('csrftoken'),
            'Content-Range': contentRange,
          },
          body: data,
        });
        const json: ChunkResponse = await response.json();

        // On our first chunk we don't yet have an upload ID as this is set
        // by the initial backend response on upload of the first chunk.
        //
        // Here we set our upload ID based on the initial response from the
        // backend.
        if (!this.uploadId) {
          this.uploadId = json.id;
          this.onCreated(this.uploadId, this.progress);
        }

        // Update our upload progress tracker
        const progress = Math.min(Math.ceil((end / size) * 100), 100);

        // Check whether we still have chunks to upload
        if (end < size) {
          this._onProgress({ uploaded: progress });
          // Reset our start pointer for the next chunk
          start += this.chunkSize;
          // Move on to the next chunk
          return _upload();
        } else {
          return this.completeUpload();
        }
      } catch (e) {
        console.error(e);
        console.log('upload error');
        this._onProgress({ status: STATUS.error });
        return;
      }
    };

    return _upload();
  };

  /**
   * After all the chunks have been uploaded we send a final POST
   * request to our API containing the MD5 hash of our entire file which
   * will be matched on the backend. This is to ensure that no bytes have
   * been lost in transit
   */
  completeUpload = () => {
    const { uploadId, md5 } = this;

    this._onProgress({
      uploaded: 100,
      status: STATUS.completed,
    });

    if (uploadId) {
      return this.onComplete(uploadId, { md5 });
    }
  };

  cancelUpload = () => {
    const { uploadId } = this;
    this._onProgress({
      status: STATUS.cancelled,
    });

    if (uploadId) {
      this.onCancel(uploadId);
    }
  };
}

const useChunkUpload = (isDisabled = false) => {
  const dispatch = useDispatch();

  const _onStart =
    (filename: string, filesize: number, metadata?: Object) => () =>
      dispatch(
        backgroundActions.startChunkUpload({
          filename,
          filesize,
          metadata,
        })
      );

  const _onCreated =
    (filename: string, filesize: number, metadata?: Object) =>
    (id: string, progress: Progress) =>
      dispatch(
        backgroundActions.createChunkUpload({
          id,
          progress: progress.uploaded,
          status: progress.status,
          filename,
          filesize,
          metadata,
        })
      );

  const _onProgress =
    (filename: string, filesize: number) => (id: string, progress: Progress) =>
      dispatch(
        backgroundActions.updateChunkUpload({
          id,
          progress: progress.uploaded,
          status: progress.status,
          filename,
          filesize,
        })
      );

  const onComplete = (id: string, data: { md5: string }) =>
    dispatch(backgroundActions.completeUpload(id, data));

  const onCancel = (id: string) =>
    dispatch(backgroundActions.cancelChunkUpload(id));

  // Chunk upload factory for creating and initialising ChunkUpload's with
  // callbacks used to update our redux store
  const createChunkUpload = (
    filename: string,
    filesize: number,
    metadata?: Object,
    chunkSize?: number
  ) => {
    const onStart = _onStart(filename, filesize, metadata);
    const onCreated = _onCreated(filename, filesize, metadata);
    const onProgress = _onProgress(filename, filesize);

    return new ChunkUpload({
      onStart,
      onCreated,
      onProgress,
      onComplete,
      onCancel,
      chunkSize,
      isDisabled,
    });
  };

  return createChunkUpload;
};

export default useChunkUpload;
